import React from "react";
import Joi from "joi-browser";
import Form from "../common/Form";

class NotesForm extends Form {
  state = {
    errors: {},
  };

  schema = {
    notes: Joi.string().max(255).allow("").label("Notes"),
  };

  handleChange = ({ currentTarget: input }) => {
    const { orderReturn, onChangeNote } = this.props;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (!errorMessage) {
      delete errors[input.name];
      onChangeNote(orderReturn, input.value);
    } else {
      errors[input.name] = errorMessage;
    }

    this.setState({ errors });
  };

  render() {
    const { orderReturn } = this.props;
    return (
      <React.Fragment>
        {this.renderTextarea("notes", "", "Hinweise und Kommentare", "texarea", orderReturn.notes)}
      </React.Fragment>
    );
  }
}

export default NotesForm;
