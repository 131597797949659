import { useState, useContext, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap";
import PurchasingCollapsibleTable from "./PurchasingCollapsibleTable";
import productsService from "../../services/productsService";
import AutoSaveField from "../common/AutoSaveField";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import CombinedChart from "./CombinedChart";
import utils from "../../utils/arrayUtils";
import moment from "moment-timezone";
import logger from "../../services/logService";
import { isSafeToClick } from "../../utils/helper";
import { LocalizationContext, returnLocalizedString } from "../../services/localizationService";
import Checkbox from "../common/Checkbox";

const Purchasing = () => {
  const { current: lang } = useContext(LocalizationContext);
  console.log("🚀 ~ file: Purchasing.jsx ~ line 19 ~ Purchasing ~ lang", lang);

  const columns = [
    { path: "group", label: "#", width: "3%" },
    {
      path: "name",
      label: "Produktname",
      width: "15%",
      content: product => (
        <div>
          <small>
            <Link to={`/warehouse/product/${product.sku}`}>{product.name}</Link>
          </small>
          <br />
          <small>
            <span className="text-muted">SKU </span>
            {product.sku}
          </small>
          <br />
          <small>
            <span className="text-muted">EAN </span>
            {product.ean}
          </small>
        </div>
      ),
    },
    {
      path: "forecast.stockReach",
      label: "Reichweite",
      width: "7%",
      content: product =>
        product.dropshipping === true ? (
          "-"
        ) : product.forecast?.stockReach !== undefined &&
          product.forecast.stockReach !== -1 &&
          product.forecast?.stockReachWithOrdered !== undefined &&
          product.forecast.stockReachWithOrdered !== -1 ? (
          <div>
            <span className={`text-${getColor(product.forecast?.stockReach, product.leadTime, product.leadTime * 2)}`}>
              {`${product.forecast?.stockReach}`} <small>Tage</small>
            </span>
            <br />
            {product.forecast?.stockReachWithOrdered !== product.forecast?.stockReach ? (
              <small
                className={`text-${getColor(
                  product.forecast?.stockReachWithOrdered,
                  product.leadTime,
                  product.leadTime * 2
                )}`}
              >{`${product.forecast?.stockReachWithOrdered} Tage inkl. bestellte`}</small>
            ) : (
              ""
            )}
          </div>
        ) : (
          <small className="text-muted">Keine Verkaufshistorie</small>
        ),
      sortContent: product => {
        return product.forecast?.stockReach ? product.forecast.stockReach : -1;
      },
    },
    {
      path: "inventory",
      label: "Lagerbestand",
      width: "7%",
      content: product => {
        const validFlag = typeof product.history?.stockLevels === "object" && product.history.stockLevels.length;
        const fbaValid = typeof product.history?.stockLevelsFba === "object" && product.history.stockLevelsFba.length;
        const stock = validFlag ? product.history.stockLevels[product.history.stockLevels.length - 1].quantity : 0;
        const fbaStock = fbaValid
          ? product.history.stockLevelsFba[product.history.stockLevelsFba.length - 1].quantity
          : 0;

        // const stockLevel = stock - fbaStock;
        const stockLevel = stock;

        return (
          <Fragment>
            <span title="Gesamter Lagerbestand inkl. FBA Lager">
              {stockLevel !== undefined ? `${stockLevel} Stück` : "Keine Daten"}
            </span>
            {!product.dropshipping && (product.fba || fbaStock > 0) ? <br /> : ""}
            <small className="text-muted">
              {product.dropshipping ? "" : product.fba || fbaStock > 0 ? `inkl. ${fbaStock} FBA Lager` : ""}
            </small>
            {!product.dropshipping ? <br /> : ""}
            <small className="text-muted">{product.dropshipping ? "" : `+ ${product.quantityOrdered} bestellt`}</small>
            <br />
            <small className={product.fbaIncoming ? "text-success" : "text-muted"}>
              {product.dropshipping ? "" : product.fba ? `+ ${product.fbaIncoming} versendet` : ""}
            </small>
          </Fragment>
        );
      },
      sortContent: product => {
        const validFlag = typeof product.history?.stockLevels === "object" && product.history.stockLevels.length;
        const stockLevel = validFlag ? product.history.stockLevels[product.history.stockLevels.length - 1].quantity : 0;
        return stockLevel !== undefined ? stockLevel : "-";
      },
    },
    {
      path: "forecast.averageSales",
      label: "Verkaufsrate",
      width: "7%",
      content: product => {
        const salesCountCurrentMonth = product.forecast?.salesCount?.currentMonth || 0;
        const salesCountLastMonth = product.forecast?.salesCount?.lastMonth || 0;

        return product.forecast && product.forecast.averageSales > -1 ? (
          <Fragment>
            <span title="Durchschnittliche Verkaufsrate auf Basis der Zahlen der letzten 7, 30 und 90 Tage. Tage mit Warenbestand < 10 werden nicht berücksichtigt. Für FBA Artikel gilt der Bestand im FBA Lager, für Dropshipping wird jeder Tag gezählt.">{`~ ${product.forecast.averageSales.toFixed(
              2
            )}/Tag`}</span>
            {salesCountLastMonth && salesCountCurrentMonth ? (
              <div title="Entwicklung letzte 30 Tag vs. die 30 Tage davor, Prozentual und in absoluten Zahlen.">
                <br />
                {renderDevelopment(salesCountCurrentMonth / salesCountLastMonth)}
                <br />
                {salesCountLastMonth} → {salesCountCurrentMonth} Einheiten
              </div>
            ) : (
              ""
            )}
          </Fragment>
        ) : (
          "Keine Verkaufshistorie"
        );
      },
      sortContent: product => {
        return product.forecast?.averageSales ? product.forecast.averageSales : 0;
      },
    },
    {
      path: "forecast.reorderDeadline",
      label: "Bestellen",
      width: "8%",
      content: product => {
        const disabled = disabledCategories.indexOf(product.parentProduct?._id) !== -1;
        return product.dropshipping ? (
          "-"
        ) : isBStock(product) ? (
          "B-Ware"
        ) : (
          <Fragment>
            {disabled || isBStock(product) ? (
              "-"
            ) : (
              <span title="Letztmögliches Bestelldatum, um OOS zu vermeiden. Basiert auf der errechneten Verkaufsrate und der angegebenen Lieferzeit. Bereits bestellte Ware schon berücksichtigt. Bei FBA wird die Auffülldauer mit einbezogen.">
                {moment(product.forecast?.reorderDeadline).tz("Europe/Berlin").format("DD.MM.YYYY")}
              </span>
            )}
            <br />
            {disabled || isBStock(product) ? "" : renderLeadTimeField(product)}
          </Fragment>
        );
      },
      sortContent: product => {
        const date = new Date(product.forecast?.reorderDeadline);
        const disabled = disabledCategories.indexOf(product.parentProduct?._id) !== -1;
        return product.dropshipping || disabled || isBStock(product) ? "sortLast" : date.getTime() || -1;
      },
    },
    {
      path: "forecast.outOfStockStatus",
      label: "Status",
      width: "4%",
      content: product => {
        const status = product.forecast?.outOfStockStatus;
        const disabled = disabledCategories.indexOf(product.parentProduct?._id) !== -1;
        const { color, text, longText } = disabled
          ? {
              color: "secondary",
              text: "INAKTIV",
              longText: "Statusanzeige deaktiviert, klicke auf die Statusanzeige um sie zu reaktivieren",
            }
          : isBStock(product)
          ? {
              color: "brown",
              text: "B-WARE",
              longText: "Statusanzeige deaktiviert, da B-Ware nicht für die Beschaffung relevant ist.",
            }
          : status === 3
          ? { color: "danger", text: "GEFAHR", longText: "Bestellung überfällig, Out of Stock Gefahr!" }
          : status === 2
          ? { color: "warning", text: "BALD", longText: "Jetzt bestellen, Out of Stock Gefahr in Kürze" }
          : status === 1
          ? { color: "success", text: "OK", longText: "Genügend Lagerbestand vorhanden" }
          : { color: "secondary", text: "UNBEKANNT" };

        return product.parentProduct?._id === "Nicht Kategorisiert" || product.dropshipping === true ? (
          "-"
        ) : (
          <h3 className={`badge badge-${color}`} title={longText} onClick={() => toggleActiveState(product)}>
            {text}
          </h3>
        );
      },
      sortContent: product => {
        return product.parentProduct?._id === "Nicht Kategorisiert" ||
          product.dropshipping === true ||
          disabledCategories.indexOf(product.parentProduct?._id) !== -1 ||
          isBStock(product)
          ? "sortLast"
          : product.forecast?.outOfStockStatus;
      },
    },
    {
      path: "forecast.stockReachFba",
      label: "Reichweite FBA",
      width: "8%",
      content: product =>
        product.fba === true ? (
          product.forecast?.stockReachFba !== undefined && product.forecast.stockReachFba !== -1 ? (
            <span
              className={`text-${getColor(
                product.forecast?.stockReachFba,
                product.restockTime,
                product.restockTime * 5
              )}`}
            >{`${product.forecast?.stockReachFba} Tage`}</span>
          ) : (
            "Keine Veraufshistorie"
          )
        ) : (
          "-"
        ),
      sortContent: product => {
        return product.forecast?.stockReachFba ? product.forecast.stockReachFba : -1;
      },
    },
    {
      path: "history.stockLevelsFba",
      label: "Lager FBA",
      width: "12%",
      content: product => {
        const validFlag = typeof product.history?.stockLevelsFba === "object" && product.history.stockLevelsFba.length;
        const stockEntry = product.history.stockLevelsFba[product.history.stockLevelsFba.length - 1];
        const fbaTotal = validFlag ? stockEntry.quantity : 0;
        const fbaReserved = validFlag
          ? typeof stockEntry.quantityReserved === "number"
            ? stockEntry.quantityReserved
            : 0
          : 0;
        const fbaUnsellable = validFlag
          ? typeof stockEntry.quantityUnsellable === "number"
            ? stockEntry.quantityUnsellable
            : 0
          : 0;
        const lineOne = product.fba === true ? (fbaTotal !== undefined ? `${fbaTotal} Stück` : "Keine Daten") : "-";
        const lineTwo =
          product.fba === true
            ? fbaReserved !== undefined && fbaTotal !== undefined && fbaReserved !== null && fbaTotal !== null
              ? `${fbaTotal - fbaReserved - fbaUnsellable} verfügbar`
              : ""
            : "-";
        const lineThree =
          product.fba === true
            ? fbaReserved !== undefined && fbaReserved !== null
              ? `${fbaReserved} reserviert`
              : ""
            : "-";
        const lineFour =
          product.fba === true
            ? fbaUnsellable !== undefined && fbaUnsellable !== null
              ? `${fbaUnsellable} unverkäuflich`
              : ""
            : "-";
        const lineFive =
          product.fba === true
            ? product.fbaIncoming !== undefined && product.fbaIncoming !== null
              ? `+ ${product.fbaIncoming} unterwegs`
              : ""
            : "-";

        return (
          <div>
            {lineOne}
            {product.fba && (
              <Fragment>
                <br />
                <small title="Zum Verkauf verfügbar im FBA Lager" className="text-muted">
                  {lineTwo}
                </small>
                <br />
                <small
                  title="Reservierte Menge (Bewegung zwischen Fulfillment Centers oder Reservierung für Käufe)"
                  className="text-muted"
                >
                  {lineThree}
                </small>
                <br />
                <small title="Menge unverkäuflicher Artikel" className="text-muted">
                  {lineFour}
                </small>
                <br />
                <small
                  title="An Amazon versandte und noch nicht eingelagerte Ware"
                  className={product.fbaIncoming ? "text-success" : "text-muted"}
                >
                  {lineFive}
                </small>
              </Fragment>
            )}
          </div>
        );
      },
      sortContent: product => {
        const validFlag = typeof product.history?.stockLevelsFba === "object" && product.history.stockLevelsFba.length;
        const fbaTotal = validFlag
          ? product.history.stockLevelsFba[product.history.stockLevelsFba.length - 1].quantity
          : 0;
        return fbaTotal;
      },
    },
    {
      path: "forecast.restockDeadlineFba",
      label: "Auffüllen",
      width: "8%",
      content: product => {
        const disabled = disabledCategories.indexOf(product.parentProduct?._id) !== -1;

        return product.fba ? (
          <Fragment>
            {disabled ? (
              "-"
            ) : (
              <span title="Letztmögliches Datum zum Auffüllen des FBA Lagerbestandes, um OOS zu vermeiden. Basiert auf der errechneten Verkaufsrate und der angegebenen Wareneingangszeit.">
                {moment(product.forecast?.restockDeadlineFba).tz("Europe/Berlin").format("DD.MM.YYYY")}
              </span>
            )}
            <br />
            {disabled ? "" : renderRestockTimeField(product)}
          </Fragment>
        ) : (
          "-"
        );
      },
      sortContent: product => {
        const disabled = disabledCategories.indexOf(product.parentProduct?._id) !== -1;
        return disabled || !product.fba ? "sortLast" : product.forecast?.restockDeadlineFba;
      },
    },
    {
      path: "forecast.outOfStockStatusFba",
      label: "Status FBA",
      width: "4%",
      content: product => {
        const status = product.forecast?.outOfStockStatusFba;
        const disabled = disabledCategories.indexOf(product.parentProduct?._id) !== -1;
        const { color, text, longText } = disabled
          ? {
              color: "secondary",
              text: "INAKTIV",
              longText: "Statusanzeige deaktiviert, klicke auf die Statusanzeige um sie zu reaktivieren",
            }
          : status === 3
          ? { color: "danger", text: "GEFAHR", longText: "Nachfüllen überfällig, Out of Stock Gefahr!" }
          : status === 2
          ? { color: "warning", text: "BALD", longText: "Jetzt nachfüllen, Out of Stock Gefahr in Kürze" }
          : status === 1
          ? { color: "success", text: "OK", longText: "Genügend Lagerbestand vorhanden" }
          : { color: "secondary", text: "UNBEKANNT" };

        return !product.fba || product.parentProduct?._id === "Nicht Kategorisiert" || product.dropshipping === true ? (
          "-"
        ) : (
          <h3 className={`badge badge-${color}`} title={longText} onClick={() => toggleActiveState(product)}>
            <i className="fab fa-amazon mr-1"></i>
            {text}
          </h3>
        );
      },
      sortContent: product => {
        return product.forecast?.outOfStockStatusFba && product.fba ? product.forecast.outOfStockStatusFba : -1;
      },
    },
    {
      path: "history",
      label: "",
      width: "20%",
      content: product => <CombinedChart data={product.mergedChartData} fba={product.fba} />,
    },
  ];

  const groupColumns = [
    {
      path: "group",
      label: "#",
      hasGroupToggle: true,
      content: () => <i id="arrowIndicator" className="fas fa-angle-right mr-2"></i>,
      sortContent: () => "0",
    },
    {
      path: "name",
      label: "Produktname",
      content: groupedData => {
        return groupedData._id !== "unassigned" ? returnLocalizedString(groupedData.name, lang) : "Nicht Kategorisiert";
      },
      sortContent: groupedData =>
        groupedData._id !== "unassigned" ? returnLocalizedString(groupedData.name, lang) : "sortLast",
    },
    {
      path: "forecast.stockReach",
      label: "Reichweite",
      content: groupedData => {
        const stockReachArray = groupedData.data?.map(d => d.forecast?.stockReach || -1);
        const stockReachWithOrderedArray = groupedData.data?.map(d => d.forecast?.stockReachWithOrdered || -1);
        return groupedData._id === "unassigned" || groupedData.data?.find(d => d.dropshipping) ? (
          "-"
        ) : (
          <div title="Wenn rot markiert unterschreitet mindestens eine Variante die Mindestreichweite. Diese basiert auf der angegebenen Lieferzeit">
            {renderRange(
              stockReachArray,
              groupedData.data[0].leadTime,
              groupedData.data[0].leadTime * 2,
              stockReachWithOrderedArray
            )}{" "}
            <small>Tage</small>
            <br />
            <small className="text-muted">
              {renderRange(
                stockReachWithOrderedArray,
                groupedData.data[0].leadTime,
                groupedData.data[0].leadTime * 2,
                stockReachWithOrderedArray
              )}{" "}
              Tage inkl. bestellte
            </small>
          </div>
        );
      },
      sortContent: groupedData => {
        const stockReachArray = groupedData.data?.map(d => d.forecast?.stockReach || -1);
        return groupedData._id === "unassigned" || groupedData.data?.find(d => d.dropshipping)
          ? "sortLast"
          : stockReachArray;
      },
    },
    {
      path: "inventory",
      label: "Lagerbestand",
      content: groupedData => {
        const resultsInventory = groupedData.data?.map(d =>
          d.history?.stockLevels && d.history.stockLevels.length
            ? d.history.stockLevels[d.history.stockLevels.length - 1].quantity
            : 0
        );
        const resultsOrdered = groupedData.data?.map(d => d.quantityOrdered || 0);
        const resultsSentToFba = groupedData.data?.map(d => d.fbaIncoming || 0);

        const sum = utils.getSumOfArray(resultsInventory);
        const sumOrdered = utils.getSumOfArray(resultsOrdered);
        const sumSentToFba = utils.getSumOfArray(resultsSentToFba);
        return (
          <Fragment>
            <span title="Gesamter Lagerbestand inkl. FBA Lager">
              {groupedData._id === "unassigned" || groupedData.data.find(d => d.dropshipping) === true
                ? "-"
                : sum + " Stück"}
            </span>
            {!groupedData.data.find(d => d.dropshipping) ? <br /> : ""}
            {
              <Fragment>
                <small
                  title="Offene bestätigte Bestellungen aus ERPNext. Entwürfe werden nicht gezählt."
                  className="text-muted"
                >
                  {groupedData._id === "unassigned" || groupedData.data.find(d => d.dropshipping)
                    ? ""
                    : "+ " + sumOrdered + " bestellt"}
                </small>
                {!groupedData.data.find(d => d.dropshipping) && groupedData.data.find(d => d.fba) ? <br /> : ""}
                <small className="text-success">
                  {groupedData._id === "unassigned" || groupedData.data.find(d => d.dropshipping)
                    ? ""
                    : groupedData.data.find(d => d.fba)
                    ? `+ ${sumSentToFba} versendet`
                    : ""}
                </small>
              </Fragment>
            }
          </Fragment>
        );
      },
      sortContent: groupedData => {
        const resultsInventory = groupedData.data?.map(d =>
          d.history?.stockLevels && d.history.stockLevels.length
            ? d.history.stockLevels[d.history.stockLevels.length - 1].quantity
            : 0
        );

        return groupedData._id === "unassigned" || groupedData.data.find(d => d.dropshipping)
          ? "sortLast"
          : utils.getSumOfArray(resultsInventory);
      },
    },
    {
      path: "forecast.averageSales",
      label: "Verkaufsrate",
      content: groupedData => {
        const results = groupedData.data?.map(d => d.forecast?.averageSales || -1);
        const filtered = results.filter(r => r !== -1);

        const salesCurrentMonth = groupedData.data?.map(d => d.forecast?.salesCount?.currentMonth || 0);
        const salesLastMonth = groupedData.data?.map(d => d.forecast?.salesCount?.lastMonth || 0);
        const salesCountCurrentMonth = utils.getSumOfArray(salesCurrentMonth);
        const salesCountLastMonth = utils.getSumOfArray(salesLastMonth);

        return groupedData._id !== "unassigned" && filtered.length ? (
          <Fragment>
            <span title="Durchschnittliche Verkaufsrate auf Basis der Zahlen der letzten 7, 30 und 90 Tage. Tage mit Warenbestand < 10 werden nicht berücksichtigt. Für FBA Artikel gilt der Bestand im FBA Lager, für Dropshipping wird jeder Tag gezählt.">{`~ ${utils
              .getSumOfArray(filtered)
              .toFixed(2)}/Tag`}</span>
            {salesCountLastMonth && salesCountCurrentMonth ? (
              <div title="Entwicklung letzte 30 Tag vs. die 30 Tage davor, Prozentual und in absoluten Zahlen.">
                <br />
                {renderDevelopment(salesCountCurrentMonth / salesCountLastMonth)}
                <br />
                {salesCountLastMonth} → {salesCountCurrentMonth} Einheiten
              </div>
            ) : (
              ""
            )}
          </Fragment>
        ) : (
          "-"
        );
      },
      sortContent: groupedData => {
        const results = groupedData.data?.map(d => d.forecast?.averageSales || -1);
        const filtered = results.filter(r => r !== -1);
        return groupedData._id !== "unassigned" && filtered.length ? utils.getSumOfArray(filtered) : "sortLast";
      },
    },
    {
      path: "forecast.reorderDeadline",
      label: "Bestellen",
      content: groupedData => {
        const results = groupedData.data?.map(d => new Date(d.forecast?.reorderDeadline).getTime() || -1);
        const min = utils.getMinOfArray(results);
        const disabled = disabledCategories.indexOf(groupedData._id) !== -1;

        if (!min) return "Keine Daten";
        return groupedData._id === "unassigned" || groupedData.data.find(d => d.dropshipping) || disabled ? (
          "-"
        ) : (
          <span title="Letztmögliches Bestelldatum, um OOS zu vermeiden. Basiert auf der errechneten Verkaufsrate und der angegebenen Lieferzeit.">
            {moment(min).tz("Europe/Berlin").format("DD.MM.YYYY")}
          </span>
        );
      },
      sortContent: groupedData => {
        const results = groupedData.data?.map(d => new Date(d.forecast?.reorderDeadline).getTime() || -1);
        const disabled = disabledCategories.indexOf(groupedData._id) !== -1;
        return groupedData._id === "unassigned" || groupedData.data.find(d => d.dropshipping) || disabled
          ? "sortLast"
          : utils.getMinOfArray(results);
      },
    },
    {
      path: "forecast.outOfStockStatus",
      label: "Status",
      content: groupedData => {
        const statusArray = groupedData.data?.map(d => d.forecast?.outOfStockStatus);
        const disabled = disabledCategories.indexOf(groupedData._id) !== -1;
        const highestValue = utils.getMaxOfArray(statusArray);

        const { color, text, longText } = disabled
          ? {
              color: "secondary",
              text: "INAKTIV",
              longText: "Statusanzeige deaktiviert, klicke auf die Statusanzeige um sie zu reaktivieren",
            }
          : highestValue === 3
          ? { color: "danger", text: "GEFAHR", longText: "Bestellung überfällig, Out of Stock Gefahr!" }
          : highestValue === 2
          ? { color: "warning", text: "BALD", longText: "Jetzt bestellen, Out of Stock Gefahr in Kürze" }
          : highestValue === 1
          ? { color: "success", text: "OK", longText: "Genügend Lagerbestand vorhanden" }
          : { color: "secondary", text: "UNBEKANNT" };

        return groupedData._id === "unassigned" || groupedData.data.find(d => d.dropshipping) ? (
          "-"
        ) : (
          <h3 className={`badge badge-${color}`} title={longText} onClick={() => toggleActiveState(groupedData)}>
            {text}
          </h3>
        );
      },
      sortContent: groupedData => {
        const statusArray = groupedData.data?.map(d => d.forecast?.outOfStockStatus);
        const highestValue = utils.getMaxOfArray(statusArray);

        return groupedData._id === "unassigned" ||
          groupedData.data.find(d => d.dropshipping) ||
          disabledCategories.indexOf(groupedData._id) !== -1
          ? "sortLast"
          : highestValue;
      },
    },
    {
      path: "forecast.stockReachFba",
      label: "Reichweite FBA",
      content: groupedData => {
        const fbaStockReachArray = groupedData.data?.map(d => (d.fba && d.forecast?.stockReachFba) || -1);
        return !groupedData.data.find(d => d.fba) ||
          groupedData._id === "unassigned" ||
          groupedData.data.find(d => d.dropshipping) ? (
          "-"
        ) : (
          <Fragment>{renderRange(fbaStockReachArray, groupedData.data[0].restockTime)} Tage</Fragment>
        );
      },
      sortContent: groupedData => {
        const fbaStockReachArray = groupedData.data?.map(d => (d.fba && d.forecast?.stockReachFba) || -1);
        return !groupedData.data.find(d => d.fba) ||
          groupedData._id === "unassigned" ||
          groupedData.data.find(d => d.dropshipping)
          ? "sortLast"
          : fbaStockReachArray;
      },
    },
    {
      path: "history.stockLevelsFba",
      label: "Lager FBA",
      content: groupedData => {
        const latestStockLevelsFba = { quantity: [], quantityReserved: [], quantityUnsellable: [] };
        groupedData.data.forEach(d => {
          const stockLevelsFba = d.history?.stockLevelsFba;
          const isValid = utils.isValidArray(d.history?.stockLevelsFba);
          const stockEntry = stockLevelsFba[stockLevelsFba.length - 1];

          latestStockLevelsFba.quantity.push(isValid ? stockEntry.quantity : 0);
          latestStockLevelsFba.quantityReserved.push(
            isValid && typeof stockEntry.quantityReserved === "number" ? stockEntry.quantityReserved : 0
          );
          latestStockLevelsFba.quantityUnsellable.push(
            isValid && typeof stockEntry.quantityUnsellable === "number" ? stockEntry.quantityUnsellable : 0
          );
        });

        const fbaIncoming = groupedData.data?.map(d => d.fbaIncoming || 0);

        const fbaTotalSum = utils.getSumOfArray(latestStockLevelsFba.quantity);
        const fbaReservedSum = utils.getSumOfArray(latestStockLevelsFba.quantityReserved);
        const fbaUnsellableSum = utils.getSumOfArray(latestStockLevelsFba.quantityUnsellable);
        const fbaIncomingSum = utils.getSumOfArray(fbaIncoming);

        const lineOne = groupedData.data.find(d => d.fba) ? `${fbaTotalSum} Stück` : "-";
        const lineTwo = groupedData.data.find(d => d.fba)
          ? `${fbaTotalSum - fbaReservedSum - fbaUnsellableSum} verfügbar`
          : "-";
        const lineThree = groupedData.data.find(d => d.fba) ? `${fbaReservedSum} reserviert` : "-";
        const lineFour = groupedData.data.find(d => d.fba) ? `${fbaUnsellableSum} unverkäuflich` : "-";
        const lineFive = groupedData.data.find(d => d.fba) ? `+ ${fbaIncomingSum} unterwegs` : "-";
        return (
          <div>
            {lineOne}
            {groupedData.data.find(d => d.fba) && (
              <Fragment>
                <br />
                <small title="Zum Verkauf verfügbar im FBA Lager" className="text-muted">
                  {lineTwo}
                </small>
                <br />
                <small
                  title="Reservierte Menge (Bewegung zwischen Fulfillment Centers oder Reservierung für Käufe)"
                  className="text-muted"
                >
                  {lineThree}
                </small>
                <br />
                <small title="Menge unverkäuflicher Artikel" className="text-muted">
                  {lineFour}
                </small>
                <br />
                <small
                  title="An Amazon versandte und noch nicht eingelagerte Ware"
                  className={fbaIncomingSum ? "text-success" : "text-muted"}
                >
                  {lineFive}
                </small>
              </Fragment>
            )}
          </div>
        );
      },
      sortContent: groupedData => {
        const latestStockLevelsFba = groupedData.data?.map(d => {
          const stockLevelsFba = d.history?.stockLevelsFba;
          const isValid = utils.isValidArray(d.history?.stockLevelsFba);
          return isValid ? stockLevelsFba[stockLevelsFba.length - 1].quantity : 0;
        });

        return utils.getSumOfArray(latestStockLevelsFba);
      },
    },
    {
      path: "forecast.restockDeadlineFba",
      label: "Auffüllen",
      content: groupedData => {
        const results = groupedData.data?.map(d => (d.fba && new Date(d.forecast?.restockDeadlineFba).getTime()) || -1);
        return groupedData._id === "Nicht Kategorisiert" || !groupedData.data.find(d => d.fba) ? (
          "-"
        ) : (
          <span title="Letztmögliches Datum zum Auffüllen des FBA Lagerbestandes, um OOS zu vermeiden. Basiert auf der errechneten Verkaufsrate und der angegebenen Wareneingangszeit.">
            {moment(utils.getMinOfArray(results)).tz("Europe/Berlin").format("DD.MM.YYYY")}
          </span>
        );
      },
      sortContent: groupedData => {
        const results = groupedData.data?.map(d => (d.fba && new Date(d.forecast?.restockDeadlineFba).getTime()) || -1);
        return groupedData._id === "Nicht Kategorisiert" || !groupedData.data.find(d => d.fba)
          ? "sortLast"
          : utils.getMinOfArray(results);
      },
    },
    {
      path: "forecast.outOfStockStatusFba",
      label: "Status FBA",
      content: groupedData => {
        const outOfStockStatusFbaArray = groupedData.data?.map(d => (d.fba && d.forecast?.outOfStockStatusFba) || -1);
        const disabled = disabledCategories.indexOf(groupedData._id) !== -1;
        const maxStatus = utils.getMaxOfArray(outOfStockStatusFbaArray);
        const { color, text, longText } = disabled
          ? {
              color: "secondary",
              text: "INAKTIV",
              longText: "Statusanzeige deaktiviert, klicke auf die Statusanzeige um sie zu reaktivieren",
            }
          : maxStatus === 3
          ? { color: "danger", text: "GEFAHR", longText: "Nachfüllen überfällig, Out of Stock Gefahr!" }
          : maxStatus === 2
          ? { color: "warning", text: "BALD", longText: "Jetzt nachfüllen, Out of Stock Gefahr in Kürze" }
          : maxStatus === 1
          ? { color: "success", text: "OK", longText: "Genügend Lagerbestand vorhanden" }
          : { color: "secondary", text: "UNBEKANNT" };

        return !groupedData.data.find(d => d.fba) ||
          groupedData._id === "unassigned" ||
          groupedData.data.find(d => d.dropshipping) ? (
          "-"
        ) : (
          <h3 className={`badge badge-${color}`} title={longText} onClick={() => toggleActiveState(groupedData)}>
            <i className="fab fa-amazon mr-1"></i>
            {text}
          </h3>
        );
      },
      sortContent: groupedData => {
        const outOfStockStatusFbaArray = groupedData.data?.map(d => (d.fba && d.forecast?.outOfStockStatusFba) || -1);

        return !groupedData.data.find(d => d.fba) ||
          groupedData._id === "unassigned" ||
          groupedData.data.find(d => d.dropshipping) ||
          disabledCategories.indexOf(groupedData._id) !== -1
          ? "sortLast"
          : utils.getMaxOfArray(outOfStockStatusFbaArray);
      },
    },
    {
      path: "history",
      label: "",
      content: groupedData => <CombinedChart data={groupedData.groupData} fba={groupedData.data.find(d => d.fba)} />,
    },
  ];

  function toggleActiveState(element) {
    const categoriesCopy = [...disabledCategories];
    const index = categoriesCopy.indexOf(element._id);
    if (index === -1) categoriesCopy.push(element._id);
    else categoriesCopy.splice(index, 1);
    localStorage.setItem("disabledCategories", JSON.stringify(categoriesCopy));
    setDisabledCategories(categoriesCopy);
  }

  function isBStock(product) {
    if (!product?.sku) return false;
    return product.sku.substring(product.sku.length - 2, product.sku.length) === "-U" ? true : false;
  }

  function getColor(value, minThreshold, maxThreshold) {
    return value < minThreshold ? "danger" : value > maxThreshold ? "danger" : "black";
  }

  function renderRestockTimeField(product) {
    return (
      <Fragment>
        <div style={{ width: "80px" }}>
          <AutoSaveField
            name="restockTime"
            label=""
            placeholder=""
            type="number"
            value={product.restockTime}
            onFocus={() => handleBackupRestockTime(product)}
            onChange={newValue => handleChangeRestockTime(product, newValue)}
            onSave={newValue => handleSaveRestockTime(product, newValue)}
            joiSchema={{
              restockTime: Joi.number().required().min(0).max(999).label("Verarbeitungszeit"),
            }}
          />
        </div>
        <small className="text-muted">{product.restockTime === 1 ? "Tag" : "Tage"} Eingang</small>
      </Fragment>
    );
  }

  function renderLeadTimeField(product) {
    const { leadTime, history } = product;
    const tooltipContent =
      history && history.leadTimes && typeof history.leadTimes === "object"
        ? Object.keys(history.leadTimes)?.map(k => (
            <Fragment key={k}>
              {`${history.leadTimes[k].name}: `}
              {renderRange(history.leadTimes[k].leadTimes)}
              <br />
            </Fragment>
          ))
        : null;
    return (
      <Fragment>
        <div style={{ width: "80px" }}>
          <AutoSaveField
            name="leadTime"
            label=""
            placeholder=""
            type="number"
            value={leadTime}
            onFocus={() => handleBackupLeadTime(product)}
            onChange={newValue => handleChangeLeadTime(product, newValue)}
            onSave={newValue => handleSaveLeadTime(product, newValue)}
            joiSchema={{
              leadTime: Joi.number().required().min(0).max(999).label("Lieferzeit"),
            }}
          />
        </div>
        <OverlayTrigger placement="top" delay={{ show: 50, hide: 400 }} overlay={renderTooltip(tooltipContent)}>
          <small className="text-muted">{leadTime === 1 ? "Tag" : "Tage"} Lieferzeit</small>
        </OverlayTrigger>
      </Fragment>
    );
  }

  function renderRange(dataArray, minThreshold) {
    const filteredDataArray = dataArray.filter(d => d !== -1);
    const min = utils.getMinOfArray(filteredDataArray);
    const max = utils.getMaxOfArray(filteredDataArray);

    const minDanger = utils.getMinOfArray(filteredDataArray) < minThreshold;
    const maxDanger = utils.getMaxOfArray(filteredDataArray) < minThreshold;

    return min === max ? (
      <span className={minDanger ? "text-danger" : ""}>{min || 0}</span>
    ) : (
      <Fragment>
        <span className={minDanger ? "text-danger" : ""}>{min || 0}</span>
        <span className={minDanger && maxDanger ? "text-danger" : ""}>-</span>
        <span className={maxDanger ? "text-danger" : ""}>{max || 0}</span>
      </Fragment>
    );
  }

  function renderTooltip(content, props) {
    return content ? (
      <Tooltip id="button-tooltip" {...props}>
        {content}
      </Tooltip>
    ) : (
      <Tooltip style={{ display: "none" }} />
    );
  }

  function renderDevelopment(dev) {
    dev = Math.abs(dev);
    return (
      <Fragment>
        {dev > 1 ? (
          <i className="fas fa-arrow-up text-success"></i>
        ) : dev < 1 ? (
          <i className="fas fa-arrow-down text-danger"></i>
        ) : (
          <i className="fas fa-arrow-right text-warning"></i>
        )}
        <small className="text-muted">{` ${dev < 1 ? "" : "+"}${((dev - 1) * 100).toFixed(0)}%`}</small>
      </Fragment>
    );
  }

  async function refreshData() {
    setIsRefreshing(true);
    setMessage("Aktualisiere...");
    const result = await productsService.refreshAnalysisData();
    setIsRefreshing(false);
    if (!result) return setMessage("Update fehlgeschlagen.");
    setLastUpdated(result);
    setMessage("");
  }

  async function deepRefreshData() {
    setIsRefreshing(true);
    setMessage("Aktualisiere...");
    const result = await productsService.refreshAnalysisData(true);
    setIsRefreshing(false);
    if (!result) return setMessage("Update fehlgeschlagen.");
    setLastUpdated(result);
    setMessage("");
  }

  const [sortColumn, setSortColumn] = useState({ path: "forecast.reorderDeadline", order: "asc" });
  const [productList, setProductList] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [message, setMessage] = useState("");
  const [backupRestockTime, setBackupRestockTime] = useState(-1);
  const [backupLeadTime, setBackupLeadTime] = useState(-1);
  const [disabledCategories, setDisabledCategories] = useState(
    JSON.parse(localStorage.getItem("disabledCategories")) || []
  );
  const [hideBstock, setHideBstock] = useState(JSON.parse(localStorage.getItem("bStockHidden")) || false);
  const [hideBundles, setHideBundles] = useState(JSON.parse(localStorage.getItem("bundlesHidden")) || true);

  //Load List Content
  useEffect(() => {
    console.log("Loading Products data...");
    let isMounted = true; // note this flag denote mount status
    async function loadProductList() {
      setIsRefreshing(true);
      const productList = await productsService.getProductAnalysisList(60, hideBstock, hideBundles);
      productList?.map(p => p);
      const enhancedProductList = productList?.map(p => {
        for (const c of columns) if (c.content && c.sortContent) p["sortContent." + c.path] = c.sortContent(p);
        return p;
      });

      const groupedList = productsService.groupList(enhancedProductList);

      const enhancedList = groupedList?.map(group => {
        groupColumns.forEach(col => {
          if (col.content && col.sortContent) group["sortContent." + col.path] = col.sortContent(group);
        });
        return group;
      });

      enhancedList.forEach(el =>
        el.data.forEach(eld => {
          if (typeof eld.ean === "object") {
            eld.ean = null;
          }
        })
      );

      if (isMounted) {
        setProductList(enhancedList);
        setIsRefreshing(false);
        console.log("Finished Loading Products data...");
      }
    }
    loadProductList();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated, disabledCategories, hideBstock, hideBundles]);

  const onSort = sortColumn => {
    setSortColumn(sortColumn);
  };

  function handleBackupLeadTime(product, newValue) {
    const productInstance = findProduct(productList, product._id);
    setBackupLeadTime(productInstance.leadTime);
  }

  function handleChangeLeadTime(product, newValue) {
    const productListCopy = [...productList];
    const productInstance = findProduct(productListCopy, product._id);
    productInstance.leadTime = newValue;
    setProductList(productListCopy);
  }

  async function handleSaveLeadTime(product, newValue) {
    try {
      await productsService.saveLeadTime(product._id, newValue);
      setMessage(`Lieferzeiten verändert, klicke "Daten aktualisieren" um die Prognosen neu zu berechnen.`);
    } catch (err) {
      toast.error("Konnte den Wert nicht anpassen.");
      logger.error("There was a problem saving the value");
      handleChangeLeadTime(product, backupLeadTime);
    }
  }

  function handleBackupRestockTime(product, newValue) {
    const productInstance = findProduct(productList, product._id);
    setBackupRestockTime(productInstance.restockTime);
  }

  function handleChangeRestockTime(product, newValue) {
    const productListCopy = [...productList];
    const productInstance = findProduct(productListCopy, product._id);
    productInstance.restockTime = newValue;
    setProductList(productListCopy);
  }

  async function handleSaveRestockTime(product, newValue) {
    try {
      await productsService.saveRestockTime(product._id, newValue);
      setMessage(`Einlagerungszeiten verändert, klicke "Daten aktualisieren" um die Prognosen neu zu berechnen.`);
    } catch (err) {
      toast.error("Konnte den Wert nicht anpassen.");
      logger.error("There was a problem saving the value. ", err);
      handleChangeRestockTime(product, backupRestockTime);
    }
  }

  async function handleToggleHideBstock() {
    setHideBstock(!hideBstock);
    localStorage.setItem("bStockHidden", JSON.stringify(!hideBstock));
  }

  async function handleToggleHideBundles() {
    setHideBundles(!hideBundles);
    localStorage.setItem("bundlesHidden", JSON.stringify(!hideBundles));
  }

  function findProduct(theObject, productId) {
    var result = null;
    if (theObject instanceof Array) {
      for (var i = 0; i < theObject.length; i++) {
        result = findProduct(theObject[i], productId);
        if (result) {
          break;
        }
      }
    } else {
      for (var prop in theObject) {
        if (prop === "_id") {
          if (theObject[prop] === productId) {
            return theObject;
          }
        }
        if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
          result = findProduct(theObject[prop], productId);
          if (result) {
            break;
          }
        }
      }
    }
    return result;
  }

  function getPageData() {
    const categoriesSorted = utils.orderByWithIntercept(
      productList,
      "sortContent." + sortColumn.path,
      sortColumn.order,
      (item, sortOrder) => {
        return item && typeof item === "object"
          ? sortOrder === "asc"
            ? Math.min(...stripNegative(item))
            : Math.max(...stripNegative(item))
          : item;

        function stripNegative(array) {
          return array.filter(i => typeof i === "number" && i >= 0);
        }
      }
    );
    categoriesSorted.forEach(c => {
      c.data = utils.orderByWithIntercept(c.data, "sortContent." + sortColumn.path, sortColumn.order, null);
    });
    return categoriesSorted;
  }

  return (
    <div className="container-fluid spacing-top">
      <div className="row justify-content-between align-items-center mb-5">
        <div className="col col-auto">
          <h1>Produkte & Analyse</h1>
        </div>
        <div className="col col-auto">
          <div className="row justify-content-end align-items-center">
            <div className="col col-auto">{message && <div className="alert alert-warning mb-0">{message}</div>}</div>
            <div className="col col-auto">
              <Checkbox
                name="hideBstock"
                id="hideBstock"
                label="B-Ware ausblenden"
                isSelected={hideBstock}
                onCheckboxChange={handleToggleHideBstock}
              />
              <Checkbox
                name="hideBundles"
                id="hideBundles"
                label="Bundles ausblenden"
                isSelected={hideBundles}
                onCheckboxChange={handleToggleHideBundles}
              />
            </div>
            <div className="col col-auto">
              <ButtonGroup aria-label={`Refresh-Gruppe`}>
                <button
                  className="btn btn-light"
                  title="Aktualisiert alle in ERPNext veränderten Daten und berechnet alle Werte neu. Änderungen an Produkten, sowie Änderungen in anderen Externen Systemen werden nicht aktualisiert."
                  onClick={refreshData}
                >
                  <i className={`fas fa-redo mr-2 ${isRefreshing ? "fa-spin" : ""}`}></i>Daten aktualisieren
                  {isSafeToClick(true)}
                </button>
                <DropdownButton
                  disabled={isRefreshing}
                  alignRight
                  as={ButtonGroup}
                  title=""
                  id="bg-nested-dropdown"
                  variant="light"
                >
                  <Dropdown.Item onClick={deepRefreshData}>
                    Deep Update (dauert 5-10 Minuten){isSafeToClick(true)}
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <PurchasingCollapsibleTable
            sticky={true}
            columns={columns}
            groupColumns={groupColumns}
            groupedData={getPageData()}
            getId={item => item._id}
            sortColumn={sortColumn}
            onSort={onSort}
            style={isRefreshing ? { opacity: 0.5 } : { opacity: 1.0 }}
          />
        </div>
      </div>
    </div>
  );
};

export default Purchasing;
