import http from "./httpService";
import { toast } from "react-toastify";
import logger from "../services/logService";
import { getCurrentUser } from "../services/authService";
import { printReturnLabel } from "./warehouseService";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getOpenReturns() {
  const amazonResult = await http.get(apiUrl + "returns/amazon");
  const amazonReturns = mapFields(amazonResult.data);

  const preannouncedResult = await http.get(apiUrl + "returns/?received=false&sortBy=-modifiedAt");
  const preannouncedReturns =
    preannouncedResult && preannouncedResult.data && preannouncedResult.data.returns
      ? mapFieldsPreannounced(preannouncedResult.data.returns)
      : [];

  const filteredPreannounced = preannouncedReturns.filter(
    p => !p.orderNumber.includes("-Shipping-") && !p.orderNumber.includes("-S-")
  );
  return [...filteredPreannounced, ...amazonReturns];
}

/**
 *
 * @param {boolean} excludeInspected Defaults to false, if set to true excludes the receives that have already been inspected.
 */
export async function getReturns(
  sortBy,
  pageNumber,
  pageSize,
  searchQuery,
  excludeInspected = false,
  onlyInspected = false
) {
  const endpointName = "returns";

  const result = await http.get(
    `${apiUrl}${endpointName}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}${searchQuery ? "&searchQuery=" + searchQuery : ""
    }${excludeInspected ? "&excludeInspected=true" : ""}${onlyInspected ? "&onlyInspected=true" : ""}`
  );
  //data contains { returns: [Array], totalCount: Number, ...}
  return result.data;
}

/**
 *
 * @param {boolean} excludeInspected Defaults to false, if set to true excludes the receives that have already been inspected.
 */
export async function getReceivedReturns(
  sortBy,
  pageNumber,
  pageSize,
  searchQuery,
  combineOrders = false,
  excludeInspected = false,
  onlyInspected = false,
  excludeRefunded = false,
  onlyRefunded = false,
  includePreannouncedWhenPartiallyReceived = false
) {
  const endpointName = "returns";

  const result = await http.get(
    `${apiUrl}${endpointName}?received=true&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}${searchQuery ? "&searchQuery=" + searchQuery : ""
    }${combineOrders ? "&combineOrders=true" : ""}${excludeInspected ? "&excludeInspected=true" : ""}${onlyInspected ? "&onlyInspected=true" : ""
    }${excludeRefunded ? "&excludeRefunded=true" : ""}${onlyRefunded ? "&onlyRefunded=true" : ""}${includePreannouncedWhenPartiallyReceived ? "&includePreannouncedWhenPartiallyReceived=true" : ""}`
  );
  return result.data;
}

export async function getReceivedReturn(receiveId, barcode = false) {
  const endpointName = "returns";

  const result = await http.get(`${apiUrl}${endpointName}/${barcode ? receiveId + "?barcode=true" : receiveId}`);
  return result.data;
}

export async function deleteReceivedReturn(_id) {
  return http.delete(`${apiUrl}returns/${_id}`);
}

function mapFields(inputArray) {
  const mappedArray = inputArray.map(report => {
    return {
      name: report.name,
      type: "amazonReturnRequest",
      falseDelivery: false,
      orderNumber: report["Order ID"],
      productName: "",
      notes: "",
      quantity: report["Return quantity"],
      sku: report["Merchant SKU"],
      orderId: report.billbeeId,
      orderDetails: { loading: true, data: null },
    };
  });
  return mappedArray;
}

function mapFieldsPreannounced(inputArray) {
  const mappedArray = inputArray.map(r => {
    return {
      name: r.order.customer.name,
      type: "preannouncement",
      falseDelivery: r.falseDelivery || false,
      orderNumber: r.order.orderNumber,
      productName: r.product.name,
      quantity: r.product.count,
      notes: r.notes || "",
      sku: r.product.sku,
      orderId: parseInt(r.order.billbeeId),
      orderDetails: { loading: true, data: null },
    };
  });
  return mappedArray;
}

export async function preannounceOrderReturn(
  orderNumber,
  sku,
  count,
  notes,
  refundDeduction = 0,
  refundRequired = true,
  falseDelivery = false
) {
  const endpointName = "returns/preannounce";

  const body = {
    sku,
    orderNumber,
    count,
    notes,
    falseDelivery,
    refundDeduction,
    refundRequired,
  };

  try {
    const result = await http.post(apiUrl + endpointName, body);
    if (!result) return logger.log("Could not preannounce OrderReturn");
    return result;
  } catch (err) {
    if (err && err.response && err.response.status === 409) toast.error(err.response.data);
    else throw err;
  }
}

export async function receiveProduct(
  orderNumber,
  sku,
  userId,
  notes,
  refundDeduction,
  refundRequired,
  falseDelivery = false
) {
  const endpointName = "returns";

  const body = {
    sku,
    orderNumber,
    refund: {
      user: userId,
      refundDeduction,
      refundRequired,
    },
    count: 1,
    userId,
    falseDelivery,
    notes,
  };
  const result = await http.post(apiUrl + endpointName, body);

  if (!result) return logger.log("Could not receive Product");

  if (result.data.toastMessage) toast.warning(result.data.toastMessage);

  await printReturnLabel(result.data.barcode);

  return result;
}

export async function updateReceivedProduct(receiveId, sku) {
  const body = {
    sku: sku,
  };

  try {
    const result = await http.put(apiUrl + "returns/" + receiveId, body);
    if (!result) return logger.log("Could not update received product.");
    return result;
  } catch (err) {
    toast.error(err.response.data);
  }
}

export async function markAsRefunded(orderReturns) {
  const { _id: userId } = getCurrentUser();

  try {
    for (const o of orderReturns) {
      await http.post(apiUrl + "returns/mark-refunded/" + o._id, { userId });
    }
  } catch (err) {
    toast.error(
      "Beim Versuch die Rücksendung als erstattet zu markieren ist ein Fehler aufgetreten. " + err.response.data || ""
    );
    for (const o of orderReturns) {
      await http.post(apiUrl + "returns/mark-refunded/?reverse=true" + o._id);
    }
  }
}

export async function refund(orderReturns, amount) {
  try {
    const timestamp = Date.now();
    const body = {
      orderReturnIds: orderReturns.map(o => o._id),
      amount,
      timestamp,
    };

    await http.post(apiUrl + "returns/authorize-refund/", body);

    return timestamp;
  } catch (err) {
    logger.error("Error while authorizing refund: ", err);
    if (err.response?.data) toast.error("Fehler bei der Ausführung der Gutschrift. " + err.response.data);
    else if (err.message === "Network Error")
      toast.error(
        `Die Ausführung hat zu lange gedauert, war aber höchstwahrscheinlich erfolgreich. Bitte prüfe, ob eine Gutschrift für die Bestellung ${orderReturns[0].order?.orderNumber} in Billbee erstellt wurde, und prüfe in einigen Minuten, ob die Erstattung über Unzer ausgeführt wurde. Die Ausführung der Gutschrift ist dort erst nach ca. 5-10 Minuten sichtbar. Markiere anschließend die Erstattung als erledigt.`,
        { autoClose: false, closeButton: true, closeOnClick: false }
      );
    else {
      toast.error("Fehler beim Ausführen der Gutschrift, bitte wende dich an den Administrator! " + err.message);
      logger.error("Error while executing refund, probably Network Error, please check!", err);
    }
  }
}

export async function getRefundStatus(timestamp) {
  //Is it ready?
  return await http.get(apiUrl + `returns/authorize-refund/${timestamp}`);
}

export async function updateNotes(receiveId, notes) {
  const endpointName = "returns";
  const body = { notes };
  const result = await http.put(apiUrl + endpointName + "/" + receiveId + "?field=notes", body);
  if (!result) return logger.error("Could not update received product.");

  return result;
}

export async function saveInspectionResult(orderReturn, inspectionResult, notes) {
  //´
  const endpointName = "returns/inspection";
  const { _id: userId } = getCurrentUser();

  const body = {
    receiveId: orderReturn._id,
    userId,
    inspectionResult,
    notes,
  };

  let saveInspectionResult;

  if (orderReturn.inspection) {
    saveInspectionResult = await http.put(apiUrl + endpointName, body);
    toast("Zustand erfolgreich verändert");
  } else {
    saveInspectionResult = await http.post(apiUrl + endpointName, body);
    if (!saveInspectionResult) return logger.error("Could not create inspection in database.");
  }

  if (inspectionResult !== "stock" && inspectionResult !== "stockUsed") return saveInspectionResult; //continue only if something is put in stock...

  const adjustedSku =
    orderReturn.product.sku.substring(orderReturn.product.sku.length - 2) !== "-U"
      ? `${orderReturn.product.sku}${inspectionResult === "stockUsed" ? "-U" : ""}`
      : inspectionResult === "stockUsed"
        ? orderReturn.product.sku
        : orderReturn.product.sku.substring(0, orderReturn.product.sku.length - 2);

  if (adjustedSku === orderReturn.product.sku) return;

  try {
    await updateReceivedProduct(orderReturn._id, adjustedSku);
  } catch (err) {
    toast.error("Zustand konnte nicht erfasst werden, bitte wende dich an den Administrator. " + err.response.data);
    if (!orderReturn.inspection)
      saveInspectionResult = await http.delete(apiUrl + endpointName + "/" + orderReturn._id);
  }
}

export async function updateRefundDetails(_id, body) {
  try {
    await http.put(apiUrl + "returns/refund-details/" + _id, body);
  } catch (err) {
    toast.error("Änderungen konnten nicht gespeichert werden. " + err.response?.data);
  }
}

const returnsService = {
  getOpenReturns,
  receiveProduct,
  getReturns,
  getReceivedReturns,
  getReceivedReturn,
  updateNotes,
  deleteReceivedReturn,
  updateReceivedProduct,
  saveInspectionResult,
  preannounceOrderReturn,
  markAsRefunded,
  refund,
  getRefundStatus,
  updateRefundDetails,
};

export default returnsService;
