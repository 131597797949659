import React from "react";
import Checkbox from "./Checkbox";
import _ from "lodash";

interface Data {
  [x: string]: boolean;
}

interface Col {
  key: string;
  label: string;
}

interface MatrixSelectProps {
  rowLabels: string[];
  cols: Col[];
  data: Data[];
  onChange: (newValue: Data[]) => void;
}

const MatrixSelect = ({ rowLabels, cols, data, onChange }: MatrixSelectProps) => {
  const handleChange = (i: number, col: string) => {
    const newValue = _.cloneDeep(data);
    newValue[i][col] = !data[i][col];
    onChange(newValue);
  };

  return (
    <div className="form-row">
      <div className="col-auto">
        {rowLabels.map(rowLabel => (
          <div key={rowLabel} className="form-row">
            <div className="col-auto">{rowLabel}:</div>
          </div>
        ))}
      </div>

      {cols.map(col => (
        <div key={col.key} className="col">
          {rowLabels.map((rowLabel, i) => {
            const identifier = `${rowLabel}-${col.key}`;
            return (
              <div key={identifier} className="form-row">
                <div className="col">
                  <Checkbox
                    name={identifier}
                    id={identifier}
                    label={col.label}
                    isSelected={data[i][col.key]}
                    onCheckboxChange={() => handleChange(i, col.key)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default MatrixSelect;
