import React, { Fragment } from "react";
import warehouseService from "../../services/warehouseService";
import ProductLocations from "./ProductLocations";
import PrintButton from "./PrintButton";

const ProductContent = ({ data }) => {
  // eslint-disable-next-line
  const { name, ean, sku, bStock, totalQuantity, availablePickingQuantity } = data || {};

  const handlePrintProductLabel = async () => {
    await warehouseService.printProductLabel(sku);
  };

  return (
    <main className="container-fluid spacing-top">
      <div className="row">
        <div className="col">
          <p className="text-light-gray mb-0">Produkt</p>
          <h3 className="mb-2">
            <b>{`${name}`}</b>
          </h3>
          <p className="text-muted">
            {ean ? (
              <Fragment>
                <i className="fas fa-barcode text-muted" style={{ marginLeft: "2px", marginRight: "2px" }}></i>
                <span className="text-muted"> EAN</span> {ean} <br />
              </Fragment>
            ) : (
              ""
            )}
            {sku ? (
              <Fragment>
                <i className="fas fa-pallet text-muted"></i>
                <span className="text-muted"> SKU</span> {sku} <br />
              </Fragment>
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="col-auto mb-2">
          <PrintButton onPrint={handlePrintProductLabel} />
        </div>
      </div>
      <h5 className="text-light-gray">Picking-Platz</h5>
      <ProductLocations product={data} />
      {/* <Contents storageUnit={data} onEditReceive={() => onReceive()} receiveDate={receiveDate} /> */}
      {/* <Location location={location} className="mb-5" /> */}
    </main>
  );
};

export default ProductContent;
