import React, { Fragment } from "react";
import { format } from "date-fns";

const style = {
  padding: 6,
  backgroundColor: "#fff",
  border: "1px solid #ccc"
};

function toolTipText(key, value, stockFactor) {
  switch (key) {
    case "quantity":
      return <small>{`Einheiten: ${value.toFixed(0)}`}</small>;
    case "revenue":
      return <small>{`Umsatz: ${value.toFixed(2).replace(".", ",")} €`}</small>;
    case "tax":
      return <small>{`Umsatzsteuer: ${value.toFixed(2).replace(".", ",")} €`}</small>;
    case "stockLevels":
      return <small>{`Lager Gesamt: ${(value * stockFactor).toFixed(0)}`}</small>;
    case "stockLevelsFba":
      return <small>{`Lager FBA: ${(value * stockFactor).toFixed(0)}`}</small>;
    default:
      return <span>{`${key}: ${value}`}</span>;
  }
}

const CustomTooltip = ({ active, payload, plotKeys, stockFactor }) => {
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <div className="area-chart-tooltip" style={style}>
        {currData ? format(new Date(currData.date), "dd. MMM yyyy") : " -- "}
        <br />
        {currData && currData.total && (
          <small>
            {`${currData.total} Einheiten`}
            <br />
          </small>
        )}
        {currData &&
          plotKeys.map(k =>
            currData[k] ? (
              <Fragment key={k}>
                {toolTipText(k, currData[k], stockFactor)}
                <br />
              </Fragment>
            ) : (
              ""
            )
          )}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
