import React, { useEffect, useState, Fragment } from "react";
import warehouseService from "../../services/warehouseService";
import _ from "lodash";
import { Link } from "react-router-dom";
import StorageList from "./StorageList";

const ProductLocations = ({ product }) => {
  // eslint-disable-next-line
  const { sku } = product;

  const [locations, setLocations] = useState([]);
  const [storageUnits, setStorageUnits] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await warehouseService.loadProductLocations(sku);
      if (!result) return;
      const { locations, storageUnits } = result;

      setLocations(locations);

      const sortedStorageUnits = _.orderBy(storageUnits, o => o.receiveDate, "asc");
      setStorageUnits(sortedStorageUnits);
    })();
  }, [sku]);

  return (
    <Fragment>
      {locations &&
        locations.map((l, i) => (
          <ul key={`locationGroup-${i}`} className="list-group mb-3">
            <li className="list-group-item" style={{ fontSize: "24px" }}>
              <div className="row">
                <div className="col">
                  {l.map((gl, i) => (
                    <Fragment key={"groupNumber" + i}>
                      <Link to={`/warehouse/location/${gl.locationId}`} className="text-dark">
                        {i ? " | " : ""}
                        <b>{warehouseService.getLocationNumber(gl)}</b>
                      </Link>
                    </Fragment>
                  ))}
                </div>
                <div
                  className={`col-auto ${
                    l[0].contents &&
                    l[0].contents.length &&
                    l[0].contents.find(c => c.product.sku === sku) &&
                    l[0].contents.find(c => c.product.sku === sku).quantity > 0
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  {l[0].contents && l[0].contents.length && l[0].contents.find(c => c.product.sku === sku)
                    ? l[0].contents.find(c => c.product.sku === sku).quantity
                    : "Fehler"}
                </div>
              </div>
              <span style={{ fontSize: "14px" }}>Inhalte der Packlisten bereits ausgebucht</span>
            </li>
          </ul>
        ))}
      <h5 className="text-light-gray">Nachfülllager</h5>
      <StorageList storageUnits={storageUnits} sku={sku} />
    </Fragment>
  );
};

export default ProductLocations;
