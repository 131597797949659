import React from "react";
import Button from "../common/Button";
import { Link } from "react-router-dom";
import warehouseService from "../../services/warehouseService";
import { isSafeToClick } from "../../utils/helper";

const StorageUnitReadOnly = ({ index, data, onDelete }) => {
  const { storageUnitId, category, contents, unitNo, totalUnitsShipment } = data;
  return (
    <div className="form-row bg-light p-3 mb-2">
      <div className="col">
        <span className="text-muted">FBA Label Nr. {index + 1}</span>
        <br />
        <b style={{ fontSize: "20px", padding: "10px 0px" }}>
          <Link to={`/warehouse/storageunit/${storageUnitId}`}>{storageUnitId}</Link>
        </b>
        <p>
          {category === "pallet" ? "Palette" : "Karton"} <b>{unitNo}</b> von {totalUnitsShipment}
        </p>
        <ul className="py-0 px-3">
          {contents.map((c, i) => (
            <li key={`content-item-${i}`}>
              <b>{warehouseService.getCurrentQuantity(c)}x</b> {c.name} ({c.sku || c.ean}){c.bStock ? <br /> : ""}
              <span className={c.bStock ? "text-danger" : "text-dark"}>{c.bStock ? "B-Ware" : ""}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="col-auto">
        {onDelete ? (
          <Button
            classes="line-height-normal"
            title={"<i class='fas fa-minus-circle'></i>" + isSafeToClick(true)}
            color="danger"
            onClick={() => onDelete(data)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default StorageUnitReadOnly;
