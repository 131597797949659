import React, { useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import Button from "react-bootstrap/Button";
import AutoFocusInput from "./AutoFocusInput";
import Joi from "joi-browser";

const QuantityModal = ({
  isOpen,
  onResolve,
  title,
  description,
  initialQuantity,
  spinner,
  confirmText,
  cancelText,
  buttonColor,
  schema,
}) => {
  const [currentValue, setCurrentValue] = useState(initialQuantity);
  const [errors, setErrors] = useState([]);

  const validate = value => {
    if (schema) {
      const options = { abortEarly: true };
      const { error } = Joi.validate({ quantity: value }, schema, options);

      if (!error) {
        setErrors([]);
        return true;
      }

      const errors = [];
      for (let item of error.details) errors.push(item.message);
      setErrors(errors);
      return false;
    } else {
      setErrors([]);
      return true;
    }
  };

  function handleChangeNumber({ currentTarget }) {
    const value = parseFloat(currentTarget.value);
    setCurrentValue(value);
  }

  function handleSubmit() {
    const valid = validate(currentValue);
    if (!valid) return;
    onResolve(currentValue);
  }

  return (
    <Modal
      show={isOpen}
      size="lg"
      backdrop="static"
      onHide={() => onResolve()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{`${title}` || "Anzahl bestätigen"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {spinner ? (
          <div className="m-5 text-center">
            <Loader className="mb-2" />
          </div>
        ) : (
          <Fragment>
            <div>
              <p>{description || ""}</p>
              <AutoFocusInput
                name="quantity"
                type="number"
                className="form-control"
                placeholder={initialQuantity}
                onChange={handleChangeNumber}
                value={currentValue || currentValue === 0 ? currentValue : ""}
              />
              {errors.length ? <p className="alert alert-danger">{errors.join(", ")}</p> : ""}
            </div>
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onResolve()}>
          {cancelText || "Abbrechen"}
        </Button>
        <Button variant={buttonColor ? buttonColor : "primary"} onClick={handleSubmit}>
          {confirmText || "Ja"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuantityModal;
