import http from "./httpService";
import { toast } from "react-toastify";
import logger from "./logService";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getSessionRemissions() {
  try {
    const sessionId = sessionStorage.getItem("sessionId");

    const remissions = {
      aStock: {},
      bStock: {},
      notSellable: {},
    };

    if (!sessionId) return remissions;
    const { data } = await http.get(apiUrl + `remissionitems?refurbishingsession=${sessionId}`);
    const dataAStock = data.filter(d => d.sku.substr(d.sku.length - 2, d.sku.length) !== "-U" && d.sellable === true);
    const dataBStock = data.filter(d => d.sku.substr(d.sku.length - 2, d.sku.length) === "-U" && d.sellable === true);
    const dataNotSellable = data.filter(d => d.sellable === false);

    if (dataAStock) {
      remissions.aStock = dataAStock.reduce((acc, cur) => {
        acc[cur.sku] = acc[cur.sku] ? acc[cur.sku] + 1 : 1;
        return acc;
      }, {});
    }
    if (dataBStock) {
      remissions.bStock = dataBStock.reduce((acc, cur) => {
        const skuSub = cur.sku.substr(cur.sku.length - 2, cur.sku.length);
        const sku = skuSub === "-U" ? cur.sku.substr(0, cur.sku.length - 2) : cur.sku;
        acc[sku] = acc[sku] ? acc[sku] + 1 : 1;
        return acc;
      }, {});
    }
    if (dataNotSellable) {
      remissions.notSellable = dataNotSellable.reduce((acc, cur) => {
        acc[cur.sku] = acc[cur.sku] ? acc[cur.sku] + 1 : 1;
        return acc;
      }, {});
    }

    return remissions;
  } catch (err) {
    if (err.response?.status === 404)
      return {
        aStock: {},
        bStock: {},
        notSellable: {},
      };
    logger.error("Error getting the list of remissions", err);
  }
}

export async function addRemission(session, sku, bStock, sellable) {
  await http.post(apiUrl + "remissionitems", {
    sku: sku + (bStock ? "-U" : ""),
    sellable,
    refurbishingSession: session,
  });
}

export async function removeLatestRemission(session, sku, bStock, sellable) {
  try {
    await http.post(apiUrl + "remissionitems/deletelatest", {
      sku: sku + (bStock ? "-U" : ""),
      sellable,
      refurbishingSession: session,
    });
  } catch (err) {
    if (err.response?.status === 404) {
      toast.info("Die aufbereitete Menge kann nicht weiter reduziert werden.");
      return;
    }

    throw err;
  }
}

export async function startSession(name) {
  try {
    const { data } = await http.post(apiUrl + "refurbishingsessions/", {
      name,
    });
    if (!data?._id || !data?.dateCreated) throw new Error("_id is not set, data contents:" + JSON.stringify(data));
    return { sessionId: data._id, sessionStart: data.dateCreated };
  } catch (err) {
    toast.error("Fehler beim Starten einer Session");
    logger.error("Error starting the refurbishingsession", err);
  }
}

export async function stopSession(sessionId) {
  try {
    await http.post(apiUrl + `refurbishingsessions/close/${sessionId}`);
  } catch (err) {
    toast.error("Fehler beim Beenden dieser Session - Die Session wird heute Nacht automatisch beendet.");
    logger.error("Error closing the refurbishingsession", err);
  }
}
