import React, { Fragment } from "react";
import LoadingScreen from "./LoadingScreen";
import FooterButton from "./FooterButton";
import Button from "../common/Button";
import StorageGroupContent from "./StorageGroupContent";
import { isSafeToClick } from "../../utils/helper";

const StorageGroupScreen = ({ id, data, modalActive, onReload, onStore, onEdit }) => {
  const { error, location, contents } = data || {};

  return (
    <Fragment>
      {!Object.keys(data || {}).length ? (
        <LoadingScreen text="Lagergruppe wird geladen..." id={id} />
      ) : error ? (
        <div className="container-fluid spacing-top">
          <h4 className="text-light-gray">Lagergruppe</h4>
          <h3 className="mb-0">
            <b>{error === 404 ? "Nicht gefunden" : "Server Fehler"}</b>
          </h3>
          <p className="mb-5">
            {error === 404
              ? `Eintrag für ${id} konnten nicht gefunden werden, stelle sicher, dass der Barcode lesbar ist und korrekt gescannt wurde.`
              : "Bitte wende dich an den Administrator"}
          </p>
        </div>
      ) : (
        <div className="splitview">
          <StorageGroupContent data={data} onEdit={onEdit} onReload={onReload} />
          <footer className="container-fluid">
            <Button
              title={"Zur Gruppe hinzufügen" + isSafeToClick(true)}
              onClick={() => onEdit(contents)}
              outline={false}
              color="secondary"
              classes="btn-block"
            />
            <FooterButton type="storagegroup" connected={location} onStore={onStore} modalActive={modalActive} />
          </footer>
        </div>
      )}
    </Fragment>
  );
};

export default StorageGroupScreen;
