import React, { Component } from "react";
import PropTypes from "prop-types";
import Toggle from "./Toggle";
import Select from "./SelectSimple";

// columns: array
// sortColumn: object
// onSort: function

class TableSortBy extends Component {
  constructor() {
    super();
    this.handleSort = this.handleSort.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
  }

  handleOrder(data) {
    const { onSort, sortColumn } = this.props;
    onSort({
      path: sortColumn.path,
      order: data.value,
    });
  }

  handleSort({ currentTarget: data }) {
    const { onSort, sortColumn } = this.props;
    onSort({
      path: data.value,
      order: sortColumn.order,
    });
  }

  render() {
    const { columns, sortColumn } = this.props;
    return (
      <div className="row">
        <div className="col-8 pr-2">
          <Select
            name="sortBy"
            label=""
            chooseLabel="Sortieren nach..."
            options={columns
              .filter(c => c.label)
              .map(c => {
                return { name: c.label, _id: c.path };
              })}
            onChange={this.handleSort}
          />
        </div>
        <div className="col-4 pl-2">
          <Toggle
            name="order"
            current={sortColumn.order}
            options={[
              { name: <i className="fa fa-caret-up"></i>, _id: "asc" },
              { name: <i className="fa fa-caret-down"></i>, _id: "desc" },
            ]}
            onChange={this.handleOrder}
            addClasses="line-height-normal w-100"
          />
        </div>
      </div>
    );
  }
}

TableSortBy.propTypes = {
  columns: PropTypes.array.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default TableSortBy;
