import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { getReceivedQuantity } from "../../services/warehouseService";
// eslint-disable-next-line
const ChecklistItem = ({ index, item, onChange, onDelete }) => {
  // eslint-disable-next-line
  const { sku, ean, name, initialQuantity, receivedQuantity } = item;

  useEffect(() => {
    const tempQuantity = getReceivedQuantity(item);
    const data = { index, name: "receivedQuantity", value: parseInt(tempQuantity) };
    onChange(data); // eslint-disable-next-line
  }, []);

  const handleChangeNumber = ({ currentTarget: target }) => {
    const data = { index, name: target.name, value: parseInt(target.value) };
    onChange(data);
  };
  return (
    <li className="list-group-item">
      <div className="row">
        <div
          className="col-8 col-md-6 col-lg text-left order-1"
          style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}
        >
          <small>{name}</small>
        </div>
        <div
          className="col-4 col-md-2 col-lg-auto order-2 text-right"
          style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}
        >
          {onDelete && (
            <Button variant="secondary" size="md" onClick={() => onDelete(item)}>
              <i className="fas fa-trash-alt"></i>
            </Button>
          )}
        </div>
        <div className="col-12 col-md-4 col-lg-12 order-3">
          <form>
            <div className="form-row">
              <div className="col-12 text-left mb-2">
                <b>SKU </b>
                {sku}
                {ean && (
                  <>
                    <br />
                    <b>EAN </b>
                    {ean}
                  </>
                )}
              </div>
              <div className="col-auto" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
                <b className="d-block d-lg-none">Erwartet </b>
              </div>
              <div className="col-auto" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
                {initialQuantity}{" "}
                <span className="mx-1">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </div>
              <div className="col-auto" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
                <b className="d-block d-lg-none">Geliefert </b>
              </div>
              <div className="col">
                <input
                  name="receivedQuantity"
                  type="number"
                  style={{ width: "100%" }}
                  className="form-control"
                  placeholder={initialQuantity}
                  onChange={handleChangeNumber}
                  value={receivedQuantity || receivedQuantity === 0 ? receivedQuantity : ""}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </li>
  );
};

export default ChecklistItem;
