import http from "../services/httpService";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export function setCurrentPrinter(printer) {
  localStorage.setItem("currentPrinter", JSON.stringify(printer));
}

export async function getCurrentPrinter() {
  const printerStorage = localStorage.getItem("currentPrinter");
  if (!printerStorage || printerStorage === "undefined") {
    const printers = await getListOfPrinters();
    return printers[0];
  }

  const currentPrinter = JSON.parse(localStorage.getItem("currentPrinter"));
  if (currentPrinter) return currentPrinter;
}

export async function getListOfPrinters() {
  const { data } = await http.get("/warehouse/printers/");
  return data;
}

const printerService = { setCurrentPrinter, getCurrentPrinter, getListOfPrinters };

export default printerService;
