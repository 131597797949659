import React, { Fragment } from "react";
import warehouseService from "../../services/warehouseService";

import { Link } from "react-router-dom";
const ConnectedLocations = ({ data: connectedLocations }) => {
  return (
    <Fragment>
      {connectedLocations && connectedLocations.length ? (
        <div className="mb-2 badge badge-light">
          <span className="text-light-gray">
            <i class="fas fa-link"></i>
          </span>{" "}
          {connectedLocations.map((c, i) => (
            <Fragment>
              <Link to={`/warehouse/location/${c.locationId}`} className="text-dark">
                <span key={`connectedLocation-${i}`}>{warehouseService.getLocationNumber(c)}</span>
              </Link>
              {i + 1 < connectedLocations.length ? ", " : ""}
            </Fragment>
          ))}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default ConnectedLocations;
