import React from "react";
import Button from "../common/Button";
import SimplePromiseModal from "../common/SimplePromiseModal";
import { createModal } from "react-modal-promise";
import { isSafeToClick } from "../../utils/helper";

const FooterButton = ({ type, category, connectMode, connected, onStore, modalActive }) => {
  const showConfirmModal = createModal(SimplePromiseModal);

  return (
    <Button
      title={
        (type === "location"
          ? connected
            ? "Eingelagerte Einheit ersetzen"
            : connectMode
            ? "Einheit hier einlagern"
            : "Etwas einlagern"
          : category === "pallet"
          ? connected
            ? "Diese Palette umlagern"
            : "Diese Palette einlagern"
          : category === "box"
          ? connected
            ? "Diesen Karton umlagern"
            : "Diesen Karton einlagern"
          : connected
          ? "Diese Einheit umlagern"
          : "Diese Einheit einlagern") + isSafeToClick(true)
      }
      onClick={async () => {
        if (type === "location" && connected) {
          const result = await showConfirmModal({
            title: "Inhalt ersetzen?",
            description: `Bitte bestätige, dass du den Inhalt des Lagerplatzes auslagern und durch den neuen Inhalt ersetzen willst`,
            confirmText: "Auslagern und Ersetzen",
            cancelText: "Abbrechen",
          });
          if (!result) return;
        }
        onStore();
      }}
      outline={false}
      color={modalActive ? "light" : category === "box" ? "secondary" : "primary"}
      classes="btn-block"
    />
  );
};

export default FooterButton;
