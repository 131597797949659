import React, { Fragment } from "react";
import warehouseService from "../../services/warehouseService";
import Button from "../common/Button";
import ProductLineItem from "./ProductLineItem";
import { isSafeToClick } from "../../utils/helper";

import PrintButton from "./PrintButton";

const Contents = ({ storageUnit, receiveDate, onEdit, showUnitId }) => {
  const { contents, storageUnitId } = storageUnit;

  const handlePrintStorageUnitLabel = async () => {
    await warehouseService.printUnitLabel(storageUnitId);
  };

  return (
    <div>
      <div className="row justify-content-between mb-2">
        <div className="col-auto my-auto">
          <h5 className="text-light-gray">Inhalt {showUnitId && `(${storageUnitId})`}</h5>
        </div>
        <div className="col-auto">
          <PrintButton
            description={`Label für Lagereinheit ${storageUnitId} drucken?`}
            onPrint={handlePrintStorageUnitLabel}
            classes="mr-2"
          />
          {onEdit && (
            <Button
              title={"Bearbeiten" + isSafeToClick(true)}
              onClick={onEdit}
              outline={false}
              color="light"
              classes="btn btn-sm"
            />
          )}
        </div>
      </div>
      {contents && contents.length ? (
        <Fragment>
          <ul className="list-group mb-3">
            {contents.map((c, i) => (
              <li key={`${c.sku}-${i}`} className="list-group-item bg-light-gray">
                <ProductLineItem product={c} />
              </li>
            ))}
          </ul>
        </Fragment>
      ) : (
        <div className="text-center text-light-gray pt-5">
          <h4>Kein Inhalt hinterlegt</h4>
        </div>
      )}
    </div>
  );
};

export default Contents;
