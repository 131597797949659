const Stepper = ({ onSubtract, onAdd, currentValue, color, buttonStyle }) => {
  return (
    <div className="input-group" style={{ minWidth: "100px" }}>
      <div className="input-group-prepend line-height-normal">
        <button
          className={`btn btn-${buttonStyle}`}
          type="button"
          onClick={currentValue > 0 ? onSubtract : () => {}}
          disabled={currentValue <= 0}
        >
          -
        </button>
      </div>
      <input
        type="text"
        value={currentValue}
        className="form-control"
        disabled
        style={{ backgroundColor: color || "white", textAlign: "center" }}
      />
      <span className="input-group-append">
        <button className={`btn btn-${buttonStyle}`} type="button" onClick={onAdd}>
          +
        </button>
      </span>
    </div>
  );
};

export default Stepper;
