import React, { Component, Fragment } from "react";
import Table from "../common/Table";
import linkUtils from "../../utils/linkUtils";
import dateUtils from "../../utils/dateUtils";
import PropTypes from "prop-types";
import Checkbox from "../common/Checkbox";
import NotesForm from "./NotesForm";
import Loader from "../common/Loader";
import { isSafeToClick } from "../../utils/helper";

class RefundTable extends Component {
  state = {
    columns: [
      {
        path: "receive.date",
        label: "Annahme",
        content: orderReturns => {
          return orderReturns.map((orderReturn, i) => (
            <div key={orderReturn._id + "-" + i} className="mb-2">
              {orderReturn.receive ? (
                <Fragment>
                  {dateUtils.formatDate(orderReturn.receive.date)}
                  <br />
                  {orderReturn.receive.user.name}
                </Fragment>
              ) : (
                <span className={orderReturn.receive ? "" : "text-muted"}>Bisher nicht angenommen</span>
              )}
            </div>
          ));
        },
      },
      {
        path: "order.orderNumber",
        label: "Bestellnummer",
        content: orderReturns => (
          <a href={linkUtils.linkToBillbee(orderReturns[0].order.billbeeId)} target="_blank" rel="noopener noreferrer">
            {orderReturns[0].order.orderNumber}
          </a>
        ),
      },
      { path: "order.customer.name", label: "Kunde", content: orderReturns => orderReturns[0].order.customer.name },
      {
        path: "sku",
        label: "Produkt",
        content: orderReturns =>
          orderReturns.map((orderReturn, i) => (
            <div key={orderReturn._id + "-" + i} className="mb-2">
              <span className={orderReturn.receive ? "" : "text-muted"}>
                {orderReturn.product.count}x {orderReturn.product.name}
                <br />
                <span className="text-muted">SKU: {orderReturn.product.sku} </span>
                {orderReturn.falseDelivery && (
                  <Fragment>
                    <br />
                    <span className="badge badge-warning">Falsches Produkt</span>
                  </Fragment>
                )}
              </span>
              <br />
            </div>
          )),
      },
      {
        path: "refund.refundNotes",
        label: "Notizen Erstattung",
        content: orderReturns => <NotesForm orderReturns={orderReturns} onChangeNote={this.props.onChangeNote} />,
      },
      {
        path: "refund.refundDeduction",
        label: "Abzug Erstattung",
        content: orderReturns => (
          <div>
            {orderReturns.map(orderReturn => (
              <div key={orderReturn._id} className="mb-2">
                <button className="btn btn-sm btn-secondary">
                  <div onClick={() => this.props.onChangeDeduction(orderReturn)} className="text-nowrap">
                    {orderReturn.refund && orderReturn.refund.refundDeduction > 0
                      ? "-" + orderReturn.refund.refundDeduction.toFixed(2).replace(".", ",") + "€"
                      : "Kein Abzug"}
                    {isSafeToClick(true)}
                    <i className="fas fa-pen ml-2"></i>
                  </div>
                </button>
                <Checkbox
                  name={"refundRequired-" + orderReturn._id}
                  id={"refundRequired-" + orderReturn._id}
                  label="Erstattung"
                  disabled={!orderReturn.receive}
                  isSelected={orderReturn.refund && orderReturn.refund.refundRequired === false ? false : true}
                  onCheckboxChange={() => this.props.onToggleRefundRequired(orderReturn)}
                />
              </div>
            ))}
          </div>
        ),
      },
      {
        path: "price",
        label: "Erstattungssumme",
        content: orderReturns => (
          <div>
            {orderReturns.map((orderReturn, i) => (
              <div key={orderReturn._id + "-" + i} className={orderReturn.receive ? "mb-2" : "mb-2 text-muted"}>
                <span className="text-nowrap">
                  {orderReturn.product.price
                    ? orderReturn.product.price.toFixed(2).replace(".", ",") + "€"
                    : "Nicht verfügbar"}
                  <br />
                </span>
                <span className="text-nowrap">
                  {orderReturn.refund && orderReturn.refund.refundDeduction > 0
                    ? "Abzug: -" + orderReturn.refund.refundDeduction.toFixed(2).replace(".", ",") + "€"
                    : "Kein Abzug"}
                </span>
              </div>
            ))}
            {orderReturns[0].order.shippingCost || orderReturns[0].order.shippingCost === 0 ? (
              <span className="text-nowrap">
                {"Versand: " + orderReturns[0].order.shippingCost.toFixed(2).replace(".", ",") + "€"}
                <br />
              </span>
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        key: "externalNotes",
        content: orderReturns => (
          <Fragment>
            {this.getWarehouseNotes(orderReturns)}
            {this.getBillbeeNotes(orderReturns)}
          </Fragment>
        ),
      },
      {
        key: "refund",
        content: orderReturns => (
          <Fragment>
            <button
              onClick={() =>
                orderReturns.every(o => o.product.price)
                  ? this.props.onRefund(orderReturns, this.getSum(orderReturns))
                  : null
              }
              disabled={!orderReturns.every(o => o.receive)}
              className={`btn btn-block text-nowrap ${orderReturns.every(o => o.receive) ? "btn-primary" : "btn-secondary"}`}
            >
              {orderReturns.every(o => o.product.price)
                ? this.getSum(orderReturns).toFixed(2).replace(".", ",") + "€ erstatten"
                : "Erstatten"}
              {isSafeToClick(true)}
            </button>
          </Fragment>
        ),
      },
      {
        key: "markRefunded",
        content: orderReturns => (
          <Fragment>
            <button
              onClick={() => this.props.onMarkRefunded(orderReturns)}
              className="btn btn-secondary btn-block text-nowrap"
              disabled={!orderReturns.every(o => o.receive)}
            >
              Erstattet markieren
              {isSafeToClick(true)}
            </button>
          </Fragment>
        ),
      },
    ],
  };

  getSum(orderReturns) {
    const products = orderReturns.filter(r => !r.refund || (r.refund && r.refund.refundRequired)).map(o => o.product);
    const refunds = orderReturns.map(o => o.refund).filter(r => r && r.refundRequired);
    const productTotal = products ? products.reduce((acc, red) => acc + red.price, 0) : 0;
    const shippingCost = orderReturns[0].order ? orderReturns[0].order.shippingCost : 0;
    const returnCost = refunds ? refunds.reduce((acc, red) => acc + (red.refundDeduction || 0), 0) : 0;

    return productTotal + shippingCost - returnCost;
  }

  getWarehouseNotes(orderReturns) {
    const notes = orderReturns
      .map(r => r.notes)
      .filter(n => n)
      .join(", ");
    return notes ? (
      <div className="alert alert-warning mb-1">
        <div className="mb-1">
          <small>Lagernotizen</small>
        </div>
        {notes}
      </div>
    ) : (
      ""
    );
  }

  getBillbeeNotes(orderReturns) {
    const { billbeeOrders } = this.props;
    const billbeeId = orderReturns[0].order.billbeeId;
    const order = billbeeOrders.find(o => o.BillBeeOrderId + "" === billbeeId);
    if (!order)
      return (
        <div className="my-3">
          <Loader />
        </div>
      );
    const notes = order.SellerComment;
    return notes ? (
      <div className="alert alert-success mb-1">
        <div className="mb-1">
          <small>Billbee Notizen</small>
        </div>
        {notes}
      </div>
    ) : (
      ""
    );
  }

  render() {
    const { orderReturns, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.state.columns}
        data={orderReturns}
        sortColumn={sortColumn}
        getId={item => item[0]["_id"]}
        onSort={onSort}
      />
    );
  }
}

RefundTable.propTypes = {
  orderReturns: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  sortColumn: PropTypes.object.isRequired,
};

export default RefundTable;
