import React, { Component, Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

class CollapsibleTableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path);
  };

  renderGroup = (groupData, column) => {
    if (column.content) return column.content(groupData);
    return _.get(groupData, column.path);
  };

  createKey = (itemId, column) => {
    return itemId + "-" + (column.path || column.key);
  };

  handleClick = e => {
    const elements = document.getElementsByClassName(e.currentTarget.id);
    const arrow = e.currentTarget.querySelector("#arrowIndicator");

    arrow.className.indexOf("open") > -1 ? arrow.classList.remove("open") : arrow.classList.add("open");

    for (const element of elements) {
      element.className.indexOf("collapse") > -1 && element.className.indexOf("show") > -1
        ? element.classList.remove("show")
        : element.classList.add("show");
    }
  };

  sanitize(groupString) {
    return groupString?.replace(" ", "-") || "";
  }

  render() {
    const { idField, groupedData, columns, groupColumns } = this.props;

    return (
      <tbody>
        {groupedData.map(group => (
          <Fragment key={`${this.sanitize(group[idField])}-group`}>
            <tr
              key={`${this.sanitize(group[idField])}-row`}
              id={`${this.sanitize(group[idField])}`}
              onClick={this.handleClick}
            >
              {groupColumns.map(column => (
                <td
                  key={this.createKey(`${this.sanitize(group[idField])}`, column)}
                  className={`align-middle${column.shading ? " bg-light" : ""}`}
                >
                  {this.renderGroup(group, column)}
                </td>
              ))}
            </tr>
            {group &&
              group.children &&
              group.children.map(item => (
                <tr key={item[idField] + "-row"} className={`accordion-body collapse ${this.sanitize(group[idField])}`}>
                  {columns.map(column => (
                    <td
                      key={this.createKey(item[idField], column)}
                      className={`align-middle tableGroupContent${column.shading ? " bg-light" : ""}`}
                    >
                      <div>{this.renderCell(item, column)}</div>
                    </td>
                  ))}
                </tr>
              ))}
          </Fragment>
        ))}
      </tbody>
    );
  }
}

CollapsibleTableBody.propTypes = {
  idField: PropTypes.string.isRequired,
  groupedData: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

CollapsibleTableBody.defaultProps = {
  idField: "_id",
};

export default CollapsibleTableBody;
