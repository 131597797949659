import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getProductDocs() {
  const endpointName = "shopify/docs";
  const { data } = await http.get(apiUrl + endpointName);
  if (data) return data;
  return null;
}
 
const productDocsService = {
  getProductDocs
};

export default productDocsService;
