import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import { createModal } from "react-modal-promise";
import { toast } from "react-toastify";
import SimplePromiseModal from "../components/common/SimplePromiseModal";
import http from "./httpService";
import logger from "./logService";
import printerService from "./printerService";

const showConfirmModal = createModal(SimplePromiseModal);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export async function loadStorageUnits(page, pageSize, sortColumn, searchQuery) {
  try {
    const { data } =
      page && pageSize
        ? await http.get(
            `/storage-units/?page=${page}&pageSize=${pageSize}${sortColumn ? `&sortBy=${getSortBy(sortColumn)}` : ""}${
              searchQuery ? `&searchQuery=${searchQuery}` : ""
            }`
          )
        : await http.get(`/storage-units/`);

    function getSortBy(sortColumn) {
      if (!sortColumn) return;
      return sortColumn.order === "asc" ? sortColumn.path : `-${sortColumn.path}`;
    }
    return data;
  } catch (err) {
    if (err.response && err.response.status === 404) return { error: 404 };
    return { error: 500 };
  }
}

export async function loadWarehouseLocation(id) {
  try {
    const { data } = await http.get(`/warehouse/${id}`);
    return data;
  } catch (err) {
    if (err.response && err.response.status === 404) return { error: 404 };
    return { error: 500 };
  }
}

export async function loadPickingSpots() {
  try {
    const { data } = await http.get(`/warehouse/pickingspots`);
    return data;
  } catch (err) {
    if (err.response && err.response.status === 404) return { error: 404 };
    return { error: 500 };
  }
}

export async function loadStorageUnit(id) {
  try {
    const { data } = await http.get(`/storage-units/${id}`);
    return data;
  } catch (err) {
    if (err.response && err.response.status === 404) return { error: 404 };
    return { error: 500 };
  }
}

export async function loadStorageGroup(id) {
  try {
    const { data } = await http.get(`/storage-groups/${id}`);
    return data;
  } catch (err) {
    if (err.response && err.response.status === 404) return { error: 404 };
    return { error: 500 };
  }
}

export async function loadProduct(id) {
  try {
    const { data } = await http.get(`/products/${encodeURIComponent(id)}`);
    return data;
  } catch (err) {
    if (err.response && err.response.status) return { error: err.response.status };
    return { error: 500 };
  }
}

export async function loadProductLocations(sku) {
  try {
    const { data } = await http.get(`/warehouse/locations-by-sku/${encodeURIComponent(sku)}`);
    return data;
  } catch (err) {
    if (err.response && err.response.status === 404) return { error: 404 };
    return { error: 500 };
  }
}

export async function updateStorageUnit(_id, newStorageUnit) {
  try {
    const newData = _.cloneDeep(newStorageUnit);
    delete newData.creationDate;
    delete newData.createdBy;
    delete newData.idCounter;
    delete newData.parentShipmentId;
    delete newData.parentGroup;
    delete newData.location;
    const { data } = await http.put(`/storage-units/${_id}`, newData);
    return data;
  } catch (err) {
    if (err.response && err.response.status === 400) toast.error(err.response?.data);
    throw err;
  }
}

export async function createStorageUnit(storageUnit) {
  try {
    const body = _.cloneDeep(storageUnit);
    body.contents = storageUnit?.contents?.map(c => _.omit(c, "name"));
    const { data } = await http.post(`/storage-units/`, body);
    printUnitLabel(data.storageUnitId);
    return data;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      if (err.response?.data === '"category" is required') toast.error("Bitte wähle eine Kategorie");
      else if (err.response?.data === '"contents" is required')
        toast.error("Bitte füge mindestens einen Artikel hinzu");
      else toast.error(err.response?.data);
    }
    throw err;
  }
}

/**
 *
 * @param {object} product Product object
 * @param {object} storageUnit Storage Unit object
 * @param {Number} amount Number of items to remove. Optional, if left empty all of the products' sku will be removed
 */
export async function removeProductFromStorageUnit(product, storageUnit, amount = 0) {
  try {
    const body = { ...storageUnit };

    let restAmount = parseInt(amount);

    const items = body.contents?.filter(c => c.sku === product.sku) || [];
    for (const item of items) {
      if (restAmount <= 0) continue;
      const subtractAmount = Math.min(getCurrentQuantity(item), restAmount);
      restAmount -= subtractAmount;
      item.currentQuantity = getCurrentQuantity(item) - subtractAmount;
    }
    if (restAmount > 0) logger.error(`Rest amount is greater than zero: ${restAmount}`);

    const itemsLeft = body.contents.length && body.contents.find(c => getCurrentQuantity(c) > 0) ? true : false;
    body.emptyDate = itemsLeft ? null : Date.now();

    const result = await updateStorageUnit(storageUnit._id, body);
    if (!result) return;

    if (!itemsLeft && storageUnit.location) {
      const removeResult = await removeStorageUnitFromLocation(storageUnit._id, storageUnit.location._id);
      if (!removeResult) return;
    }

    if (itemsLeft && storageUnit.location)
      await showConfirmModal({
        title: "Gemischte Palette",
        description: `Es sind noch weitere Einheiten mit anderer SKU auf der Palette vorhanden. Stelle die Palette auf ihren Lagerplatz ${getLocationNumber(
          storageUnit.location
        )} zurück oder lagere den Inhalt auf den zugehörigen Picking-Plätzen ein.`,
        confirmText: "OK",
        cancelText: "",
        buttonColor: "secondary",
      });

    if (itemsLeft && !storageUnit.location)
      await showConfirmModal({
        title: "Gemischte Palette",
        description: `Es sind noch weitere Einheiten mit anderer SKU auf der Palette vorhanden. Lagere die Palette in einen Nachfüll-Lageplatz, oder den Inhalt auf den zugehörigen Picking-Plätzen ein.`,
        confirmText: "OK",
        cancelText: "",
        buttonColor: "secondary",
      });
  } catch (err) {
    toast.error("Fehler beim entfernen der Produkte aus der Lagereinheit." + JSON.stringify(err));
    logger.error("Error removing items from storage Unit: ", err);
  }
}

export async function storeUnitAtLocation(storageUnitObjectId, warehouseLocationId, showConfirmModal) {
  const body = {
    _id: storageUnitObjectId,
    locationId: warehouseLocationId,
  };
  try {
    const { data } = await http.post(`/storage-units/location/`, body);
    if (data) toast("Erfolgreich eingelagert");
    return data;
  } catch (err) {
    const { code, useForcePossible } = err.response.data;
    if (code === "pickingSpotMatch" || code === "pickingSpotPartialMatch") {
      try {
        //Check contents
        const storageUnit = await loadStorageUnit(storageUnitObjectId);
        const warehouseLocation = await loadWarehouseLocation(warehouseLocationId);
        const matches =
          storageUnit.contents?.filter(s => warehouseLocation.contents.find(c => c.product.sku === s.sku)) || [];

        if (matches) {
          let quantity = 0;
          const quantities = {};
          const productsBySku = {};
          for (const match of matches) {
            quantity += parseFloat(getCurrentQuantity(match));
            // alert("line 174 " + JSON.stringify(quantity));

            quantities[match.sku] = quantities[match.sku]
              ? parseFloat(quantities[match.sku]) + getCurrentQuantity(match)
              : getCurrentQuantity(match);

            // alert("line 180 " + JSON.stringify(quantities[match.sku]));
            const matchingContent = warehouseLocation.contents.find(c => c.product.sku === match.sku);
            productsBySku[match.sku] = matchingContent.product;
          }
          const result = await showConfirmModal({
            title: `${quantity} Einheiten einlagern?`,
            description: `${getUnitInfo(storageUnit, warehouseLocation)} wird aus der Lagereinheit ${
              storageUnit.storageUnitId
            } entfernt und einzeln in ${getLocationNumber(warehouseLocation)} eingelagert.`,
            confirmText: "Einlagern",
          });
          if (!result) return;
          for (const sku in quantities) {
            // alert("line 193 SKU " + sku);
            // alert("line 194 " + quantities[sku]);
            // alert("line 194 " + productsBySku[sku]._id + " " + productsBySku[sku].sku);
            const result = await changeProductQuantity(
              productsBySku[sku]._id,
              warehouseLocation._id,
              null,
              quantities[sku]
            );
            if (result) await removeProductFromStorageUnit(productsBySku[sku], storageUnit, quantities[sku]);
          }
        }
      } catch (err) {
        toast.error("Fehler beim einlagern der Lagereinheit.");
        logger.error("There was an error in storeUnitAtLocation when storing into a picking Spot: ", err);
      }
    } else if (code && !useForcePossible) {
      toast.error(getErrorMessage(code));
    } else if (code && useForcePossible) {
      const { title, description, confirmText } = getModalTexts(code);
      const result = await showConfirmModal({
        title,
        description,
        confirmText,
        cancelText: "Abbrechen",
      });
      if (result) {
        const { data } = await http.post(`/storage-units/location/?force=true`, body);
        toast("Lagereinheit wurde erfolgreich eingelagert.");
        return data;
      }
      return err.response.data;
    } else {
      toast.error("Zuordnung fehlgeschlagen: " + err.response.data);
      logger.error("Could not store unit at location: ", err);
    }
  }
}

export function getUnitInfo(storageUnit, location) {
  const matches = storageUnit.contents?.filter(c => location.contents.find(lc => lc.product.sku === c.sku)) || [];
  if (!matches || !matches.length) return;

  const returnString = matches
    .map(match => {
      const { name, sku } = match;
      return `${getCurrentQuantity(match)}x ${name || sku || "Einheit"}`;
    })
    .join(", ");
  return returnString;
}

export async function storeGroupAtLocation(storageGroupId, warehouseLocationId, showConfirmModal) {
  const body = {
    _id: storageGroupId,
    locationId: warehouseLocationId,
  };
  try {
    const { data } = await http.post(`/storage-groups/location/`, body);
    if (data) toast("Erfolgreich eingelagert");
    return data;
  } catch (err) {
    const { code, useForcePossible } = err.response.data;
    if (code && !useForcePossible) {
      toast.error(getErrorMessage(code));
    } else if (code && useForcePossible) {
      const { title, description, confirmText } = getModalTexts(code);
      const result = await showConfirmModal({
        title,
        description,
        confirmText,
        cancelText: "Abbrechen",
      });
      if (result) {
        try {
          const { data } = await http.post(`/storage-groups/location/?force=true`, body);
          toast("Lagergruppe wurde erfolgreich eingelagert.");
          return data;
        } catch (err) {
          if (err.response && err.response.data && err.response.data.code)
            toast.error(getErrorMessage(err.response.data.code));
          else {
            toast.error("Es ist ein unerwarteter Fehler aufgetreten, bitte wende dich an den Administrator");
            logger.error("Error forcing storeGroupAtLocation: ", err);
          }
        }
      }
      return err.response.data;
    } else {
      toast.error("Zuordnung fehlgeschlagen: " + err.response.data);
      logger.error("Could not store group at location: ", err);
    }
  }
}

export async function testCompatibility(itemId, warehouseLocationId, type) {
  const body =
    type === "product"
      ? {
          productObjectId: itemId,
        }
      : {
          _id: itemId,
          locationId: warehouseLocationId,
        };

  try {
    //Test only will give possible errors before showing the quantity modal. It does not yet save any data.
    if (type === "product") {
      await http.put(`/warehouse/quantity/${warehouseLocationId}?testOnly=true`, body);
    } else {
      await http.post(
        `/${
          type === "storageUnit" || type === "storageunit"
            ? "storage-units/location"
            : type === "storageGroup" || type === "storagegroup"
            ? "storage-groups/location"
            : type
        }/?testOnly=true`,
        body
      );
    }
    return "Erfolg";
  } catch (err) {
    if (type === "product") {
      const { messageDE } = err.response.data;
      return messageDE;
    } else {
      const { code } = err.response.data;
      return code ? getErrorMessage(code) : err.response.data;
    }
  }
}

export async function testChangeWarehouseLocation(locationId, body) {
  try {
    //Test only will give possible errors before showing the quantity modal. It does not yet save any data.
    await http.put(`/warehouse/${locationId}/?testOnly=true`, body);
    return "Erfolg";
  } catch (err) {
    const { messageDE } = err.response.data;
    toast.warning(messageDE);
  }
}

export async function changeProductQuantity(
  productObjectId,
  warehouseLocationId,
  showQuantityModal = null,
  quantity = null
) {
  const body = {
    productObjectId,
    quantity,
  };

  try {
    //Test only will give possible errors before showing the quantity modal. It does not yet save any data.
    await http.put(`/warehouse/quantity/${warehouseLocationId}?testOnly=true`, body);
    let result = quantity;
    if (showQuantityModal) {
      result = await showQuantityModal({
        title: "Anzahl eingeben",
        description: "Gib die Anzahl der Produkte an, die eingelagert werden sollen.",
        confirmText: "Artikel einlagern",
        cancelText: "Abbrechen",
      });
      if (!result) return;
    }
    if (!showQuantityModal && !quantity) return;
    if (typeof result === "number") body.quantity = showQuantityModal ? result : quantity;

    const { data } = await http.put(`/warehouse/quantity/${warehouseLocationId}`, body);
    if (data) toast("Erfolgreich eingelagert");
    return data;
  } catch (err) {
    const { messageDE } = err.response?.data || {};
    toast.error("Zuordnung fehlgeschlagen: " + messageDE);
    logger.error("Could not store product at location: ", err);
  }
}

export async function createStorageGroup(storageUnits) {
  const body = {
    contents: storageUnits.map(s => s._id),
  };

  try {
    const { data } = await http.post(`/storage-groups/`, body);
    printUnitLabel(data.storageGroupId);
    return data;
  } catch (err) {
    if (err.response && err.response.status === 400) toast.error("Gruppen müssen mindestens eine Einheit enthalten.");
  }
}

export async function printUnitLabel(unitId) {
  const printBody = { storageUnit: unitId };
  const currentPrinter = await printerService.getCurrentPrinter();
  try {
    await http.post(`/warehouse/print/${currentPrinter.id}`, printBody);
  } catch (err) {
    const { code } = err.response.data;
    const errorMessage = getErrorMessage(code);
    toast.warning(errorMessage);
    logger.error("Fehler beim Drucken des Gruppen-Labels: ", err);
  }
}

export async function printReturnLabel(barcode, notifyNoPrinterSelected = true) {
  const printBody = { orderReturn: barcode };
  try {
    const currentPrinter = await printerService.getCurrentPrinter();
    if (currentPrinter?.id) await http.post(`/warehouse/print/${currentPrinter.id}`, printBody);
    else if (notifyNoPrinterSelected)
      toast.warning("Es konnte kein Druck erfolgen, bitte wähle zunächst einen Drucker aus.");
  } catch (err) {
    const { code } = err.response.data;
    const errorMessage = getErrorMessage(code);
    toast.warning(errorMessage);
    logger.error("Fehler beim Drucken des Retoure-Labels: ", err);
  }
}

export async function printProductLabel(sku) {
  const printBody = { sku };
  const currentPrinter = await printerService.getCurrentPrinter();
  try {
    await http.post(`/warehouse/print/${currentPrinter.id}`, printBody);
  } catch (err) {
    if (!err.response) {
      toast.error("Fehler beim Drucken, bitte prüfe, ob ein Drucker ausgwählt ist.");
      logger.error("No response", err);
      return;
    }
    const { code } = err.response.data;
    const errorMessage = getErrorMessage(code);
    toast.warning(errorMessage);
    logger.error("Fehler beim Drucken des Produkt-Labels: ", err);
  }
}

export async function printRackLabel(locationId, size = null) {
  const printBody = { location: locationId };
  const currentPrinter = await printerService.getCurrentPrinter();
  try {
    await http.post(`/warehouse/print/${currentPrinter.id}${size ? "?size=" + size : ""}`, printBody);
  } catch (err) {
    const { code } = err.response.data;
    const errorMessage = getErrorMessage(code);
    toast.warning(errorMessage);
    logger.error("Fehler beim Drucken des Regal-Labels: ", err);
  }
}

export async function updateStorageGroup(storageUnits, _id) {
  const body = {
    contents: storageUnits.map(s => s._id),
  };

  const { data } = await http.put(`/storage-groups/${_id}`, body);
  return data;
}

export async function updateLocationSettings(locationId, data, setErrorMessage) {
  try {
    await http.put(`/warehouse/${locationId}`, data);
    return "Success";
  } catch (err) {
    if (err.response.data) setErrorMessage(err.response.data.messageDE || "");
    if (!err.response.data.messageDE) logger.error("Could not update the location settings: ", err);
    return;
  }
}

export async function removeFromGroup(storageUnitId, storageGroup) {
  try {
    const { contents: storageUnits } = await loadStorageGroup(storageGroup._id);
    const newUnits = storageUnits.filter(u => u.storageUnitId !== storageUnitId);
    await updateStorageGroup(newUnits, storageGroup._id);
    toast("Die Einheit wurde erfolgreich aus der Gruppe entfernt");
  } catch (err) {
    toast.error("Fehler beim entfernen der Einheit aus der Gruppe.");
    logger.error("Could not remove unit from group: ", err);
  }
}

export async function removeStorageUnitFromLocation(storageUnitId, locationId) {
  const body = {
    _id: storageUnitId,
    locationId,
  };

  try {
    await http.post("/storage-units/remove-location", body);
    return "success";
  } catch (err) {
    const { code } = err.response.data;
    const errorMessage = getErrorMessage(code);
    toast.error(errorMessage);
  }
}

export async function removeStorageGroupFromLocation(storageGroupId, locationId) {
  const body = {
    _id: storageGroupId,
    locationId,
  };

  try {
    await http.post("/storage-groups/remove-location", body);
    return "success";
  } catch (err) {
    const { code } = err.response.data;
    const errorMessage = getErrorMessage(code);
    toast.error(errorMessage);
  }
}

export function getLocationNumber(location, includeWarehouseName = false) {
  return location
    ? `${includeWarehouseName ? `${location.warehouse} ` : ""}${location.aisle}-${location.rack}-${location.level}-${
        location.position
      }`
    : "";
}

export async function removeStorageUnitFromWarehouse(storageUnitId) {
  try {
    await http.post(`/storage-units/mark-as-removed/${storageUnitId}`);
    toast(`${storageUnitId} erfolgreich als entfernt markiert`);
  } catch (err) {
    if (!err.response || !err.response.data || !err.response.data.messageDE) {
      logger.error(`Error returning the error message while removing ${storageUnitId} from warehouse.`, err);
      toast.error("Es ist ein unerwarteter Fehler aufgetreten.", JSON.stringify(err));
    }

    const { messageDE } = err.response.data;
    toast.error(messageDE);
    throw err;
  }
}

/**
 * Get the current quantity contained in an line item of a contents object within a storage unit.
 * @param {object} c Contents Object of Storage Unit used to return the currently contained quantity
 * @returns Number
 */
export function getCurrentQuantity(c) {
  if (!c) return 0;
  const quantity =
    c.currentQuantity || c.currentQuantity === 0
      ? c.currentQuantity
      : c.receivedQuantity || c.receivedQuantity === 0
      ? c.receivedQuantity
      : c.initialQuantity;

  return parseInt(quantity);
}

/**
 * Get the received quantity for a line item of a contents object within a storage unit.
 * @param {object} c Contents Object of Storage Unit used to return the originally received quantity
 * @returns Number
 */
export function getReceivedQuantity(c) {
  const quantity = c.receivedQuantity || c.receivedQuantity === 0 ? c.receivedQuantity : c.initialQuantity;
  return parseInt(quantity);
}

export function getStorageDateByStu(storageUnit, dateFormat = "DD.MM.YYYY") {
  const date = storageUnit.parentGroup ? storageUnit.parentGroup.storageDate : storageUnit.storageDate;
  return date ? moment(date).tz("Europe/Berlin").format(dateFormat) : null;
}

export function getUnloadDateByStu(storageUnit, dateFormat = "DD.MM.YYYY") {
  const date = storageUnit.unloadDate;
  return date ? moment(date).tz("Europe/Berlin").format(dateFormat) : null;
}

export function getLocationByStu(storageUnit) {
  return storageUnit.parentGroup ? storageUnit.parentGroup.location : storageUnit.location;
}

export function getLocationNumberByStu(storageUnit) {
  const location = getLocationByStu(storageUnit);
  return location ? getLocationNumber(location) : null;
}

function getErrorMessage(code) {
  switch (code) {
    case "locationNotFound":
      return "Es konnte kein Lagerort mit der gescannten ID gefunden werden.";
    case "storageUnitNotFound":
      return "Es konnte keine Lagereinheit mit der gescannten ID gefunden werden.";
    case "storageGroupNotFound":
      return "Es konnte keine Lagergruppe mit der gescannten ID gefunden werden.";
    case "notYetReceived":
      return "Nicht empfangene Einheiten können nicht eingelagert werden.";
    case "alreadyRemoved":
      return "Die Einheit wurde schon aus dem Lager entfernt und kann nicht in den Lagerplatz eingebucht werden. Prüfe ob sie Teil einer augehenden Lieferung (FBA) ist.";
    case "storeGroupInstead":
      return "Einheiten, die Teil einer Gruppe sind können nicht einzeln eingelagert werden.";
    case "removeGroupInstead":
      return "Einheiten, die Teil einer Gruppe sind können nicht einzeln ausgelagert werden.";
    case "removeFromGroupFirst":
      return "Einheiten, die Teil einer Gruppe sind können nicht als entfernt markiert werden.";
    case "removeFromLocationFirst":
      return "Einheiten, die eingelagert sind können nicht als entfernt markiert werden.";
    case "itemAlreadyStoredAtLocation":
      return "Diese Einheit ist diesem Lagerplatz schon zugeordnet.";
    case "somethingAlreadyStoredAtLocation":
      return "Es ist schon etwas diesem Lagerplatz zugeordnet.";
    case "pickingSpotMatch":
      return "Dieser Lagerplatz ist ein Picking-Platz. Der Inhalt der Lagereinheit wird vollständig in den Lagerplatz eingelager.";
    case "storeGroupPickingSpotIncompatible":
      return "Dieser Lagerplatz ist ein Picking-Platz. Es können keine Lagergruppen eingelagert werden. Entferne eine Lagereinheit aus der Gruppe um sie in den Picking-Platz einzulagern.";
    case "storeGroupBlockStorageIncompatible":
      return "Dieser Lagerplatz ist ein Blocklagerplatz. Es können hier keine Lagergruppen eingelagert werden.";
    case "pickingSpotPartialMatch":
      return "Dieser Lagerplatz ist ein Picking-Platz. Alle Einheiten mit passender SKU werden hier eingelagert. Es verbleiben noch Einheiten mit anderer SKU ind er Lagereinheit.";
    case "notPickingSpot":
      return "Dieser Lagerplatz ist kein Picking-Platz. Einzelne Produkte können hier nicht eingelagert werden.";
    case "invalidLocation":
      return "Dieser Picking-Platz ist schon einer anderen SKU zugeordnet.";
    case "pickingSpotNotConfigured":
      return "Dieser Picking-Platz ist noch nicht konfiguriert.";
    case "unpackStorageUnit":
      return "Dieser Lagerplatz ist ein Picking-Platz. Lagereinheiten müssen entpackt werden um hier eingelagert zu werden.";
    case "printRequestInvalid":
      return "Label konnte nicht gedruckt werden, die angeforderten ID ist nicht für den Labeldruck vorgesehen.";
    case "invalidCodeForPrint":
      return "Label konnte nicht gedruckt werden, weil die gewünschte Einheit nicht gefunden wurde.";
    case "printerNotFound":
      return "Der angegebene Drucker konnte nicht gefunden werden, bitte ändere deine Auswahl im Drucker-Menü.";
    case "noPrinterChosen":
      return "Es wird kein Label gedruckt, weil die Option 'Kein Drucker' gewählt ist.";
    default:
      return `${
        code ? "Fehlercode " + code + " " : ""
      }Es ist ein unerwarteter Fehler aufgetreten. Bitte wende dich an den Administrator.`;
  }
}

function getModalTexts(code) {
  switch (code) {
    case "notYetReceived":
      return {
        title: "Noch nicht empfangen",
        description: "Die Einheit wurde noch nicht empfangen, möchtest du dies jetzt tun?",
        confirmText: "Zur Warenannahme",
      };
    case "itemAlreadyStoredAtLocation":
      return {
        title: "Schon eingelagert",
        description: "Diese Einheit ist diesem Lagerplatz schon zugeordnet, möchtest du sie trotzdem hier einlagern?",
        confirmText: "Erneut einlagern",
      };
    case "somethingAlreadyStoredAtLocation":
      return {
        title: "Lagerplatz belegt",
        description: "Es ist schon etwas diesem Lagerplatz zugeordnet, möchtest du den Inhalt ersetzen?",
        confirmText: "Ersetzen",
      };
    case "unpackStorageUnit":
      return {
        title: "Lagereinheit entpacken",
        description: "Möchtest du den Inhalt der Lagereinheit jetzt entpacken?",
        confirmText: "Ersetzen",
      };
    default:
      return {
        title: "Bist du sicher?",
        description: "Bist du sicher, dass du fortfahren möchtest?",
        confirmText: "Fortfahren",
      };
  }
}

const warehouseService = {
  loadStorageUnits,
  loadWarehouseLocation,
  loadPickingSpots,
  loadStorageUnit,
  loadStorageGroup,
  loadProduct,
  loadProductLocations,
  updateStorageUnit,
  createStorageUnit,
  getUnitInfo,
  storeUnitAtLocation,
  storeGroupAtLocation,
  changeProductQuantity,
  createStorageGroup,
  updateStorageGroup,
  updateLocationSettings,
  removeFromGroup,
  getLocationNumber,
  getCurrentQuantity,
  getReceivedQuantity,
  printReturnLabel,
  printUnitLabel,
  printProductLabel,
  printRackLabel,
  testCompatibility,
  testChangeWarehouseLocation,
  removeStorageUnitFromLocation,
  removeStorageGroupFromLocation,
  removeStorageUnitFromWarehouse,
  getLocationByStu,
  getLocationNumberByStu,
  getStorageDateByStu,
  getUnloadDateByStu,
};

export default warehouseService;
