import React from "react";
import BootstrapButton from "react-bootstrap/Button";
import { createModal } from "react-modal-promise";
import SimplePromiseModal from "../common/SimplePromiseModal";
import { isSafeToClick } from "../../utils/helper";

const PrintButton = ({ onPrint, description = "", compact = true, outline = false, color = "light", classes }) => {
  const showConfirmModal = createModal(SimplePromiseModal);

  const handlePrint = async () => {
    const result = await showConfirmModal({
      title: "Etikett drucken",
      description: description,
      confirmText: "Drucken" + isSafeToClick(false),
    });
    if (!result) return;
    onPrint();
  };

  return (
    <BootstrapButton
      variant={`${outline ? "outline-" : ""}${color ? color : "light"}`}
      className={`${compact ? "btn-sm " : ""} px-3 ${classes}`}
      onClick={handlePrint}
    >
      <i className="fas fa-print"></i>
      {isSafeToClick(true)}
    </BootstrapButton>
  );
};

export default PrintButton;
