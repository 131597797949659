import React from "react";
import Contents from "./Contents";
import Location from "./Location";
import GroupContents from "./GroupContents";
import LocationProducts from "./LocationProducts";
import ConnectedLocations from "./ConnectedLocations";
import warehouseService from "../../services/warehouseService";

const LocationContent = ({ data, onReload, onAdjustStock }) => {
  const { pickingSpot, storageUnit, storageUnitMultiple, storageGroup, contents, warehouse, connectedLocations } = data;

  return (
    <main className="container-fluid spacing-top">
      <div className="row">
        <div className="col">
          <p className="text-light-gray mb-0">Lagerplatz</p>
          <h3 className="mb-0">
            <b>{warehouseService.getLocationNumber(data)}</b>
          </h3>
          <ConnectedLocations data={connectedLocations} />
          <p className="mb-4 text-muted">{warehouse === "Main warehouse" ? "Hauptlager" : warehouse}</p>
        </div>
        <div className="col-auto">
          <div
            className={`m-2 ${
              pickingSpot
                ? "btn btn-circle-icon btn-brown padding-y-6"
                : "btn btn-circle-icon btn-secondary padding-y-4"
            }`}
          >
            {pickingSpot ? <i className="fas fa-dolly"></i> : <i className="fas fa-boxes"></i>}
          </div>
        </div>
      </div>
      <div>
        <Location location={data} hideNumber={true} onReload={onReload} />
        {storageUnit ? <Contents storageUnit={storageUnit} showUnitId={true} /> : ""}
        {storageUnitMultiple
          ? storageUnitMultiple.map(su => <Contents key={su._id} storageUnit={su} showUnitId={true} />)
          : ""}
        {storageGroup ? <GroupContents storageGroup={storageGroup} showGroupNumber={true} /> : ""}
        {contents && contents.length ? <LocationProducts contents={contents} onAdjustStock={onAdjustStock} /> : ""}
        {!storageUnit && !storageGroup && (!contents || !contents.length) ? (
          <p className="text-light-grey text-center mt-5" style={{ fontSize: "20px" }}>
            Lagerplatz leer
          </p>
        ) : (
          ""
        )}
      </div>
    </main>
  );
};

export default LocationContent;
