import React, { Component } from "react";

class SearchForm extends Component {
  state = {
    query: "",
  };

  handleChange = ({ currentTarget: input }) => {
    const query = input.value;
    if (query === "") this.handleReset();

    this.setState({ query });
  };

  handleReset = () => {
    this.props.onReset("");
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state.query);
  };

  render() {
    const { placeholder, submitLabel, noMargin, small } = this.props;
    const { query } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div
          className={
            small
              ? noMargin
                ? "input-group"
                : "input-group my-4"
              : noMargin
              ? "input-group input-group-lg"
              : "input-group my-4 input-group-lg"
          }
        >
          <input
            type="text"
            name="query"
            placeholder={placeholder}
            value={query}
            onChange={this.handleChange}
            className="form-control pr-0"
          />
          <div className="input-group-append">
            <button className="btn btn-primary" dangerouslySetInnerHTML={{ __html: submitLabel }}></button>
          </div>
        </div>
      </form>
    );
  }
}

export default SearchForm;
