import React from "react";
import TableHeader from "./TableHeader";
import TableBodyStacked from "./TableBodyStacked";
import PropTypes from "prop-types";

// columns: object
// sortColumn: object
// onSort: function
const TableStacked = ({ columns, data, getId, sortColumn, onSort, style }) => {
  return (
    <div className="table-responsive" style={style}>
      <table className="table table-hover align-middle table-responsive-stack">
        <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
        <TableBodyStacked getId={getId} data={data} columns={columns} />
      </table>
    </div>
  );
};

TableStacked.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getId: PropTypes.func.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
};

TableStacked.defaultProps = {
  getId: item => item._id,
};

export default TableStacked;
