import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["CF-Access-Client-Id"] = process.env.REACT_APP_CLOUDFLARE_CLIENT_ID;
axios.defaults.headers.common["CF-Access-Client-Secret"] = process.env.REACT_APP_CLOUDFLARE_CLIENT_SECRET;

axios.interceptors.response.use(null, error => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

  if (!expectedError) {
    toast.error(`Es ist ein unerwarteter Fehler aufgetreten. ${error.message}`, {
      pauseOnFocusLoss: false,
      closeOnClick: false,
      autoClose: 5000,
    });
  }
  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

const httpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt,
};

export default httpService;
