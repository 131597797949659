import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "../common/Loader";
import RegisterForm from "./RegisterForm";

const AddSupplierModal = ({ show, onHide, onSubmit }) => {
  const [spinner, setSpinner] = useState(false);
  return (
    <Modal
      show={show}
      onHide={onHide}
      onSubmit={onSubmit}
      size="xs"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="max480"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add supplier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {spinner ? (
          <div className="m-5">
            <Loader />
          </div>
        ) : (
          <RegisterForm onSubmit={onSubmit} setSpinner={setSpinner} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddSupplierModal;
