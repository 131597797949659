import React from "react";

const StorageUnitList = ({ items, onDelete }) => {
  return (
    <ul class="list-group text-left">
      {items.map(i => (
        <li class="list-group-item d-flex justify-content-between bg-light border-light">
          <div className="form-row align-items-center">
            <div className="col-auto">
              <b>{i.storageUnitId}</b>
            </div>
            <div className="col-auto">
              {i.category === "box"
                ? "Karton"
                : i.category === "single" || i.category === "single_multiple"
                ? "Stück"
                : "Einheit"}{" "}
              {i.unitNo}
            </div>
          </div>
          <div className="form-row align-items-center">
            <div className="col-auto">
              <i class="fas fa-trash-alt text-secondary" onClick={() => onDelete(i)}></i>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default StorageUnitList;
