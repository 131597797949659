import React, { useRef, useEffect } from "react";

const AutoFocusInput = ({ ...rest }) => {
  const inputElement = useRef(null);
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  return <input {...rest} ref={inputElement} />;
};

export default AutoFocusInput;
