import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "../common/Form";
import Loader from "../common/Loader";
import { isSafeToClick } from "../../utils/helper";
import { returnLocalizedString } from "../../services/localizationService";
import { getProductsList } from "../../services/productsService";
import _ from "lodash";
import SearchBox from "../common/SearchBox";
import Checkbox from "../common/Checkbox";

class SelectChildrenModal extends Form {
  state = {
    data: {},
    products: {},
    searchTerm: "",
    selectAll: false,
    errors: {},
  };

  schema = {};

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.loadProductList();
    }
  }

  loadProductList = async () => {
    console.log("Loading data");
    const { currentObject } = this.props;

    const data = {};
    const products = {};

    const productsData = await getProductsList(false);

    //Create initial state
    productsData.forEach(({ _id, parentProduct, sku, name }) => {
      data[_id] = parentProduct === currentObject._id;
      products[_id] = {
        _id,
        sku,
        name,
        parentProduct,
      };
    });
    this.setState({ data, products });
  };

  handleSearch = input => {
    this.setState({ searchTerm: input });
  };

  handleSelectAll = () => {
    const newState = !this.state.selectAll;
    this.setState({ selectAll: newState });

    const data = { ...this.state.data };
    const filtered = this.getPageData();
    filtered.forEach(f => (data[f._id] = newState));
    this.setState({ data });
  };

  onHide = () => {
    this.props.onHide();
    setTimeout(this.resetState, 500);
  };

  doSubmit = async () => {
    const { data, products } = this.state;
    const selectedProductIds = Object.keys(data).filter(p => data[p] === true);
    const selectedProductSkus = selectedProductIds.map(_id => products[_id].sku);
    await this.props.onSubmit(selectedProductSkus);
    setTimeout(this.resetState, 500);
  };

  resetState = () => {
    this.setState({
      data: {},
      products: {},
      searchTerm: "",
      selectAll: false,
      errors: {},
    });
  };

  getPageData = () => {
    const { searchTerm, products } = this.state;
    const { currentObject } = this.props;
    const mappedProducts = Object.keys(products).map(p => products[p]);
    const filteredProducts =
      searchTerm === "active"
        ? mappedProducts.filter(p => p.parentProduct === currentObject._id)
        : mappedProducts.filter(
            p =>
              p.name.toLowerCase().match(searchTerm.replace("(", "\\(").replace(")", "\\)").toLowerCase()) ||
              p.sku.toLowerCase().match(searchTerm.replace("(", "\\(").replace(")", "\\)").toLowerCase())
          );
    return _.sortBy(filteredProducts, ["name", "sku"]);
  };

  render() {
    const { show, onSubmit, spinner, lang, currentObject } = this.props;
    const { selectAll, searchTerm } = this.state;

    const filteredProducts = this.getPageData();

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        onSubmit={onSubmit}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Zugehörige Artikel auswählen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {spinner || !filteredProducts ? (
            <div className="m-5">
              <Loader />
            </div>
          ) : (
            <>
              <div className="form-row">
                <div className="col-12">
                  <h4>{currentObject && returnLocalizedString(currentObject.name, lang)}</h4>
                </div>
                <div className="col-12 col-lg-6">
                  Wähle die Artikel aus, die diesem übergeordneten Artikel zugeordnet werden sollen.
                </div>
              </div>
              <div className="form-row">
                <div className="col-12">
                  <SearchBox placeholder="Produkte filtern..." value={searchTerm} onChange={this.handleSearch} />
                </div>
              </div>
              <div className="form-row mb-4">
                <div className="col-12">
                  <Checkbox
                    name="selectAll"
                    id="selectAll"
                    label={`${searchTerm ? "Alle Suchergebnisse" : "Alle"} auswählen`}
                    isSelected={selectAll}
                    onCheckboxChange={this.handleSelectAll}
                  />
                </div>
              </div>
              <div className="form-row mb-3">
                {filteredProducts &&
                  filteredProducts.map(product => (
                    <div key={product._id} className="col-12 col-lg-6">
                      {this.renderCheckbox(product._id, returnLocalizedString(product.name, lang) || product.name)}
                    </div>
                  ))}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="full-width">
            <Button variant="secondary" className="mr-2" onClick={this.onHide}>
              Abbrechen
            </Button>
            {this.renderButton(`Speichern ${isSafeToClick(true)}`, this.handleSubmit)}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SelectChildrenModal;
