import React, { Fragment } from "react";
import Form from "./Form";

class AutoSaveField extends Form {
  state = {
    savingSpinner: false,
    format: true,
    errors: {},
  };
  schema = this.props.joiSchema;

  componentDidMount() {
    this.setState({ savingSpinner: false, format: true });
  }

  handleFocus = async ({ currentTarget: input }) => {
    this.setState({ format: false });
    const { onFocus } = this.props;
    if (!onFocus) return;
    onFocus(input.value);
  };

  handleChange = async ({ currentTarget: input }) => {
    const { saveOnChange, onChange } = this.props;
    onChange(input.value);
    if (saveOnChange) this.handleSave(input);
  };

  handleBlur = async ({ currentTarget: input }) => {
    this.setState({ format: true });
    this.handleSave(input, true, true);
  };

  handleSave = async (input, spinner = false) => {
    if (spinner) this.setState({ savingSpinner: true });
    const { onSave } = this.props;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (!errorMessage) {
      delete errors[input.name];
      await onSave(input.value);
    } else {
      errors[input.name] = errorMessage;
    }

    this.setState({ savingSpinner: false });
    this.setState({ errors });
  };

  formatValue(input) {
    const { decimals, type } = this.props;
    if (this.state.format) {
      if (!decimals) return input;
      const output = type === "number" ? parseFloat(input).toFixed(decimals) : input;
      return output;
    }
  }

  render() {
    const { name, label, placeholder, value, type, step } = this.props;
    let { savingSpinner } = this.state;

    return (
      <Fragment>
        {this.renderInputPassive(name, label, placeholder, type, this.formatValue(value), savingSpinner, step)}
      </Fragment>
    );
  }
}

export default AutoSaveField;
