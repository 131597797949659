import React, { Fragment, useEffect, useState } from "react";
import warehouseService from "../../../services/warehouseService";
import PrepCenterTable from "./PrepCenterTable";
import Pagination from "../../common/Pagination";
import SearchForm from "../../common/SearchForm";

const PrepCenter = ({ shipmentUnits, onAdd }) => {
  const [storageUnits, setStorageUnits] = useState([]);
  const [sortColumnPrepItems, setSortColumnPrepItems] = useState({ path: "receiveDate", order: "asc" });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();

  const pageSize = 10;

  useEffect(() => {
    (async () => {
      const { data, totalCount } = await warehouseService.loadStorageUnits(
        currentPage,
        pageSize,
        sortColumnPrepItems,
        searchQuery
      );
      if (!data) return;

      const filteredData = data.filter(stu => !shipmentUnits.find(f => f._id === stu._id));
      setStorageUnits(filteredData);
      setTotalCount(totalCount);
    })();
  }, [currentPage, pageSize, sortColumnPrepItems, searchQuery, shipmentUnits]);

  const handleSearch = input => {
    setCurrentPage(1);
    setSearchQuery(input);
  };

  const handleAdd = storageUnit => {
    onAdd(storageUnit);
    // paginate();
  };

  return (
    <Fragment>
      <div className="bg-light p-3">
        <div className="row">
          <div className={`col ${shipmentUnits.length ? "mb-3" : "my-auto"}`}>
            Wähle aus der Liste der zu versendenden Einheiten aus.
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col my-auto">
          <SearchForm placeholder="Suche..." submitLabel="Suchen" onSubmit={handleSearch} onReset={handleSearch} />
          <PrepCenterTable
            data={storageUnits}
            sortColumn={sortColumnPrepItems}
            onSort={setSortColumnPrepItems}
            onAction={handleAdd}
            type="add"
          />
          <Pagination
            currentPage={currentPage}
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default PrepCenter;
