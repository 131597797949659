import React from "react";
import TableStacked from "../../common/TableStacked";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import warehouseService, { getCurrentQuantity } from "../../../services/warehouseService";
import { isSafeToClick } from "../../../utils/helper";

const PrepCenterTable = ({ data, sortColumn, onSort, onAction, type }) => {
  const columns = [
    {
      path: "receiveDate",
      label: "Eingang",
      content: prepUnit =>
        prepUnit.receiveDate
          ? moment(prepUnit.receiveDate).tz("Europe/Berlin").format("DD.MM.YYYY")
          : "Noch nicht eingegangen",
    },
    {
      path: "storageUnitId",
      label: "Nr.",
      content: prepUnit => (
        <p className="m-0">
          {`Nr. ${prepUnit.unitNo} von ${prepUnit.totalUnitsShipment}`}
          <br />
          <Link to={`/warehouse/storageunit/${prepUnit.storageUnitId}`}>{prepUnit.storageUnitId}</Link>
        </p>
      ),
    },
    {
      path: "location",
      label: "Lagerplatz",
      content: prepUnit => (
        <p className="m-0">
          {prepUnit.location || prepUnit.parentGroup?.location
            ? warehouseService.getLocationNumber(prepUnit.location || prepUnit.parentGroup?.location, true)
            : "Nicht eingelagert"}
        </p>
      ),
    },
    {
      path: "parentShipmentId.shipmentId",
      label: "Lieferung",
      content: prepUnit =>
        prepUnit.parentShipmentId ? prepUnit.parentShipmentId.shipmentId : "Keiner Lieferung zugeordnet",
    },
    {
      path: "contents.0.poNumber",
      label: "PO-Nummer",
      contents: prepUnit => prepUnit.contents.map(p => p.poNumber).join(", "),
    },
    {
      path: "contents",
      label: "Inhalt",
      content: prepUnit =>
        prepUnit.contents.map((c, i) => (
          <p key={c.sku + "-" + i} className="m-0">
            {c.name}
            <br />
            {c.sku}
            <br />
            {getCurrentQuantity(c)} Stück
          </p>
        )),
    },
    {
      path: "action",
      label: "",
      content: prepUnit => (
        <Button onClick={() => onAction(prepUnit)} variant={type === "delete" ? "danger" : "primary"}>
          {type === "add" ? (
            <i className="fas fa-plus"></i>
          ) : type === "delete" ? (
            <i className="fas fa-minus-circle"></i>
          ) : (
            "Los"
          )}
          {isSafeToClick(true)}
        </Button>
      ),
    },
  ];
  return (
    <TableStacked columns={columns} data={data} sortColumn={sortColumn} getId={item => item._id} onSort={onSort} />
  );
};

export default PrepCenterTable;
