import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Pagination = ({ currentPage, itemsCount, pageSize, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  const displayRange = 6;

  // if (pagesCount === 0) return null;

  const startNumber = Math.max(1, currentPage - displayRange / 2);
  const endNumber = Math.min(pagesCount, startNumber + displayRange);
  const pages = _.range(startNumber, endNumber + 1);

  return (
    <nav aria-label="pagination">
      <ul className="pagination">
        <li>
          <button
            style={{ width: "35px" }}
            onClick={() => onPageChange(1)}
            disabled={currentPage === 1}
            className={`px-1 page-link${currentPage === 1 ? " text-light-grey" : ""}`}
          >
            <i className="fas fa-angle-double-left"></i>
          </button>
        </li>
        <li>
          <button
            style={{ width: "35px" }}
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-1 page-link${currentPage === 1 ? " text-light-grey" : ""}`}
          >
            <i className="fas fa-angle-left"></i>
          </button>
        </li>
        {pages.map(page => (
          <li key={page} className={page === currentPage ? "page-item active" : "page-item"}>
            <button style={{ width: "35px" }} onClick={() => onPageChange(page)} className="px-1 page-link">
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
            style={{ width: "35px" }}
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage >= pagesCount - 1}
            className={`px-1 page-link${currentPage >= pagesCount - 1 ? " text-light-grey" : ""}`}
          >
            <i className="fas fa-angle-right"></i>
          </button>
        </li>
        <li>
          <button
            style={{ width: "35px" }}
            onClick={() => onPageChange(pagesCount)}
            disabled={currentPage >= pagesCount - 1}
            className={`px-1 page-link${currentPage >= pagesCount - 1 ? " text-light-grey" : ""}`}
          >
            <i className="fas fa-angle-double-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
