import React, { Fragment } from "react";
import Button from "../common/Button";
import PrintButton from "./PrintButton";
import { useHistory } from "react-router";
import warehouseService from "../../services/warehouseService";
import ProductLineItem from "./ProductLineItem";
import { isSafeToClick } from "../../utils/helper";

const GroupContents = ({ storageGroup, onEditGroup, showGroupNumber }) => {
  const { contents, storageGroupId } = storageGroup;
  const history = useHistory();

  const handleNavigateStorageUnit = storageUnitId => {
    history.push(`/warehouse/storageunit/${storageUnitId}/single`);
  };

  const handleNavigateStorageGroup = () => {
    history.push(`/warehouse/storagegroup/${storageGroupId}`);
  };

  const handlePrintGroupLabel = () => {
    warehouseService.printUnitLabel(storageGroupId);
  };

  return (
    <div>
      <div className="row justify-content-between align-items-center mb-2">
        <div className="col my-auto">
          <h5 className="text-light-gray mb-0">Inhalt {showGroupNumber && `(${storageGroupId})`}</h5>
        </div>
        <div className="col-auto">
          {onEditGroup ? (
            <div className="mb-1">
              <PrintButton onPrint={handlePrintGroupLabel} classes="mr-2" />
              <Button
                title={"Gruppe bearbeiten" + isSafeToClick(true)}
                onClick={onEditGroup}
                outline={false}
                color="light"
                classes="btn btn-sm"
              />
            </div>
          ) : (
            <Button
              title={"Gruppe öffnen" + isSafeToClick(true)}
              onClick={handleNavigateStorageGroup}
              outline={false}
              color="light"
              classes="btn btn-sm mb-1"
            />
          )}
        </div>
      </div>
      {contents && contents.length ? (
        <Fragment>
          {contents.map((c, i) => (
            <ul key={`${c.storageUnitId}`} className="list-group mb-3">
              <li className="list-group-item bg-light-gray">
                {c.storageUnitId && (
                  <div className="d-flex justify-content-between align-items-start mt-1">
                    <div>
                      <h5 className="mb-0">{c.storageUnitId}</h5>
                      {c.unitNo && (
                        <p>
                          {c.category === "pallet" ? "Palette" : c.category === "box" ? "Karton" : "Einheit"} Nr.{" "}
                          {c.unitNo}
                        </p>
                      )}
                    </div>
                    <Button
                      title={"Öffnen" + isSafeToClick(true)}
                      onClick={() => handleNavigateStorageUnit(c.storageUnitId)}
                      outline={false}
                      color="secondary"
                      classes="line-height-normal mt-1"
                    />
                  </div>
                )}

                {c.contents && c.contents.length && (
                  <Fragment>
                    <h5 className="text-light-gray">Inhalt</h5>
                    {c.contents.map((i, index) => (
                      <div key={`${i.name}-${index}`}>
                        <ProductLineItem product={i} loadPickingSpot={false} />
                      </div>
                    ))}
                  </Fragment>
                )}
              </li>
            </ul>
          ))}
        </Fragment>
      ) : (
        <div className="text-center text-light-gray pt-5">
          <h4>Kein Inhalt hinterlegt</h4>
        </div>
      )}
    </div>
  );
};

export default GroupContents;
