import React from "react";
import ProductCard from "./ProductCard";

const ProductList = ({ products, imageDb, onChoose, buttonText }) => {
  return (
    <div className="row">
      {products ? (
        products.length ? (
          products.map((product, i) => (
            <div key={product.sku + "-" + i} className="col-12 col-md-6 col-lg-4 mb-4">
              <ProductCard product={product} imageDb={imageDb} onChoose={onChoose} buttonText={buttonText} />
            </div>
          ))
        ) : (
          <p className="mx-auto">Keine Ergebnisse. Bitte Suchbegriff eingeben.</p>
        )
      ) : (
        <p className="mx-auto">Bitte Suchbegriff eingeben.</p>
      )}
    </div>
  );
};

export default ProductList;
