import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "../common/Card";
import Image from "../common/Image";
import ImageService from "../../services/imageService";
import Loader from "../common/Loader";
import Button from "../common/Button";

class ProductCard extends Component {
  mapProduct(product) {
    const { name, sku, ean } = product;
    return {
      title: name,
      sku,
      ean
    };
  }

  render() {
    const { onChoose, product, imageDb, buttonText } = this.props;
    function getImageUrl(sku) {
      return ImageService.getThumbUrl(sku, imageDb);
    }

    return (
      product.name && (
        <Card
          description={
            !product ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col">
                  {imageDb.loading ? <Loader /> : <Image url={getImageUrl(product.sku)} />}
                  <p className="mt-3">{product.name}</p>
                  <Button title={buttonText} onClick={() => onChoose(product)} />
                </div>
              </div>
            )
          }
        />
      )
    );
  }
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  onChoose: PropTypes.func.isRequired,
  imageDb: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired
};

export default ProductCard;
