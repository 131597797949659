import http from "./httpService";
import logger from "./logService";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";

const apiUrl = process.env.REACT_APP_API_URL;

// Gets list of parent products
export async function getParentProductsList(populateParentProduct = true) {
  try {
    const { data } = await http.get(
      `${apiUrl}parentproducts${populateParentProduct ? "?populateParentProduct=true" : ""}`
    );
    if (!data) return data;
    return data;
  } catch (err) {
    // No parent Products have been created yet
    if (err.response?.status === 404) return;
  }
}

export async function createParentProduct(body) {
  try {
    await http.post(apiUrl + `parentproducts`, body);
  } catch (err) {
    toast.error("Fehler beim Erstellen eines neuen übergeordneten Produktes.");
    logger.error("Error creaing a new parentProduct entry: ", err);
  }
}

export async function updateParentProduct(_id, body) {
  const leanBody = _.cloneDeep(body);
  delete leanBody.children;
  await http.put(apiUrl + `parentproducts/${_id}`, leanBody);
}

export async function deleteParentProduct(_id) {
  await http.delete(apiUrl + `parentproducts/${_id}`);
}

export async function setChildren(_id, childrenSkus) {
  // const body = children.map(child => console.l)
  const body = { childrenSkus };
  console.log(body);
  await http.put(`${apiUrl}parentproducts/setchildren/${_id}`, body);
}

export const parentProductSchema = {
  _id: Joi.string().allow(null),
  parentProduct: Joi.string().allow(null),
  name: Joi.array().items(
    Joi.object({
      lang: Joi.string().min(5).max(5),
      value: Joi.string().min(2).max(300).label("Name (mehrsprachig)"),
    })
      .required()
      .label("Name")
  ),
  requestReasonsOptions: Joi.array().items(Joi.object({ requestReason: Joi.string(), returnNeeded: Joi.boolean() })),
  sellRefillStock: Joi.boolean(),
  useWarehouseStock: Joi.boolean(),
  category: Joi.string().max(150).allow(""),
  active: Joi.boolean(),
  fba: Joi.boolean(),
  dropshipping: Joi.boolean(),
  leadTime: Joi.number().min(0).max(999),
  restockTime: Joi.number().min(0).max(999),
  returnFee: Joi.number().min(0).max(999).label("Rücksendegebühr"),
  returnParcelService: Joi.string().required().label("Rücksendeweg"),
  returnInstructions: Joi.array()
    .items(
      Joi.object({
        lang: Joi.string().min(5).max(5),
        value: Joi.string().min(0).allow(""),
      })
    )
    .label("Rücksendeanleitung"),
  returnConditions: Joi.array()
    .items(
      Joi.object({
        lang: Joi.string().min(5).max(5),
        value: Joi.string().min(0).allow("").label("Voraussetzungen für die Rücksendung (mehrsprachig)"),
      })
    )
    .label("Voraussetzungen für die Rücksendung"),
  returnNotice: Joi.array()
    .items(
      Joi.object({
        lang: Joi.string().min(5).max(5),
        value: Joi.string().min(0).allow(""),
      })
    )
    .label("Erläuterung zur Rücksendung"),
  batchNumberDescription: Joi.array()
    .items(
      Joi.object({
        lang: Joi.string().min(5).max(5),
        value: Joi.string().min(0).allow(""),
      })
    )
    .label("Chargennummer Handlungsanleitung"),
  batchNumberOptions: Joi.string().label("Auswahl-Optionen für die Chargennummer (kommasepariert)").allow(""),
  batchNumberNeeded: Joi.boolean(),
  internalReasons: Joi.array().items(Joi.string()),
};

export const parentProductEmptyData = {
  parentProduct: null,
  name: [],
  requestReasonsOptions: [],
  useWarehouseStock: true,
  sellRefillStock: true,
  category: "",
  active: true,
  fba: false,
  dropshipping: false,
  leadTime: 60,
  restockTime: 10,
  returnFee: 0,
  returnParcelService: "",
  returnInstructions: [],
  returnConditions: [],
  batchNumberNeeded: false,
  batchNumberDescription: [],
  batchNumberOptions: "",
  internalReasons: [],
};
