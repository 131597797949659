import React, { Fragment } from "react";
import warehouseService from "../../services/warehouseService";
import SettingsButton from "./SettingsButton";
import PrintButtonRack from "./PrintButtonRack";
import ConnectedLocations from "./ConnectedLocations";
import { Link } from "react-router-dom";

const Location = ({ location, hideNumber, onReload, ...rest }) => {
  const { pickingSpot, storageCategory, connectedLocations } = location || {};

  const handlePrint = async (size = null) => {
    await warehouseService.printRackLabel(location.locationId, size);
  };

  return (
    <div {...rest}>
      {location ? (
        <Fragment>
          <div className="form-row justify-content-between mb-2 align-items-center">
            <div className="col">
              <h5 className="text-light-gray mb-0">Lagerplatz</h5>
            </div>
            <div className="col-auto">
              <PrintButtonRack onPrint={handlePrint} onReload={onReload} />
              <SettingsButton location={location} onReload={onReload} />
            </div>
          </div>
          <ul className="list-group mb-3">
            <li className="list-group-item bg-light-gray">
              {!hideNumber && (
                <Fragment>
                  <span className="text-light-gray">Nummer</span>
                  <span key={"locationBadge-stu"} className="badge badge-light mr-1">
                    <div key={location.locationId}>
                      <Link to={`/warehouse/location/${location.locationId}`} className="text-dark">
                        <span className="text-black">{warehouseService.getLocationNumber(location)}</span>
                      </Link>
                    </div>
                  </span>
                  <ConnectedLocations data={connectedLocations} />
                  <br />
                </Fragment>
              )}
              {
                <Fragment>
                  <span className="text-light-gray">Art</span>{" "}
                  {pickingSpot
                    ? "Picking-Platz"
                    : storageCategory === "pallet_multiple"
                    ? "Nachfüll-Lagerplatz (Blocklager)"
                    : "Nachfüll-Lagerplatz"}
                  <br />
                </Fragment>
              }
              {!pickingSpot ? (
                <Fragment>
                  <span className="text-light-gray">Lagereinheit</span>
                  {storageCategory === "pallet" || storageCategory === "palett"
                    ? " Palette"
                    : storageCategory === "box"
                    ? " Karton"
                    : storageCategory === "pallet_multiple"
                    ? " Paletten (Blocklager)"
                    : ""}
                  <br />
                </Fragment>
              ) : (
                ""
              )}
            </li>
          </ul>
        </Fragment>
      ) : (
        <Fragment>
          <div className="text-center text-light-gray pt-5">
            <h4>Noch nicht eingelagert</h4>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Location;
