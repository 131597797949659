import React from "react";
import { Link } from "react-router-dom";

const Button = ({ title, onClick, outline = true, color = "secondary", classes = "", ...rest }) => {
  return (
    <Link
      dangerouslySetInnerHTML={{ __html: title }}
      to="#"
      onClick={onClick}
      className={`btn btn${outline ? "-outline" : ""}-${color} ${classes ? ` ${classes}` : ""}`}
      {...rest}
    ></Link>
  );
};

export default Button;
