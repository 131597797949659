import { useState, useEffect } from "react";
import RemissionTable from "./RemissionTable";
import Pagination from "../common/Pagination";
import productsService from "../../services/productsService";
import { addRemission, getSessionRemissions, removeLatestRemission } from "../../services/remissionService";
import { toast } from "react-toastify";
import _ from "lodash";
import logger from "../../services/logService";
import { printProductLabel } from "../../services/warehouseService";

const Remissions = () => {
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [remissions, setRemissions] = useState({ aStock: {}, bStock: {}, notSellable: {} });
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const [dataLoading, setDataLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  //paginate
  useEffect(() => {
    const filteredProductsList = _.orderBy(products, sortColumn.path, sortColumn.order).filter(
      p =>
        p.ean &&
        p.sku &&
        p.sku.slice(p.sku.length - 2, p.sku.length) !== "-U" &&
        searchQuery.split(" ").every(keyword => JSON.stringify(p).toLowerCase().includes(keyword.toLowerCase()))
    );
    setTotalCount(filteredProductsList.length);
    const paginatedProductsList = filteredProductsList.slice((currentPage - 1) * pageSize, currentPage * pageSize - 1);

    setFilteredProducts(paginatedProductsList);
  }, [pageSize, currentPage, products, sortColumn, searchQuery]);

  async function loadData() {
    const [products, remissions] = await Promise.all([productsService.getAllProducts(), getSessionRemissions()]);
    setProducts(products);
    setRemissions(remissions);
    setDataLoading(false);
  }

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleSort = sortColumn => {
    setSortColumn(sortColumn);
  };

  const handleSearch = searchQuery => {
    setSearchQuery(searchQuery);
    setCurrentPage(1);
  };

  const handleAdd = async (sku, bStock, sellable) => {
    const remissionsStateCopy = _.cloneDeep(remissions);
    try {
      increaseRemissionState(sku, bStock, sellable);

      const sessionId = sessionStorage.getItem("sessionId");
      if (!sessionId) {
        toast.warning("Bitte starte zunächst die Aufbereitung");
        setRemissions(remissionsStateCopy);
        return;
      }

      await addRemission(sessionId, sku, bStock, sellable);
      if (sellable) {
        toast("Ein Label wird auf dem ausgewählten Drucker gedruckt.");
        await printProductLabel(sku + (bStock ? "-U" : ""));
      }
    } catch (err) {
      setRemissions(remissionsStateCopy);
      toast.error("Fehler beim Erhöhen der Anzahl der aufbereiteten Remissionen.");
      logger.error("Error while increasing the remission count", err);
    }
  };

  const handleSubtract = async (sku, bStock, sellable) => {
    const remissionsStateCopy = _.cloneDeep(remissions);
    try {
      decreaseRemissionState(sku, bStock, sellable);

      const sessionId = sessionStorage.getItem("sessionId");
      if (!sessionId) {
        toast.warning("Bitte starte zunächst die Aufbereitung");
        setRemissions(remissionsStateCopy);
        return;
      }

      await removeLatestRemission(sessionId, sku, bStock, sellable);
    } catch (err) {
      setRemissions(remissionsStateCopy);
      toast.error("Fehler beim Reduzieren der Anzahl der aufbereiteten Remissionen.");
      logger.error("Error while decreasing the remission count", err);
    }
  };

  const increaseRemissionState = (sku, bStock, sellable) => {
    const conditionKey = bStock ? "bStock" : sellable ? "aStock" : "notSellable";
    const newRemissions = _.cloneDeep(remissions);
    newRemissions[conditionKey][sku] = newRemissions[conditionKey][sku] ? newRemissions[conditionKey][sku] + 1 : 1;
    setRemissions(newRemissions);
  };

  const decreaseRemissionState = (sku, bStock, sellable) => {
    const conditionKey = bStock ? "bStock" : sellable ? "aStock" : "notSellable";
    const newRemissions = _.cloneDeep(remissions);
    if (!newRemissions[conditionKey][sku]) return toast.error("Der Vorgang ist nicht möglich.");
    newRemissions[conditionKey][sku] = newRemissions[conditionKey][sku] ? newRemissions[conditionKey][sku] - 1 : 0;
    setRemissions(newRemissions);
  };

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col">
          <RemissionTable
            products={filteredProducts}
            remissions={remissions}
            totalCount={totalCount}
            onSort={handleSort}
            onSearch={handleSearch}
            sortColumn={sortColumn}
            loading={dataLoading}
            onSubtract={handleSubtract}
            onAdd={handleAdd}
            onUpdateSession={loadData}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Pagination
            currentPage={currentPage}
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Remissions;
