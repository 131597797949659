import React from "react";

const SelectSimple = ({ name, label, chooseLabel, options, ...rest }) => {
  return (
    <React.Fragment>
      <label htmlFor="name" className="select mb-0">
        {label !== "" ? <label htmlFor={name}>{label}</label> : ""}
        <select name={name} id={name} {...rest} className="form-control">
          <option key="choose" value="">
            {chooseLabel}
          </option>
          {options.map(option => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </select>
      </label>
    </React.Fragment>
  );
};

export default SelectSimple;
