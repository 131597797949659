import React, { Fragment } from "react";
import Button from "react-bootstrap/Button";
import Contents from "./Contents";
import Location from "./Location";
import moment from "moment-timezone";
import { getLocationByStu, getStorageDateByStu, getUnloadDateByStu } from "../../services/warehouseService";
import { isSafeToClick } from "../../utils/helper";

const StorageUnitContent = ({ data, onEdit, onReceive, navigateToParentGroup }) => {
  const {
    storageUnitId,
    parentShipmentId,
    parentGroup,
    receiveDate,
    category,
    unitNo,
    inspection,
    totalUnitsShipment,
  } = data || {};

  return (
    <main className="container-fluid spacing-top">
      <div className="row">
        <div className="col">
          <p className="text-light-gray mb-0">{data.parentGroup ? "Gruppierte " : ""}Lagereinheit</p>
          <h3 className="mb-2">
            <b className={data.outboundDate ? "text-danger" : ""}>{`${storageUnitId}`}</b>
            {data.outboundDate ? (
              <p className="text-danger" style={{ fontSize: "14px" }}>
                Diese Einheit wurde aus dem Lager entfernt.
              </p>
            ) : (
              ""
            )}
          </h3>
          {unitNo && totalUnitsShipment && (
            <p>
              <b>
                {category === "box" ? "Karton" : category === "pallet" ? "Palette" : "Einheit"} {unitNo}/
                {totalUnitsShipment}
              </b>
            </p>
          )}
        </div>
        <div className="col-auto">
          {parentGroup ? (
            <Fragment>
              <div className={`move-behind-1 btn btn-circle-icon btn-warning padding-y-6`}></div>
              <div className={`move-behind-2 btn btn-circle-icon btn-warning padding-y-6`}></div>
            </Fragment>
          ) : (
            ""
          )}
          <Button
            onClick={parentGroup ? navigateToParentGroup : () => {}}
            className={`m-2 btn btn-circle-icon btn-warning padding-y-6`}
          >
            <i className="fas fa-pallet"></i>
            {isSafeToClick(true)}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="mb-4 text-muted">
            {parentShipmentId && parentShipmentId.shipmentId ? (
              <Fragment>
                <i className="fas fa-truck text-muted"></i> {parentShipmentId.shipmentId} <br />
              </Fragment>
            ) : (
              ""
            )}
            <i className="fas fa-truck-loading text-muted" alt="Empfangsdatum"></i>{" "}
            {receiveDate ? (
              moment(receiveDate).tz("Europe/Berlin").format("dd, DD. MMMM YYYY")
            ) : (
              <span className="text-danger">Noch nicht angenommen</span>
            )}
            <br />
            <i className="fas fa-pallet text-muted"></i>{" "}
            {getStorageDateByStu(data) ? (
              getStorageDateByStu(data, "dd, DD. MMMM YYYY")
            ) : !data.contents || !data.contents.length || data.unloadDate ? (
              <span className="text-success">
                {getUnloadDateByStu(data, "dd, DD. MMMM YYYY")}
                <small>
                  <i className="fas fa-arrow-right mx-2"></i>
                </small>
                Picking-Platz
              </span>
            ) : (
              <span className="text-danger">Noch nicht eingelagert</span>
            )}
          </p>
        </div>
      </div>
      <Contents storageUnit={data} onEdit={onEdit} onEditReceive={() => onReceive()} receiveDate={receiveDate} />
      {inspection && inspection.notes ? <div className="alert alert-warning">Notizen: {inspection.notes}</div> : ""}
      <Location location={getLocationByStu(data)} className="mb-5" />
    </main>
  );
};

export default StorageUnitContent;
