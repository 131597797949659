import React from "react";

const PleaseScan = ({ scanDescription, compact }) => {
  return (
    <div className="text-center">
      {compact ? (
        <>
          <h4 className="text-light-gray">
            {" "}
            <i className="fas fa-barcode mr-2" style={{ fontSize: "23px" }}></i>
            <b>Bitte scannen</b>
          </h4>
        </>
      ) : (
        <>
          <h2 className="text-light-gray my-4">
            <b>Bitte scannen</b>
          </h2>
        </>
      )}
      {compact ? (
        <p>
          <small>
            {scanDescription ||
              "Bitte scanne einen Barcode oder QR-Code auf einem Regal, einer Palette oder einem Produkt um fortzufahren."}
          </small>
        </p>
      ) : (
        <p className="mb-4">
          {scanDescription ||
            "Bitte scanne einen Barcode oder QR-Code auf einem Regal, einer Palette oder einem Produkt um fortzufahren."}
        </p>
      )}
      {!compact && (
        <p>
          <i className="fas fa-barcode" style={{ fontSize: "60px" }}></i>
        </p>
      )}
    </div>
  );
};

export default PleaseScan;
