import React, { Fragment } from "react";
import LoadingScreen from "./LoadingScreen";
import SubmitButton from "./SubmitButton";
import LocationContent from "./LocationContent";
import warehouseService from "../../services/warehouseService";
import { toast } from "react-toastify";

const LocationScreen = ({ id, data, onStore, onReload, onAdjustStock }) => {
  const { error, storageUnit, storageGroup, storageCategory: category, _id } = data;

  const handleStore = () => {
    onStore(_id);
  };

  const handleAdjustStock = async product => {
    await onAdjustStock(data, product);
  };

  const handleRemove = async () => {
    let result;
    if (storageUnit && storageUnit._id) {
      result = await warehouseService.removeStorageUnitFromLocation(storageUnit._id, _id);
    }
    if (storageGroup && storageGroup._id) {
      result = await warehouseService.removeStorageGroupFromLocation(storageGroup._id, _id);
    }
    if (result) toast("Einheit erfolgreich ausgelagert");
    await onReload();
  };

  return (
    <Fragment>
      {!Object.keys(data || {}).length ? (
        <LoadingScreen text="Lagerplatz wird geladen..." id={id} />
      ) : error ? (
        <Fragment>
          <h4 className="text-light-gray">Lagerplatz</h4>
          <h3 className="mb-0">
            <b>{error === 404 ? "Nicht gefunden" : "Server Fehler"}</b>
          </h3>
          <p className="mb-5">
            {error === 404
              ? `Eintrag für ${id} konnten nicht gefunden werden, stelle sicher, dass der Barcode lesbar ist und korrekt gescannt wurde.`
              : "Bitte wende dich an den Administrator"}
          </p>
        </Fragment>
      ) : (
        <div className="splitview">
          <LocationContent data={data} onReload={onReload} onAdjustStock={handleAdjustStock} />
          <footer className="container-fluid">
            {data.pickingSpot && data.storageCategory === "single" ? (
              <SubmitButton
                subject="Bestand"
                verb="anpassen"
                icon="sliders-h"
                onSubmit={() => data.contents.length && handleAdjustStock(data.contents[0].product)}
                buttonColor="secondary"
              />
            ) : (
              ""
            )}
            <SubmitButton
              subject={
                storageUnit || storageGroup
                  ? category === "pallet"
                    ? "Palette"
                    : category === "box"
                    ? "Karton"
                    : "Einheit"
                  : "Hier etwas"
              }
              verb={storageUnit || storageGroup ? "auslagern" : "einlagern"}
              icon="pallet"
              confirmationNeeded={storageUnit || storageGroup ? true : false}
              confirmationTitle="Einheit auslagern?"
              confirmationDescription="Bist du sicher, dass du die Einheit auslagern möchtest?"
              confirmText="Auslagern"
              onSubmit={storageUnit || storageGroup ? handleRemove : handleStore}
              buttonColor="primary"
            />
          </footer>
        </div>
      )}
    </Fragment>
  );
};

export default LocationScreen;
