import React from "react";
import Select from "../common/Select";
import Checkbox from "../common/Checkbox";
import Input from "../common/Input";
import Button from "../common/Button";
import { isSafeToClick } from "../../utils/helper";

const StorageUnit = ({ index, data, onChange, onDelete, onAddItem, onRemoveItem }) => {
  const handleChange = ({ currentTarget }, itemIndex = 0) => {
    onChange({ index: index, contentsIndex: itemIndex, name: currentTarget.name, value: currentTarget.value });

    const item = data.contents[itemIndex];
    //If -U is added check the bStock Checkbox
    if (
      currentTarget.name === "itemNumber" &&
      currentTarget.value.substr(currentTarget.value.length - 2, 2) === "-U" &&
      item.bStock === false
    )
      onChange({ index: index, contentsIndex: itemIndex, name: "bStock", value: true });

    //If the checkbox is unchecked and -U is in itemNumber, remove it
    if (currentTarget.name === "bStock") {
      if (item.itemNumber.substr(item.itemNumber.length - 2, 2) === "-U" && item.bStock === false) {
        onChange({
          index: index,
          contentsIndex: itemIndex,
          name: "itemNumber",
          value: item.itemNumber.substr(0, item.itemNumber.length - 2),
        });
      }
    }
  };

  return (
    <div className="form-row bg-light py-1">
      <div className="col-1 col-lg-1 col-xl-1 pl-3 min-50 py-2">
        <div className="pt-1">
          <b style={{ fontSize: "20px", padding: "10px 0px" }}>{index + 1}</b>
        </div>
      </div>
      <div className="col col-lg-11 col-xl-11 my-auto">
        <div className="form-row">
          <div className="col my-2">
            <Select
              name={"category"}
              value={data.category}
              label=""
              options={[
                { _id: "pallet", name: "Pallet" },
                { _id: "box", name: "Box" },
              ]}
              onChange={handleChange}
            />
          </div>
          <div className="col-auto col-lg-auto col-xl-auto py-2">
            <Button
              classes="line-height-normal"
              title={"<i class='fas fa-trash-alt'></i>" + isSafeToClick(true)}
              color="danger"
              onClick={() => onDelete(index, data._id)}
            />
          </div>
          <div className="col-12 my-auto">
            {data.contents.map((c, i) => (
              <div key={`content-item-${i}`} className="form-row">
                <div className="col-6 col-lg-3 col-xl-2 my-auto py-2">
                  <Input
                    name="poNumber"
                    type="string"
                    label=""
                    title="Purchase Order number must be of format 'PO-XXXXX where the X must be numbers.'"
                    placeholder="PO-No."
                    value={c.poNumber}
                    onChange={e => handleChange(e, i)}
                  />
                </div>
                <div className="col-6 col-lg-4 col-xl-3 my-auto py-2">
                  <Input
                    name="itemNumber"
                    type="string"
                    label=""
                    placeholder="EAN / SKU"
                    value={c.itemNumber || c.itemNumber === "" ? c.itemNumber : c.ean} //Fallback to old schema where the field was called c.ean
                    onChange={e => handleChange(e, i)}
                    className="w-100"
                  />
                </div>
                <div className="col-6 col-lg col-xl-auto my-auto py-2">
                  <Input
                    name="initialQuantity"
                    type="string"
                    label=""
                    placeholder="Quantity"
                    value={c.initialQuantity}
                    onChange={e => handleChange(e, i)}
                  />
                </div>
                <div className="col-auto col-lg-auto col-xl-auto my-auto px-2 py-2">
                  <Checkbox
                    name="bStock"
                    id={`bStock-${index}-${i}`}
                    label="B-Stock"
                    isSelected={c.bStock}
                    onCheckboxChange={e => handleChange(e, i)}
                  />
                </div>
                <div className="col-1 col-lg-auto my-auto">
                  {i === data.contents.length - 1 ? (
                    <Button
                      onClick={() => onAddItem(index)}
                      title="<i class='fas fa-plus-circle text-success'></i>"
                      classes="line-height-normal px-2"
                      outline={false}
                      color="light"
                    />
                  ) : (
                    <Button
                      onClick={() => onRemoveItem(index, i)}
                      title={"<i class='fas fa-minus-circle text-danger'></i>" + isSafeToClick(true)}
                      classes="line-height-normal px-2"
                      outline={false}
                      color="light"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorageUnit;
