import React, { useState, useContext, useEffect, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PleaseScan from "./PleaseScan";
import Barcode from "../common/Barcode";
import barcodeService, { BarcodeContext } from "../../services/barcodeService";
import Loader from "../common/Loader";
import { toast } from "react-toastify";

const DeleteModal = ({ loading, show, title, description, scanMessage, onHide, onSubmit, deleteContent }) => {
  const [scanData, setScanData] = useState({});
  const barcode = useContext(BarcodeContext);

  if (!loading) loading = { loading: false, message: "" };
  const { loading: isLoading, message } = loading;

  async function handleScan(data) {
    const scanFeedback = await barcodeService.getData(data);
    setScanData(scanFeedback);
  }

  const handleHide = () => {
    setScanData({});
    onHide();
  };

  useEffect(() => {
    (async () => {
      if (show && scanData.data) {
        if (scanData.type === "storageunit") {
          try {
            if (scanData.data === deleteContent._id || scanData.data === deleteContent.storageUnitId)
              onSubmit(deleteContent);
            else
              toast.warning(
                "Gescannter Code stimmt nicht mit der zu löschenden Lagereinheit überein. Bitte versuche es erneut."
              );
          } catch (err) {
            toast.warning(
              "Gescannter Code stimmt nicht mit der zu löschenden Lagereinheit überein. Bitte versuche es erneut."
            );
          }
        } else {
          toast.warning("Bitte scanne die zum Löschen ausgewählte Lagereinheit.");
        }
      }
    })(); // eslint-disable-next-line
  }, [scanData, deleteContent]);

  useEffect(() => {
    setScanData({});
  }, [show]);

  return (
    <Fragment>
      {show && barcode.scanModeConnect && <Barcode onScan={handleScan} />}
      <div className="modal-dialog">
        <Modal
          show={show}
          onHide={handleHide}
          onSubmit={onSubmit}
          size="sm"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="fullscreen-modal"
        >
          <Modal.Body>
            <div className="splitview">
              <main className="container-fluid spacing-top">
                <div className="row text-center mb-3">
                  <div className="col">
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col p-3">
                    {isLoading ? <Loader message={message} /> : <PleaseScan scanDescription={scanMessage} />}
                  </div>
                </div>
              </main>
              <footer>
                <div className="container-fluid mb-2">
                  <Button variant="secondary" onClick={onHide} className="btn-block">
                    Abbrechen
                  </Button>
                </div>
              </footer>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

export default DeleteModal;
