import React, { Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import AddSupplierModal from "./AddSupplierModal";
import Button from "react-bootstrap/Button";
import Form from "../common/Form";
import Loader from "../common/Loader";
import Joi from "joi-browser";
import { getCurrentUser } from "../../services/authService";
import { getListOfSupplierCodes } from "../../services/shipmentService";

class AddShipmentModal extends Form {
  state = {
    data: {
      shippingDate: "",
      supplierShortCode: "",
    },
    shortCodes: [],
    showShortCodeField: false,
    showAddSupplierModal: false,
    errors: {},
  };

  async componentDidMount() {
    const user = getCurrentUser();
    const data = { ...this.state.data };
    data.supplierShortCode = (user && user.shortCode) || "";
    const showShortCodeField = data.supplierShortCode ? false : true;

    if (showShortCodeField) await this.refreshSupplierList();

    this.setState({ data, showShortCodeField });
  }

  schema = {
    shippingDate: Joi.date().label("Shipping date"),
    supplierShortCode: Joi.string().label("ShortCode for supplier"),
  };

  refreshSupplierList = async () => {
    const result = await getListOfSupplierCodes();
    const shortCodes = result.map(s => ({ name: s, _id: s }));
    shortCodes.sort((a, b) => a.name.localeCompare(b.name));
    this.setState({ shortCodes });
  };

  handleHide = () => {
    this.props.onHide();
    setTimeout(this.resetState, 500);
  };

  handleAddSupplier = () => {
    this.setState({ showAddSupplierModal: true });
  };

  handleAddSupplierSave = async () => {
    await this.refreshSupplierList();
    this.setState({ showAddSupplierModal: false });
  };

  handleHideAddSupplierModal = () => {
    this.setState({ showAddSupplierModal: false });
  };

  doSubmit = async () => {
    try {
      console.log("Errors", this.errors);
      await this.props.onSubmit(this.state.data);
      this.props.onHide();
      setTimeout(this.resetState, 500);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.shippingDate = err.response.data;
        this.setState({ errors });
      }
    }
  };

  resetState = () => {
    this.setState({
      data: {
        shippingDate: null,
        supplierShortCode: "",
      },
      errors: {},
    });
  };

  render() {
    const { show, onHide, spinner } = this.props;
    const { showShortCodeField, shortCodes } = this.state;

    return (
      <Fragment>
        <Modal
          show={show}
          onHide={onHide}
          size="xs"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="max480"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Add shipment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {spinner ? (
              <div className="m-5">
                <Loader />
              </div>
            ) : (
              <Fragment>
                <p>Please set the shipping date. This is the date when the goods leave your factory</p>
                <div className="row">
                  <div className="col">{this.renderDatePicker("shippingDate", "Shipping date")}</div>
                </div>
                {showShortCodeField && (
                  <div className="form-row align-items-end">
                    <div className="col">
                      {this.renderSelect(
                        "supplierShortCode",
                        "Supplier short code",
                        "Bitte wählen...",
                        shortCodes || []
                      )}
                    </div>
                    <div className="col-auto">
                      <Button variant="secondary" onClick={this.handleAddSupplier} title="Add supplier account">
                        <i className="fas fa-plus"></i>
                      </Button>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button variant="secondary" className="mr-2" onClick={this.handleHide}>
              Abbrechen
            </Button>
            {this.renderButton("Add shipment", this.handleSubmit)}
          </Modal.Footer>
        </Modal>
        <AddSupplierModal
          show={this.state.showAddSupplierModal}
          onHide={this.handleHideAddSupplierModal}
          onSubmit={this.handleAddSupplierSave}
          spinner={false}
        />
      </Fragment>
    );
  }
}

export default AddShipmentModal;
