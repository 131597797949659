import React, { Fragment } from "react";
import moment from "moment-timezone";
import Button from "../common/Button";
import { isSafeToClick } from "../../utils/helper";

const ShipmentOutbound = ({ data, onEdit, onView, onDelete }) => {
  const { shippingDate, shipmentId, pdf, draftContent, receiveDate } = data;
  return (
    <div className={`row bg-light p-3 mb-1 ${receiveDate && "text-muted"}`}>
      <div className="col-9 col-md-3 col-md mb-4 my-md-auto">
        <h5 className="my-auto">
          <i className="fas fa-sign-out-alt mr-2 text-warning"></i>
          {shipmentId}
        </h5>
        {draftContent ? <span className="badge badge-secondary">Draft</span> : ""}
      </div>
      <div className="col-3 col-md-auto order-md-2 my-md-auto text-right">
        <Button
          title={"<i class='fas fa-trash-alt'></i>" + isSafeToClick(true)}
          color="danger"
          classes={draftContent ? "" : "disabled"}
          onClick={() => {
            if (draftContent) onDelete(data);
          }}
        />
      </div>
      <div className="col-6 col-md-3 col-md  mb-4 my-md-auto">
        <span className="text-secondary">Ship date</span>
        <br />
        {shippingDate ? moment(shippingDate).tz("Europe/Berlin").format("YYYY-MM-DD") : ""}
      </div>
      <div className="col-6 col-md my-md-auto">
        {pdf && pdf.webContentLink ? (
          <Fragment>
            <span className="text-secondary">Shipping Labels</span>
            <br />
            <a href={pdf.webContentLink} target="_blank" rel="noopener noreferrer">
              <i className="far fa-file-pdf"></i> Download
            </a>
          </Fragment>
        ) : (
          ""
        )}
      </div>
      <div className="col-12 col-md-auto order-md-1 my-md-auto">
        {draftContent ? (
          <Button
            title={"<i class='fas fa-pen mr-2'></i> Edit contents" + isSafeToClick(true)}
            classes="btn-block"
            outline={false}
            onClick={() => onEdit(data)}
          />
        ) : (
          <Button
            title={"<i class='fas fa-check-circle text-success'></i> Submitted" + isSafeToClick(true)}
            classes="btn-block btn-success"
            outline={false}
            onClick={() => onView(data)}
          />
        )}
      </div>
    </div>
  );
};

export default ShipmentOutbound;
