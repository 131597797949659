import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const ReturnsMenu = () => {
  return (
    <Fragment>
      <NavLink className="nav-link my-auto mr-3" to="/receive">
        <i className="fas fa-barcode mr-2b"></i>Rücksendungen erfassen
      </NavLink>
      <NavLink className="nav-link my-auto  mr-3" to="/returns">
        <i className="fas fa-list-ul mr-2b"></i>Erfasste Rücksendungen
      </NavLink>
      <NavLink className="nav-link my-auto mr-3" to="/refund">
        <i className="fas fa-hand-holding-usd mr-2"></i>Erstattungen ausführen
      </NavLink>
      <NavLink className="nav-link my-auto mr-3" to="/zuschnitt">
        <i className="fas fa-cut mr-2"></i>Zuschnitt
      </NavLink>
    </Fragment>
  );
};

export default ReturnsMenu;
