import React from "react";

const SpecialCutting = () => {
  return (
    <div>
      <iframe
        width="100%"
        height="800px"
        frameBorder="0"
        src="https://www.formbench.com/automation-scripts/automation39462810388201873568329874632922347834782313/zuschnitt/sonderzuschnittkrabbelmatte.html"
        title="Zuschnitt kalkulieren"
      ></iframe>
    </div>
  );
};

export default SpecialCutting;
