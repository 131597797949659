import React from "react";

const Image = ({ url }) => {
  return (
    <div className="text-center">
      <img
        style={{ objectFit: "contain", maxWidth: "100%" }}
        alt="Produktbild"
        src={url || "https://www.formbench.com/upload/placeholder.png"}
      />
    </div>
  );
};

export default Image;
