import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { createModal } from "react-modal-promise";
import Table from "../common/CollapsibleTable";
import Checkbox from "../common/Checkbox";
import Button from "react-bootstrap/Button";
import {
  getParentProductsList,
  createParentProduct,
  updateParentProduct,
  setChildren,
  deleteParentProduct,
} from "../../services/parentProductsService";
import { getProductsList, updateProduct } from "../../services/productsService";
import { getInternalReasonsDE, getReturnParcelServices } from "../../services/returnsPortalService";
import { loadPickingSpots, getLocationNumber } from "../../services/warehouseService";
import { LocalizationContext, returnLocalizedString } from "../../services/localizationService";
import ParentProductModal from "./ParentProductModal";
import SelectChildrenModal from "./SelectChildrenModal";
import AutoSaveField from "../common/AutoSaveField";
import AutoSaveSelect from "../common/AutoSaveSelect";
import SimplePromiseModal from "../common/SimplePromiseModal";
import _, { sortBy, uniqBy } from "lodash";
import logger from "../../services/logService";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { isSafeToClick } from "../../utils/helper";

const ParentProductsList = () => {
  const { current: lang } = useContext(LocalizationContext);
  const showConfirmModal = createModal(SimplePromiseModal);

  //STATE
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const [parentProducts, setParentProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeParentProduct, setActiveParentProduct] = useState();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectChildrenModalShow, setSelectChildrenModalShow] = useState(false);
  const [selectChildrenSpinner, setSelectChildrenSpinner] = useState(false);
  const [modalEditMode, setModalEditMode] = useState(false);
  // const [requestReasons, setRequestReasons] = useState([]);
  const [locations, setLocations] = useState([]);
  const [returnParcelServices, setReturnParcelServices] = useState([]);
  const [internalReasonsOptions, setInternalReasonsOptions] = useState([]);

  const columns = [
    { path: "group", label: "#" },
    {
      path: "name",
      label: "Name",
      content: product => (
        <>
          <Link to={`/warehouse/product/${product.sku}`}>{product.name}</Link>
          <br />
          <small>
            <span className="text-muted">SKU </span>
            {product.sku}
            {product.ean && (
              <>
                <br />
                <span className="text-muted">EAN </span>
                {product.ean}
              </>
            )}
          </small>
        </>
      ),
    },
    {
      path: "useWarehouseStock",
      label: "Lager → ERPNext",
      content: product => (
        <Checkbox
          name="useWarehouseStock"
          id={`useWarehouseStock-${product?._id}`}
          label="Sync"
          isSelected={product?.settings?.useWarehouseStock || false}
          onCheckboxChange={() => handleItemChangeValue(product?._id, "settings.useWarehouseStock")}
        />
      ),
    },
    {
      path: "sellRefillStock",
      label: "Nachfülllager verkaufbar",
      content: product => (
        <Checkbox
          name="settings.sellRefillStock"
          id={`sellRefillStock-${product?._id}`}
          label="Verkaufbar"
          isSelected={product?.settings?.sellRefillStock || false}
          onCheckboxChange={() => handleItemChangeValue(product?._id, "settings.sellRefillStock")}
        />
      ),
    },
    {
      path: "returnFee",
      label: "Rücksendegebühr (€)",
      content: () => "-",
    },
    {
      path: "returnParcelService",
      label: "Retoureweg",
      content: () => "-",
    },
    {
      path: "children-more",
      label: "",
      content: product => {
        const productLocations = locations.filter(l => l.contents.find(c => c.product === product._id));
        if (!productLocations.length) return <div className="text-center">Kein Picking Platz</div>;
        return productLocations.map(location => (
          <div key={location._id} className="text-center">
            <Link to={`/warehouse/location/${location.locationId}`}>{getLocationNumber(location)}</Link>
            <br />
            {location.contents.find(c => c.product === product._id).quantity + " Stück" || "Bestand nicht verfügbar"}
            <br />
          </div>
        ));
      },
    },
  ];

  //Load List Content
  useEffect(() => {
    const status = { isMounted: true };
    // note this flag denotes mount status.
    // It is an Object because it enables changes while functions are executed.
    setIsRefreshing(false);

    loadData(status);

    return () => {
      status.isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadData(status = { isMounted: true }) {
    console.log("Loading Data...");
    await Promise.all([
      loadParentProducts(status),
      // loadRequestReasons(status),
      loadInternalReasonsOptions(status),
      loadReturnParcelServices(status),
      loadLocations(status),
      loadProducts(status),
    ]);
    console.log("Finshed loading data.");
  }

  async function loadProducts(status = { isMounted: true }) {
    const productsList = await getProductsList(true);
    const enhancedProductList = productsList.map(p => {
      for (const c of columns) if (c.content && c.sortContent) p["sortContent." + c.path] = c.sortContent(p);
      return p;
    });
    if (status.isMounted) {
      setProducts(enhancedProductList);
    }
  }

  async function loadLocations(status = { isMounted: true }) {
    const result = await loadPickingSpots();
    if (!result) return;
    if (status.isMounted) {
      setLocations(result);
    }
  }

  async function loadParentProducts(status = { isMounted: true }) {
    const parentProductList = await getParentProductsList(true);
    const enhancedParentProductList = parentProductList?.map(p => {
      const parentProduct = _.cloneDeep(p);
      for (const c of groupColumns)
        if (c.content && c.sortContent) parentProduct["sortContent." + c.path] = c.sortContent(p);
      parentProduct.internalReasons = uniqBy(
        sortBy(
          p.internalReasons.map(i => ({
            label: i.name?.find(l => l.lang === "de_DE")?.value || i.name?.[0]?.value,
            value: i._id,
          })),
          ["label"]
        ),
        "value"
      );
      return parentProduct;
    });
    if (status.isMounted) {
      setParentProducts(enhancedParentProductList);
    }
  }

  // async function loadRequestReasons(status = { isMounted: true }) {
  //   const requestReasonsList = await getRequestReasons();
  //   if (!requestReasonsList) return toast.error("Die Liste der Gründe konnte nicht geladen werden.");
  //   if (!Array.isArray(requestReasonsList)) {
  //     logger.error("Error while loading requestReasons. The list is not an array", requestReasonsList);
  //     return toast.error("Es ist ein Fehler aufgetreten. Die Liste der Gründe hat ein unerwartetes Format.");
  //   }
  //   if (status.isMounted) {
  //     setRequestReasons(requestReasonsList);
  //   }
  // }

  async function loadInternalReasonsOptions(status = { isMounted: true }) {
    try {
      const internalReasonList = await getInternalReasonsDE();
      if (!internalReasonList) return toast.error("Die Liste der internen Gründe konnte nicht geladen werden.");
      if (!Array.isArray(internalReasonList)) {
        logger.error("Error while loading internalReasons. The list is not an array", internalReasonList);
        return toast.error("Es ist ein Fehler aufgetreten. Die Liste der Gründe hat ein unerwartetes Format.");
      }
      if (status.isMounted) setInternalReasonsOptions(internalReasonList);
    } catch (err) {
      toast.error("Interne Gründe konnten nicht geladen werden.");
    }
  }

  async function loadReturnParcelServices(status = { isMounted: true }) {
    let { data } = await getReturnParcelServices();
    if (!data || !Array.isArray(data)) {
      toast.error("Fehler beim laden der Paketdienste. Bitte kontaktiere den Administrator");
      logger.error("There was an error loading the parcelServices. data was not in the expected format", data);
      return [];
    }
    const returnParcelOptions = data.map(r => ({
      value: r._id,
      name: r.name,
    }));
    if (status.isMounted) {
      setReturnParcelServices(returnParcelOptions);
    }
  }

  const groupColumns = [
    {
      path: "group",
      label: "#",
      hasGroupToggle: true,
      content: () => <i id="arrowIndicator" className="fas fa-angle-right mr-2"></i>,
      sortContent: () => "0",
    },
    {
      path: "name",
      label: "Name",
      content: parentProduct =>
        parentProduct._id === "unassigned" ? "Unkategorisiert" : returnLocalizedString(parentProduct.name, lang),
      sortContent: parentProduct =>
        parentProduct._id === "unassigned" ? "sortLast" : returnLocalizedString(parentProduct.name, lang),
    },
    {
      path: "useWarehouseStock",
      label: "Lager → ERPNext",
      content: () => "-",
    },
    {
      path: "sellRefillStock",
      label: "Nachfülllager verkaufbar",
      content: () => "-",
    },
    {
      path: "returnFee",
      label: "Rücksendegebühr (€)",
      content: parentProduct => {
        const key = `returnFee-${parentProduct._id}`;
        return parentProduct._id === "unassigned" ? (
          ""
        ) : (
          <AutoSaveField
            name={key}
            label=""
            placeholder=""
            type="number"
            step="0.01"
            decimals={2}
            value={parentProduct.returnFee}
            saveOnChange={false}
            onChange={newValue => handleChangeField(parentProduct, "returnFee", newValue)}
            onSave={newValue => handleSaveValue(parentProduct, "returnFee", newValue)}
            joiSchema={{
              [key]: Joi.number().min(0).max(99).required().label("Rücksendegebühr"),
            }}
          />
        );
      },
      sortContent: parentProduct => parentProduct.returnFee,
    },
    {
      path: "returnParcelService",
      label: "Retoureweg",
      content: parentProduct => {
        const key = `returnParcelService-${parentProduct._id}`;
        return parentProduct._id === "unassigned" ? (
          ""
        ) : (
          <AutoSaveSelect
            name={key}
            label=""
            value={parentProduct.returnParcelService}
            onSave={newValue => handleSaveValue(parentProduct, "returnParcelService", newValue)}
            options={returnParcelServices}
            joiSchema={{
              [`returnParcelService-${parentProduct._id}`]: Joi.string().min(0).max(99).label("Retoureweg"),
            }}
          />
        );
      },
      sortContent: parentProduct => parentProduct.returnParcelService,
    },
    {
      path: "children-more",
      label: "",
      content: parentProduct => {
        return parentProduct._id === "unassigned" ? (
          ""
        ) : (
          <div className="text-center">
            <Button onClick={e => handleSelectChildrenModalShow(e, parentProduct)} variant="secondary" className="mr-2">
              <i className="fas fa-sitemap"></i>
              {isSafeToClick(true)}
            </Button>
            <Button onClick={e => handleEditModalShow(e, parentProduct)} variant="secondary" className="mr-2">
              <i className="fas fa-ellipsis-h"></i>
              {isSafeToClick(true)}
            </Button>
            <Button onClick={e => handleDelete(e, parentProduct)} variant="danger">
              <i className="fas fa-trash-alt"></i>
              {isSafeToClick(true)}
            </Button>
          </div>
        );
      },
    },
  ];

  // function applyRequestReasonsOptions(requestReasons, requestReasonsOptions, defaults = {}) {
  //   // Iterate through all available options. Apply settings from the specific row.
  //   // Merge the "complete list" with the set options
  //   const appliedRequestReasonsOptions = requestReasons.map(r => {
  //     const match = requestReasonsOptions.find(o => o.requestReason === r._id);
  //     return { requestReason: r._id, ...(match || defaults) };
  //   });
  //   return appliedRequestReasonsOptions;
  // }

  // async function handleParentChangeValue(_id, path) {
  //   const parentProductsCopy = _.cloneDeep(parentProducts);
  //   const parentProduct = parentProductsCopy.find(p => p._id === _id);
  //   const valueBefore = _.get(parentProduct, path);
  //   _.set(parentProduct, path, !valueBefore);
  //   setParentProducts(_.cloneDeep(parentProductsCopy)); //Clone deep is needed so we don't modify the state by modifying the constant "product"
  //   //Create "Before" state in case we need to revert the changes. If we do this in the Catch part it doesn't work.
  //   try {
  //     await updateParentProduct(_id, parentProduct);
  //   } catch (err) {
  //     _.set(parentProduct, path, valueBefore);
  //     setParentProducts(parentProductsCopy);
  //     toast.error("Einstellungen konnten nicht gespeichert werden. Versuche es später noch einmal");
  //     logger.error("Could not save member settings.", err);
  //   }
  // }

  async function handleItemChangeValue(_id, path) {
    const productsCopy = _.cloneDeep(products);
    const product = productsCopy.find(p => p._id === _id);
    const valueBefore = _.get(product, path);
    _.set(product, path, !valueBefore);
    setProducts(_.cloneDeep(productsCopy)); // Clone deep is needed so we don't modify the state by modifying the constant "product"
    // Create "Before" state in case we need to revert the changes. If we do this in the Catch part it doesn't work.
    try {
      await updateProduct(_id, product);
    } catch (err) {
      _.set(product, path, valueBefore);
      setProducts(productsCopy);
      toast.error("Einstellungen konnten nicht gespeichert werden. Versuche es später noch einmal");
      logger.error("Could not save member settings.", err);
    }
  }

  const onSort = sortColumn => {
    setSortColumn(sortColumn);
  };

  function sortGroupsData() {
    return parentProducts ? _.orderBy(parentProducts, "sortContent." + sortColumn.path, sortColumn.order) : [];
  }

  function sortItemsData() {
    return products ? _.orderBy(products, sortColumn.path, sortColumn.order) : [];
  }

  function handleChangeField(data, path, newValue) {
    const parentProductsListCopy = _.cloneDeep(parentProducts);
    const parentProductInstance = parentProductsListCopy.find(p => p._id === data._id);
    _.set(parentProductInstance, path, newValue);
    setParentProducts(parentProductsListCopy);
  }

  async function handleSaveValue(data, path, newValue) {
    handleChangeField(data, path, newValue);

    try {
      const currentParentProduct = _.cloneDeep(parentProducts.find(p => p._id === data._id));
      _.set(currentParentProduct, path, newValue);
      await updateParentProduct(data._id, currentParentProduct);
    } catch (err) {
      toast.error("Konnte den Wert nicht anpassen. " + err.response.data);
      logger.error("There was a problem saving the value. ", err);
    }
  }

  function handleEditModalShow(e, parentProduct) {
    e.stopPropagation();
    setActiveParentProduct(parentProduct);
    setModalEditMode(true);
    setModalShow(true);
  }

  async function handleDelete(e, parentProduct) {
    e.stopPropagation();
    const result = await showConfirmModal({
      title: "Übergeordnetes Produkt löschen?",
      description:
        "Wenn ein übergeordnetes Produkt gelöscht wird, gehen alle darin gespeicherten Einstellungen und die Verknüpfungen zu Produkten verloren. Verknüpfte Produkte werden nicht gelöscht.",
      buttonColor: "danger",
      confirmText: "Löschen",
    });
    if (!result) return;

    const filteredParentProducts = parentProducts.filter(p => p._id !== parentProduct._id);
    setParentProducts(filteredParentProducts);
    await deleteParentProduct(parentProduct._id);

    loadParentProducts();
    loadProducts();
  }

  function handleAddModalShow() {
    setModalEditMode(false);
    setModalShow(true);
  }

  async function handleAddParentProduct(data) {
    await createParentProduct(data);
    setModalShow(false);
    loadParentProducts();
  }

  async function handleEditParentProduct(data) {
    if (!data?._id) {
      toast.error("Fehler beim Speichern. Bitte wende dich an den Administrator.");
      logger.error(
        "Error while executing handleEditParentProduct() - data._id is not defined. Original Data attached:",
        data
      );
      return;
    }
    await updateParentProduct(data._id, data);
    loadData();
    setModalShow(false);
  }

  function handleModalClose() {
    setActiveParentProduct({});
    setModalEditMode(false);
    setModalShow(false);
  }

  function handleSelectChildrenModalShow(e, parentProduct) {
    e.stopPropagation();
    setActiveParentProduct(parentProduct);
    setSelectChildrenModalShow(true);
  }

  function handleSelectChildrenModalClose() {
    setSelectChildrenModalShow(false);
  }

  async function handleAddChildren(childrenSkus) {
    setSelectChildrenSpinner(true);
    await setChildren(activeParentProduct._id, childrenSkus);
    setSelectChildrenModalShow(false);
    setSelectChildrenSpinner(false);
    window.location.reload();
  }

  return (
    <div className="container-fluid spacing-top">
      <div className="row justify-content-between align-items-center mb-5">
        <div className="col col-auto">
          <h1>Produkte</h1>
        </div>
        <div className="col col-auto">
          <div className="form-row align-items-center">
            <div className="col-auto">
              <Button onClick={handleAddModalShow}>
                <i className="fas fa-plus"></i>
                {isSafeToClick(true)}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Table
            sticky={true}
            columns={columns}
            groupColumns={groupColumns}
            itemsData={sortItemsData()}
            parentsData={sortGroupsData()}
            pathToParent="parentProduct"
            idField="_id"
            sortColumn={sortColumn}
            onSort={onSort}
            style={isRefreshing ? { opacity: 0.5 } : { opacity: 1.0 }}
          />
        </div>
      </div>
      <ParentProductModal
        show={modalShow}
        editMode={modalEditMode}
        currentObject={activeParentProduct}
        returnParcelServices={returnParcelServices}
        // requestReasons={requestReasons}
        internalReasonsOptions={internalReasonsOptions}
        // applyRequestReasonsOptions={applyRequestReasonsOptions}
        lang={lang}
        onHide={handleModalClose}
        onSubmit={modalEditMode ? handleEditParentProduct : handleAddParentProduct}
      />
      <SelectChildrenModal
        show={selectChildrenModalShow}
        currentObject={activeParentProduct}
        spinner={selectChildrenSpinner}
        lang={lang}
        onHide={handleSelectChildrenModalClose}
        onSubmit={handleAddChildren}
      />
    </div>
  );
};

export default ParentProductsList;
