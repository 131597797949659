import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import logger from "../../services/logService";

const SimpleModal = ({
  isOpen,
  onResolve,
  title,
  description,
  spinner,
  confirmText,
  cancelText,
  buttonColor,
  secondaryAction,
  onLoadDescription,
  primaryFunction,
  secondaryFunction,
}) => {
  const [localDescription, setLocalDescription] = useState({ __html: description });
  const [localSpinner, setLocalSpinner] = useState(spinner);
  const [localSpinnerText, setLocalSpinnerText] = useState("");

  useEffect(() => {
    if (onLoadDescription) {
      (async () => {
        const result = await onLoadDescription();
        setLocalDescription({ __html: result });
        setLocalSpinner(false);
      })();
    }
  }, [onLoadDescription]);

  async function handlePrimaryFunction() {
    try {
      setLocalSpinner(true);
      if (primaryFunction) await primaryFunction(setLocalSpinnerText);
      onResolve("primary");
      setLocalSpinner(false);
    } catch (err) {
      setLocalSpinner(false);
      toast.error("Fehler bei der Ausführung: " + err);
      logger.error("Error while executing primary function", err);
    }
  }

  async function handleSecondaryFunction() {
    try {
      setLocalSpinner(true);
      if (secondaryFunction) await secondaryFunction(setLocalSpinnerText);
      onResolve("secondary");
      setLocalSpinner(false);
    } catch (err) {
      setLocalSpinner(false);
      toast.error("Fehler bei der Ausführung: " + err);
      logger.error("Error while executing primary function", err);
    }
  }

  return (
    <Modal
      show={isOpen}
      size="lg"
      backdrop="static"
      onHide={() => onResolve()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title || "Bist du sicher?"}</Modal.Title>
      </Modal.Header>
      {localDescription ? (
        <Modal.Body>
          {localSpinner ? (
            <div className="m-5 text-center">
              <Loader className="mb-2" />
              {localSpinnerText ? <span>{localSpinnerText}</span> : ""}
            </div>
          ) : (
            <div dangerouslySetInnerHTML={localDescription || ""}></div>
          )}
        </Modal.Body>
      ) : (
        ""
      )}
      <Modal.Footer>
        {secondaryAction ? (
          <Button variant={buttonColor ? buttonColor : "light"} onClick={handleSecondaryFunction}>
            {secondaryAction}
          </Button>
        ) : cancelText === "" ? (
          ""
        ) : (
          <Button variant="secondary" disabled={localSpinner} onClick={() => onResolve()}>
            {cancelText || "Abbrechen"}
          </Button>
        )}
        <Button variant={buttonColor ? buttonColor : "primary"} disabled={localSpinner} onClick={handlePrimaryFunction}>
          {confirmText || "Ja"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SimpleModal;
