import React, { Component } from "react";
import { loadAllShipments, loadStorageUnits } from "../../services/shipmentService";
import { addDays, formatDate } from "../../utils/dateUtils";
import _ from "lodash";
import { Timeline, TimelineEvent } from "react-event-timeline";
import Loader from "../common/Loader";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

class UpcomingShipmentsTimeline extends Component {
  state = {
    shipments: [],
    loading: true,
  };

  componentDidMount() {
    (async () => {
      const shipments = await loadAllShipments();
      const filteredShipments = this.filterShipments(shipments);
      this.setState({ shipments: filteredShipments, loading: false });
      const stus = await Promise.all(filteredShipments.map(shipment => loadStorageUnits(shipment)));
      filteredShipments.forEach((s, i) => {
        const types = stus[i].map(stu => stu.category);
        const typeList = [...new Set(types)];
        s.contents = typeList.map(type => ({ type: type, count: stus[i].filter(stu => stu.category === type).length }));
      });
      this.setState({ shipments: filteredShipments, loading: false });
    })();
  }

  getContent = item => {
    const receivedCount = item.remainingStorageUnits.length
      ? item.contents.reduce((acc, red) => acc + red.count, 0) - item.remainingStorageUnits.length
      : undefined;

    return (
      <div>
        <div className="mb-3">
          {item.shipmentType === "outbound"
            ? ""
            : item.contents?.length
            ? item.contents.map(content =>
                content.count > 1
                  ? `${receivedCount >= 0 ? `${receivedCount} von ` : ""}${content.count} ${
                      content.type === "pallet" ? "Paletten" : "Kartons"
                    }${receivedCount >= 0 ? " empfangen" : ""}`
                  : `1 ${content.type === "pallet" ? "Palette" : "Karton"}`
              )
            : "⁣"}
        </div>
        {item.pdf && (
          <a href={item.pdf.webContentLink} target="_blank" className="btn btn-secondary" rel="noreferrer">
            <i className="fas fa-file-download mr-2"></i>STU-Labels
          </a>
        )}
      </div>
    );
  };

  getReceiveStatus = item =>
    item.receiveDate ? <i className="fas fa-truck-loading"></i> : <i className="fas fa-truck"></i>;

  getRowColor(shipment) {
    if (shipment.shipmentType === "outbound") return "#FFAB00";
    if (shipment.receiveCompletedDate) return "#8BD86C";
    if (shipment.receiveDate) return "#FFC22C";
    return "#523D3D";
  }

  getDateString(shipment) {
    return shipment.receiveDate
      ? `Angenommen am ${formatDate(shipment.receiveDate, false)}`
      : `Versand am ${formatDate(shipment.shippingDate, false)}`;
  }

  filterShipments(shipments) {
    const filtered = shipments
      ? shipments.filter(s => {
          if (s.shipmentType === "outbound") {
            return false;
          }
          if (s.shipmentType === "inbound" && (!s.receiveDate || new Date(s.receiveDate) >= addDays(new Date(), -7))) {
            return true;
          }
          return false;
        })
      : shipments;

    return filtered;
  }

  render() {
    const { shipments } = this.state;
    //console.log("Shipments: ", shipments);
    const filtered = shipments;
    const sorted = _.orderBy(filtered, ["receiveDate", "shippingDate"], "asc");

    return (
      <div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <Timeline>
            {sorted.map(f => (
              <TimelineEvent
                key={f.shipmentId}
                title={f.shipmentId}
                createdAt={this.getDateString(f)}
                icon={this.getReceiveStatus(f)}
                iconColor={this.getRowColor(f)}
                iconStyle={{ marginLeft: "2px", marginTop: "2px" }}
                contentStyle={{ background: "transparent", boxShadow: "none", padding: "0px" }}
                titleStyle={{ fontSize: "20px" }}
              >
                {this.getContent(f)}
              </TimelineEvent>
            ))}
          </Timeline>
        )}
        <br />
        <Link to={`/warehouse`}>
          <Button variant="outline-secondary">Zur Lagerübersicht</Button>
        </Link>
      </div>
    );
  }
}

//ZUM INSTALLIEREN DER LIBRARY: npm i react-event-timeline

export default UpcomingShipmentsTimeline;
