import React, { useEffect, useState, Fragment } from "react";
import warehouseService from "../../services/warehouseService";
import _ from "lodash";
import SubmitButton from "./SubmitButton";
import StorageList from "./StorageList";

const LocationProducts = ({ contents, onAdjustStock }) => {
  const [storageUnitsGrouped, setStorageUnitsGrouped] = useState([]);

  useEffect(() => {
    (async () => {
      const tempStorageUnitsGrouped = [];
      for (const c of contents) {
        const { sku } = c.product;
        const result = await warehouseService.loadProductLocations(sku);
        if (result) tempStorageUnitsGrouped.push({ sku, storageUnits: result.storageUnits });
      }

      for (const su of storageUnitsGrouped) su.storageUnits = _.orderBy(su.storageUnits, o => o.receiveDate, "asc");

      setStorageUnitsGrouped(tempStorageUnitsGrouped);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents]);

  return (
    <Fragment>
      {contents &&
        contents.map((c, i) => (
          <ul key={`contentGroup-${i}`} className="list-group mb-3">
            <li className="list-group-item" style={{ fontSize: "18px" }}>
              <div className="row">
                <div className="col">
                  <span>
                    <b>{c.product.name}</b>
                    <br />
                    <small>{c.product.sku}</small>
                  </span>
                </div>
                <div className={`col-auto ${c.quantity > 0 ? "text-success" : "text-danger"}`}>{c.quantity}</div>
              </div>
              <p style={{ fontSize: "14px" }}>Inhalte der Packlisten bereits ausgebucht</p>
              {contents.length > 1 && onAdjustStock ? (
                <SubmitButton
                  subject="Bestand"
                  verb="anpassen"
                  icon="sliders-h"
                  onSubmit={() => onAdjustStock(c.product)}
                  buttonColor="secondary"
                />
              ) : (
                ""
              )}
            </li>
          </ul>
        ))}
      <h5 className="text-light-gray">Nachfülllager</h5>
      {storageUnitsGrouped.map((sug, i) => {
        const { sku, storageUnits } = sug;
        return <StorageList key={"storageUnitsGroup-" + i} storageUnits={storageUnits} sku={sku} />;
      })}
    </Fragment>
  );
};

export default LocationProducts;
