import React from "react";

const Warning = ({ text, centered = false }) => {
  return (
    <div className={`alert alert-warning ${centered && "text-center"}`} role="alert">
      {text}
    </div>
  );
};

export default Warning;
