import React, { Component, Fragment } from "react";
import TableStacked from "../common/TableStacked";
import TableSortBy from "../common/TableSortBy";
import SearchForm from "../common/SearchForm";
import auth from "../../services/authService";
import dateUtils from "../../utils/dateUtils";
import PrintButton from "../warehouse/PrintButton";
import PropTypes from "prop-types";
import warehouseService from "../../services/warehouseService";
import Loader from "../common/Loader";
import { toast } from "react-toastify";
import { isSafeToClick } from "../../utils/helper";
import imageService from "../../services/imageService";

class StockTable extends Component {
  columns = [
    {
      path: "product.imageObj.thumb",
      label: "",
      content: orderReturn => {
        return (
          <img
            src={
              this.props.imageDb
                ? imageService.getThumbUrl(orderReturn.product.sku, this.props.imageDb)
                : "https://www.formbench.com/upload/placeholder.png"
            }
            alt=""
            width="60"
          />
        );
      },
    },
    {
      path: "inspection.date",
      label: "Inspektion",
      content: orderReturn => {
        return orderReturn.inspection?.date ? dateUtils.formatDate(orderReturn.inspection.date) : "";
      },
    },
    {
      path: "inspection.user.name",
      label: "Mitarbeiter",
      content: orderReturn => {
        return orderReturn.inspection?.user?.name || "";
      },
    },
    {
      path: "product.sku",
      label: "Produkt",
      content: orderReturn => (
        <div className="row">
          <div className="col">
            <span>{orderReturn.product.name}</span>
            <br />
            <span className="text-muted">{orderReturn.product.sku}</span>
            <br />
            <span className="text-muted">Barcode: {orderReturn.barcode}</span>
          </div>
        </div>
      ),
    },
    { path: "product.count", label: "Anzahl" },
    {
      path: "order.orderNumber",
      label: "Bestellnummer",
      content: orderReturn => orderReturn.order.orderNumber,
    },
    { path: "order.customer.name", label: "Kunde" },
    {
      path: "changeStatus",
      key: "changeStatus",
      content: orderReturn => (
        <button
          onClick={() => this.props.onButtonClick(orderReturn)}
          className={`btn ${this.getClassFromStatus(orderReturn.inspection?.result)}`}
        >
          {this.getTextFromStatus(orderReturn.inspection?.result)}
        </button>
      ),
    },
    {
      path: "printLabel",
      key: "printLabel",
      content: orderReturn => (
        <PrintButton
          onPrint={() => {
            this.handlePrintLabel(orderReturn.product.sku);
          }}
          description={`Soll das Label für das Produkt "${
            orderReturn.product.name || orderReturn.product.sku
          }" ausgedruckt werden?`}
          color="secondary"
          compact={false}
          outline={true}
        />
      ),
    },
    {
      key: "delete",
      content: orderReturn => (
        <button onClick={() => this.props.onDelete(orderReturn)} className="btn btn-danger">
          <i className="fas fa-trash-alt"></i>
          {isSafeToClick(true)}
        </button>
      ),
    },
  ];

  getClassFromStatus(result) {
    if (result === "stock") return "btn-outline-success btn-block";
    if (result === "stockUsed") return "btn-outline-brown btn-block";
    if (result === "trash") return "btn-outline-danger btn-block";
    return "btn-outline-secondary btn-block";
  }

  getTextFromStatus(result) {
    if (result === "stock") return "Aufbereitet (Neuwertig)" + isSafeToClick(true);
    if (result === "stockUsed") return "Aufbereitet (B-Ware)" + isSafeToClick(true);
    if (result === "trash") return "Entsorgt" + isSafeToClick(true);
    return "Status unbekannt";
  }

  async handlePrintLabel(sku) {
    await warehouseService.printProductLabel(sku);
    toast("Label wird gedruckt...");
  }

  render() {
    const { orderReturns, onSort, onSearch, sortColumn, loading } = this.props;

    const columns = this.columns;
    if (!auth.getCurrentUser()) columns.pop();

    return (
      <Fragment>
        <div className="row justify-content-between align-items-center pb-4">
          <div className="col-12 col-lg-4 col-xl-6">
            <h1 className="mb-2">Geprüft und Aufbereitet</h1>
            <p className="mb-0">Folgende Artikel wurden bereits aufbereitet.</p>
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <SearchForm placeholder="Suche..." onSubmit={onSearch} onReset={onSearch} submitLabel="Suchen" />
            <TableSortBy columns={this.columns} sortColumn={sortColumn} onSort={onSort} />
          </div>
        </div>
        <div className="row">
          {loading ? (
            <div className="py-3 mx-auto">
              <Loader message="Lade erfasste Retouren..." />
            </div>
          ) : (
            <TableStacked
              columns={this.columns}
              data={orderReturns}
              sortColumn={sortColumn}
              getId={item => item._id}
              onSort={onSort}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

StockTable.propTypes = {
  orderReturns: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  sortColumn: PropTypes.object.isRequired,
};

export default StockTable;
