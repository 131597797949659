import React from "react";

const Statistics = ({ inspected, putInStock, trashed, bStock, total, onClick, onReset, filterActive }) => {
  return (
    <div style={{ lineHeight: "1" }}>
      <span className="badge badge-pill badge-light mx-1" onClick={() => onClick("")}>
        {total} {total > 1 ? "Rücksendungen" : "Rücksendung"}
      </span>
      <span className="badge badge-pill badge-light mx-1" onClick={() => onClick("")}>
        {inspected} Geprüft
      </span>
      <span className="badge badge-pill badge-light mx-1" onClick={() => onClick("")}>
        {total - inspected} Ungeprüft
      </span>
      <span
        className="badge badge-pill badge-success mx-1"
        style={filterActive && filterActive !== "stock" ? { opacity: "0.3" } : {}}
        onClick={() => onClick("stock")}
      >
        {putInStock} Eingelagert
      </span>
      <span
        className="badge badge-pill badge-brown mx-1"
        style={filterActive && filterActive !== "stockUsed" ? { opacity: "0.3" } : {}}
        onClick={() => onClick("stockUsed")}
      >
        {bStock} B-Ware
      </span>
      <span
        className="badge badge-pill badge-danger mx-1"
        style={filterActive && filterActive !== "trash" ? { opacity: "0.3" } : {}}
        onClick={() => onClick("trash")}
      >
        {trashed} Entsorgt
      </span>
      {filterActive ? (
        <span className="badge badge-pill badge-white mx-1" onClick={onReset}>
          <i className="fas fa-times"></i>
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Statistics;
