import React from "react";
import Button from "react-bootstrap/Button";
import SimplePromiseModal from "../common/SimplePromiseModal";
import { createModal } from "react-modal-promise";
import { isSafeToClick } from "../../utils/helper";

const SubmitButton = ({
  subject,
  verb,
  icon,
  overrideText,
  onSubmit,
  buttonColor,
  confirmationNeeded,
  confirmationTitle,
  confirmationDescription,
  confirmText,
  ...rest
}) => {
  const showConfirmModal = createModal(SimplePromiseModal);

  const subjectText = subject === "pallet" ? "Palette" : subject === "box" ? "Karton" : subject;

  return (
    <Button
      onClick={async () => {
        if (confirmationNeeded) {
          const result = await showConfirmModal({
            title: confirmationTitle,
            description: confirmationDescription,
            confirmText: confirmText + isSafeToClick(true),
            cancelText: "Abbrechen",
          });
          if (!result) return;
        }
        onSubmit();
      }}
      variant={buttonColor}
      className="btn-block"
      {...rest}
    >
      {icon ? <i className={`fas fa-${icon} mr-2`}></i> : ""}
      {overrideText ? overrideText : `${subject ? subjectText + " " : ""}${verb}`}
      {isSafeToClick(true)}
    </Button>
  );
};

export default SubmitButton;
