import React from "react";
import Form from "../common/Form";
import Joi from "joi-browser";
import * as userService from "../../services/userService";

class RegisterForm extends Form {
  state = {
    data: { username: "", password: "", name: "", shortCode: "" },
    errors: {}
  };

  schema = {
    username: Joi.string().required().label("Username").email(),
    password: Joi.string().required().label("Password").min(5).max(255),
    name: Joi.string().min(3).max(30).required().label("Name"),
    shortCode: Joi.string()
      .regex(/[A-Z]/)
      .length(3)
      .label("Short Code")
      .error(errors => {
        errors.forEach(err => {
          err.message = "Short Code must be exactly three uppercase letters.";
        });
        return errors;
      })
      .required()
  };

  doSubmit = async () => {
    const { setSpinner, onSubmit } = this.props;
    try {
      setSpinner(true);
      await userService.registerSupplier(this.state.data);
      setSpinner(false);
      onSubmit();
    } catch (ex) {
      setSpinner(false);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        console.log(ex.response);
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="container-fluid spacing-top">
        <form onSubmit={this.handleSubmit}>
          <p>No automatic email will be sent. Please send the credentials to the supplier manually.</p>
          {this.renderInput("username", "E-Mail Address", "name@domain.de")}
          {this.renderInput("password", "Password", "Small/Capital letters, glyphs and numbers required", "password")}
          {this.renderInput("name", "Name", "Full name")}
          {this.renderInput("shortCode", "Short Code", "e.g. KOL")}
          <p>
            Please make sure to save the password before pressing the 'Create account' button. The password can not be
            restored.
          </p>
          {this.renderButton("Create account", this.handleSubmit, "mb-3 btn-block")}
        </form>
      </div>
    );
  }
}

export default RegisterForm;
