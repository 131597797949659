import React, { Component, Fragment } from "react";
import Input from "./Input";
import Textarea from "./Textarea";
import Select from "./Select";
import Nav from "react-bootstrap/Nav";
import Joi from "joi-browser";
import DatePicker from "react-datepicker";
import LocalizedStringEditor from "./LocalizedStringEditor";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "./Checkbox";
import _ from "lodash";
import MatrixSelect from "./MatrixSelect";
import CreatableSelect from "react-select/creatable";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false, stripUnknown: true };
    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) return null;
    else {
      console.error(error.details);
    }

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  validateProperty = inputElement => {
    const options = { allowUnknown: true };
    const value = inputElement.value || inputElement.data;
    const name = inputElement.name;
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema, options);

    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    console.log("Submit");
    e.preventDefault();

    //validate and return any errors
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  renderButton(label, onClick, className = "") {
    return (
      <button
        className={className ? className + " btn btn-primary" : "btn btn-primary"}
        // disabled={this.validate()}
        onClick={onClick}
      >
        {label}
      </button>
    );
  }

  renderInput(name, label, placeholder, type = "text", disabled = false, onChange = this.handleChange) {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        value={data[name]}
        onChange={onChange}
        error={errors[name]}
        ref={name}
        disabled={disabled}
      />
    );
  }

  renderInputNumber(name, label, step = "0.02", decimals = 2) {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        type="number"
        step={step}
        decimals={decimals}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
        ref={name}
      />
    );
  }

  renderInputPassive(name, label, placeholder, type = "text", value, spinner = false, step = 1) {
    const { errors } = this.state;
    return (
      <div style={{ position: "relative" }}>
        {spinner && (
          <i
            className="fa fa-1x fa-circle-notch fa-spin text-secondary"
            style={{ position: "absolute", top: "11px", right: "11px" }}
          />
        )}
        <Input
          name={name}
          type={type}
          label={label}
          placeholder={placeholder}
          value={value}
          step={step}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          error={errors[name]}
          ref={name}
        />
      </div>
    );
  }

  renderInputWithButton(name, label, placeholder, type = "text", buttonText) {
    const { data, errors } = this.state;
    const error = errors[name];
    return (
      <React.Fragment>
        <div className="input-group">
          <input
            name={name}
            id={name}
            type={type}
            label={label}
            placeholder={placeholder}
            value={data[name]}
            onChange={this.handleChange}
            className="form-control"
          />
          <div className="input-group-append">{this.renderButton(buttonText)}</div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
      </React.Fragment>
    );
  }

  renderDatePicker(name, label) {
    const { errors } = this.state;
    return (
      <Fragment>
        <div className="form-group">
          <label htmlFor={name}>{label}</label>
          <DatePicker
            name={name}
            selected={this.state.data[name]}
            onChange={date => this.handleChange({ currentTarget: { name, value: date } })}
            className="form-control"
          />
        </div>
        {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
      </Fragment>
    );
  }

  renderTextarea(name, label, placeholder, type = "textarea", value = "", spinner = false) {
    const { errors } = this.state;
    return (
      <div style={{ position: "relative" }}>
        {spinner && (
          <i
            className="fa fa-1x fa-circle-notch fa-spin text-secondary"
            style={{ position: "absolute", top: "11px", right: "11px" }}
          />
        )}
        <Textarea
          name={name}
          type={type}
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={this.handleChange}
          error={errors[name]}
        />
      </div>
    );
  }

  renderTextareaActive(name, label, placeholder, type = "textarea", disabled = false, onChange = this.handleChange) {
    const { data, errors } = this.state;
    return (
      <Textarea
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        value={data[name]}
        onChange={onChange}
        error={errors[name]}
        ref={name}
        disabled={disabled}
      />
    );
  }

  /**
   *
   * @param {string} name identifier of the select object
   * @param {string} label Visible Name of the Select Object
   * @param {string} chooseLabel Choose message inside the select box
   * @param {array} options array of objects with format: [{ name: "name", _id: "unique id" }]
   */
  renderSelect(name, label, chooseLabel, options) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        chooseLabel={chooseLabel}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        multiple={false}
      />
    );
  }

  renderCreatableMultiSelect(name, label, options) {
    const { data, errors } = this.state;

    const handleChange = input => {
      const newData = _.cloneDeep(data);
      newData[name] = input;
      this.setState({ data: newData });
    };

    return (
      <>
        <CreatableSelect
          isMulti
          name={name}
          value={data[name]}
          label={label}
          options={options}
          onChange={handleChange}
        />
        {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
      </>
    );
  }

  /**
   *
   * @param {string} name identifier of the select object
   * @param {string} label Visible Name of the Select Object
   * @param {string} chooseLabel Choose message inside the select box
   * @param {array} options array of objects with format: [{ name: "name", _id: "unique id" }]
   */
  renderSelectPassive(name, label, value, chooseLabel, options) {
    const { errors } = this.state;
    return (
      <Select
        key={name}
        name={name}
        value={value}
        label={label}
        chooseLabel={chooseLabel}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        multiple={false}
      />
    );
  }

  /**
   *
   * @param {string} name Identifiert for form element
   * @param {string} label array of option is forma { _id: "id", name: "name" }
   * @param {string} addClasses additional css classes to add
   */
  renderCheckbox(name, label, addClasses = "") {
    const { errors } = this.state;

    const toggleState = () => {
      const stateDataCopy = _.cloneDeep(this.state.data);
      stateDataCopy[name] = !stateDataCopy[name];
      this.setState({ data: stateDataCopy });
    };

    const isCheckboxSelected = () => {
      return this.state.data[name] === true;
    };

    return (
      <div key={name} className={addClasses}>
        <Checkbox
          name={name}
          id={name}
          label={label}
          isSelected={isCheckboxSelected(name)}
          onCheckboxChange={toggleState}
        />
        {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
      </div>
    );
  }

  /**
   *
   * @param {string} name Identifiert for form element
   * @param {array} options array of option is forma { _id: "id", name: "name" }
   * @param {string} addClasses additional css classes to add
   */
  renderCheckboxGroup(name, label, options, addClasses = "") {
    const { errors } = this.state;

    const removeFromState = option => {
      const stateDataCopy = { ...this.state.data };
      stateDataCopy[name] = stateDataCopy[name].filter(i => i !== option);
      this.setState({ data: stateDataCopy });
    };

    const addToState = option => {
      const stateDataCopy = { ...this.state.data };
      stateDataCopy[name] = [...this.state.data[name], option];
      this.setState({ data: stateDataCopy });
    };

    const toggleState = option => {
      if (isCheckboxSelected(option)) removeFromState(option);
      else addToState(option);
    };

    const isCheckboxSelected = option => {
      return this.state.data[name].indexOf(option) !== -1;
    };

    return (
      <div className={addClasses}>
        <div className="mb-1">{label}</div>
        {options.map(o => (
          <Checkbox
            key={o._id}
            name={o._id}
            id={o._id}
            label={o.name}
            isSelected={isCheckboxSelected(o._id)}
            onCheckboxChange={() => toggleState(o._id)}
          />
        ))}
        {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
      </div>
    );
  }

  /**
   *
   * @param {string} name Identifiert for form element
   * @param {array} rowLabels array of rows is as strings
   * @param {array} cols array of cols is as strings
   * @param {array} data [ { key: string, value: boolean } ]
   * @param {string} addClasses CSS classes to add to the enclosing div
   */
  renderMatrixSelect(name, rowLabels, cols, data, addClasses = "") {
    const { data: stateData, errors } = this.state;

    const handleChangeData = checkboxData => {
      const newData = _.cloneDeep(stateData);
      newData[name] = checkboxData;
      this.setState({ data: newData });
    };

    return (
      <div className={addClasses}>
        <MatrixSelect rowLabels={rowLabels} cols={cols} data={data} label={name} onChange={handleChangeData} />
        {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
      </div>
    );
  }

  renderRadioButtons(name, options, addClasses = "") {
    const { errors } = this.state;

    return (
      <Nav variant="pills" className="form-row mb-3">
        {options.map(o => (
          <Nav.Item key={o.key} className="col-4">
            <Nav.Link
              name={o.key}
              eventKey={`link-${o.key}`}
              onSelect={() =>
                this.handleChange({
                  currentTarget: { name: name, value: o.key },
                })
              }
              className={
                o.color
                  ? `text-center btn btn-outline-${o.color} py-4 ${addClasses}`
                  : `text-center btn btn-outline py-5 ${addClasses}`
              }
            >
              <span>{o.label}</span>
            </Nav.Link>
          </Nav.Item>
        ))}
        {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
      </Nav>
    );
  }

  renderLocalizedStringEditor(name, label = "", placeholder = "", type = "input", disabled = false) {
    const { data, errors } = this.state;
    return (
      <LocalizedStringEditor
        id={name}
        label={label}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        data={data[name]}
        error={errors[name]}
        onSave={() => {}}
        onChange={newData => this.handleChange({ currentTarget: { name, value: newData || "" } })}
      />
    );
  }
}

export default Form;
