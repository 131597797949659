import React, { useRef } from "react";
import SalesAndStockChart from "./SalesAndStockChart";
import { useIsVisible } from "react-is-visible";
import utils from "../../utils/dateUtils";

const CombinedChart = props => {
  const { data, fba } = props;

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);

  const startDate = props.startDate || utils.addDays(new Date(), -60);
  const endDate = props.endDate || new Date();
  return (
    <div ref={nodeRef} style={{ width: "300px", height: "120px", verticalAlign: "middle", padding: "10px 0px" }}>
      {isVisible && data && data.length ? (
        <SalesAndStockChart data={data} startDate={startDate} endDate={endDate} fba={fba} />
      ) : (
        <div style={{ width: "50px", height: "120px" }}></div>
      )}
    </div>
  );
};

export default CombinedChart;
