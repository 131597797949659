import React from "react";

const Select = ({ name, icon, label, chooseLabel, options, value, error, onChange, ...rest }) => {
  return (
    <React.Fragment>
      <div className={label !== "" ? "label select mb-0" : "select mb-0"} {...rest}>
        {icon ? <i className={icon}></i> : ""}
        {label !== "" ? <label htmlFor={name}>{label}</label> : ""}
        <select
          name={name}
          id={`${name}-select`}
          onClick={event => event.stopPropagation()}
          className="form-control d-block"
          onChange={onChange}
          value={value}
        >
          {chooseLabel && (
            <option key="choose" value="">
              {chooseLabel}
            </option>
          )}
          {options.map(option => (
            <option
              key={option._id || option.key || option.id || option.value}
              value={option._id || option.key || option.id || option.value}
            >
              {option.name || option.title}
            </option>
          ))}
        </select>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </React.Fragment>
  );
};

export default Select;
