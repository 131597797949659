import React from "react";
import { add, format, differenceInCalendarDays } from "date-fns";
import { ComposedChart, Area, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import CustomTooltip from "../charts/CustomTooltip";
import _ from "lodash";

const dateFormatter = date => {
  return format(new Date(date), "dd. MMM");
};

/**
 * get the dates between `startDate` and `endSate` with equal granularity
 */
const getTicks = (startDate, endDate, num) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);

  let current = startDate,
    velocity = Math.round(diffDays / (num - 1));

  const ticks = [startDate.getTime()];

  for (let i = 1; i < num - 1; i++) {
    ticks.push(add(current, { days: i * velocity }).getTime());
  }

  ticks.push(endDate.getTime());
  return ticks;
};

/**
 * Add data of the date in ticks,
 * if there is no data in that date in `data`.
 *
 * @param Array<number> _ticks
 * @param {*} data
 */
// const fillTicksData = (_ticks, data) => {
//   const ticks = [..._ticks];
//   const filled = [];
//   let currentTick = ticks.shift();
//   let lastData = null;
//   for (const it of data) {
//     if (ticks.length && it.date > currentTick && lastData) {
//       filled.push({ ...lastData, ...{ date: currentTick } });
//       currentTick = ticks.shift();
//     } else if (ticks.length && it.date === currentTick) {
//       currentTick = ticks.shift();
//     }

//     filled.push(it);
//     lastData = it;
//   }

//   return filled;
// };

const SalesAndStockChart = ({ data, startDate, endDate, fba }) => {
  const domain = [dataMin => dataMin, () => endDate.getTime()];
  const ticks = getTicks(startDate, endDate, 3);
  const sortedData = _.sortBy(data, ["date"]);
  const plotKeys = _.without(Object.keys(data[0]), "date", "_id");

  const salesMax = _.max(sortedData.map(s => s.quantity));
  const stockMax = _.maxBy(sortedData.map(s => s.stockLevels));
  const fbaStockMax = _.maxBy(sortedData.map(s => s.stockLevelsFba));
  const stockFactor = fba ? fbaStockMax / salesMax : stockMax / salesMax;

  const adjustedData = [];

  for (const dataPoint of sortedData) {
    const adjusted = { ...dataPoint };
    adjusted.stockLevels = dataPoint.stockLevels / stockFactor;
    adjusted.stockLevelsFba = dataPoint.stockLevelsFba / stockFactor;
    adjustedData.push(adjusted);
  }

  return (
    <ResponsiveContainer>
      <ComposedChart data={adjustedData} className="chart">
        <XAxis
          dataKey="date"
          hasTick
          tickCount={3}
          scale="time"
          tickFormatter={dateFormatter}
          type="number"
          domain={domain}
          ticks={ticks}
          tick={{ fontSize: 10 }}
          height={12}
        />
        <YAxis tickCount={3} hasTick domain={[0, "dataMax"]} width={20} tick={{ fontSize: 10 }} />
        <Tooltip content={<CustomTooltip plotKeys={plotKeys} stockFactor={stockFactor} />} />
        <CartesianGrid stroke="#ddd" strokeDasharray="5 5" />
        <Area type="monotone" dataKey="quantity" stroke="#6388b6" fill="#82a7d5" />
        <Line
          type="monotone"
          dataKey={fba ? "stockLevelsFba" : "stockLevels"}
          stroke={fba ? "#FBA300" : "#FA6050"}
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default SalesAndStockChart;
