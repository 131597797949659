import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import StorageUnitReadOnly from "./StorageUnitReadOnly";
import Loader from "../common/Loader";
import PrepCenter from "./prep/PrepCenter";
import { getCurrentQuantity } from "../../services/warehouseService";
import { isSafeToClick } from "../../utils/helper";

class EditOutboundShipmentModal extends Component {
  getTotalQuantity = storageUnits => {
    const result = storageUnits.reduce(
      (acc, cur) => acc + cur.contents.reduce((acc, cur) => acc + parseInt(getCurrentQuantity(cur) || 0), 0),
      0
    );
    if (typeof result !== "number" && typeof result !== "string") return;
    return result;
  };

  render() {
    const {
      show,
      errors,
      readOnly,
      spinner,
      spinnerText,
      shipment,
      storageUnits,
      onSubmit,
      onSaveAsDraft,
      onHide,
      onDelete,
      onAdd,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-w90"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <i className="fas fa-sign-out-alt mr-2 text-warning"></i>Outbound shipment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {spinner ? (
            <div className="m-5 text-center">
              <Loader className="mb-2" />
              <p>{spinnerText}</p>
            </div>
          ) : (
            <Fragment>
              <h3>{shipment.shipmentId}</h3>
              <p>
                {readOnly
                  ? "This shipment can't be modified at this point"
                  : "Please add shipment units to this shipment."}
              </p>
              {errors && typeof errors === "string" ? <div className="alert alert-danger">{errors}</div> : ""}
              <div className="form-row justify-content-between mb-3">
                <div className="col-auto my-auto">
                  <b>
                    {storageUnits.length} Unit
                    {storageUnits.length === 1 ? "" : "s"}
                  </b>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-xl-4">
                  {storageUnits.map((s, i) => (
                    <StorageUnitReadOnly key={i} index={i} data={s} onDelete={readOnly ? null : onDelete} />
                  ))}
                </div>
                <div className="col-12 col-xl-8">
                  {readOnly ? (
                    "No new items can be added to this shipment. Please create a new one."
                  ) : (
                    <PrepCenter onAdd={onAdd} shipmentUnits={storageUnits} />
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col-6 col-lg-auto my-lg-auto">
              <Button variant="secondary" className="my-3 btn-block" onClick={onHide} disabled={spinner}>
                Cancel
              </Button>
            </div>
            <div className="col my-auto text-right">
              Total Quantity <b>{this.getTotalQuantity(storageUnits)}</b>
            </div>
            <div className="col-12 col-lg-auto mb-3 my-lg-auto">
              <Button variant="secondary" className="btn-block" onClick={onSaveAsDraft} disabled={spinner || readOnly}>
                Save as Draft{isSafeToClick(true)}
              </Button>
            </div>
            <div className="col-12 col-lg-auto mb-3 my-lg-auto">
              <Button
                variant="primary"
                className="btn-block"
                onClick={readOnly ? () => {} : onSubmit}
                disabled={spinner || readOnly}
              >
                Submit{isSafeToClick(false)}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditOutboundShipmentModal;
