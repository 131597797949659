import React, { useState, useContext, useEffect, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import StorageUnitList from "./StorageUnitList";
import PleaseScan from "./PleaseScan";
import Barcode from "../common/Barcode";
import barcodeService, { BarcodeContext } from "../../services/barcodeService";
import warehouseService from "../../services/warehouseService";
import Loader from "../common/Loader";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";
import { isSafeToClick } from "../../utils/helper";

const GroupModal = ({
  storageGroup,
  loading,
  show,
  onHide,
  onSubmit,
  onReceive,
  groupContents,
  setGroupContents,
  showConfirmModal,
}) => {
  const [scanData, setScanData] = useState({});
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteContent, setDeleteContent] = useState();
  const barcode = useContext(BarcodeContext);

  const { loading: isLoading, message } = loading;

  async function handleScan(data) {
    const scanFeedback = await barcodeService.getData(data);
    setScanData(scanFeedback);
  }

  async function handleSubmit() {
    //create new group or update existing group?
    setScanData({});
    const _id = storageGroup._id || null;
    await onSubmit(groupContents, _id);
  }

  const handleHide = () => {
    setScanData({});
    onHide();
  };

  useEffect(() => {
    (async () => {
      if (show && scanData.data) {
        if (scanData.type === "storageunit") {
          try {
            const doubleScan = groupContents.find(c => c.storageUnitId === scanData.data || c._id === scanData.data);
            if (doubleScan) return toast.info("Lagereinheit wurde bereits der Gruppe hinzugefügt");
            try {
              const storageUnit = await warehouseService.loadStorageUnit(scanData.data);

              if (!storageUnit.receiveDate) {
                const result = await onReceive(storageUnit);
                if (!result) return;
              }

              if (storageUnit.parentGroup) {
                const result = await showConfirmModal({
                  title: "Bereits gruppiert",
                  description: `Einheit ${storageUnit.storageUnitId} ist schon einer Gruppe zugeordnet. Soll diese in die neue Gruppe verschoben werden?`,
                  confirmText: "Verschieben",
                  cancelText: "Abbrechen",
                });
                if (!result) return;
              }

              if (storageUnit.location) {
                const result = await showConfirmModal({
                  title: "Bereits eingelagert",
                  description: `Einheit ${storageUnit.storageUnitId} ist schon einem Lagerplatz zugeordnet. Soll diese ausgelagert und zur Gruppe hinzugefügt werden?`,
                  confirmText: "Auslagern",
                  cancelText: "Abbrechen",
                });
                if (!result) return;
              }

              setGroupContents([...groupContents, storageUnit]);
              return;
            } catch (err) {
              if (err.response && err.response.data) {
              } else {
                toast.error("Beim hinzufügen zur Gruppe ist ein Fehler aufgetreten.");
              }
            }
          } catch (err) {
            toast.warning("Keine Lagereinheit mit dieser Nummer gefunden. Bitte versuche es noch einmal");
          }
        } else {
          toast.error('Bitte scanne eine weitere Einheit oder tippe auf "Gruppe abschließen"');
        }
      }
    })(); // eslint-disable-next-line
  }, [scanData]);

  const handleDelete = item => {
    setDeleteContent(item);
    setDeleteModalShow(true);
  };

  const handleConfirmDelete = () => {
    const newGroupContents = groupContents.filter(c => c._id !== deleteContent._id);
    setGroupContents(newGroupContents);
    setDeleteModalShow(false);
  };

  const handleHideDeleteModal = () => {
    setDeleteContent(null);
    setDeleteModalShow(false);
  };

  return (
    <Fragment>
      {!deleteModalShow && barcode.scanModeConnect && <Barcode onScan={handleScan} />}
      <div className="modal-dialog">
        <Modal
          show={show}
          onHide={handleHide}
          onSubmit={onSubmit}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="fullscreen-modal"
        >
          <Modal.Body>
            <div className="splitview">
              <main className="container-fluid spacing-top">
                <div className="row text-center mb-3">
                  <div className="col">
                    <h3>
                      {storageGroup && Object.keys(storageGroup).length ? storageGroup.storageGroupId : "Neue Gruppe"}
                    </h3>
                    <p>Gruppierte Einheiten</p>
                    <StorageUnitList items={groupContents} onDelete={handleDelete} />
                  </div>
                </div>
                <div className="row">
                  <div className="col bg-light p-3">
                    {isLoading ? (
                      <Loader message={message} />
                    ) : (
                      <PleaseScan scanDescription='Bitte scanne eine weitere Einheit oder wähle "Gruppe abschließen"' />
                    )}
                  </div>
                </div>
              </main>
              <footer>
                <div className="container-fluid mb-2">
                  <Button variant="secondary" onClick={handleHide} className="btn-block">
                    Abbrechen
                  </Button>
                </div>
                <div className="container-fluid">
                  <Button variant="primary" onClick={handleSubmit} className="btn-block">
                    {storageGroup && Object.keys(storageGroup).length ? "Gruppe speichern" : "Gruppe abschließen"}
                    {isSafeToClick(true)}
                  </Button>
                </div>
              </footer>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <DeleteModal
        show={deleteModalShow}
        title="Entfernen bestätigen"
        description="Entfernt die ausgewählte Einheit aus der Gruppe"
        scanMessage="Scanne die Lagereinheit um das Entfernen aus der Gruppe zu bestätigen"
        onHide={handleHideDeleteModal}
        onSubmit={handleConfirmDelete}
        deleteContent={deleteContent}
      />
    </Fragment>
  );
};

export default GroupModal;
