import { Component, Fragment } from "react";
import TableStacked from "../common/TableStacked";
import SearchForm from "../common/SearchForm";
import PropTypes from "prop-types";
import Loader from "../common/Loader";
import Stepper from "../common/Stepper";
import RefurbishingSession from "./RefurbishingSession";

class RemissionTable extends Component {
  columns = [
    {
      path: "ean",
      label: "EAN",
    },
    {
      path: "name",
      label: "Name",
    },
    {
      path: "remission.aStock",
      label: "Neuwertig",
      content: product => (
        <div className="mr-lg-4">
          <Stepper
            currentValue={this.props.remissions.aStock[product.sku] || 0}
            onAdd={() => this.props.onAdd(product.sku, false, true)}
            onSubtract={() => this.props.onSubtract(product.sku, false, true)}
            color="#C8EBD2"
            buttonStyle="success"
          />
        </div>
      ),
    },
    {
      path: "remission.bStock",
      label: "B-Ware",
      content: product => (
        <div className="mr-lg-4">
          <Stepper
            currentValue={this.props.remissions.bStock[product.sku] || 0}
            onAdd={() => this.props.onAdd(product.sku, true, true)}
            onSubtract={() => this.props.onSubtract(product.sku, true, true)}
            color="#D7C9B7"
            buttonStyle="brown"
          />
        </div>
      ),
    },
    {
      path: "remission.trashed",
      label: "Nicht verkaufbar",
      content: product => (
        <div className="mr-lg-4">
          <Stepper
            currentValue={this.props.remissions.notSellable[product.sku] || 0}
            onAdd={() => this.props.onAdd(product.sku, false, false)}
            onSubtract={() => this.props.onSubtract(product.sku, false, false)}
            color="#FBDCDC"
            buttonStyle="danger"
          />
        </div>
      ),
    },
  ];

  render() {
    const { products, onSort, onSearch, sortColumn, loading } = this.props;

    return (
      <>
        <div className="row justify-content-between align-items-end pb-4">
          <div className="col-12 col-lg-5 col-xl-6 text-center text-lg-left mb-2 mb-lg-0">
            <h2>Remissionen aufbereiten</h2>
          </div>
          <div className="col-12 col-lg-7 col-xl-6">
            <div className="form-row">
              <div className="col-12 col-lg-6 mb-4 mb-lg-0 text-center text-lg-right">
                <RefurbishingSession onToggle={this.props.onUpdateSession} />
              </div>
              <div className="col-12 col-lg-6">
                <SearchForm
                  placeholder="Suche..."
                  onSubmit={onSearch}
                  onReset={onSearch}
                  submitLabel="Suchen"
                  noMargin={true}
                  small={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {loading ? (
            <div className="py-3 mx-auto">
              <Loader message="Lade erfasste Retouren..." />
            </div>
          ) : (
            <TableStacked
              columns={this.columns}
              data={products}
              sortColumn={sortColumn}
              getId={item => item._id}
              onSort={onSort}
            />
          )}
        </div>
      </>
    );
  }
}

RemissionTable.propTypes = {
  products: PropTypes.array.isRequired,
  remissions: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  sortColumn: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RemissionTable;
