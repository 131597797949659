function Checkbox({ name, id, label, isSelected, onCheckboxChange, error = "", ...rest }) {
  return (
    <div className="form-check">
      <input
        name={name}
        className="form-check-input"
        type="checkbox"
        id={id}
        onClick={event => event.stopPropagation()}
        checked={isSelected}
        onChange={onCheckboxChange}
        {...rest}
      />
      <label className="form-check-label" htmlFor={id} onClick={event => event.stopPropagation()}>
        {label}
      </label>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

export default Checkbox;
