import React, { Component } from "react";
import { toast } from "react-toastify";
import SearchForm from "../common/SearchForm";
import LoadMore from "../common/LoadMore";
import CardList from "./CardList";
import Loader from "../common/Loader";
import ReturnsService from "../../services/returnsService";
import OrdersService from "../../services/ordersService";
import ImageService from "../../services/imageService";
import { getCurrentUser } from "../../services/authService";
import paginateAdditive from "../../utils/paginateAdditive";
import { removeDuplicatesByKey, uniqueBy } from "../../utils/arrayUtils";
import ReceiveModal from "./ReceiveModal";
import ProductListModal from "../modals/ProductListModal";
import logger from "../../services/logService";
import { BarcodeContext } from "../../services/barcodeService";
import { isSafeToClick } from "../../utils/helper";
import productsService from "../../services/productsService";

class Receive extends Component {
  static contextType = BarcodeContext;

  _isMounted = false;
  state = {
    pageSize: 12,
    currentPage: 1,
    searchQuery: "",
    orderDetails: {},
    openReturns: [],
    receivedReturns: {},
    products: {},
    preannouncedReturns: {},
    searchResults: [],
    imageDb: {
      loading: true,
      data: {},
    },
    spinner: false,
    listLoading: true,
    receiveModalShow: false,
    productListModalShow: false,
    receiveData: {},
  };

  //SETUP
  async componentDidMount() {
    this._isMounted = true;
    await Promise.all([
      this.fetchImageDb(),
      this.fetchReturnsData(),
      this.fetchReceivedReturns(),
      this.fetchProducts(),
    ]);
    this.setState({ listLoading: false });
  }

  componentWillUnmount() {
    this.context.setScanModeConnect(false);
    this._isMounted = false;
  }

  fetchImageDb = async () => {
    let imageDb = { ...this.state.imageDb };
    // if (imageDb.loading === false) return;

    //if cache is not available
    imageDb = await ImageService.getImageDb();
    if (!imageDb) return;

    imageDb.loading = false;
    if (this._isMounted) this.setState({ imageDb });
  };

  fetchReceivedReturns = async () => {
    const receivedReturns = { ...this.state.receivedReturns };
    const { returns } = await ReturnsService.getReceivedReturns(
      "-receive.date",
      this.state.currentPage,
      this.state.pageSize * 2,
      this.state.searchQuery
    );
    const returnsDict = {};
    returns.forEach(
      r =>
        (returnsDict[r.order.orderNumber] = returnsDict[r.order.orderNumber]
          ? [...returnsDict[r.order.orderNumber], r]
          : [r])
    );
    Object.assign(receivedReturns, returnsDict);

    if (this._isMounted) this.setState({ receivedReturns });
  };

  fetchProducts = async () => {
    const productData = await productsService.getAllProducts();

    const productDict = {};
    productData.forEach(p => (productDict[p.sku] = p));

    if (this._isMounted) this.setState({ products: productDict });
  };

  fetchReturnsData = async () => {
    this.setState({ listLoading: true });
    const openReturns = await ReturnsService.getOpenReturns();
    const filteredReturns = uniqueBy(openReturns, ["orderid", "sku", "falseDelivery"]);
    const preannouncedReturnsArray = openReturns.filter(o => o.type === "preannouncement");
    const preannouncedReturns = {};
    preannouncedReturnsArray.forEach(
      p =>
        (preannouncedReturns[p.orderNumber] = preannouncedReturns[p.orderNumber]
          ? [...preannouncedReturns[p.orderNumber], p]
          : [p])
    );

    if (this._isMounted) this.setState({ openReturns: filteredReturns, preannouncedReturns });
  };

  handleLoadDetails = async orderReturn => {
    const { orderId } = orderReturn;
    let orderDetails = { ...this.state.orderDetails };
    if (orderDetails.hasOwnProperty(orderId)) return;

    //try cache first
    const cachedOrderDetailsJSON = localStorage.getItem("orderDetails");

    if (cachedOrderDetailsJSON) {
      const cachedOrderDetails = JSON.parse(cachedOrderDetailsJSON);
      if (cachedOrderDetails.hasOwnProperty(orderId) && this._isMounted) {
        this.setState(prevState => {
          const orderDetails = { ...prevState.orderDetails };
          orderDetails[orderId] = cachedOrderDetails[orderId];
          return { orderDetails };
        });
        return;
      }
    }

    //get data from API
    const { data } = await OrdersService.getOrderDetails(orderId);
    if (!data) return;

    // Filter out Bundles (Product.Type === 2)
    const filteredOrderItemsData = {
      ...data,
      OrderItems: data.OrderItems.filter(oi => this.state.products[oi.Product?.SKU]?.isBundle !== true),
    };

    if (this._isMounted)
      this.setState(prevState => {
        let orderDetails = { ...prevState.orderDetails };
        orderDetails[orderId] = filteredOrderItemsData;
        try {
          localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
        } catch (err) {
          logger.error("Error writing to local Storage", err);
        }
        return { orderDetails };
      });
  };

  //HANDLERS
  handleSearch = async query => {
    this.setState({ searchQuery: query, currentPage: 1, searchResults: [], listLoading: true }, this.fetchReturnsData);
    const searchResults = await OrdersService.getSearchResults(query);
    if (this._isMounted) this.setState({ searchResults, listLoading: false });
  };

  handleReset = () => {
    this.setState({ searchQuery: "", currentPage: 1 }, this.fetchReturnsData);
  };

  handleReceive = (orderDetail, orderItem, receivedItems, preannouncedItems) => {
    const barcode = this.context;
    barcode.setScanModeConnect(true);
    this.setState({
      receiveModalShow: true,
      receiveData: { orderDetail, orderItem, falseDelivery: false, receivedItems, preannouncedItems },
    });
  };

  handleReceiveOther = orderDetail => {
    this.setState({
      productListModalShow: true,
      receiveData: { orderDetail, falseDelivery: true },
    });
  };

  handleConfirmReceiveOther = orderItem => {
    const receiveData = { ...this.state.receiveData };
    receiveData.orderItem = orderItem;
    receiveData.falseDelivery = true;

    this.context.setScanModeConnect(true);
    this.setState({
      productListModalShow: false,
      receiveModalShow: true,
      receiveData,
    });
  };

  handleConfirmReceive = async ({ amount: count, notes, refundDeduction, refundRequired }) => {
    try {
      this.setState({ spinner: true });
      const { orderDetail, orderItem, falseDelivery } = this.state.receiveData;
      const orderNumber = orderDetail.OrderNumber;
      const sku = orderItem.sku;
      const user = getCurrentUser();
      const result = await ReturnsService.receiveProduct(
        orderNumber,
        sku,
        user._id,
        notes,
        refundDeduction,
        refundRequired,
        falseDelivery
      );
      this.fetchReceivedReturns();

      if (result && result.data) toast.success("Die Rücksendung wurde erfolgreich erfasst.");
    } catch (err) {
      logger.log(err);
      toast.error("Es ist ein Fehler aufgetreten. " + err.response?.data || err);
    }
    this.setState({ receiveModalShow: false, spinner: false, receiveData: {} });
    this.context.setScanModeConnect(false);
    await this.fetchReturnsData();
  };

  handlePreannounce = async ({ amount: count, notes, refundDeduction, refundRequired }) => {
    try {
      this.setState({ spinner: true });
      const { orderDetail, orderItem, falseDelivery } = this.state.receiveData;
      const orderNumber = orderDetail.OrderNumber;
      const sku = orderItem.sku;
      try {
        const result = await ReturnsService.preannounceOrderReturn(
          orderNumber,
          sku,
          count,
          notes,
          refundDeduction,
          refundRequired,
          falseDelivery
        );
        if (result) toast("Vielen Dank, die Anmerkung wurde erfolgreich gespeichert.");
      } catch (err) {
        logger.error("Error while preannouncing the return. ", err);
        toast.error("Fehler bei der Anmeldung der Retoure. " + err.response?.data || err);
        return;
      }
      this.fetchReceivedReturns();
    } catch (err) {
      logger.error("Error while reloading received returns. ", err);
      toast.error("Ein unbekannter Fehler ist aufgetreten.");
    }
    this.setState({ receiveModalShow: false, spinner: false });
    this.context.setScanModeConnect(false);
    await this.fetchReturnsData();
  };

  handlePageChange = async page => {
    this.setState({ currentPage: page, listLoading: true });
    await this.fetchReceivedReturns();
    this.setState({ listLoading: false });
  };

  //FILTERING AND PAGINATION
  getPageData = () => {
    const { searchQuery, openReturns, orderDetails, searchResults, pageSize, currentPage } = this.state;

    let itemList = openReturns;
    let filteredReturns;

    if (searchQuery !== "") {
      // When searching the orders will be displayed, so the only duplicate order entries should be those that have been preannounced by clicking other product.
      filteredReturns = removeDuplicatesByKey(openReturns, searchResults, ["orderId", "falseDelivery"]);

      filteredReturns = filteredReturns.filter(currentResult =>
        JSON.stringify(currentResult).toLowerCase().includes(searchQuery.toLowerCase())
      );

      //remove double entries from search results using the sku data from the orderDetails data,
      //since the search results don't contain skus
      const filteredSearchResults = searchResults.filter(s => {
        if (!orderDetails[s.orderId]) return true;
        return openReturns.find(
          o =>
            o.orderId === s.orderId &&
            orderDetails[s.orderId].OrderItems.find(o => o.Product?.SKU === o.sku) &&
            orderDetails[s.orderId].OrderItems.find(o => o.Product?.SKU === o.sku)?.Product !== null
        )
          ? false
          : true;
      });

      itemList = [...filteredReturns, ...filteredSearchResults];
    }

    const pageData = paginateAdditive(itemList, currentPage, pageSize);

    return { pageData: pageData, totalCount: itemList.length };
  };

  render() {
    const {
      searchQuery,
      currentPage,
      pageSize,
      imageDb,
      orderDetails,
      productListModalShow,
      receivedReturns,
      preannouncedReturns,
      receiveModalShow,
      spinner,
      listLoading,
      receiveData,
    } = this.state;

    const receiveModalClose = () => {
      this.setState({ receiveModalShow: false });
      this.context.setScanModeConnect(false);
    };
    const productListModalClose = () => this.setState({ productListModalShow: false });

    const user = getCurrentUser();

    let canPreannounceReturn = false;
    if (user.isAdmin || user.roles?.includes("support")) canPreannounceReturn = true;

    const { pageData, totalCount } = this.getPageData();
    return (
      <div className="container-fluid spacing-top">
        <h1 className="text-center">Rücksendungen erfassen</h1>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <SearchForm
              placeholder="Suche"
              value={searchQuery}
              onSubmit={this.handleSearch}
              onReset={this.handleReset}
              submitLabel="<i class='fas fa-search'></i>"
            />
          </div>
        </div>
        <div className="row">
          <CardList
            pageData={pageData}
            receivedReturns={receivedReturns}
            preannouncedReturns={preannouncedReturns}
            imageDb={imageDb}
            onReceive={this.handleReceive}
            onReceiveOther={this.handleReceiveOther}
            onLoadDetails={this.handleLoadDetails}
            orderDetails={orderDetails}
          />
        </div>
        {listLoading ? (
          <div className="w-100 p-5 d-block">
            <Loader />
          </div>
        ) : (
          <LoadMore
            currentPage={currentPage}
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            label="Mehr laden..."
            className="mb-4"
          />
        )}
        <ReceiveModal
          show={receiveModalShow}
          onHide={receiveModalClose}
          onSubmit={this.handleConfirmReceive}
          onPreannounce={this.handlePreannounce}
          canPreannounceReturn={canPreannounceReturn}
          preannouncedItems={receiveData.preannouncedItems}
          receivedItems={receiveData.receivedItems}
          spinner={spinner}
          user={user}
        />
        <ProductListModal
          show={productListModalShow}
          imageDb={imageDb}
          onHide={productListModalClose}
          onSubmit={this.handleConfirmReceiveOther}
          buttonText={"Produkt annehmen" + isSafeToClick(true)}
        />
      </div>
    );
  }
}

export default Receive;
