import React, { Component, Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

class CollapsibleTableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path);
  };

  renderGroup = (groupData, column) => {
    if (column.content) return column.content(groupData);
    return _.get(groupData, column.path);
  };

  createKey = (itemId, column) => {
    return itemId + "-" + (column.path || column.key);
  };

  handleClick = e => {
    const elements = document.getElementsByClassName(e.currentTarget.id);
    const arrow = e.currentTarget.querySelector("#arrowIndicator");

    arrow.className.indexOf("open") > -1 ? arrow.classList.remove("open") : arrow.classList.add("open");

    for (const element of elements) {
      element.className.indexOf("collapse") > -1 && arrow.className.indexOf("open") > -1
        ? element.classList.add("show")
        : element.classList.remove("show");
    }
  };

  sanitize(groupString) {
    if (!groupString) return "";
    return groupString.replace(" ", "-");
  }

  render() {
    const { getId, groupedData, columns, groupColumns } = this.props;

    return (
      <tbody>
        {groupedData.map(group => (
          <Fragment key={this.sanitize(group._id) + "-fragment"}>
            <tr key={this.sanitize(group._id) + "-row"} id={this.sanitize(group._id)} onClick={this.handleClick}>
              {groupColumns.map(column => (
                <td
                  key={this.createKey(this.sanitize(group._id), column)}
                  className={`align-middle${column.shading ? " bg-light" : ""}`}
                >
                  {this.renderGroup(group, column)}
                </td>
              ))}
            </tr>
            {group.data.map(item => (
              <tr key={getId(item) + "-row"} className={"accordion-body collapse " + this.sanitize(group._id)}>
                {columns.map(column => (
                  <td
                    key={this.createKey(getId(item), column)}
                    className={`align-middle tableGroupContent${column.shading ? " bg-light" : ""}`}
                  >
                    <div>{this.renderCell(item, column)}</div>
                  </td>
                ))}
              </tr>
            ))}
          </Fragment>
        ))}
      </tbody>
    );
  }
}

CollapsibleTableBody.propTypes = {
  getId: PropTypes.func.isRequired,
  groupedData: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

CollapsibleTableBody.defaultProps = {
  getId: item => item._id,
};

export default CollapsibleTableBody;
