import React, { useContext } from "react";
import BootstrapButton from "react-bootstrap/Button";
import { createModal } from "react-modal-promise";
import LocationSettings from "./LocationSettings";
import { BarcodeContext } from "../../services/barcodeService";

const SettingsButton = ({ location, onReload }) => {
  const showLocationSettings = createModal(LocationSettings);
  const barcode = useContext(BarcodeContext);

  const onOpenSettings = async () => {
    barcode.setScanModeConnect(true);
    await showLocationSettings({ confirmText: "Speichern", onReload: onReload, location: location });
    barcode.setScanModeConnect(false);
  };

  return (
    <BootstrapButton variant="light" className="btn-sm px-3" onClick={onOpenSettings}>
      <i className="fas fa-gear"></i>
    </BootstrapButton>
  );
};

export default SettingsButton;
