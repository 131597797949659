import _ from "lodash";

/**
 * Removes the duplicates from the source array and returns it.
 * @param {array} sourceArray
 * @param {array} compareArray
 * @param {string} key
 */
export function removeDuplicatesByKey(sourceArray, compareArray, keys) {
  const filteredSourceArray = sourceArray.filter(
    sourceItem => !compareArray.find(compareItem => keys.every(key => sourceItem[key] === compareItem[key]))
  );
  return filteredSourceArray;
}

export function uniqueBy(a, keys) {
  var seen = {};
  return a.filter(item => {
    const uniqueStr = keys.map(k => item[k]).join("-");
    return seen.hasOwnProperty(uniqueStr) ? false : (seen[uniqueStr] = true);
  });
}

export function addToObject(sourceArray, objectToAdd, keyToUse, matchValue, findByKey) {
  const result = sourceArray.map(function (object) {
    if (object[findByKey] === matchValue) {
      object[keyToUse] = objectToAdd;
    }
    return object;
  });

  return result;
}

export function getMedianOfArray(array) {
  if (!array) return;
  array.sort((a, b) => a - b);
  let lowMiddle = Math.floor((array.length - 1) / 2);
  let highMiddle = Math.ceil((array.length - 1) / 2);
  let median = (array[lowMiddle] + array[highMiddle]) / 2;
  return median;
}

export function getAverageOfArray(array) {
  if (!array) return;
  let sum = array.reduce((previous, current) => (current += previous));
  let avg = sum / array.length;
  return avg;
}

export function getSumOfArray(array) {
  if (!array) return;
  let sum = array.reduce((previous, current) => (current += previous), 0);
  return sum;
}

export function getMinOfArray(array) {
  if (!array) return;
  let filtered =
    typeof array === "object" && Array.isArray(array) ? array.filter(i => typeof i === "number" && i >= 0) : [];
  if (!filtered.length) return null;
  return Math.min(...filtered);
}

export function getMaxOfArray(array) {
  if (!array) return;
  let filtered =
    typeof array === "object" && Array.isArray(array) ? array.filter(i => typeof i === "number" && i >= 0) : [];
  if (!filtered.length) return null;
  return Math.max(...filtered);
}

export function orderByWithIntercept(inputArray, path, sortOrder, interceptFunction, sortLastString = "sortLast") {
  const sortArray = _.cloneDeep(inputArray);
  if (interceptFunction)
    sortArray.forEach(item => {
      item[path] = interceptFunction(item[path], sortOrder);
    });

  //First split out the "sortLast" to prevent issues while sorting.
  //lodash does not sort mixed numbers and strings properly
  const front = sortArray.filter(e => e[path] !== sortLastString);
  const back = sortArray.filter(e => e[path] === sortLastString);

  return [..._.orderBy(front, [path], [sortOrder]), ...back];
}

export function isValidArray(obj) {
  return typeof obj === "object" && Array.isArray(obj) && obj.length;
}

const arrayUtils = {
  removeDuplicatesByKey,
  addToObject,
  uniqueBy,
  getMedianOfArray,
  getAverageOfArray,
  getSumOfArray,
  getMinOfArray,
  getMaxOfArray,
  orderByWithIntercept,
  isValidArray,
};

export default arrayUtils;
