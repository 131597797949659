import React from "react";

const Textarea = ({ name, label, error, ...rest }) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={`${name}-textarea`}>{label}</label>}
      <textarea
        name={name}
        id={`${name}-textarea`}
        onClick={event => event.stopPropagation()}
        {...rest}
        className="form-control"
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Textarea;
