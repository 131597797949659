import React from "react";
import GroupContents from "./GroupContents";
import Location from "./Location";
import moment from "moment-timezone";

const StorageGroupContent = ({ data, onEdit, onReload }) => {
  const { storageGroupId, location, storageDate, contents } = data || {};

  return (
    <main className="container-fluid spacing-top">
      <div className="row">
        <div className="col">
          <p className="text-light-gray mb-0">Lagergruppe</p>
          <h3 className="mb-2">
            <b>{`${storageGroupId}`}</b>
          </h3>
          <p className="mb-4 text-muted">
            <i className="fas fa-pallet text-muted"></i>{" "}
            {storageDate ? (
              moment(storageDate).tz("Europe/Berlin").format("dd, DD. MMMM YYYY")
            ) : (
              <span className="text-danger">Noch nicht eingelagert</span>
            )}
          </p>
        </div>
        <div className="col-auto">
          <div className={`m-2 btn btn-circle-icon btn-warning padding-y-6`}>
            <i className="fas fa-boxes"></i>
          </div>
        </div>
      </div>
      <GroupContents storageGroup={data} onEditGroup={() => onEdit(contents)} />
      <Location location={location} className="mb-5" onReload={onReload} />
    </main>
  );
};

export default StorageGroupContent;
