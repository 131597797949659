import React, { Fragment } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { createModal } from "react-modal-promise";
import Shipment from "./Shipment";
import ShipmentOutbound from "./ShipmentOutbound";
import Form from "../common/Form";
import AddShipmentModal from "./AddShipmentModal";
import EditShipmentModal from "./EditShipmentModal";
import EditOutboundShipmentModal from "./EditOutboundShipmentModal";
import SimpleModal from "../common/SimpleModal";
import DateModal from "../common/DatePromiseModal";
import ConfirmModal from "../common/SimplePromiseModal";
import Button from "../common/Button";
import Loader from "../common/Loader";
import shipmentService, {
  loadAllShipments,
  createShipment,
  createOutboundShipment,
  createStorageUnit,
  deleteShipment,
  deleteStorageUnit,
  updateStorageUnit,
  createShipmentLabels,
  updateShipment,
  removeDraftContent,
} from "../../services/shipmentService";
import logger from "../../services/logService";
import { getCurrentUser } from "../../services/authService";
import asyncUtils from "../../utils/asyncUtils";
import { isSafeToClick } from "../../utils/helper";

class Shipments extends Form {
  state = {
    user: null,
    shipments: [],
    currentShipment: {},
    shipmentsSpinner: true,
    showAddShipmentDialog: false,
    showEditShipmentDialog: false,
    showEditOutboundShipmentDialog: false,
    editOutboundError: null,
    addShipmentSpinner: false,
    editShipmentSpinner: false,
    editShipmentSpinnerText: "",
    showAreYouSureModal: false,
    areYouSureSpinner: false,
    deletionQueue: [],
    data: {
      storageUnits: [
        {
          _id: "",
          category: "pallet",
          unitNo: 1,
          contents: [{ itemNumber: "", initialQuantity: "", poNumber: "", bStock: false }],
        },
      ],
    },
    errors: { storageUnits: "" },
  };

  showDateModal = createModal(DateModal);
  showConfirmModal = createModal(ConfirmModal);

  defaultItem = { itemNumber: "", initialQuantity: "", poNumber: "", bStock: false };

  defaultData = (index = 0, category = "pallet") => {
    return {
      _id: "",
      category,
      unitNo: index + 1,
      contents: [{ itemNumber: "", initialQuantity: "", poNumber: "", bStock: false }],
    };
  };

  schema = {
    storageUnits: Joi.array()
      .required()
      .label("Storage Units")
      .min(1)
      .items(
        Joi.object({
          _id: Joi.string().allow(""),
          category: Joi.string().label("Packaging"),
          unitNo: Joi.number().label("Unit number"),
          totalUnitsShipment: Joi.number().label("Total units"),
          contents: Joi.array()
            .required()
            .label("Contents")
            .min(1)
            .items(
              Joi.object({
                poNumber: Joi.string().label("Purchase Order number (PO-No.)").required(),
                itemNumber: Joi.string().label("EAN or SKU"),
                ean: Joi.string().label("EAN"), //Needed during migrate of "ean" field to "itemNumber" field
                sku: Joi.string().label("SKU"), //Needed during migrate of "ean" field to "itemNumber" field
                initialQuantity: Joi.number().min(1).label("Quantity").required(),
                bStock: Joi.boolean().label("B-Stock").required(),
              })
            ),
        })
      ),
  };

  componentDidMount() {
    this.fetchShipmentsList();
    const user = getCurrentUser();
    this.setState({ user });
  }

  fetchShipmentsList = async () => {
    const result = await loadAllShipments();
    if (!result) return this.setState({ shipmentsSpinner: false });
    this.setState({ shipments: result, shipmentsSpinner: false });
  };

  handleAddShipment = () => {
    this.setState({ showAddShipmentDialog: true });
  };

  handleHideAddShipmentDialog = () => {
    this.setState({ showAddShipmentDialog: false, addShipmentSpinner: false });
  };

  handleConfirmAddShipment = async ({ shippingDate, supplierShortCode = null }) => {
    this.setState({ addShipmentSpinner: true });
    try {
      const result = await createShipment(shippingDate, supplierShortCode);
      await this.fetchShipmentsList();
      const shipment = this.state.shipments.find(s => s._id === result._id);
      this.setState({
        addShipmentSpinner: false,
        editShipmentSpinner: false,
        showEditShipmentDialog: true,
        currentShipment: shipment,
      });
    } catch (err) {
      this.setState({ addShipmentSpinner: false });
      throw err;
    }
  };

  ///DELETE SHIPMENTS

  handleDeleteShipment = s => {
    this.setState({ currentShipment: s, showAreYouSureModal: true });
  };

  handleConfirmDeleteShipment = async () => {
    this.setState({ areYouSureSpinner: true });
    const oldShipments = [...this.state.shipments];
    const _id = this.state.currentShipment._id;
    try {
      await deleteShipment(_id);
      await this.fetchShipmentsList();
      this.setState({ showAreYouSureModal: false });
      setTimeout(() => this.setState({ areYouSureSpinner: false }), 500);
    } catch (err) {
      this.setState({ shipments: oldShipments });
      toast.error(`Could not delete shipment because of an error. ${err}`);
    }
  };

  ///EDIT SHIPMENT CONTENTS
  handleEditShipment = async s => {
    this.setState({
      currentShipment: s,
      showEditShipmentDialog: true,
      editShipmentSpinner: true,
      editShipmentSpinnerText: "Loading shipment...",
    });
    const storageUnits = await shipmentService.loadStorageUnits(s);
    const data = { ...this.state.data };
    data.storageUnits = storageUnits;
    this.setState({ data, editShipmentSpinner: false, editShipmentSpinnerText: "" });
  };

  handleEditShipmentOutbound = async (s, readOnly = false) => {
    this.setState({
      currentShipment: s,
      showEditOutboundShipmentDialog: true,
      outboundShipmentReadOnly: readOnly,
      editShipmentSpinner: true,
      editShipmentSpinnerText: "Loading shipment...",
    });

    let storageUnits;

    if (s.draftContent && s.draftContent.storageUnits) {
      storageUnits = s.draftContent.storageUnits;
    } else {
      storageUnits = s.contents;
    }
    const data = { ...this.state.data };
    data.storageUnits = storageUnits;
    this.setState({ data, editShipmentSpinner: false, editShipmentSpinnerText: "" });
  };

  handleStorageUnitAddUnit = category => {
    const nextIndex = this.state.data.storageUnits.length || 0;
    const data = { ...this.state.data };
    const newLine = this.defaultData(nextIndex, category);
    data.storageUnits = [...data.storageUnits, newLine];
    this.setState({ data });
  };

  handleStorageUnitAddItem = storageUnitIndex => {
    const data = { ...this.state.data };
    data.storageUnits[storageUnitIndex].contents.push({ ...this.defaultItem });
    this.setState({ data });
  };

  handleStorageUnitRemoveItem = (storageUnitIndex, eanIndex) => {
    const data = { ...this.state.data };
    data.storageUnits[storageUnitIndex].contents.splice(eanIndex, 1);
    this.setState({ data });
  };

  handleStorageUnitChange = input => {
    const data = { ...this.state.data };
    const { index, contentsIndex, name, value } = input;
    if (name === "category" || name === "index" || name === "unitNo") data.storageUnits[index][name] = value;
    else if (name.substr(0, 6) === "bStock")
      data.storageUnits[index].contents[contentsIndex][name] = !data.storageUnits[index].contents[contentsIndex][name];
    else data.storageUnits[index].contents[contentsIndex][name] = value;

    this.setState({ data });
  };

  handleDeleteStorageUnit = async (index, _id = "") => {
    const data = { ...this.state.data };
    data.storageUnits.splice(index, 1);
    this.setState({ data });
    if (_id) {
      this.enqueueForDeletion(_id);
    }
  };

  enqueueForDeletion(_id) {
    const deletionQueue = [...this.state.deletionQueue];
    deletionQueue.push(_id);
    this.setState({ deletionQueue });
  }

  emptyDeletionQueue() {
    this.setState({ deletionQueue: [] });
  }

  async executeDeletionQueue() {
    const deletionQueue = [...this.state.deletionQueue];
    try {
      for (const _id of deletionQueue) await deleteStorageUnit(_id);
      this.setState({ deletionQueue: [] });
    } catch (err) {
      this.setState({ deletionQueue: [] });
      await this.fetchShipmentsList();
      await this.handleEditShipment(this.state.currentShipment);
      throw err;
    }
  }

  handleHideEditShipmentDialog = async () => {
    this.setState({ showEditShipmentDialog: false });
    await this.fetchShipmentsList();
    this.emptyDeletionQueue();
    setTimeout(this.resetState, 500);
  };

  handleAddOutboundShipment = async () => {
    const data = { ...this.state.data };
    const date = await this.showDateModal({
      title: "Lieferdatum",
      description: `Bitte definiere das gewünschte Lieferdatum für den Versand zu Amazon.`,
      confirmText: "Lieferung erstellen" + isSafeToClick(true),
      cancelText: "Abbrechen",
      defaultDate: new Date(),
    });
    if (!date) return;
    data.storageUnits = [];
    this.setState({ data, showEditOutboundShipmentDialog: true });
    const { data: currentShipment } = await createOutboundShipment(date, this.state.data);
    this.setState({ currentShipment });
  };

  handleHideEditOutboundShipmentDialog = async () => {
    this.setState({ showEditOutboundShipmentDialog: false });
    await this.fetchShipmentsList();
    // this.emptyDeletionQueue();
    setTimeout(this.resetState, 500);
  };

  //Used for both Inbound and Outbound Shipments
  handleSaveAsDraft = async () => {
    try {
      this.setState({ editShipmentSpinner: true, editShipmentSpinnerText: "Saving Draft..." });
      await this.executeDeletionQueue();
      await updateShipment(this.state.currentShipment._id, this.state.data);
      await this.fetchShipmentsList();
      this.handleHideEditShipmentDialog();
      this.handleHideEditOutboundShipmentDialog();
    } catch (err) {
      logger.error("Error saving the draft. ", err);
      toast.error("Error saving the draft. " + err.message);
    }
  };

  handleStorageUnitOutboundAdd = storageUnit => {
    const data = { ...this.state.data };
    data.storageUnits = [...data.storageUnits, storageUnit];
    this.setState({ data });
  };

  handleStorageUnitOutboundRemove = storageUnit => {
    const data = { ...this.state.data };
    data.storageUnits = data.storageUnits.filter(s => s.storageUnitId !== storageUnit.storageUnitId);
    this.setState({ data });
  };

  handleSubmitOutbound = async () => {
    const { currentShipment, data } = this.state;

    const body = {
      draftContent: { storageUnits: data.storageUnits.map(s => s._id) },
    };

    const result = await this.showConfirmModal({
      title: "Lieferung an Amazon übermitteln?",
      description: `Möchtest du die Lieferung an Amazon übermitteln? Die Einheiten werden dadurch aus dem Lager entfernt und eine Anlieferung in Amazon erstellt. Die Labels werden per Email an info@formbench.com zugestellt.`,
      confirmText: "Übermitteln",
      cancelText: "Abbrechen",
    });
    if (!result) return;
    try {
      this.setState({
        editShipmentSpinner: true,
        editShipmentSpinnerText: "Lieferung wird in Amazon erstellt. Dies kann einige Minuten dauern...",
      });
      await shipmentService.submitOutboundShipment(currentShipment._id, body);

      for (let i = 0; i < 120; i++) {
        await asyncUtils.timeout(1000);
        const { data: result } = await shipmentService.getSubmitOutboundShipmentStatus(currentShipment._id);
        console.log(result.status);
        if (result && result.status === "done") {
          this.setState({
            editShipmentSpinner: true,
            editShipmentSpinnerText: "Lieferung erfolgreich übermittelt.",
          });
          await asyncUtils.timeout(300);
          this.handleHideEditOutboundShipmentDialog();
          this.fetchShipmentsList();
          setTimeout(this.resetState, 500);
        } else if (result && result.status === "pending")
          this.setState({
            editShipmentSpinner: true,
            editShipmentSpinnerText: result.messageDE,
          });
        else if (result && result.status === "failed")
          return this.setState({ editShipmentSpinner: false, editOutboundError: result.messageDE });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        editShipmentSpinner: false,
        editOutboundError: (err.response && err.response.data) || "An unexcpected error occured.",
      });
    }
  };

  //Save or Update all Storage units
  doSubmit = async () => {
    try {
      await this.executeDeletionQueue();
      const data = { ...this.state.data };
      const augmentedStorageUnits = data.storageUnits.map((s, i) => {
        const o = {
          unitNo: i + 1,
          totalUnitsShipment: data.storageUnits.length,
        };
        Object.assign(s, o);

        //if itemNumber is not set pull data from ean field (migrate old draft data to new format ean -> itemNumber)
        s.contents = s.contents.map(c => {
          c.itemNumber = c.itemNumber ? c.itemNumber : c.ean;
          return c;
        });
        return s;
      });

      data.storageUnits = augmentedStorageUnits;
      this.setState({ data, editShipmentSpinner: true, editShipmentSpinnerText: "Saving shipment..." });

      for (const s of augmentedStorageUnits) {
        if (s._id) await updateStorageUnit(s);
        else {
          const result = await createStorageUnit(s, this.state.currentShipment);
          const data = { ...this.state.data };
          data.storageUnits.find(s => s.unitNo === result.unitNo)._id = result._id;
          this.setState({ data });
        }
      }
      const { currentShipment } = this.state;
      await removeDraftContent(currentShipment._id);
      this.setState({ editShipmentSpinnerText: "Creating shipment labels..." });
      await createShipmentLabels(currentShipment);
      this.setState({ editShipmentSpinner: false, editShipmentSpinnerText: "" });
      await this.fetchShipmentsList();
      this.handleHideEditShipmentDialog();
      setTimeout(this.resetState, 500);
    } catch (err) {
      this.setState({ editShipmentSpinner: false, editShipmentSpinnerText: "" });
      const errors = {
        storageUnits:
          ((err.response && err.response.data) || err.message) +
          ". Please contact info@formbench.com to fix the issue. Please leave this window open so you don't loose the content of this form.",
      };
      this.setState({ errors });
    }
  };

  resetState = () => {
    this.setState({
      data: { storageUnits: [this.defaultData()] },
      editShipmentSpinner: false,
      editShipmentSpinnerText: "",
      editOutboundError: null,
      errors: {},
    });
  };

  render() {
    const {
      currentShipment,
      shipments,
      shipmentsSpinner,
      errors,
      data,
      outboundShipmentReadOnly,
      showEditShipmentDialog,
      showEditOutboundShipmentDialog,
      editOutboundError,
      showAddShipmentDialog,
      addShipmentSpinner,
      editShipmentSpinner,
      editShipmentSpinnerText,
      showAreYouSureModal,
      areYouSureSpinner,
      user,
    } = this.state;

    return (
      <Fragment>
        <div className="container spacing-top">
          <h1>Shipments</h1>
          <div className="row my-4 bg-light p-3">
            <div className="col my-auto">Please add a shipment and specify the shipment content.</div>
            <div className="col-auto">
              <div className="mr-2 d-inline">
                {user && (user.isAdmin || user.roles.includes("warehouseManager")) ? (
                  <Button
                    title={"Add FBA Shipment" + isSafeToClick(true)}
                    outline={false}
                    color="secondary"
                    onClick={this.handleAddOutboundShipment}
                  />
                ) : (
                  ""
                )}
              </div>
              <Button
                title={"Add Shipment" + isSafeToClick(true)}
                outline={false}
                color="primary"
                onClick={this.handleAddShipment}
              />
            </div>
          </div>
          {shipmentsSpinner && (
            <div className="white-overlay">
              <div className="content text-center pb-5">
                <Loader className="mb-2" />
                <div className="text-center">Loading shipments</div>
              </div>
              <div className="bg"></div>
            </div>
          )}
          {shipments.map(s =>
            s.shipmentType === "outbound" ? (
              <ShipmentOutbound
                key={s._id}
                data={s}
                onView={s => this.handleEditShipmentOutbound(s, true)}
                onEdit={this.handleEditShipmentOutbound}
                onDelete={this.handleDeleteShipment}
              />
            ) : (
              <Shipment key={s._id} data={s} onEdit={this.handleEditShipment} onDelete={this.handleDeleteShipment} />
            )
          )}
        </div>
        <AddShipmentModal
          show={showAddShipmentDialog}
          onHide={this.handleHideAddShipmentDialog}
          onSubmit={this.handleConfirmAddShipment}
          spinner={addShipmentSpinner}
        />
        <EditShipmentModal
          shipment={currentShipment}
          storageUnits={data.storageUnits}
          show={showEditShipmentDialog}
          errors={errors.storageUnits}
          onAddUnit={this.handleStorageUnitAddUnit}
          onAddItem={this.handleStorageUnitAddItem}
          onRemoveItem={this.handleStorageUnitRemoveItem}
          onHide={this.handleHideEditShipmentDialog}
          onSubmit={this.handleSubmit}
          onSaveAsDraft={this.handleSaveAsDraft}
          onChange={this.handleStorageUnitChange}
          onDeleteUnit={this.handleDeleteStorageUnit}
          spinner={editShipmentSpinner}
          spinnerText={editShipmentSpinnerText}
        />
        <EditOutboundShipmentModal
          shipment={currentShipment}
          readOnly={outboundShipmentReadOnly}
          storageUnits={data.storageUnits}
          errors={editOutboundError}
          show={showEditOutboundShipmentDialog}
          onAdd={this.handleStorageUnitOutboundAdd}
          onHide={this.handleHideEditOutboundShipmentDialog}
          onSubmit={this.handleSubmitOutbound}
          onSaveAsDraft={this.handleSaveAsDraft}
          onDelete={this.handleStorageUnitOutboundRemove}
          spinner={editShipmentSpinner}
          spinnerText={editShipmentSpinnerText}
        />
        <SimpleModal
          show={showAreYouSureModal}
          spinner={areYouSureSpinner}
          title="Are you sure?"
          description={`Do you really want to delete the shipment ${currentShipment.shipmentId}?`}
          confirmText={"Delete" + isSafeToClick(true)}
          cancelText="Cancel"
          onHide={() => this.setState({ showAreYouSureModal: false })}
          onConfirm={this.handleConfirmDeleteShipment}
          buttonColor="danger"
          className="maxWidth480"
        />
      </Fragment>
    );
  }
}

export default Shipments;
