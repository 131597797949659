import React, { useState, useEffect } from "react";
import Table from "../common/TableStacked";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import { getInternalReasons, saveInternalReasons } from "../../services/returnsPortalService";
import Input from "../common/Input";

const InternalReasons = () => {
  const columns = [
    {
      path: "de_DE",
      label: "Deutsch",
      content: reason => reason?.name?.find(n => n.lang === "de_DE").value,
    },
    {
      path: "en_GB",
      label: "Englisch",
      content: reason => (
        <Input
          name="nameEN"
          defaultValue={reason?.name?.find(n => n.lang === "en_GB")?.value || ""}
          onChange={e => handleChangeEnglishName(e.target.value, reason?._id)}
        />
      ),
    },
  ];

  //STATE
  const [sortColumn, setSortColumn] = useState({ path: "nameDE", order: "asc" });
  const [internalReasons, setInternalReasons] = useState([]);

  //Load List Content
  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    loadInternalReasons(isMounted);

    return () => {
      isMounted = false;
    };
  }, []);

  async function loadInternalReasons(isMounted) {
    const internalReasonsList = await getInternalReasons();
    if (isMounted) {
      setInternalReasons(internalReasonsList.filter(i => !i.deleted));
      console.log("Finished Loading Internal Reasons data...");
    }
  }

  async function handleSave() {
    await saveInternalReasons(internalReasons);
    await loadInternalReasons();
  }

  async function handleChangeEnglishName(newValue, _id) {
    const internalReasonsCopy = _.cloneDeep(internalReasons);
    const internalReason = internalReasonsCopy.find(i => i._id === _id);
    const name = internalReason.name.find(n => n.lang === "en_GB");
    if (name) name.value = newValue;
    else internalReason.name.push({ lang: "en_GB", value: newValue });
    setInternalReasons(internalReasonsCopy);
  }

  const onSort = sortColumn => {
    setSortColumn(sortColumn);
  };

  function getPageData() {
    const membersSorted = _.orderBy(
      internalReasons,
      r => r?.name?.find(n => n.lang === sortColumn.path)?.value,
      sortColumn.order
    );
    return membersSorted;
  }

  return (
    <div className="container-fluid spacing-top">
      <div className="row justify-content-between align-items-center mb-5">
        <div className="col col-auto">
          <h1>Interne Gründe übersetzen</h1>
        </div>
        <div className="col col-auto">
          <div className="form-row align-items-center">
            <div className="col-auto mr-3">
              <Button name="save" onClick={handleSave}>
                Speichern
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Table
            columns={columns}
            data={getPageData()}
            getId={item => item._id}
            sortColumn={sortColumn}
            onSort={onSort}
          />
        </div>
      </div>
    </div>
  );
};

export default InternalReasons;
