import React, { useEffect, useState, Fragment } from "react";
import warehouseService, { getCurrentQuantity } from "../../services/warehouseService";
import { Link } from "react-router-dom";

const ProductLineItem = ({ product, showQuantity = true, loadPickingSpot = true }) => {
  const [locations, setLocations] = useState([]);
  const [locationsPlaceholder, setLocationsPlaceholder] = useState("Laden...");

  useEffect(() => {
    (async () => {
      if (loadPickingSpot) {
        const { locations: compatibleLocations } = await warehouseService.loadProductLocations(product.sku);
        if (!compatibleLocations || !compatibleLocations.length) setLocationsPlaceholder("Nicht definiert");
        else setLocations(compatibleLocations);
      }
    })();
  }, [product, loadPickingSpot]);

  return (
    <Fragment>
      {product.name && (
        <Fragment>
          <b>{product.name}</b>
          <br />
        </Fragment>
      )}
      <Fragment>
        {loadPickingSpot ? (
          <Fragment>
            <span className="text-light-gray">Picking-Platz</span>{" "}
            {locations && locations.length ? (
              locations.map((group, i) => {
                return group && group.length ? (
                  <span key={"locationBadge-" + i} className="badge badge-light mr-1">
                    {group.map((l, i) => (
                      <div key={l.locationId}>
                        {i ? ", " : ""}
                        <Link to={`/warehouse/location/${l.locationId}`} className="text-dark">
                          <span className="text-black">{warehouseService.getLocationNumber(l)}</span>
                        </Link>
                      </div>
                    ))}
                  </span>
                ) : (
                  ""
                );
              })
            ) : (
              <span className="text-muted">{locationsPlaceholder}</span>
            )}
            <br />
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
      {product.sku && (
        <Fragment>
          <span className="text-light-gray">SKU</span> {product.sku}
          <br />
        </Fragment>
      )}
      {product.ean && (
        <Fragment>
          <span className="text-light-gray">EAN</span> {product.ean}
          <br />
        </Fragment>
      )}
      {showQuantity ? (
        <div className="form-row align-items-center">
          <div className="col-auto">
            <span className="text-light-gray">Menge in Lagereinheit</span>
          </div>
          <div className={`col-auto pl-0 ${getCurrentQuantity(product) <= 0 ? "text-danger" : ""}`}>
            {getCurrentQuantity(product)}
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default ProductLineItem;
