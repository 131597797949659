import React from "react";
import { useUpdateCheck } from "react-update-notification";

const UpdateNotification = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    ignoreServerCache: true,
    interval: 1000 * 60 * 5,
  });

  if (status === "checking" || status === "current") {
    return null;
  }

  function update() {
    console.log("Updating...");
    localStorage.removeItem("orderDetails");
    sessionStorage.clear();
    reloadPage();
  }

  return (
    <div
      className="bg-success text-white position-fixed p-3 rounded"
      style={{ left: "1rem", bottom: "1rem", width: "260px", zIndex: "9999999999" }}
    >
      <div className="d-flex">
        <span>Es ist ein Update verfügbar!</span>
        <button type="button" className="btn btn-light" onClick={update}>
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateNotification;
