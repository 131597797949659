import React, { Fragment } from "react";
import Joi from "joi-browser";
import Form from "../common/Form";

class NotesForm extends Form {
  state = {
    errors: {}
  };

  schema = {
    refundNotes: Joi.string().required().max(255).allow("").label("Notes")
  };

  handleChange = ({ currentTarget: input }) => {
    const { orderReturns, onChangeNote } = this.props;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (!errorMessage) {
      delete errors[input.name];
      onChangeNote(orderReturns, input.value);
    } else {
      errors[input.name] = errorMessage;
    }

    this.setState({ errors });
  };

  render() {
    const { orderReturns } = this.props;
    return (
      <Fragment>
        {this.renderTextarea(
          "refundNotes",
          "",
          "Notizen zur Erstattung",
          "texarea",
          orderReturns[0].refund ? orderReturns[0].refund.refundNotes : ""
        )}
      </Fragment>
    );
  }
}

export default NotesForm;
