import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class Card extends Component {
  buttonClasses(button) {
    let classes = "btn ";
    button.primary ? (classes += " btn-primary") : (classes += " btn-outline-secondary");

    !button.top ? (button.right ? (classes += " ml-auto") : (classes += " mr-auto")) : (classes += " ml-auto");

    button.circle ? (classes += " btn-circle") : (classes += "");

    return classes;
  }

  renderButton(button) {
    return (
      <Link
        key={button._id}
        dangerouslySetInnerHTML={{ __html: button.title }}
        to="#"
        onClick={button.onClick}
        className={this.buttonClasses(button)}
      ></Link>
    );
  }

  render() {
    const { title, subtitle, description, buttons } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title">{title}</h5>
            {buttons &&
              buttons.map(button => button.top && <span className="text-right">{this.renderButton(button)}</span>)}
          </div>
          {subtitle && <h6 className="card-subtitle mb-4 text-muted">{subtitle}</h6>}
          <div className="card-text">{description}</div>
          <div className="btn-toolbar justify-content-between">
            {buttons && buttons.map(button => !button.top && this.renderButton(button))}
          </div>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.object,
  buttons: PropTypes.array
};

export default Card;
