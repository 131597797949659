import React, { useState, useContext, useEffect, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PleaseScan from "./PleaseScan";
import Barcode from "../common/Barcode";
import barcodeService, { BarcodeContext } from "../../services/barcodeService";
import warehouseService, { getCurrentQuantity } from "../../services/warehouseService";
import Loader from "../common/Loader";
import { toast } from "react-toastify";
import { isSafeToClick } from "../../utils/helper";
import ProductListModal from "../modals/ProductListModal";
import imageService from "../../services/imageService";
import Select from "../common/Select";
import _ from "lodash";
import ChecklistItem from "./ChecklistItem";
import AutoSaveField from "../common/AutoSaveField";
import Joi from "joi-browser";

const EditStuModal = ({ storageUnit, loading, show, onHide, onSubmit, showConfirmModal, showQuantityModal }) => {
  const [imageDb, setImageDb] = useState({});
  const [scanData, setScanData] = useState({});
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newStorageUnit, setNewStorageUnit] = useState();
  const barcode = useContext(BarcodeContext);

  const { loading: isLoading, message } = loading;

  async function handleScan(data) {
    const scanFeedback = await barcodeService.getData(data);
    setScanData(scanFeedback);
  }

  async function handleSubmit() {
    setScanData({});
    await onSubmit(newStorageUnit);
  }

  const handleHide = () => {
    setScanData({});
    onHide();
  };

  const handleSaveProduct = scanFeedback => {
    setAddModalOpen(false);
    if (!scanFeedback) return;
    setScanData({ type: "product", data: scanFeedback.sku });
  };

  const handleSaveNote = input => {
    const tempStorageUnit = _.cloneDeep(newStorageUnit);
    tempStorageUnit.inspection = tempStorageUnit.inspection || {};
    tempStorageUnit.inspection.notes = input;
    setNewStorageUnit(tempStorageUnit);
  };

  useEffect(() => {
    (async () => {
      const imageDb = await imageService.getImageDb();
      setImageDb(imageDb);
    })();
  }, []);

  useEffect(() => {
    setNewStorageUnit(storageUnit);
  }, [storageUnit]);

  useEffect(() => {
    (async () => {
      if (show && scanData.data) {
        if (scanData.type === "product") {
          try {
            const doubleScan = newStorageUnit?.contents?.find(
              c => c?.ean === scanData.data || c?.sku === scanData.data || c?._id === scanData.data
            );
            if (doubleScan) return toast.info("Produkt wurde bereits der Lagereinheit hinzugefügt");
            try {
              const product = await warehouseService.loadProduct(scanData.data);
              const { sku, name } = product;
              const content = {
                sku,
                name,
                bStock: sku.substr(sku.length - 2, 2) === "-U",
                initialQuantity: await showQuantityModal({
                  title: "Menge",
                  description: "Welche Menge soll der Lagereinheit hinzugefügt werden?",
                  confirmText: "Hinzufügen",
                  cancelText: "Abbrechen",
                }),
              };
              const tempStorageUnit = _.cloneDeep(newStorageUnit);
              tempStorageUnit.contents = newStorageUnit?.contents ? [...newStorageUnit?.contents, content] : [content];
              if (content.initialQuantity) setNewStorageUnit(tempStorageUnit);
            } catch (err) {
              if (err.response && err.response.data) {
              } else {
                toast.error("Beim Hinzufügen zur Lagereinheit ist ein Fehler aufgetreten.");
              }
            }
            setScanData({});
          } catch (err) {
            toast.warning("Kein Produkt mit dieser Nummer gefunden. Bitte versuche es noch einmal");
          }
        } else {
          toast.error(
            'Bitte scanne ein weiteres Produkt, wähle "Produkt suchen" oder tippe auf "Lagereinheit abschließen"'
          );
        }
      }
    })(); // eslint-disable-next-line
  }, [scanData]);

  const handleDelete = async item => {
    const result = await showConfirmModal({
      title: "Entfernen",
      description: `Soll der Artikel ${getCurrentQuantity(item)}x ${item.name} aus der Lagereinheit entfernt werden?`,
    });
    if (!result) return;
    const tempStorageUnit = _.cloneDeep(newStorageUnit);
    tempStorageUnit.contents = newStorageUnit.contents.filter(c => c.sku !== item.sku);
    setNewStorageUnit(tempStorageUnit);
  };

  const handleSetCategory = ({ currentTarget }) => {
    const tempStorageUnit = _.cloneDeep(newStorageUnit);
    tempStorageUnit.category = currentTarget.value;
    setNewStorageUnit(tempStorageUnit);
  };

  const handleChangeItem = ({ index, name, value }) => {
    const tempStorageUnit = _.cloneDeep(newStorageUnit);
    const item = tempStorageUnit.contents[index];
    item[name] = value;
    setNewStorageUnit(tempStorageUnit);
  };

  return (
    <Fragment>
      {barcode.scanModeConnect && <Barcode onScan={handleScan} />}
      <div className="modal-dialog">
        <Modal
          show={show}
          onHide={handleHide}
          onSubmit={onSubmit}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="fullscreen-modal"
        >
          <Modal.Body>
            <div className="splitview">
              <main className="container-fluid spacing-top">
                <div className="row text-center mb-3">
                  <div className="col">
                    <h2>{newStorageUnit?.storageUnitId || "Neue Lagereinheit"}</h2>
                    <div className="mt-4 mb-3">
                      <Select
                        name="categorySelect"
                        label=""
                        chooseLabel="Kategorie wählen..."
                        value={newStorageUnit?.category}
                        options={[
                          { id: "box", name: "Karton" },
                          { id: "pallet", name: "Palette" },
                        ]}
                        onChange={handleSetCategory}
                      />
                    </div>
                    <div className="row">
                      <div className="col bg-light p-3 mb-3">
                        {isLoading ? (
                          <Loader message={message} />
                        ) : (
                          <>
                            <PleaseScan
                              scanDescription='Bitte scanne ein weiteres Produkt, wähle "Produkt suchen" oder tippe auf "Lagereinheit speichern"'
                              compact={true}
                            />
                            <div className="text-center mb-2">
                              <Button variant="outline-primary" size="sm" onClick={() => setAddModalOpen(true)}>
                                Produkt suchen
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <ul className="list-group mb-3">
                      {newStorageUnit?.contents?.map((u, i) => (
                        //Display each element
                        <ChecklistItem
                          index={i}
                          key={`${u.sku}-${i}`}
                          item={u}
                          onChange={handleChangeItem}
                          onDelete={handleDelete}
                        />
                      ))}
                    </ul>
                    <AutoSaveField
                      name="notes"
                      placeholder="Notizen"
                      type="text"
                      value={newStorageUnit?.inspection?.notes || ""}
                      saveOnChange={true}
                      onChange={() => {}}
                      onSave={handleSaveNote}
                      joiSchema={{
                        notes: Joi.string().allow("").label("Notizen"),
                      }}
                    />
                    <br />
                  </div>
                </div>
              </main>
              <footer>
                <div className="container-fluid mb-2">
                  <Button variant="secondary" onClick={handleHide} className="btn-block">
                    Abbrechen
                  </Button>
                </div>
                <div className="container-fluid">
                  <Button variant="primary" onClick={handleSubmit} className="btn-block">
                    Lagereinheit speichern
                    {isSafeToClick(true)}
                  </Button>
                </div>
              </footer>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <ProductListModal
        imageDb={imageDb}
        show={addModalOpen}
        onHide={() => setAddModalOpen(false)}
        onSubmit={handleSaveProduct}
        buttonText="Produkt hinzufügen"
      />
    </Fragment>
  );
};

export default EditStuModal;
