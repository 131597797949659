import React from "react";
import PropTypes from "prop-types";

const LoadMore = ({ currentPage, itemsCount, pageSize, onPageChange, label, ...rest }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);

  if (pagesCount === 1 || currentPage === pagesCount) return null;

  return (
    <div {...rest}>
      <nav aria-label="load more" className="text-center">
        <button href="#" onClick={() => onPageChange(currentPage + 1)} className="btn btn-outline-secondary">
          {label}
        </button>
      </nav>
    </div>
  );
};

LoadMore.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default LoadMore;
