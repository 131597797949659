import React from "react";
import TableStacked from "../common/TableStacked";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import warehouseService from "../../services/warehouseService";
import moment from "moment-timezone";
import { isSafeToClick } from "../../utils/helper";

const WarehouseTable = ({ data, sortColumn, onSort, onMarkAsRemoved }) => {
  const columns = [
    {
      path: "receiveDate",
      label: "Wareneingang",
      content: storageUnit =>
        storageUnit.receiveDate
          ? moment(storageUnit.receiveDate).tz("Europe/Berlin").format("DD.MM.YYYY")
          : "Noch nicht eingegangen",
    },
    {
      path: "storageDate",
      label: "Einlagerung",
      content: storageUnit => {
        const date = warehouseService.getStorageDateByStu(storageUnit);
        return date ? (
          <div>
            {date}
            <br />
            {warehouseService.getLocationNumberByStu(storageUnit)}
          </div>
        ) : (
          "Noch nicht eingelagert"
        );
      },
    },
    {
      path: "storageUnitId",
      label: "Nr.",
      content: storageUnit => (
        <p className="m-0">
          {`Nr. ${storageUnit.unitNo} von ${storageUnit.totalUnitsShipment}`}
          <br />
          <Link to={`/warehouse/storageunit/${storageUnit.storageUnitId}`}>{storageUnit.storageUnitId}</Link>
        </p>
      ),
    },
    {
      path: "parentShipmentId.shipmentId",
      label: "Lieferung",
      content: storageUnit =>
        storageUnit.parentShipmentId ? storageUnit.parentShipmentId.shipmentId : "Keiner Lieferung zugeordnet",
    },
    {
      path: "contents.0.poNumber",
      label: "PO-Nummer",
      contents: storageUnit => storageUnit.contents?.map(p => p.poNumber).join(", "),
    },
    {
      path: "contents",
      label: "Inhalt",
      content: storageUnit =>
        storageUnit.contents?.map((c, i) => (
          <p key={c.sku + "-" + i} className="m-0">
            {c.name}
            <br />
            {c.sku}
            <br />
            {warehouseService.getCurrentQuantity(c)} Stück
          </p>
        )),
    },
    {
      path: "action",
      label: "",
      content: storageUnit => (
        <Button onClick={() => onMarkAsRemoved(storageUnit)} variant="danger">
          <i className="fas fa-times"></i>
          {isSafeToClick(true)}
        </Button>
      ),
    },
  ];
  return (
    <TableStacked columns={columns} data={data} sortColumn={sortColumn} getId={item => item._id} onSort={onSort} />
  );
};

export default WarehouseTable;
