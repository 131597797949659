import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ProductsService from "../../services/productsService";
import Form from "../common/Form";
import Loader from "../common/Loader";
import SearchForm from "../common/SearchForm";
import ProductList from "./ProductList";
import logger from "../../services/logService";
import _ from "lodash";

class ProductListModal extends Form {
  _isMounted = false;
  state = {
    data: {
      sku: "",
    },
    searchQuery: "",
    allProducts: null,
    filteredProducts: null,
    timeout: null,
    errors: {},
  };

  doFiltering = async () => {
    const { allProducts, searchQuery } = this.state;
    if (searchQuery === "") {
      const filteredProducts = [];
      return this.setState({ filteredProducts });
    }

    if (!allProducts) return;

    this.setState({ filteredProducts: ["Loading"] });

    const filteredProducts = allProducts.filter(o =>
      searchQuery
        .toLowerCase()
        .split(" ")
        .every(q => Object.values(o).find(v => ("" + v).toLowerCase().includes(q)))
    );
    const sortedProducts = _.sortBy(filteredProducts, "ean", "desc");
    this.setState({ filteredProducts: sortedProducts });
  };

  async componentDidMount() {
    this._isMounted = true;
    this.timer = null;
    try {
      const allProducts = await ProductsService.getAllProducts();
      if (allProducts && this._isMounted) {
        this.setState({ allProducts });
      }
    } catch (ex) {
      logger.error("Could not get all products", ex);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timer);
    this.timer = null;
  }

  onHide = () => {
    this.props.onHide();
    setTimeout(this.resetState(), 500);
  };

  doSubmit = () => {
    this.props.onSubmit(this.state.data.amount);
    setTimeout(this.resetState(), 500);
  };

  onSearch = async query => {
    this.setState({ searchQuery: query }, () => {
      // clearTimeout(this.timer);
      // this.timer = setTimeout(this.doFiltering, 100);
      this.doFiltering();
    });
  };

  resetState = () => {
    this.setState({
      data: {
        sku: "",
      },
      searchQuery: "",
      filteredProducts: null,
    });
  };

  render() {
    const { filteredProducts, searchQuery } = this.state;
    const { imageDb, show, onHide, onSubmit, buttonText } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Suche nach Produkt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchForm
            placeholder="Suche nach SKU, Produktname, Größe, etc."
            value={searchQuery}
            submitLabel="Suchen"
            onSubmit={this.onSearch}
            onReset={this.resetState}
          />
          {!filteredProducts ? (
            <div className="d-flex justify-content-center">
              <p className="text-muted">Bitte Suchbegriff eingeben</p>
            </div>
          ) : filteredProducts[0] === "Loading" ? (
            <Loader />
          ) : (
            <ProductList products={filteredProducts} imageDb={imageDb} onChoose={onSubmit} buttonText={buttonText} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onHide}>
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ProductListModal;
