import React from "react";

const Toggle = ({ name, current, options, onChange, addClasses = "" }) => {
  return (
    <div className="btn-group btn-group-toggle mb-0 btn-block" data-toggle="buttons">
      {options.map(o => (
        <label key={o._id} className={`btn btn-outline-secondary${current === o._id ? " active" : ""} ${addClasses}`}>
          <input
            type="radio"
            name={name}
            id={o._id}
            autoComplete="off"
            checked={current === o._id}
            onChange={() => onChange({ name: name, value: o._id })}
          />
          {o.name}
        </label>
      ))}
    </div>
  );
};

export default Toggle;
