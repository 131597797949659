import React, { Component } from "react";
import { toast } from "react-toastify";
import ReturnsTable from "./ReturnsTable";
import Statistics from "./Statistics";
import Pagination from "../common/Pagination";
import SimpleModal from "../common/SimpleModal";
import RefurbishModal from "../refurbish/RefurbishModal";
import ProductListModal from "../modals/ProductListModal";
import QuantityModal from "../common/QuantityModal";
import { createModal } from "react-modal-promise";
import ReturnsService from "../../services/returnsService";
import ImageService from "../../services/imageService";
import logger from "../../services/logService";
import SearchForm from "../common/SearchForm";
import Loader from "../common/Loader";
import Joi from "joi-browser";
import returnsService from "../../services/returnsService";
import _ from "lodash";
import { isSafeToClick } from "../../utils/helper";

class ReturnsList extends Component {
  _isMounted = false;
  state = {
    pageSize: 10,
    currentPage: 1,
    returns: [],
    sortColumn: { path: "modifiedAt", order: "desc" },
    areYouSureModalShow: false,
    deleteItem: {},
    searchQuery: "",
    refurbishModalShow: false,
    productListModalShow: false,
    activeOrderReturn: {},
    modalLoading: false,
    listLoading: true,
    imageDb: {
      loading: true,
      data: {},
    },
  };

  showQuantityModal = createModal(QuantityModal);

  //SETUP
  async componentDidMount() {
    this._isMounted = true;
    await Promise.all([this.fetchReceivedItems(), this.fetchImageDb()]);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchReceivedItems = async () => {
    const { currentPage, pageSize, searchQuery, sortColumn } = this.state;

    const sortBy = sortColumn.order === "asc" ? sortColumn.path : "-" + sortColumn.path;
    const { returns, totalCount, inspectedCount, stockCount, trashedCount, bStockCount } =
      await ReturnsService.getReturns(sortBy, currentPage, pageSize, searchQuery);
    if (this._isMounted)
      this.setState({ listLoading: false, returns, totalCount, inspectedCount, stockCount, trashedCount, bStockCount });
  };

  fetchImageDb = async () => {
    let imageDb = { ...this.state.imageDb };
    if (imageDb.loading === false) return;

    //if cache is not available
    imageDb = await ImageService.getImageDb();
    if (!imageDb.data) return;

    imageDb.loading = false;
    if (this._isMounted) this.setState({ imageDb });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page }, this.fetchReceivedItems);
  };

  handleSort = sortColumn => {
    // Sort column can be array of strings or string
    this.setState({ sortColumn }, this.fetchReceivedItems);
  };

  handleHideDialog = () => {
    this.setState({ areYouSureModalShow: false, modalLoading: false });
  };

  handleDelete = orderReturn => {
    this.setState({
      areYouSureModalShow: true,
      deleteItem: orderReturn,
    });
  };

  handleSearch = searchQuery => {
    this.setState({ currentPage: 1, searchQuery }, this.fetchReceivedItems);
  };

  handleConfirmDelete = async () => {
    const originalReturns = this.state.returns;
    const orderReturn = this.state.deleteItem;

    try {
      this.setState({ modalLoading: true });
      await ReturnsService.deleteReceivedReturn(orderReturn._id);
      await this.fetchReceivedItems();
      this.setState({ areYouSureModalShow: false, modalLoading: false });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) toast.error("This return has already been deleted");
      this.setState({ returns: originalReturns, modalLoading: false });
    }
  };

  //FILTERING AND PAGINATION
  getPageData = () => {
    const { returns } = this.state;
    return { pageData: returns };
  };

  handleChangeProduct = async (activeOrderReturnId = null) => {
    const activeOrderReturn = activeOrderReturnId
      ? await ReturnsService.getReceivedReturn(activeOrderReturnId)
      : this.state.activeOrderReturn;
    this.setState({
      productListModalShow: true,
      activeOrderReturn,
    });
  };

  handleConfirmChangeProduct = async productDetail => {
    const receiveId = this.state.activeOrderReturn._id;
    this.setState({
      productListModalShow: false,
    });

    //Update Product in DB
    await ReturnsService.updateReceivedProduct(receiveId, productDetail.sku);

    //Update Product in Modal
    const activeOrderReturn = await ReturnsService.getReceivedReturn(receiveId);
    this.setState({ activeOrderReturn });

    //Update List
    await this.fetchReceivedItems();
  };

  handleSetActiveId = async (data, barcode = false) => {
    if (data !== "") {
      const activeOrderReturn = await ReturnsService.getReceivedReturn(data, barcode);
      if (!activeOrderReturn) return toast.error("Rücksendung konnte nicht gefunden werden.");

      this.setState({ refurbishModalShow: true });
      this.setState({ activeOrderReturn });
    }
  };

  handleChangeNote = async (orderReturn, notes) => {
    const originalReturn = { ...orderReturn };
    //const activeOrderReturn = { ...originalActiveOrderReturn };
    orderReturn.notes = notes;
    this.setState({ activeOrderReturn: orderReturn });

    try {
      const result = await ReturnsService.updateNotes(orderReturn._id, notes);
      if (!result) {
        logger.error("Could not set Notes field, result is null when trying to update note.");
        toast.error("Die Notiz konnte nicht gespeichert werden, es ist ein unbekannter Fehler aufgetreten.");
      }
    } catch (err) {
      this.setState({ activeOrderReturn: originalReturn });
      if (err.response && err.response.status === 400)
        toast.error("Die Notiz konnte nicht gespeichert werden, ungültiger Inhalt.");
    }
  };

  handleChangeRefurbStatus = async activeOrderReturn => {
    this.setState({ refurbishModalShow: true, activeOrderReturn });
  };

  handleHideRefurbishDialog = async () => {
    this.setState({ refurbishModalShow: false });
    await this.fetchReceivedItems();
  };

  handleClickFilter = filter => {
    this.setState({ searchQuery: filter, filterActive: filter }, this.fetchReceivedItems);
  };

  handleResetFilter = () => {
    this.setState({ searchQuery: "", filterActive: null }, this.fetchReceivedItems);
  };

  handleChangeDeduction = async orderReturn => {
    //Show Quantity Modal
    const refundDeduction = await this.showQuantityModal({
      title: "Abzug von Gutschrift?",
      description: "Soll von der Gutschrift etwas abgezogen werden? (Betrag in Euro)",
      confirmText: "Speichern",
      initialQuantity: (orderReturn.refund && orderReturn.refund.refundDeduction) || 0,
      schema: {
        quantity: Joi.number().min(0).required().label("Abzug"),
      },
    });

    if (!refundDeduction && refundDeduction !== 0) return;

    //Save values
    await returnsService.updateRefundDetails(orderReturn._id, { refundDeduction });
    await this.fetchReceivedItems();
  };

  handleToggleRefundRequired = async orderReturn => {
    const returns = _.cloneDeep(this.state.returns);
    const returnsCopy = _.cloneDeep(this.state.returns);

    const toggleItem = returns.find(o => o._id === orderReturn._id);
    _.set(
      toggleItem,
      "refund.refundRequired",
      !(toggleItem.refund && toggleItem.refund.refundRequired === false ? false : true)
    );
    this.setState({ returns });
    try {
      await returnsService.updateRefundDetails(toggleItem._id, { refundRequired: toggleItem.refund.refundRequired });
    } catch (err) {
      this.setState({ returns: returnsCopy });
      toast.error("Fehler beim aktualisieren der Rücksendung." + err.reponse.data);
    }
  };

  render() {
    const {
      currentPage,
      pageSize,
      sortColumn,
      areYouSureModalShow,
      productListModalShow,
      refurbishModalShow,
      activeOrderReturn,
      imageDb,
      inspectedCount,
      stockCount,
      bStockCount,
      trashedCount,
      totalCount,
      filterActive,
      listLoading,
    } = this.state;

    const { pageData } = this.getPageData();

    const productListModalClose = () => this.setState({ productListModalShow: false });

    return (
      <React.Fragment>
        <div className="container-fluid spacing-top">
          <h1 className="text-center mb-5">Erfasste Rücksendungen</h1>
          <div className="row justify-content-end align-items-center">
            <div className="col-auto align-middle">
              <Statistics
                inspected={inspectedCount}
                putInStock={stockCount}
                trashed={trashedCount}
                bStock={bStockCount}
                total={totalCount}
                filterActive={filterActive}
                onClick={this.handleClickFilter}
                onReset={this.handleResetFilter}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
              <SearchForm
                placeholder="Suche..."
                onSubmit={this.handleSearch}
                onReset={this.handleSearch}
                submitLabel="Suchen"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              {!listLoading ? (
                <ReturnsTable
                  orderReturns={pageData}
                  onSort={this.handleSort}
                  onDelete={this.handleDelete}
                  sortColumn={sortColumn}
                  onChangeProduct={this.handleChangeProduct}
                  onChangeNote={this.handleChangeNote}
                  onChangeStatus={this.handleChangeRefurbStatus}
                  onChangeDeduction={this.handleChangeDeduction}
                  onToggleRefundRequired={this.handleToggleRefundRequired}
                  imageDb={imageDb}
                />
              ) : (
                <div
                  style={{
                    height: "30vh",
                    display: "flex",
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {totalCount && !listLoading ? (
                <Pagination
                  currentPage={currentPage}
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <SimpleModal
          show={areYouSureModalShow}
          title="Rücksendung löschen?"
          spinner={this.state.loading}
          description="Bist du sicher, dass du diese Rücksendung löschen willst?"
          confirmText={"Löschen" + isSafeToClick(true)}
          cancelText="Abbrechen"
          onHide={this.handleHideDialog}
          onConfirm={this.handleConfirmDelete}
          buttonColor="danger"
        />
        <ProductListModal
          show={productListModalShow}
          imageDb={imageDb}
          onHide={productListModalClose}
          onSubmit={this.handleConfirmChangeProduct}
          buttonText={"Produkt ändern" + isSafeToClick(true)}
        />
        <RefurbishModal
          show={refurbishModalShow}
          orderReturn={activeOrderReturn}
          title="Zustand anpassen"
          cancelText="Abbrechen"
          onHide={this.handleHideRefurbishDialog}
          onUpdateNotes={this.handleUpdateNotes}
          onChangeProduct={this.handleChangeProduct}
          buttonColor="danger"
          imageDb={imageDb}
        />
      </React.Fragment>
    );
  }
}

export default ReturnsList;
