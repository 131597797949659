import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

// movies : object
// columns : object
class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path);
  };

  createKey = (itemId, column) => {
    return itemId + (column.path || column.key);
  };

  render() {
    const { getId, data, columns } = this.props;

    return (
      <tbody>
        {data.map(item => {
          return (
            <tr key={getId(item)}>
              {columns.map(column => (
                <td key={this.createKey(getId(item), column)} className="align-middle">
                  {this.renderCell(item, column)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    );
  }
}

TableBody.propTypes = {
  getId: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

TableBody.defaultProps = {
  getId: item => item._id
};

export default TableBody;
