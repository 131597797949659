import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import StorageUnit from "./StorageUnit";
import Loader from "../common/Loader";
import { isSafeToClick } from "../../utils/helper";

class EditShipmentModal extends Component {
  getTotalQuantity = storageUnits => {
    const result = storageUnits.reduce(
      (acc, cur) => acc + cur.contents.reduce((acc, cur) => acc + parseInt(cur.initialQuantity || 0), 0),
      0
    );
    if (typeof result !== "number" && typeof result !== "string") return;
    return result;
  };

  render() {
    const {
      show,
      errors,
      spinner,
      spinnerText,
      shipment,
      storageUnits,
      onSubmit,
      onSaveAsDraft,
      onHide,
      onDeleteUnit,
      onChange,
      onAddUnit,
      onAddItem,
      onRemoveItem,
    } = this.props;

    return (
      <Modal show={show} onHide={onHide} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <i className="fas fa-sign-in-alt mr-2 text-success"></i>Shipment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {spinner ? (
            <div className="m-5 text-center">
              <Loader className="mb-2" />
              <p>{spinnerText}</p>
            </div>
          ) : (
            <Fragment>
              <h3>{shipment.shipmentId}</h3>
              <h4>{shipment.poNumber}</h4>
              <p>
                Please add shipment units to this shipment. Every pallet or box is a unique shipment unit and needs to
                be labeled separately. You can download the labels at any point. Please make sure the contents are
                accurate.
              </p>
              <div className="form-row justify-content-between mb-3">
                <div className="col-auto">
                  <Button variant="light" onClick={() => onAddUnit("pallet")} className="mr-2">
                    <i className="fas fa-plus-circle text-success"></i> Add pallet
                    {isSafeToClick(true)}
                  </Button>
                  <Button variant="light" onClick={() => onAddUnit("box")}>
                    <i className="fas fa-plus-circle text-success"></i> Add box
                    {isSafeToClick(true)}
                  </Button>
                </div>
                <div className="col-auto my-auto">
                  <b>
                    {storageUnits.length} Unit
                    {storageUnits.length > 1 ? "s" : ""}
                  </b>
                </div>
              </div>
              {storageUnits.map((s, i) => (
                <StorageUnit
                  key={i}
                  index={i}
                  data={s}
                  onChange={onChange}
                  onDelete={onDeleteUnit}
                  onAddItem={onAddItem}
                  onRemoveItem={onRemoveItem}
                />
              ))}
              {errors && typeof errors === "string" ? <div className="alert alert-danger">{errors}</div> : ""}
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col-6 col-lg-auto my-lg-auto">
              <Button variant="secondary" className="my-3 btn-block" onClick={onHide} disabled={spinner}>
                Cancel
              </Button>
            </div>
            <div className="col my-auto text-right">
              Total Quantity <b>{this.getTotalQuantity(storageUnits)}</b>
            </div>
            <div className="col-12 col-lg-auto mb-3 my-lg-auto">
              <Button variant="secondary" className="btn-block" onClick={onSaveAsDraft} disabled={spinner}>
                Save as Draft{isSafeToClick(true)}
              </Button>
            </div>
            <div className="col-12 col-lg-auto mb-3 my-lg-auto">
              <Button variant="primary" className="btn-block" onClick={onSubmit} disabled={spinner}>
                Submit{isSafeToClick(true)}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditShipmentModal;
