import React, { useContext } from "react";
import { LocalizationContext, getStringFromIsoValue } from "../../services/localizationService";
import Textarea from "../common/Textarea";
import Input from "../common/Input";

interface Data {
  lang: string;
  value: string;
}

interface DataDict {
  [x: string]: string;
}

interface LocalizedStringEditorProps {
  name: string;
  label: string;
  data: Data[];
  type: string;
  error: string;
  onSave: (state: Data[]) => void;
  onChange: (state: Data[]) => void;
}

const LocalizedString = ({ name, label, data, type, onSave, error, onChange, ...rest }: LocalizedStringEditorProps) => {
  // @ts-expect-error
  const langOptions: string[] = useContext(LocalizationContext).options;

  const updateDataObject = (value: string, language: string) => {
    const newData = data ? [...data] : [];
    const dataItem = newData.find(d => d.lang === language);
    if (dataItem) dataItem.value = value;
    else newData.push({ lang: language, value });
    return newData;
  };

  const dataDict = (data: Data[]) => {
    return data
      ? data.reduce((acc: DataDict, red: Data) => {
          acc[red.lang] = red.value;
          return acc;
        }, {})
      : {};
  };

  const handleChange = ({ currentTarget: { value } }: any, langOption: string) => {
    const newData = updateDataObject(value, langOption);
    onChange(newData);
  };

  const handleSave = ({ currentTarget: { value } }: any, langOption: string) => {
    const newData = updateDataObject(value, langOption);
    onSave(newData);
  };

  return (
    <div>
      <div className="mb-2">{label}</div>
      <div className="form-row">
        {langOptions &&
          langOptions.map((langOption: string) => (
            <div key={langOption} {...rest} className="col-12 col-lg-6" style={{ position: "relative" }}>
              <div className="badge badge-secondary" style={{ position: "absolute", right: "15px", top: "10px" }}>
                {getStringFromIsoValue(langOption)}
              </div>
              {type === "input" ? (
                <div style={{ marginBottom: "15px" }}>
                  <Input
                    name={`${name}-${langOption}`}
                    label=""
                    placeholder=""
                    error=""
                    value={dataDict(data)[langOption] || ""}
                    onChange={(e: any) => handleChange(e, langOption)}
                    onBlur={(e: any) => handleSave(e, langOption)}
                    style={{ paddingRight: "75px" }}
                    {...rest}
                  />
                </div>
              ) : (
                <Textarea
                  name={`${name}-${langOption}`}
                  label=""
                  placeholder=""
                  error=""
                  value={dataDict(data)[langOption] || ""}
                  onChange={(e: any) => handleChange(e, langOption)}
                  onBlur={(e: any) => handleSave(e, langOption)}
                  style={{ paddingRight: "75px" }}
                  {...rest}
                />
              )}
            </div>
          ))}
        {error && <div className="w-100 alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

export default LocalizedString;
