import React from "react";
import Tasks from "../tasks/Tasks";
import UpcomingShipmentsTimeline from "../upcomingShipments/UpcomingShipmentsTimeline";

const DashboardWarehouse = ({ setTasks, loadTasks, loadingTasks, tasks, ...rest }) => {
  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-12 col-lg-4 order-2 order-lg-1">
          <div className="bg-light-gray px-2 px-lg-4 py-4 rounded">
            <h1 className="text-center mb-4">Lieferungen</h1>
            <UpcomingShipmentsTimeline />
          </div>
        </div>
        <div className="col-12 col-lg-8 order-1 order-lg-2 mb-5">
          <div className="bg-light-gray py-4 px-2 px-lg-4 rounded">
            <h1 className="text-center mb-4">Aufgaben</h1>
            <Tasks tasks={tasks} setTasks={setTasks} loadTasks={loadTasks} loading={loadingTasks} {...rest} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWarehouse;
