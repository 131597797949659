import { Fragment } from "react";
import moment from "moment-timezone";
import warehouseService from "../../services/warehouseService";
import { Link } from "react-router-dom";

const StorageList = ({ storageUnits, sku }) => {
  return storageUnits && storageUnits.length ? (
    storageUnits
      .map((s, i) => {
        const productContent = s.contents.find(c => c.sku === sku);
        if (!productContent) return null;
        return (
          <Fragment key={s._id}>
            {i === 0 ? <h6>Älteste Einheit</h6> : i === 1 ? <h6>Weitere Einheiten</h6> : ""}
            <ul key={`locationGroup-${i}`} className="list-group mb-3">
              <li
                className={`list-group-item${i === 0 ? " border-none bg-light-success" : ""}`}
                style={{ fontSize: "24px" }}
              >
                <div className="row">
                  <div className="col">
                    {s.location || s.parentGroup?.location ? (
                      <Fragment>
                        <b>{warehouseService.getLocationNumber(s.location || s.parentGroup?.location)}</b>
                        {" - "}
                      </Fragment>
                    ) : (
                      <b>
                        Nicht eingelagert
                        <br />
                      </b>
                    )}
                    <Link to={`/warehouse/storageunit/${s.storageUnitId}`} className="text-dark">
                      {s.storageUnitId}
                    </Link>
                  </div>
                  <div
                    className={`col-auto ${
                      warehouseService.getCurrentQuantity(productContent) > 0 ? "text-success" : "text-danger"
                    }`}
                  >
                    {warehouseService.getCurrentQuantity(productContent)}
                  </div>
                </div>
                <div className="row" style={{ fontSize: "12px" }}>
                  <div className="col">
                    <span>
                      {s.receiveDate
                        ? "Wareneingang: " + moment(s.receiveDate).tz("Europe/Berlin").format("DD.MM.YYYY")
                        : "Noch nicht angenommen"}
                    </span>
                    <br />
                    {productContent.name}
                    {productContent.name ? <br /> : ""}
                    {productContent.sku}
                  </div>
                </div>
              </li>
            </ul>
          </Fragment>
        );
      })
      .filter(s => s)
  ) : (
    <p>Keine weiteren Einheiten vorhanden</p>
  );
};

export default StorageList;
