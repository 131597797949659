export function formatDate(datestring, time = true) {
  const date = new Date(datestring);
  const options = time
    ? {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
    : {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
  return date.toLocaleDateString("de-DE", options);
}

/**
 *
 * @param {Date} date
 * @param {Number} days
 * @returns {Date}
 */
export function addDays(date, days) {
  const newDate = date.setUTCDate(date.getUTCDate() + days);
  return new Date(newDate);
}

const dateUtils = {
  formatDate,
  addDays,
};

export default dateUtils;
