import React, { useState, useContext, useEffect, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LocationContent from "./LocationContent";
import StorageUnitContent from "./StorageUnitContent";
import StorageGroupContent from "./StorageGroupContent";
import ProductContent from "./ProductContent";
import PleaseScan from "./PleaseScan";
import Barcode from "../common/Barcode";
import barcodeService, { BarcodeContext } from "../../services/barcodeService";
import warehouseService from "../../services/warehouseService";
import { toast } from "react-toastify";
import SubmitButton from "./SubmitButton";
import logger from "../../services/logService";

const AddModal = ({ baseObject, category, show, onHide, onSubmit }) => {
  const [scanData, setScanData] = useState({});
  const [location, setLocation] = useState({});
  const [storageUnit, setStorageUnit] = useState({});
  const [storageGroup, setStorageGroup] = useState({});
  const [product, setProduct] = useState({});
  const barcode = useContext(BarcodeContext);

  async function handleScan(data) {
    const scanFeedback = await barcodeService.getData(data);
    setScanData(scanFeedback);
  }

  const handleSubmit = async (showModal = true) => {
    await onSubmit(
      scanData.type === "storageunit"
        ? storageUnit._id
        : scanData.type === "storagegroup"
        ? storageGroup._id
        : scanData.type === "product"
        ? product._id
        : scanData.type === "location"
        ? location._id
        : null,
      scanData.type ? scanData.type + (showModal ? "" : "single") : null
    );
    setScanData({});
  };

  const handleHide = () => {
    setScanData({});
    onHide();
  };

  useEffect(() => {
    (async () => {
      if (show && scanData.data) {
        setStorageGroup({});
        setStorageUnit({});
        setLocation({});
        setProduct({});

        if (
          (category === "location" && scanData.type === "location") ||
          (category !== "location" && scanData.type !== "location")
        ) {
          return toast.warning(
            `Bitte scanne einen Barcode ${
              category === "location" ? "einer Lagereinheit oder Gruppe oder eines Produktes" : "eines Lagerortes"
            }`
          );
        }
        if (
          category === "location" &&
          (baseObject.storageCategory === "single" || baseObject.storageCategory === "single_multiple")
        ) {
          if (scanData.type === "location") {
            toast.warning("Ein Lagerort kann nicht mit einem Lagerort verknüpft werden.");
          } else if (scanData.type === "storageunit") {
            const result = await warehouseService.testCompatibility(scanData.data, baseObject._id, scanData.type);
            if (result !== "Erfolg") return toast.warning(result);
            const storageUnit = await warehouseService.loadStorageUnit(scanData.data);
            setStorageUnit(storageUnit);
          } else if (scanData.type === "storagegroup") {
            toast.warning("Dieser Lagerort ist für lose Artikel vorgesehen. Die Gruppe kann nicht eingelagert werden.");
          } else if (scanData.type === "product") {
            const product = await warehouseService.loadProduct(scanData.data);
            if (!product) {
              toast.error("Das gescannte Produkt wurde nicht in der Datenbank gefunden");
              return;
            }
            if (!baseObject.contents.find(c => c.product.sku === product.sku)) {
              toast.warning(
                `Das gescannte Produkt kann nicht in diesen Lagerplatz eingelagert werden, da er nicht für dieses Produkt vorgesehen ist.`
              );
              return;
            }
            setProduct(product);
          } else {
            toast.error("Fehler beim Verknüpfen. Bitte wende dich an den Administrator.");
            logger.error(
              `There was an error when connecting two items. Category:${category || "null"}, scanData.type: ${
                (scanData && scanData.type) || "null"
              }`
            );
          }
        } else if (category === "location") {
          if (scanData.type === "location") {
            toast.warning("Ein Lagerort kann nicht mit einem Lagerort verknüpft werden.");
          } else if (scanData.type === "storageunit") {
            const storageUnit = await warehouseService.loadStorageUnit(scanData.data);
            setStorageUnit(storageUnit);
          } else if (scanData.type === "storagegroup") {
            const storageGroup = await warehouseService.loadStorageGroup(scanData.data);
            setStorageGroup(storageGroup);
          } else if (scanData.type === "product") {
            toast.warning("Dieser Lagerort ist nicht für einzelne Produkte vorgesehen.");
          } else if (scanData.type === "return") {
            toast.warning("Retouren können nicht in Lagerplätze eingelagert werden.");
          } else {
            toast.error("Fehler beim Verknüpfen. Bitte wende dich an den Administrator.");
            logger.error(
              `There was an error when connecting two items. Category:${category || "null"}, scanData.type: ${
                (scanData && scanData.type) || "null"
              }`
            );
          }
        } else {
          if (scanData.type === "location") {
            const result = await warehouseService.testCompatibility(baseObject._id, scanData.data, category);
            if (result !== "Erfolg") return toast.warning(result);
            const location = await warehouseService.loadWarehouseLocation(scanData.data);
            setLocation(location);
          } else {
            toast.warning("Bitte scanne einen Lagerort, um die Einheit einzulagern.");
          }
        }
      }
    })();
  }, [show, scanData, category, baseObject]);

  return (
    <Fragment>
      {barcode.scanModeConnect && <Barcode onScan={handleScan} />}
      <div className="modal-dialog">
        <Modal
          show={show}
          onHide={handleHide}
          onSubmit={onSubmit}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="fullscreen-modal"
        >
          <Modal.Body>
            <div className="splitview">
              {scanData && scanData.type === "storageunit" && storageUnit.storageUnitId && category === "location" ? (
                <StorageUnitContent data={storageUnit} />
              ) : scanData &&
                scanData.type === "storagegroup" &&
                storageGroup.storageGroupId &&
                category === "location" ? (
                <StorageGroupContent data={storageGroup} />
              ) : scanData && scanData.type === "product" && product.sku && category === "location" ? (
                <ProductContent data={product} />
              ) : scanData && scanData.type === "location" && location.locationId && category !== "location" ? (
                <LocationContent data={location} />
              ) : (
                <div className="my-auto">
                  <PleaseScan
                    scanDescription={
                      category === "location"
                        ? baseObject.storageCategory === "single" && baseObject.product && baseObject.product.name
                          ? `Dieser Lagerplatz ist für ${baseObject.product.name} vorgesehen. Bitte scanne einen Barcode um ein Produkt einzulagern.`
                          : "Bitte scanne eine Lagereinheit um sie in den Lagerplatz einzulagern"
                        : "Bitte scanne einen Lagerplatz um die Ware einzulagern"
                    }
                  />
                </div>
              )}
              <footer className="container-fluid">
                <Button variant="secondary" onClick={onHide} className="btn-block">
                  Abbrechen
                </Button>
                {/* Button for scanning location first, then connecting it to storage unit or group or product */}
                {category === "location" &&
                  !baseObject.pickingSpot &&
                  (storageUnit.storageUnitId || storageGroup.storageGroupId) && (
                    <SubmitButton
                      subject={
                        (storageUnit && storageUnit.category) || (storageGroup && storageGroup.category) || "Einheit"
                      }
                      verb={
                        storageUnit.location || storageGroup.location
                          ? `nach ${warehouseService.getLocationNumber(baseObject)} umlagern`
                          : `in ${warehouseService.getLocationNumber(baseObject)} einlagern`
                      }
                      icon="pallet"
                      overrideText={
                        scanData.type === "storageunit" && storageUnit.parentGroup
                          ? "Bitte Barcode der Gruppe scannen"
                          : ""
                      }
                      onSubmit={handleSubmit}
                      buttonColor="primary"
                      disabled={storageUnit.parentGroup || storageGroup.parentGroup}
                    />
                  )}
                {category === "location" && baseObject.pickingSpot && storageUnit.storageUnitId && (
                  <SubmitButton
                    subject={warehouseService.getUnitInfo(storageUnit, baseObject)}
                    verb={baseObject.location || baseObject.location ? `umlagern` : `einlagern`}
                    icon="boxes"
                    onSubmit={handleSubmit}
                    buttonColor="primary"
                  />
                )}
                {/* Button for scanning location first, then scanning product */}
                {category === "location" && baseObject.pickingSpot && product.sku && (
                  <SubmitButton
                    subject="Artikel"
                    verb={`in ${warehouseService.getLocationNumber(baseObject)} einlagern`}
                    icon="box"
                    onSubmit={() => handleSubmit(false)}
                    buttonColor="primary"
                  />
                )}
                {/* Button for scanning storage unit or group first, then connecting it to location that is not a picking spot*/}
                {(category === "storageunit" || category === "storagegroup") &&
                  location.locationId &&
                  !location.pickingSpot && (
                    <SubmitButton
                      subject={baseObject.storageUnitId || baseObject.storageGroupId || "Einheit"}
                      verb={baseObject.location || baseObject.location ? `hierher umlagern` : `hier einlagern`}
                      icon="pallet"
                      onSubmit={handleSubmit}
                      buttonColor="primary"
                    />
                  )}
                {/* Button for scanning storage unit first, then connecting it to location that is a picking spot*/}
                {category === "storageunit" && location.locationId && location.pickingSpot && (
                  <SubmitButton
                    subject={warehouseService.getUnitInfo(baseObject, location)}
                    verb={baseObject.location || baseObject.location ? `umlagern` : `einlagern`}
                    icon="boxes"
                    onSubmit={handleSubmit}
                    buttonColor="primary"
                  />
                )}
                {/* Button for scanning product first, then scanning location that is a picking spot */}
                {category === "product" && location.pickingSpot && baseObject.sku && (
                  <SubmitButton
                    subject="Artikel"
                    verb={`in ${warehouseService.getLocationNumber(location)} einlagern`}
                    icon="box"
                    onSubmit={() => handleSubmit(false)}
                    buttonColor="primary"
                  />
                )}
              </footer>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

export default AddModal;
