import React, { Component, Fragment } from "react";
import Table from "../common/Table";
import linkUtils from "../../utils/linkUtils";
import dateUtils from "../../utils/dateUtils";
import PropTypes from "prop-types";
import Button from "../common/Button";
import NotesForm from "./NotesForm";
import Status from "./Status";
import Checkbox from "../common/Checkbox";
import { isSafeToClick } from "../../utils/helper";
import imageService from "../../services/imageService";

class ReturnsTable extends Component {
  state = {
    columns: [
      {
        path: "product.imageObj.thumb",
        label: "",
        content: orderReturn => {
          return (
            <img
              src={
                this.props.imageDb
                  ? imageService.getThumbUrl(orderReturn.product.sku, this.props.imageDb)
                  : "https://www.formbench.com/upload/placeholder.png"
              }
              alt=""
              width="60"
            />
          );
        },
      },
      {
        path: "modifiedAt",
        label: "Letzte Änderung",
        content: orderReturn => {
          if (orderReturn.receive)
            return (
              <span>
                {dateUtils.formatDate(orderReturn.receive?.date)}
                <br />
                Angenommen
                {": " + orderReturn.receive.user?.name}
              </span>
            );
          else if (orderReturn.peannounce || orderReturn.createdAt)
            return (
              <span>
                {dateUtils.formatDate(orderReturn.preannounce?.date || orderReturn.createdAt)}
                <br />
                Angekündigt
                {orderReturn.preannounce ? ": " + orderReturn.preannounce?.user?.name : ""}
              </span>
            );
          else if (orderReturn.modifiedAt)
            return (
              <span>
                {dateUtils.formatDate(orderReturn.modifiedAt)}
                <br />
                Datensatz verändert
              </span>
            );
          else return "Kein Datum vorhanden";
        },
      },
      {
        path: "inspection.date",
        label: "Inspektion",
        content: orderReturn => {
          if (orderReturn.inspection)
            return `${dateUtils.formatDate(orderReturn.inspection.date)} ${orderReturn.inspection?.user?.name}`;
          return "Bisher nicht inspiziert";
        },
      },
      {
        path: "product.sku",
        label: "Produkt",
        content: orderReturn => (
          <div className="row">
            <div className="col">
              <span>
                {orderReturn.product.count}x {orderReturn.product.name}
              </span>
              <br />
              <span className="text-muted">SKU: {orderReturn.product.sku}</span>
              <br />
              <span className="text-muted">Barcode: {orderReturn.barcode || "Kein Barcode erfasst"}</span>
            </div>
            <div className="col-auto my-auto">
              <Button
                title={"Produkt ändern" + isSafeToClick(true)}
                onClick={() => this.props.onChangeProduct(orderReturn._id)}
                classes="btn-sm"
              ></Button>
            </div>
          </div>
        ),
      },
      {
        path: "notes",
        label: "Kommentare",
        content: orderReturn => <NotesForm orderReturn={orderReturn} onChangeNote={this.props.onChangeNote} />,
      },
      {
        path: "refund.refundDeduction",
        label: "Abzug Erstattung",
        content: orderReturn => (
          <Fragment>
            <div onClick={() => this.props.onChangeDeduction(orderReturn)}>
              {orderReturn.refund && orderReturn.refund.refundDeduction
                ? "-" + orderReturn.refund.refundDeduction.toFixed(2).replace(".", ",") + "€"
                : "Kein Abzug"}
            </div>
            <Checkbox
              name={"refundRequired-" + orderReturn._id}
              id={"refundRequired-" + orderReturn._id}
              label="Erstattung"
              isSelected={orderReturn.refund && orderReturn.refund.refundRequired === false ? false : true}
              onCheckboxChange={() => this.props.onToggleRefundRequired(orderReturn)}
            />
          </Fragment>
        ),
      },
      {
        path: "order.orderNumber",
        label: "Bestellnummer",
        content: orderReturn => (
          <a
            href={linkUtils.linkToExternalSystem(orderReturn.order.billbeeId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {orderReturn.order.orderNumber}
          </a>
        ),
      },
      { path: "order.customer.name", label: "Kunde" },
      {
        path: "status",
        label: "Status",
        content: orderReturn => <Status orderReturn={orderReturn} />,
      },
      {
        key: "delete",
        content: orderReturn => (
          <Fragment>
            <button
              onClick={() => this.props.onChangeStatus(orderReturn)}
              className="btn btn-outline-secondary btn-sm mb-1"
            >
              Anpassen{isSafeToClick(true)}
            </button>
            <button onClick={() => this.props.onDelete(orderReturn)} className="btn btn-danger btn-sm">
              <i className="fas fa-trash-alt"></i>
              {isSafeToClick(true)}
            </button>
          </Fragment>
        ),
      },
    ],
  };

  render() {
    const { orderReturns, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.state.columns}
        data={orderReturns}
        sortColumn={sortColumn}
        getId={item => item._id}
        onSort={onSort}
      />
    );
  }
}

ReturnsTable.propTypes = {
  orderReturns: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  sortColumn: PropTypes.object.isRequired,
};

export default ReturnsTable;
