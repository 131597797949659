import React, { useEffect, useState } from "react";
import TableHeader from "./TableHeader";
import CollapsibleTableBody from "./CollapsibleTableBody";
import PropTypes from "prop-types";
import Loader from "./Loader";
import _ from "lodash";

// columns: object
// sortColumn: object
// onSort: function
const CollapsibleTable = ({
  sticky,
  columns,
  groupColumns,
  itemsData,
  parentsData,
  pathToParent,
  idField,
  sortColumn,
  onSort,
  ...rest
}) => {
  const [groupedItems, setGroupedItems] = useState([]);

  useEffect(() => {
    const result = groupItems();
    setGroupedItems(result);

    function groupItems() {
      const unassigned = Object.assign({ [idField]: "unassigned", children: [] });
      const hierarchy = _.cloneDeep(parentsData);

      //Sort children into parents
      itemsData.forEach(item => {
        const parent = getParent(item);
        if (!parent) return unassigned.children.push(item);
        addChild(parent, item);
        setTopLevelParent(parent);
      });

      //sort parents into parents
      for (let i = 0; i < 10; i += 1) {
        if (topLevelHasParents(hierarchy)) {
          hierarchy.forEach(item => {
            if (item[idField] === "unassigned") return; // Skip Unassigned
            const parent = getParent(item);
            if (!parent) return; // Skip if parent has no parent
            addChild(parent, item);
          });
        }
      }

      return [...hierarchy, unassigned];

      ///////////////////
      // HELPER FUNCTIONS
      ///////////////////

      function addChild(parent, child) {
        const parentInHierarchy = getParentInHierarchy(parent);
        if (parentInHierarchy) {
          removeExistingChild(parentInHierarchy, child);
          parentInHierarchy.children.push(child);
        } else {
          parent.children = [child];
          hierarchy.push(parent);
        }
      }

      function getParent(item) {
        return _.get(item, pathToParent);
      }

      function topLevelHasParents(items) {
        return items.find(item => getParent(item));
      }

      function setTopLevelParent(parent) {
        if (!getParentInHierarchy(parent)) hierarchy.push(parent);
      }

      function getParentInHierarchy(parent) {
        return hierarchy.find(e => e[idField] === parent[idField]);
      }

      function removeExistingChild(parent, child) {
        const newChildren = [];
        if (!parent.children) parent.children = [];

        parent.children.forEach(e => {
          if (e[idField] === child[idField]) return;
          newChildren.push(e);
        });
        parent.children = newChildren;
        return newChildren;
      }
    }
  }, [itemsData, parentsData, pathToParent, idField]);

  return (
    <div {...rest}>
      {groupedItems.length ? (
        <div className="table-responsive">
          <table className="table table-hover align-middle sticky">
            <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
            <CollapsibleTableBody
              idField={idField}
              groupedData={groupedItems}
              columns={columns}
              groupColumns={groupColumns}
            />
          </table>
        </div>
      ) : (
        <div
          style={{
            height: "80vh",
            display: "flex",
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
};

CollapsibleTable.propTypes = {
  sticky: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  groupColumns: PropTypes.array.isRequired,
  itemsData: PropTypes.array.isRequired,
  parentsData: PropTypes.array.isRequired,
  pathToParent: PropTypes.string.isRequired,
  idField: PropTypes.string.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
};

CollapsibleTable.defaultProps = {
  idField: "_id",
  sticky: false,
};

export default CollapsibleTable;
