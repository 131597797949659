import React, { Component } from "react";
import Loader from "../common/Loader";

class Product extends Component {
  render() {
    const { data: product } = this.props;
    if (!product) return "";

    return product ? (
      <div className="row my-1">
        <div className="col text-center">
          <h4>{product.name ? <p>{product.name}</p> : ""}</h4>
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

export default Product;
