import React, { Fragment } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";

const AdminMenu = ({ taskCount, onClose }) => {
  return (
    <Fragment>
      <NavDropdown
        title={
          <React.Fragment>
            <i className="fas fa-box-open mr-2"></i>
            <span>Lager</span>
          </React.Fragment>
        }
        className="mr-1-sm-up my-auto"
        id="basic-nav-dropdown"
      >
        <NavLink className="dropdown-item my-auto" to="/tasks" onClick={onClose}>
          <i className="fas fa-check-circle mr-2b"></i>Aufgaben
          {taskCount ? <span className="taskBadge">{taskCount}</span> : ""}
        </NavLink>
        <span className="dropdown-item small text-secondary">Retouren und Aufbereitung</span>
        <NavLink className="dropdown-item my-auto" to="/receive" onClick={onClose}>
          <i className="fas fa-barcode mr-2b"></i>Rücksendungen erfassen
        </NavLink>
        <NavLink className="dropdown-item my-auto" to="/refurbish" onClick={onClose}>
          <i className="fas fa-box-open mr-2"></i>Rücksendungen aufbereiten
        </NavLink>
        <NavLink className="dropdown-item my-auto" to="/remissions" onClick={onClose}>
          <i className="fab fa-amazon mr-2"></i>Remissionen aufbereiten
        </NavLink>
        <span className="dropdown-item small text-secondary">Erstattungen</span>
        <NavLink className="dropdown-item my-auto" to="/refund" onClick={onClose}>
          <i className="fas fa-hand-holding-usd mr-2"></i>Erstattungen ausführen
        </NavLink>
        <NavLink className="dropdown-item my-auto" to="/returns" onClick={onClose}>
          <i className="fas fa-list-ul mr-2b"></i>Erfasste Rücksendungen
        </NavLink>
        <span className="dropdown-item small text-secondary">Lager</span>
        <NavLink className="dropdown-item my-auto" to="/warehouse" onClick={onClose}>
          <i className="fas fa-boxes mr-2b"></i>Lagerübersicht
        </NavLink>
        <NavLink className="dropdown-item my-auto" to="/product-docs" onClick={onClose}>
          <i className="fas fa-sliders-h mr-2b"></i>Gebrauchsanweisungen
        </NavLink>
      </NavDropdown>
      <NavLink className="nav-link my-auto mr-1-sm-up" to="/shipments" onClick={onClose}>
        <i className="fas fa-truck mr-2"></i>Shipments
      </NavLink>
      <NavDropdown
        title={
          <React.Fragment>
            <i className="fas fa-box mr-2"></i>
            <span>Produkte</span>
          </React.Fragment>
        }
        className="mr-1-sm-up my-auto"
        id="basic-nav-dropdown"
      >
        <NavLink className="nav-link mr-4 my-auto" to="/products" onClick={onClose}>
          <i className="fas fa-sliders-h mr-2b"></i>
          <span>Produkte</span>
        </NavLink>
        <NavLink className="nav-link mr-4 my-auto min-225" to="/purchasing" onClick={onClose}>
          <i className="fas fa-chart-bar mr-2b"></i>
          <span className="no-wrap">Einkauf & Analyse</span>
        </NavLink>
        <NavLink className="nav-link my-auto mr-1-sm-up" to="/zuschnitt" onClick={onClose}>
          <i className="fas fa-cut mr-2"></i>Sonderzuschnitt
        </NavLink>
        <NavLink className="nav-link my-auto mr-1-sm-up" to="/internalreasons" onClick={onClose}>
          <i className="fas fa-sliders mr-2"></i>Interne Gründe
        </NavLink>
        <NavLink className="nav-link my-auto mr-1-sm-up" to="/createlabels" onClick={onClose}>
          <i className="fas fa-sliders mr-2"></i>Labels Erstellen
        </NavLink>
      </NavDropdown>
    </Fragment>
  );
};

export default AdminMenu;
