import React from "react";

export async function route(data, history) {
  const type = barcodeLogic(data);
  switch (type) {
    case "return":
      history.push(`/refurbish/${data}`);
      break;
    case "location":
      history.push(`/warehouse/location/${data}`);
      break;
    case "storageunit":
      history.push(`/warehouse/storageunit/${data}`);
      break;
    case "storagegroup":
      history.push(`/warehouse/storagegroup/${data}`);
      break;
    case "product":
      history.push(`/warehouse/product/${data}`);
      break;
    default:
      return { type: barcodeLogic(data), data };
  }
  return { type: barcodeLogic(data), data };
}

export async function getData(data) {
  return { type: barcodeLogic(data), data };
}

function barcodeLogic(data) {
  let type = "product";
  if (data.length === 6) type = "return";
  else if (data.length === 16 && data.substring(0, 3) === "LOC") type = "location";
  else if (data.length === 9 && data.substring(0, 3) === "STU") type = "storageunit";
  else if (data.length === 9 && data.substring(0, 3) === "GRP") type = "storagegroup";
  else if (data.length === 9 && data.substring(0, 3) === "RT-") type = "return";
  else type = "product";

  return type;
}

const barcodeService = { route, getData, barcodeLogic };

export const BarcodeContext = React.createContext({});
export const BarcodeProvider = BarcodeContext.Provider;

export default barcodeService;
