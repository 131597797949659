import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getRequestReasons() {
  const { data } = await http.get(`${apiUrl}requestreasons/`);
  return data;
}

export async function getReturnParcelServices() {
  return await http.get(`${apiUrl}parcelservices/`);
}

export async function getInternalReasonsDE() {
  const { data } = await http.get(`${apiUrl}internalreasons/`);
  return data.map(r => ({ label: r.name.find(l => l.lang === "de_DE")?.value || r.name[0]?.value, value: r._id }));
}

export async function getInternalReasons() {
  const { data } = await http.get(`${apiUrl}internalreasons/`);
  return data;
}

export async function createNewInternalReason(name, lang) {
  const { data } = await http.post(`${apiUrl}internalreasons/`, { name, lang });
  return data;
}

export async function saveInternalReasons(internalReasons) {
  const { data } = await http.put(`${apiUrl}internalreasons/`, internalReasons);
  return data;
}
