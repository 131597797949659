// eslint-disable-next-line
import React, { useState, useContext, useEffect, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ChecklistItem from "./ChecklistItem";
import AutoSaveField from "../common/AutoSaveField";
import Joi from "joi-browser";
import _ from "lodash";
import { isSafeToClick } from "../../utils/helper";

const ReceiveModal = ({ isOpen, onResolve, receiveStorageUnit }) => {
  const [newStorageUnit, setNewStorageUnit] = useState({});

  const { storageUnitId, parentShipmentId, category, unitNo, totalUnitsShipment, inspection } = newStorageUnit || {};

  useEffect(() => {
    setNewStorageUnit(_.cloneDeep(receiveStorageUnit));
  }, [receiveStorageUnit]);

  const handleHide = () => {
    onResolve();
  };

  const handleSubmit = () => {
    onResolve(newStorageUnit);
  };

  const handleChangeItem = ({ index, name, value }) => {
    const modified = { ...newStorageUnit };
    const item = modified.contents[index];
    item[name] = value;
    setNewStorageUnit(modified);
  };

  const handleSaveNote = input => {
    const tempStorageUnit = { ...newStorageUnit };
    tempStorageUnit.inspection = tempStorageUnit.inspection || {};
    tempStorageUnit.inspection.notes = input;
    setNewStorageUnit(tempStorageUnit);
  };

  return (
    <Fragment>
      <div className="modal-dialog">
        <Modal
          show={isOpen}
          onHide={handleHide}
          onSubmit={handleSubmit}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="fullscreen-modal"
        >
          <Modal.Body>
            <div className="container-fluid spacing-top">
              <h4 className="text-light-gray">Ware annehmen</h4>
              <h3 className="mb-0">
                <b>{`${storageUnitId}`}</b>
                {unitNo && totalUnitsShipment && (
                  <Fragment>
                    {" - "}
                    {category === "box" ? "Karton" : category === "pallet" ? "Palette" : "Einheit"} {unitNo}/
                    {totalUnitsShipment}
                  </Fragment>
                )}
              </h3>
              <p>
                {parentShipmentId && parentShipmentId.shipmentId && (
                  <Fragment>
                    <b>Lieferung</b> {parentShipmentId.shipmentId}
                    <br />
                  </Fragment>
                )}
              </p>
              <ul className="list-group mb-3">
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-12 col-lg">Name / SKU</div>
                    <div className="d-none d-lg-block col-md-6">
                      <div className="row">
                        <div className="col-6">SOLL</div>
                        <div className="col-6">IST</div>
                      </div>
                    </div>
                  </div>
                </li>
                {newStorageUnit &&
                  newStorageUnit.contents &&
                  newStorageUnit.contents.map((u, i) => (
                    //Display each element
                    <ChecklistItem index={i} key={`${u.sku}-${i}`} item={u} onChange={handleChangeItem} />
                  ))}
              </ul>
              <AutoSaveField
                name="notes"
                placeholder="Notizen"
                type="text"
                value={(inspection && inspection.notes) || ""}
                saveOnChange={true}
                onChange={() => {}}
                onSave={handleSaveNote}
                joiSchema={{
                  notes: Joi.string().allow("").label("Notizen"),
                }}
              />
              <br />
            </div>
          </Modal.Body>
          <div className="button-area container-fluid mb-3">
            <Button variant="secondary" onClick={handleHide} className="btn-block">
              Abbrechen
            </Button>
            <Button variant="primary" onClick={handleSubmit} className="btn-block">
              Warenannahme bestätigen{isSafeToClick(true)}
            </Button>
          </div>
        </Modal>
      </div>
    </Fragment>
  );
};

export default ReceiveModal;
