import React, { Component } from "react";
import { toast } from "react-toastify";
import RefurbishTable from "./RefurbishTable";
import StockTable from "./StockTable";
import Pagination from "../common/Pagination";
import SimpleModal from "../common/SimpleModal";
import ReturnsService from "../../services/returnsService";
import ImageService from "../../services/imageService";
import RefurbishModal from "./RefurbishModal";
import ProductListModal from "../modals/ProductListModal";
import { isSafeToClick } from "../../utils/helper";

class Refurbish extends Component {
  _isMounted = false;
  state = {
    pageSize: 8,
    currentPage: 1,
    currentPageStock: 1,
    returns: [],
    returnsStock: [],
    totalCount: 0,
    totalCountStock: 0,
    sortColumn: { path: "receive.date", order: "desc" },
    sortColumnStock: { path: "inspection.date", order: "desc" },
    item: {},
    imageDb: {
      loading: true,
      data: {},
    },
    refurbishModalLoading: false,
    confirmModalLoading: false,
    productListModalLoading: false,
    refurbishModalShow: false,
    areYouSureModalShow: false,
    productListModalShow: false,
    deleteItem: {},
    activeOrderReturn: {},
    dataLoading: true,
    dataLoadingStock: true,
  };

  //SETUP
  async componentDidMount() {
    const refurbId = this.props.match.params.id;
    this._isMounted = true;
    if (refurbId) {
      await this.handleSetActiveId(refurbId, true);
    }
    await Promise.all([this.fetchItems(), this.fetchItemsStock(), this.fetchImageDb()]);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidUpdate() {
    const refurbId = this.props.match.params.id;
    if (refurbId && !this.state.refurbishModalShow) {
      await this.handleSetActiveId(refurbId, true);
    }
  }

  fetchItems = async () => {
    const { currentPage, pageSize, searchQuery, sortColumn } = this.state;
    const sortBy = sortColumn.order === "asc" ? sortColumn.path : "-" + sortColumn.path;

    const { returns, totalCount } = await ReturnsService.getReceivedReturns(
      sortBy,
      currentPage,
      pageSize,
      searchQuery,
      false,
      true,
      false
    );
    if (this._isMounted) this.setState({ returns, totalCount, dataLoading: false });
  };

  fetchItemsStock = async () => {
    const { currentPageStock, pageSize, searchQueryStock, sortColumnStock } = this.state;
    const sortBy = sortColumnStock.order === "asc" ? sortColumnStock.path : "-" + sortColumnStock.path;

    const { returns, totalCount } = await ReturnsService.getReceivedReturns(
      sortBy,
      currentPageStock,
      pageSize,
      searchQueryStock,
      false,
      false,
      true
    );
    if (this._isMounted) this.setState({ returnsStock: returns, totalCountStock: totalCount, dataLoadingStock: false });
  };

  fetchImageDb = async () => {
    let imageDb = { ...this.state.imageDb };
    if (imageDb.loading === false) return;

    //if cache is not available
    imageDb = await ImageService.getImageDb();
    if (!imageDb.data) return;

    imageDb.loading = false;
    if (this._isMounted) this.setState({ imageDb });
  };

  handleSetActiveId = async (data, barcode = false) => {
    if (data !== "") {
      const activeOrderReturn = await ReturnsService.getReceivedReturn(data, barcode);
      if (!activeOrderReturn) return toast.error("Rücksendung konnte nicht gefunden werden.");

      this.setState({ refurbishModalShow: true });
      this.setState({ activeOrderReturn });
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page }, this.fetchItems);
  };

  handlePageChangeStock = page => {
    this.setState({ currentPageStock: page }, this.fetchItemsStock);
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn }, this.fetchItems);
  };

  handleSortStock = sortColumnStock => {
    this.setState({ sortColumnStock }, this.fetchItemsStock);
  };

  handleHideRefurbishDialog = async () => {
    this.setState({ refurbishModalShow: false, activeOrderReturn: {} });
    this.props.history.push("/refurbish");
    await Promise.all([this.fetchItems(), this.fetchItemsStock()]);
  };

  handleHideAreYouSureDialog = () => {
    this.setState({ areYouSureModalShow: false });
  };

  handleButtonClick = orderReturn => {
    this.setState({ refurbishModalShow: true, activeOrderReturn: orderReturn });
  };

  handleChangeProduct = () => {
    this.setState({
      productListModalShow: true,
    });
  };

  handleConfirmChangeProduct = async productDetail => {
    const receiveId = this.state.activeOrderReturn._id;
    this.setState({
      productListModalShow: false,
    });
    await ReturnsService.updateReceivedProduct(receiveId, productDetail.sku);
    await this.fetchItems();
    await this.handleSetActiveId(receiveId);
  };

  handleSearch = searchQuery => {
    this.setState({ searchQuery, currentPage: 1 }, this.fetchItems);
  };

  handleSearchStock = searchQueryStock => {
    this.setState({ searchQueryStock, currentPage: 1 }, this.fetchItemsStock);
  };

  handleDelete = orderReturn => {
    this.setState({
      areYouSureModalShow: true,
      deleteItem: orderReturn,
    });
  };

  handleConfirmDelete = async () => {
    const originalReturns = this.state.returns;
    const originalReturnsStock = this.state.returnsStock;
    const orderReturn = this.state.deleteItem;

    try {
      this.setState({ modalLoading: true });
      await ReturnsService.deleteReceivedReturn(orderReturn._id);
      await this.fetchItems();
      await this.fetchItemsStock();
      this.setState({ areYouSureModalShow: false, confirmModalLoading: false });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) toast.error("This return has already been deleted");
      this.setState({ returns: originalReturns, returnsStock: originalReturnsStock, confirmModalLoading: false });
    }
  };

  render() {
    const {
      currentPage,
      currentPageStock,
      pageSize,
      sortColumn,
      sortColumnStock,
      refurbishModalShow,
      areYouSureModalShow,
      productListModalShow,
      activeOrderReturn,
      imageDb,
      dataLoading,
      dataLoadingStock,
      returns,
      returnsStock,
      totalCount,
      totalCountStock,
    } = this.state;

    const productListModalClose = () => {
      this.setState({ productListModalShow: false });
    };

    return (
      <div className="container-fluid spacing-top">
        <div className="row">
          <div className="col">
            <RefurbishTable
              orderReturns={returns}
              totalCount={totalCount}
              onSort={this.handleSort}
              onSearch={this.handleSearch}
              sortColumn={sortColumn}
              loading={dataLoading}
              onButtonClick={this.handleButtonClick}
              onDelete={this.handleDelete}
              imageDb={imageDb}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Pagination
              currentPage={currentPage}
              itemsCount={totalCount}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <StockTable
              orderReturns={returnsStock}
              onSort={this.handleSortStock}
              sortColumn={sortColumnStock}
              onSearch={this.handleSearchStock}
              loading={dataLoadingStock}
              onButtonClick={this.handleButtonClick}
              onDelete={this.handleDelete}
              imageDb={imageDb}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Pagination
              currentPage={currentPageStock}
              itemsCount={totalCountStock}
              pageSize={pageSize}
              onPageChange={this.handlePageChangeStock}
            />
          </div>
        </div>
        <RefurbishModal
          show={refurbishModalShow}
          orderReturn={activeOrderReturn}
          title="Zustand erfassen"
          cancelText="Abbrechen"
          submitText={"Speichern" + isSafeToClick(true)}
          onHide={this.handleHideRefurbishDialog}
          onChangeProduct={this.handleChangeProduct}
          buttonColor="danger"
          imageDb={imageDb}
        />
        <SimpleModal
          show={areYouSureModalShow}
          title="Rücksendung löschen?"
          description="Bist du sicher, dass du diese Rücksendung löschen willst?"
          confirmText={"Löschen" + isSafeToClick(true)}
          cancelText="Abbrechen"
          onHide={() => this.handleHideAreYouSureDialog(areYouSureModalShow)}
          onConfirm={this.handleConfirmDelete}
          buttonColor="danger"
        />
        <ProductListModal
          show={productListModalShow}
          imageDb={imageDb}
          onHide={productListModalClose}
          onSubmit={this.handleConfirmChangeProduct}
          buttonText={"Produkt ändern" + isSafeToClick(true)}
        />
      </div>
    );
  }
}

export default Refurbish;
