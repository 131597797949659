import React, { Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Barcode from "../common/Barcode";
import PleaseScan from "./PleaseScan";
import barcodeService from "../../services/barcodeService";

const AddProductModal = ({ isOpen, onResolve, title, description, spinner, confirmText, cancelText, buttonColor }) => {
  async function handleScan(data) {
    const scanFeedback = await barcodeService.getData(data);
    if (scanFeedback.type !== "product") return;
    onResolve(scanFeedback);
  }

  return (
    <Fragment>
      {isOpen && <Barcode onScan={handleScan} />}
      <div className="modal-dialog">
        <Modal
          show={isOpen}
          onHide={() => onResolve()}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="fullscreen-modal"
        >
          <Modal.Body>
            <div className="splitview">
              <div className="my-auto">
                <PleaseScan scanDescription="Bitte scanne ein Produkt um es zu verknüpfen." />
              </div>
              <footer className="container-fluid">
                <Button variant="secondary" onClick={() => onResolve()} className="btn-block">
                  Abbrechen
                </Button>
              </footer>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

export default AddProductModal;
