import React from "react";
import Loader from "../common/Loader";

const LoadingScreen = ({ text, id }) => {
  return (
    <div className="text-center">
      <h2 className="mt-5 text-success">Scan erfolgreich</h2>
      <span className="text-muted">{id}</span>
      <p className="mb-5">{text}</p>
      <Loader />
    </div>
  );
};

export default LoadingScreen;
