import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import PropTypes from "prop-types";

// columns: object
// sortColumn: object
// onSort: function
const Table = ({ columns, data, getId, sortColumn, onSort }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover align-middle">
        <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
        <TableBody getId={getId} data={data} columns={columns} />
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getId: PropTypes.func.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired
};

Table.defaultProps = {
  getId: item => item._id
};

export default Table;
