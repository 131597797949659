import { getCurrentUser } from "../services/authService";
import { BillbeeLocalizedString } from "billbee-types/types/BillbeeLocalizedString";
import logger from "../services/logService";

export function isSafeToClick(isSafeToClick = false) {
  if (process.env.NODE_ENV === "production") return "";
  // Only show for phil to get to know the system better
  if (
    getCurrentUser().name !== "Philipp Faber" &&
    getCurrentUser().name !== "Benjamin Arand" &&
    getCurrentUser().name !== "Jonathan Laukenmann" &&
    getCurrentUser().name !== "Ruben Remmler"
  )
    return "";
  return isSafeToClick ? " ✅" : " 💣";
}

/**
 *
 * @param localizedString localized String input with at least one translation
 * @param languageCode defaults to "DE"
 * @returns the string in the defined language
 */
export function getBillbeeLocalizedString(localizedString: BillbeeLocalizedString[], languageCode: "DE" | "EN" = "DE") {
  if (!localizedString || !Array.isArray(localizedString)) {
    logger.error(
      "Invalid input object. Got " +
        JSON.stringify(localizedString) +
        ", expected an array of objects of type BillbeeLocalizedString"
    );
    return "";
  }
  return localizedString.find(l => l.LanguageCode === languageCode)?.Text || localizedString[0]?.Text || "";
}
