import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "../common/Form";
import Loader from "../common/Loader";
import { isSafeToClick } from "../../utils/helper";
import { returnLocalizedString } from "../../services/localizationService";
import { parentProductSchema, parentProductEmptyData } from "../../services/parentProductsService";
import _ from "lodash";
import { createNewInternalReason } from "../../services/returnsPortalService";

class ParentProductModal extends Form {
  state = {
    data: _.cloneDeep(parentProductEmptyData),
    errors: {},
  };

  schema = parentProductSchema;

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.loadData();
    }
  }

  loadData = () => {
    console.log("Loading modal data...");
    const { currentObject } = this.props;
    if (!currentObject) return;
    const convertedObject = _.cloneDeep(currentObject || {});
    convertedObject.batchNumberOptions = currentObject?.batchNumberOptions?.join(", ");

    const newData = _.cloneDeep(this.state.data);
    Object.assign(newData, convertedObject);
    this.setState({ data: newData });
  };

  onHide = () => {
    this.props.onHide();
    setTimeout(this.resetState, 500);
  };

  handleChangeBatchNumberOptions = ({ currentTarget: input }) => {
    const modifiedInput = { name: input.name, value: input.value };
    modifiedInput.value = this.stringToArray(input.value);
    this.handleChange({ currentTarget: modifiedInput });
  };

  doSubmit = async () => {
    const formData = _.cloneDeep(this.state.data);
    // Transform batch number options
    formData.batchNumberOptions = this.stringToArray(formData.batchNumberOptions);

    // Transform data from { label: "something", value: "something" } to valid object for POST parent product
    const newReasons = this.state.data.internalReasons.filter(r => r.__isNew__);
    const newReasonsResponse = await Promise.all(newReasons.map(r => createNewInternalReason(r.value, "de_DE")));
    formData.internalReasons = this.state.data.internalReasons.map(r => {
      if (r.__isNew__) {
        const indexOfCurrentReason = newReasons.findIndex(n => n.value === r.value);
        return newReasonsResponse[indexOfCurrentReason]._id;
      } else return r.value;
    });

    await this.props.onSubmit(formData);
    setTimeout(this.resetState, 500);
  };

  stringToArray(commaSeparatedString) {
    if (!commaSeparatedString) return [];
    const stringArray = commaSeparatedString.split(",").map(str => str.trim());
    return stringArray;
  }

  resetState = () => {
    const emptyData = _.cloneDeep(parentProductEmptyData);
    this.setState({
      data: emptyData,
      errors: {},
    });
  };

  render() {
    const { show, onSubmit, spinner, editMode, returnParcelServices, internalReasonsOptions } = this.props;
    const sortedInternalReasonsOptions = internalReasonsOptions?.sort((a, b) => (a.label > b.label ? 1 : -1));
    const {
      name,
      data: { batchNumberNeeded },
    } = this.state;

    const buttonText = editMode
      ? `Speichern ${isSafeToClick(true)}`
      : `Übergeordnetes Produkt hinzufügen ${isSafeToClick(true)}`;

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        onSubmit={onSubmit}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {editMode
              ? `${returnLocalizedString(name, "de_DE") || "Übergeordnetes Produkt"} bearbeiten`
              : `Übergeordnetes Produkt hinzufügen`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {spinner ? (
            <div className="m-5">
              <Loader />
            </div>
          ) : (
            <>
              <div className="form-row">
                <div className="col-12">
                  <h4>Allgemein</h4>
                </div>
                <div className="col-12 col-lg-6">{this.renderLocalizedStringEditor("name", "Name")}</div>
                <div className="col-12 col-lg-6">{this.renderInput("category", "Kategorie")}</div>
              </div>
              <div className="form-row mb-3">
                <div className="col">
                  {this.renderCheckbox(
                    "useWarehouseStock",
                    "Lager → ERPNext (Realer Lagerbestand überschreibt ERPNext Lagerbestand)"
                  )}
                </div>
                <div className="col-12">{this.renderCheckbox("sellRefillStock", "Nachfüllager verkaufbar?")}</div>
              </div>
              <div className="form-row mt-5 mb-3">
                <div className="col-12">
                  <h4>Retouren</h4>
                </div>
                <div className="col-12 col-lg-6">
                  {this.renderInputNumber("returnFee", "Rücksendegebühr", "0.01", 2)}
                </div>
                <div className="col-12 col-lg-6">
                  {this.renderSelect("returnParcelService", "Rücksendeweg", "Bitte auswählen...", returnParcelServices)}
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-12">
                  {this.renderLocalizedStringEditor(
                    "returnNotice",
                    "Erläuterung zur Rücksendung (Retourenportal + Email)",
                    "Einleitender Text. Erklärt z.B. warum die Rücksendung gebührenpflichtig ist. (Optional)",
                    "textarea"
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="col-12 mb-2">
                  {this.renderCheckbox("batchNumberNeeded", "Bei Rücksendung nach Chargennummer fragen?")}
                </div>
              </div>
              <div className="form-row" style={batchNumberNeeded ? {} : { opacity: 0.5 }}>
                <div className="col-12">
                  {this.renderLocalizedStringEditor(
                    "batchNumberDescription",
                    "Beschreibung Platzierung Chargennummer (Retourenportal)",
                    "Beschreibe, wo die Chargennummer auf dem Produkt zu finden ist",
                    "textarea",
                    !batchNumberNeeded
                  )}
                </div>
              </div>
              <div className="form-row mb-3" style={batchNumberNeeded ? {} : { opacity: 0.5 }}>
                <div className="col-12">
                  {this.renderTextareaActive(
                    "batchNumberOptions",
                    "Auswahl-Optionen für die Chargennummer",
                    "Vierstellige Chargennummer (Lieferantencode). Mehrere Werte durch Komma getrennt",
                    "textarea",
                    !batchNumberNeeded
                  )}
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-12">
                  {this.renderLocalizedStringEditor(
                    "returnInstructions",
                    "Rücksendeanleitung (Email)",
                    "Hinweise an den Kunden, wie die Ware am besten verpackt werden sollte.",
                    "textarea"
                  )}
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-12">
                  {this.renderLocalizedStringEditor(
                    "returnConditions",
                    "Voraussetzungen für die Rücksendung (Retourenportal + Email)",
                    "Hinweistext der den Kunden über die Voraussetzungen für eine Rücksendung und Erstattung informiert. (Optional)",
                    "textarea"
                  )}
                </div>
              </div>
              <div className="form-row mt-5 mb-3">
                <div className="col-12">
                  <h4>Reklamationsgründe</h4>
                  <p>
                    Hier können die für Customer Care auswählbaren Reklamationsgründe vordefiniert werden.
                    <br />
                    Vorhandene Gründe sollten möglichst wiederverwendet werden.
                  </p>
                  <p>
                    Gründe die in keinem Parent Product mehr verwendet werden, werden automatisch aus der Liste
                    entfernt.
                  </p>
                  {this.renderCreatableMultiSelect(
                    "internalReasons",
                    "Interner Reklamationsgrund",
                    sortedInternalReasonsOptions
                  )}
                </div>
              </div>
              {/* <div className="form-row mb-3">
                <div className="col-12">
                  <div className="mb-2">Einstellungen pro Anfragegrund</div>
                  {this.renderMatrixSelect(
                    "requestReasonsOptions",
                    requestReasons.map(r => returnLocalizedString(r.description, lang)),
                    [{ key: "returnNeeded", label: "Ware retournieren" }],
                    applyRequestReasonsOptions(requestReasons, requestReasonsOptions, { returnNeeded: true })
                  )}
                </div>
              </div> */}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="full-width">
            <Button variant="secondary" className="mr-2" onClick={this.onHide}>
              Abbrechen
            </Button>
            {this.renderButton(buttonText, this.handleSubmit)}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ParentProductModal;
