import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DateModal extends Component {
  state = {
    date: this.props.defaultDate,
  };

  handleChangeDate = date => {
    this.setState({ date });
  };

  render() {
    const { isOpen, onResolve, title, description, spinner, confirmText, cancelText, buttonColor } = this.props;

    return (
      <Modal
        show={isOpen}
        size="lg"
        backdrop="static"
        onHide={() => onResolve()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title || "Bist du sicher?"}</Modal.Title>
        </Modal.Header>
        {description ? (
          <Modal.Body>
            {spinner ? (
              <div className="m-5 text-center">
                <Loader className="mb-2" />
              </div>
            ) : (
              <div className="mb-3">{description || ""}</div>
            )}
            {
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="form-group">
                    <DatePicker
                      name="date-picker"
                      selected={this.state.date}
                      onChange={this.handleChangeDate}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            }
          </Modal.Body>
        ) : (
          ""
        )}
        <Modal.Footer>
          {cancelText === "" ? (
            ""
          ) : (
            <Button variant="secondary" onClick={() => onResolve()}>
              {cancelText || "Abbrechen"}
            </Button>
          )}
          <Button variant={buttonColor ? buttonColor : "primary"} onClick={() => onResolve(this.state.date)}>
            {confirmText || "Ja"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DateModal;
