import React from "react";
import { Button } from "react-bootstrap";

const NewStu = ({ onCreate }) => {
  return (
    <div className="text-center pt-5">
      <h3 className="text-light-gray">Neue STU erstellen</h3>
      <p>
        <Button variant="secondary" onClick={() => onCreate()}>
          Lagereinheit erstellen
        </Button>
      </p>
    </div>
  );
};

export default NewStu;
