import React from "react";
import ReturnCard from "./ReturnCard";
import _ from "lodash";

const CardList = ({
  pageData,
  imageDb,
  onReceive,
  onReceiveOther,
  onLoadDetails,
  orderDetails,
  receivedReturns,
  preannouncedReturns,
}) => {
  function getOrderDetail(orderReturn) {
    // For regular results (search reasults) keep order as is
    if (!orderReturn.falseDelivery && orderReturn.type !== "preannouncement")
      return _.cloneDeep(orderDetails[orderReturn.orderId]);

    //Modify product, copy the rest from the original order -> For "Receive different product" and preannounces
    if (orderDetails[orderReturn.orderId]) {
      const modifiedDetail = _.cloneDeep(orderDetails[orderReturn.orderId]);
      modifiedDetail.OrderItems = [
        {
          Quantity: orderReturn.quantity,
          Product: {
            BillbeeId: orderReturn.billbeeId + "-" + orderReturn.sku,
            SKU: orderReturn.sku,
            Title: [{ LanguageCode: "DE", Text: orderReturn.productName }],
          },
        },
      ];
      return modifiedDetail;
    }
  }
  return (
    <React.Fragment>
      {pageData &&
        pageData.map((orderReturn, i) => {
          const { orderNumber, orderId, sku } = orderReturn;
          const receives = receivedReturns[orderNumber];
          const preannounces = preannouncedReturns[orderNumber];

          return (
            <div key={orderId + "-" + sku + "-" + i} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
              <ReturnCard
                orderReturn={orderReturn}
                onReceive={onReceive}
                onReceiveOther={onReceiveOther}
                onLoadDetails={onLoadDetails}
                orderDetail={getOrderDetail(orderReturn)}
                imageDb={imageDb}
                receivedReturns={receives || null}
                preannouncedReturns={preannounces || null}
              />
            </div>
          );
        })}
    </React.Fragment>
  );
};

export default CardList;
