import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getTasks() {
  const endpointName = "tasks";
  const { data } = await http.get(apiUrl + endpointName);
  if (data) return data;
  return null;
}

export async function getAttachment(gid) {
  const endpointName = `tasks/attachments/${gid}`;
  const { data } = await http.get(apiUrl + endpointName);
  if (data) return data;
  return null;
}

export async function toggleTaskCompletion(gid, completed) {
  const endpointName = `tasks?gid=${gid}`;
  const { data } = await http.put(apiUrl + endpointName, { completed });
  if (data) return data;
  return null;
}

const tasksService = {
  getTasks,
  getAttachment,
  toggleTaskCompletion
};

export default tasksService;
