import React, { useState, useEffect } from "react";
import Task from "./Task";
import tasksService, { toggleTaskCompletion } from "../../services/tasksService";
import Loader from "../common/Loader";

const Tasks = ({ tasks, setTasks, loadTasks, loading }) => {
  const [tasksSaved, setTasksSaved] = useState(Date.now());

  //Get tasks whenever a change was made
  useEffect(() => loadTasks(false), [tasksSaved, loadTasks]);

  async function handleClick(gid) {
    const tasksCopy = [...tasks];
    const tasksBackup = [...tasks];
    const task = tasksCopy.find(t => t.gid === gid);
    task.completed = !task.completed;
    setTasks(tasksCopy);
    try {
      await toggleTaskCompletion(gid, task.completed);
    } catch (err) {
      setTasks(tasksBackup);
    }
    setTasksSaved(Date.now());
  }

  async function handleOpenAttachment(gid) {
    const attachment = await tasksService.getAttachment(gid);
    window.open(attachment.download_url, "_blank");
  }

  return (
    <div className="container-fluid">
      {loading ? (
        <Loader />
      ) : tasks.length ? (
        tasks.map(task => (
          <div key={task.gid} className="row justify-content-md-center">
            <div className="col-12">
              <div>
                <Task
                  id={task.gid}
                  title={task.name}
                  description={task.html_notes}
                  attachments={task.attachments}
                  due={task.due_on}
                  completed={task.completed}
                  onComplete={handleClick}
                  onOpenAttachment={handleOpenAttachment}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="h3 text-muted text-center">Keine offenen Aufgaben</div>
      )}
    </div>
  );
};

export default Tasks;
