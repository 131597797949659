import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import TextboxPromiseModal from "../common/TextboxPromiseModal";
import SimplePromiseModal from "../common/SimplePromiseModal";
import { toast } from "react-toastify";
import { startSession, stopSession } from "../../services/remissionService";
import { logService as logger } from "../../services/logService";
import { createModal } from "react-modal-promise";

const RefurbishingSession = ({ onToggle }) => {
  const [sessionId, setSessionId] = useState();
  const [sessionStart, setSessionStart] = useState();
  const [timer, setTimer] = useState("00:00:00");

  const enterNameModal = createModal(TextboxPromiseModal);
  const areYouSureModal = createModal(SimplePromiseModal);

  useEffect(() => {
    loadSessionValues();
  }, []);

  useEffect(() => {
    const ticker = setInterval(updateTimer, 1000);

    return () => clearInterval(ticker);

    function updateTimer() {
      if (!sessionStart) {
        setTimer("");
        return;
      }
      const startDate = new Date(sessionStart);
      const delta = Date.now() - startDate.getTime();

      const hours = delta / (1000 * 60 * 60);
      const hoursFloor = Math.floor(hours);
      const minutes = delta / (1000 * 60) - hoursFloor * 60;
      const minutesFloor = Math.floor(minutes);
      const seconds = delta / 1000 - hoursFloor * 60 * 60 - minutesFloor * 60;
      const secondsFloor = Math.floor(seconds);

      setTimer(`${makeTwoDigit(hoursFloor)}:${makeTwoDigit(minutesFloor)}:${makeTwoDigit(secondsFloor)}`);

      function makeTwoDigit(input) {
        return input < 10 ? "0" + input : "" + input;
      }
    }
  }, [sessionStart]);

  function loadSessionValues() {
    const id = sessionStorage.getItem("sessionId");
    const runningSessionStart = sessionStorage.getItem("sessionStart");

    if (id && runningSessionStart) {
      setSessionId(id);
      setSessionStart(runningSessionStart);
    } else {
      setSessionId(undefined);
      setSessionStart(undefined);
    }
  }

  async function handleStartSession() {
    const result = await enterNameModal({
      title: "Name",
      placeholder: "z.B. Max Mustermann",
      description:
        "<p>Du bist dabei die Aufbereitung zu starten.</p><p><b>Bitte beachte:</b> Beim hinzufügen von Artikeln wird automatisch auf dem ausgewählten Drucker ein Barcode-Etikett gedruckt.</p><p><b>Wähle deshalb zu Beginn den korrekten Drucker aus.</b></p><p>Bitte gibt deinen Namen ein:</p>",
      confirmText: "Aufbereitung starten",
      cancelText: "Abbrechen",
      buttonColor: "success",
    });
    if (!result) return;
    const name = result;
    setTimer("00:00:00");

    const response = await startSession(name);

    sessionStorage.setItem("sessionId", response.sessionId);
    sessionStorage.setItem("sessionStart", response.sessionStart);
    setSessionId(response.sessionId);
    setSessionStart(response.sessionStart);
    onToggle();
  }

  async function handleStopSession() {
    const sessionIdCopy = sessionId;
    const sessionStartCopy = sessionStart;
    const result = await areYouSureModal({
      title: "Aufbereitung abschließen?",
      description: "Bist du sicher, dass du die Aufbereitung beenden möchtest?",
      confirmText: "Aufbereitung abschließen",
      buttonColor: "danger",
    });
    if (!result) return;

    setSessionId(undefined);
    setSessionStart(undefined);

    try {
      await stopSession(sessionId);
      sessionStorage.removeItem("sessionId");
      sessionStorage.removeItem("sessionStart");
    } catch (err) {
      setSessionId(sessionIdCopy);
      setSessionStart(sessionStartCopy);
      toast.error("Session konnte nicht gestoppt werden");
      logger.error("Failed to stop session", err);
    }
    onToggle();
  }

  return (
    <>
      {sessionId ? (
        <Button variant="danger" onClick={handleStopSession}>
          {timer} Aufbereitung beenden
        </Button>
      ) : (
        <Button variant="success" onClick={handleStartSession}>
          Aufbereitung starten
        </Button>
      )}
    </>
  );
};

export default RefurbishingSession;
