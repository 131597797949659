import React from "react";

const Loader = ({ message = "", ...rest }) => {
  return (
    <div className="loader center text-center m-auto text-muted" {...rest}>
      <i className="fa fa-2x fa-circle-notch fa-spin" />
      {message && <p className="mt-2">{message}</p>}
    </div>
  );
};

export default Loader;
