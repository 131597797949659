import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { createLabels } from "../../services/createLabelsService";

const CreateLabels = () => {
  const [skus, setSkus] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSkusChange = event => {
    const inputText = event.target.value;
    const formattedText = inputText
      .replace(/["',]/g, "") // Remove commas and quotes
      .split(/\s+/) // Split by any whitespace
      .join("\n"); // Join using newline
    setSkus(formattedText);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handleSendRequest = async () => {
    setIsLoading(true);
    setIsSuccess(false);
    const skusArray = skus.split("\n");

    const requestBody = {
      skus: skusArray,
      recipient: email,
    };

    console.log("Creating Labels with Request Body");
    console.log(requestBody);

    try {
      const response = await createLabels(requestBody);
      if (response === "Successfully saved file") {
        setIsSuccess(true);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid spacing-top">
      <div className="row justify-content-between align-items-center mb-5">
        <div className="col-md-2">
          <h1>Labels Erstellen</h1>
          <Form.Group className="mt-4">
            <Form.Label>SKUs</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              value={skus}
              onChange={handleSkusChange}
              placeholder="SKUs einfügen"
            />
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" value={email} onChange={handleEmailChange} placeholder="Email" />
          </Form.Group>
          <Button className="mt-4" disabled={isLoading} onClick={handleSendRequest}>
            {isLoading ? "Lädt..." : "Labels erstellen & senden"}
          </Button>
          {isSuccess && (
            <div className="mt-4">
              <span className="text-success">&#10003;</span> Labels erstellt - kann bis zu 10 Minuten dauern
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateLabels;
