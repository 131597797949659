import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "../common/Loader";
import Button from "react-bootstrap/Button";

class SimpleModal extends Component {
  render() {
    const { show, title, spinner, description, confirmText, cancelText, onHide, onConfirm, buttonColor } = this.props;
    return (
      <Modal
        show={show}
        size="lg"
        onHide={onHide}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {spinner ? (
            <div className="m-5 text-center">
              <Loader className="mb-2" />
            </div>
          ) : (
            <div className="container">{description}</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {cancelText}
          </Button>
          <Button variant={buttonColor ? buttonColor : "primary"} onClick={onConfirm}>
            {confirmText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SimpleModal;
