import http from "./httpService";
import axios from "axios";
import { getCurrentUser } from "./authService";
import asyncUtils from "../utils/asyncUtils";
import logger from "../services/logService";
import _ from "lodash";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export async function loadAllShipments() {
  try {
    const { data } = await http.get(`/shipments/`);
    const sortedData = _.orderBy(data, "shippingDate", "desc");
    return sortedData;
  } catch (err) {
    if (err.response?.status === 404) return [];
    toast.error("Fehler beim Laden der Lieferungen. Bitte wende dich an den Administrator.");
  }
}

export async function loadShipment(id) {
  try {
    const { data } = await http.get(`/shipments/${id}`);
    return data;
  } catch (err) {
    if (err.response?.status === 404) return { error: 404 };
    return { error: 500 };
  }
}

export async function createShipment(shippingDate, inputSupplierShortCode = null) {
  const user = getCurrentUser();

  const supplierShortCode = inputSupplierShortCode || (user && user.shortCode) || null;

  const body = {
    shippingDate,
    supplierShortCode,
  };
  const { data } = await http.post(`/shipments/`, body);
  return data;
}

export async function createOutboundShipment(date, draftData) {
  try {
    const body = {
      shippingDate: date,
      contents: draftData.storageUnits.map(s => s.storageUnitId),
    };
    return await http.post(`/shipments/outbound/`, body);
  } catch (err) {
    if (!err?.response?.data?.messageDE) {
      logger.error(`Error returning the error message while creating shipment.`, err);
      toast.error("Es ist ein unerwarteter Fehler aufgetreten.", JSON.stringify(err));
    } else {
      toast.error(err?.response?.data?.messageDE);
    }
  }
}

export async function updateShipment(_id, draftData) {
  const body = {
    draftContent: draftData,
  };
  const { data } = await http.put(`/shipments/${_id}`, body);
  return data;
}

export async function submitOutboundShipment(_id, body) {
  return await http.post(`/shipments/outbound/${_id}/submit/`, body);
}

export async function getSubmitOutboundShipmentStatus(_id) {
  return await http.get(`/shipments/outbound/${_id}/submit/`);
}

export async function removeDraftContent(_id) {
  const body = {
    draftContent: null,
  };
  const { data } = await http.put(`/shipments/${_id}`, body);
  return data;
}

export async function deleteShipment(_id) {
  const { data } = await http.delete(`/shipments/${_id}`);
  return data;
}

export async function loadStorageUnits(shipment) {
  const shipmentId = shipment._id;
  const {
    data: { data: storageUnits },
  } = await http.get(`/storage-units?shipment=${shipmentId}&pageSize=2000&includeOutbound=true&includeUnloaded=true`);

  if (shipment?.draftContent?.storageUnits) {
    shipment.draftContent.storageUnits.filter(d => storageUnits.find(s => s._id === d._id));
    return shipment.draftContent.storageUnits;
  }

  const sortedStorageUnits = _.sortBy(storageUnits, "unitNo"); //Makes sure the order is the same as when it was saved.
  if (sortedStorageUnits)
    return sortedStorageUnits.map(s => ({
      _id: s._id,
      category: s.category,
      unitNo: s.unitNo,
      totalUnitsShipment: s.totalUnitsShipment,
      contents: s.contents?.map(c => ({
        itemNumber: c.itemNumber || c.ean,
        bStock: c.bStock,
        poNumber: c.poNumber,
        initialQuantity: c.initialQuantity,
      })),
    }));
}

export async function createStorageUnit(storageUnit, currentShipment = null) {
  const { category, unitNo, totalUnitsShipment, contents } = storageUnit;

  const body = {
    parentShipmentId: currentShipment._id || null,
    category,
    unitNo,
    totalUnitsShipment,
    contents: contents.map(({ itemNumber, ean, sku, bStock, poNumber, initialQuantity }) => {
      //itemNumber is used instead of ean or sku whenever the user inputs something into the sku/ean field
      const c = {
        itemNumber: itemNumber || ean || sku,
        bStock,
        poNumber,
        initialQuantity,
      };

      return c;
    }),
  };
  await asyncUtils.timeout(100); //Slow down so the loading circle doesn't look like a "flicker"
  const { data } = await http.post(`/storage-units/`, body);
  return data;
}

export async function updateStorageUnit(storageUnit) {
  //Update based on _id
  const { category, unitNo, totalUnitsShipment, contents } = storageUnit;
  const body = {
    category,
    unitNo,
    totalUnitsShipment,
    contents: contents.map(({ itemNumber, ean, sku, bStock, poNumber, initialQuantity }) => {
      //itemNumber is used instead of ean or sku whenever the user inputs something into the sku/ean field
      const c = {
        itemNumber: itemNumber || ean || sku,
        bStock,
        poNumber,
        initialQuantity,
      };

      return c;
    }),
  };
  await asyncUtils.timeout(100); //Slow down so the loading circle doesn't look like a "flicker"
  const { data } = await http.put(`/storage-units/${storageUnit._id}`, body);
  return data;
}

export async function deleteStorageUnit(_id) {
  await http.delete(`/storage-units/${_id}`);
}

export async function createShipmentLabels(shipment) {
  //Request the data
  const requestResult = await http.post(`/shipments/${shipment._id}/create-label/`);
  if (!requestResult || requestResult.status !== 200) {
    logger.error("Error requesting the creation of shipment labels. Shipment: ", shipment);
    return;
  }

  const { requestTime } = requestResult;

  for (let i = 0; i < 60; i++) {
    await asyncUtils.timeout(1000);
    //Try getting the data, retry until status is 200
    const { status } = await http.get(`/shipments/${shipment._id}/create-label/${requestTime}`);
    if (status === 202) console.log("Not yet ready");
    if (status === 404) return console.log("Endpoint not found");
    if (status === 200) return console.log("Label ready");
  }
  return toast.error("Timeout: Could not create shipment labels. Request took too long.");
}

export async function getListOfSupplierCodes() {
  const { data } = await http.get(`/users/suppliers`);
  return data;
}

const shipmentService = {
  loadAllShipments,
  loadShipment,
  createShipment,
  createStorageUnit,
  updateStorageUnit,
  deleteShipment,
  deleteStorageUnit,
  loadStorageUnits,
  createShipmentLabels,
  removeDraftContent,
  submitOutboundShipment,
  getSubmitOutboundShipmentStatus,
};

export default shipmentService;
