import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import PrinterMenu from "./PrinterMenu";
import WarehouseMenu from "./WarehouseMenu";
import SupplierMenu from "./SupplierMenu";
import AdminMenu from "./AdminMenu";
import UserMenu from "./UserMenu";
import ReturnsMenu from "./ReturnsMenu";
import WarehouseManagerMenu from "./WarehouseManagerMenu";

class MyNavbar extends Component {
  state = {
    expanded: false,
  };

  render() {
    const { user, taskCount } = this.props;

    const handleToggle = () => {
      this.setState({ expanded: !this.state.expanded });
    };
    const handleClose = () => {
      this.setState({ expanded: false });
    };

    const isWarehouseUser =
      user && !user.isAdmin && user.roles && (user.roles.includes("Brun") || user.roles.includes("warehouse"));
    const isSupplierUser = user && !user.isAdmin && user.roles && user.roles.includes("supplier");
    const isAdminUser = user && user.isAdmin;
    const isReturnsUser = user && !user.isAdmin && user.roles && user.roles.includes("returns");
    const isWarehouseManagerUser = isWarehouseUser && user.roles.includes("warehouseManager");

    return (
      <Navbar collapseOnSelect bg="light" expand="lg" expanded={this.state.expanded} onToggle={handleToggle}>
        <Navbar.Brand className="m-0 p-0"></Navbar.Brand>
        {user && (
          <>
            <div className="d-lg-none">
              {isWarehouseUser ? (
                <>
                  <NavLink
                    className="btn btn-secondary line-height-normal d-lg-none mr-3 plus-icon"
                    to="/warehouse/storageunit/new/"
                    onClick={handleClose}
                  >
                    <i className="fas fa-plus"></i>
                  </NavLink>
                  <PrinterMenu user={user} />
                  {/* <NavLink
                    className="btn btn-primary line-height-normal d-lg-none mr-3 scanner-icon"
                    to="/warehouse/"
                    onClick={handleClose}
                  >
                    <i className="fas fa-barcode"></i>
                  </NavLink> */}
                </>
              ) : null}
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            </div>
            <Navbar.Collapse id="responsive-navbar-nav">
              {user ? (
                <Nav>
                  {isSupplierUser ? <SupplierMenu onClose={handleClose} /> : null}
                  {isWarehouseManagerUser ? (
                    <WarehouseManagerMenu taskCount={taskCount} onClose={handleClose} />
                  ) : isWarehouseUser ? (
                    <WarehouseMenu taskCount={taskCount} onClose={handleClose} />
                  ) : null}
                  {isAdminUser ? <AdminMenu user={user} taskCount={taskCount} onClose={handleClose} /> : null}
                  {isReturnsUser ? <ReturnsMenu onClose={handleClose} /> : null}
                </Nav>
              ) : null}
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
              <Nav>
                <PrinterMenu user={user} />
                <UserMenu user={user} onClose={handleClose} />
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    );
  }
}

export default MyNavbar;
