import React from "react";

const Status = ({ orderReturn }) => {
  const statusLights = ["Angenommen", "Erstattet", "Refurbished"];
  const grey = "#C8C8C8";
  const green = "#28A745";
  const brown = "#8A7557";
  const red = "#DC3545";

  function statusColor(statusTitle) {
    if (statusTitle === "Angenommen") return orderReturn.receive ? green : grey;

    if (statusTitle === "Erstattet")
      return orderReturn.refund && orderReturn.refund.refunded
        ? green
        : orderReturn.refund && orderReturn.refund.refundRequired === false
        ? red
        : grey;

    if (statusTitle === "Refurbished") {
      if (orderReturn.inspection)
        return orderReturn.inspection.result === "stock"
          ? green
          : orderReturn.inspection.result === "stockUsed"
          ? brown
          : red;
    }

    return grey;
  }

  function statusMessage(statusTitle) {
    if (statusTitle === "Angenommen")
      return orderReturn.receive
        ? `Angenommen am ${new Date(orderReturn.receive.date).toLocaleString()}`
        : `Noch nicht angenommen`;

    if (statusTitle === "Erstattet")
      return orderReturn.refund && orderReturn.refund.refunded
        ? "Erstattet"
        : orderReturn.refund && orderReturn.refund.refundRequired === false
        ? "Erstattung nicht nötig"
        : "Erstattung ausstehend";

    if (statusTitle === "Refurbished") {
      if (orderReturn.inspection)
        return orderReturn.inspection.result === "stock"
          ? `Aufbereitet am ${new Date(orderReturn.inspection.date).toLocaleString()} als Neuware`
          : orderReturn.inspection.result === "stockUsed"
          ? `Aufbereitet am ${new Date(orderReturn.inspection.date).toLocaleString()} als B-Ware`
          : `Entsorgt am ${new Date(orderReturn.inspection.date).toLocaleString()}`;
      else return "Noch nicht Aufbereitet";
    }
  }

  function statusSymbol(statusTitle) {
    if (statusTitle === "Angenommen") return "box";
    if (statusTitle === "Erstattet") return "hand-holding-usd";
    if (statusTitle === "Refurbished") return "recycle";
  }

  return (
    <div style={{ width: "75px" }}>
      {statusLights.map(statusTitle => (
        <div key={statusTitle} style={{ width: "24px", height: "24px", display: "inline-block", textAlign: "center" }}>
          <span
            className={"fas fa-" + statusSymbol(statusTitle)}
            style={{ color: statusColor(statusTitle) }}
            title={statusMessage(statusTitle)}
          ></span>
        </div>
      ))}
    </div>
  );
};
export default Status;
