import React, { Component } from "react";
import BarcodeReader from "react-barcode-reader";
import { toast } from "react-toastify";

class Barcode extends Component {
  handleScan = data => {
    const { onScan } = this.props;

    if (data === "") return toast.error("Ungültiger Barcode, bitte erneut scannen.");

    // toast.success(`Scan ergab: ${data}`);
    onScan(data);
  };

  handleError = err => {
    console.log("Scan war nicht erfolgreich, bitte erneut scannen. " + err);
  };

  render() {
    return <BarcodeReader onError={this.handleError} onScan={this.handleScan} avgTimeByChar={15} />;
  }
}

export default Barcode;
