import http from "./httpService";
import axios from "axios";
import { getCurrentUser } from "./authService";

const apiEndpoint = "/users";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export async function register(user) {
  return await http.post(apiEndpoint, {
    email: user.username,
    password: user.password,
    name: user.name,
    roles: user.roles
  });
}

export async function registerSupplier({ username, name, password, shortCode }) {
  return await http.post(apiEndpoint + "/supplier", {
    email: username,
    password: password,
    name: name,
    shortCode: shortCode
  });
}

export function getCategoriesFromUser() {
  const user = getCurrentUser();
  let productCategories = [];

  if (!user.roles) return productCategories;

  //If empty the user can see all products... e.g. Brun
  if (user.roles.indexOf("Brun") > -1) {
    // productCategories.push(
    //   "Krabbelmatte",
    //   "Babymatratze",
    //   "Rutschbrett",
    //   "BalanceBoard",
    //   "Laufrad",
    //   "Kletterdreieck"
    // );
  } else if (user.roles.indexOf("IMG") > -1) {
    productCategories.push("Stillkissen");
  }

  return productCategories;
}

const userService = {
  register,
  getCategoriesFromUser,
  registerSupplier
};

export default userService;
