import React from "react";
import { NavLink } from "react-router-dom";

const SupplierMenu = ({ onClose }) => {
  return (
    <React.Fragment>
      <NavLink className="nav-link my-auto" to="/shipments" onClick={onClose}>
        <i className="fas fa-truck mr-2"></i>Manage Shipments
      </NavLink>
    </React.Fragment>
  );
};

export default SupplierMenu;
