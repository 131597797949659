import React, { useState, useEffect, useContext, Fragment } from "react";
import LocationScreen from "./LocationScreen";
import StorageUnitScreen from "./StorageUnitScreen";
import warehouseService, { getReceivedQuantity } from "../../services/warehouseService";
import { BarcodeContext } from "../../services/barcodeService";
import AddModal from "./AddModal";
import GroupModal from "./GroupModal";
import ReceiveModal from "./ReceiveModal";
import SimplePromiseModal from "../common/SimplePromiseModal";
import QuantityModal from "../common/QuantityModal";
import { toast } from "react-toastify";
import { createModal } from "react-modal-promise";
import _ from "lodash";
import StorageGroupScreen from "./StorageGroupScreen";
import ProductScreen from "./ProductScreen";
import logger from "../../services/logService";
import WarehouseList from "./WarehouseList";
import EditStuModal from "./EditStuModal";
import NewStu from "./NewStu";

const Warehouse = ({ match, history }) => {
  const [location, setLocation] = useState({});
  const [storageUnit, setStorageUnit] = useState({});
  const [storageGroup, setStorageGroup] = useState({});
  const [product, setProduct] = useState({});
  const [productLocations, setProductLocations] = useState([]);
  const [groupContents, setGroupContents] = useState([]);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [groupModalLoading, setGroupModalLoading] = useState({ loading: false, message: "" });
  const [showEditStuModal, setShowEditStuModal] = useState(false);
  const [editStuModalLoading, setEditStuModalLoading] = useState({ loading: false, message: "" });
  const barcode = useContext(BarcodeContext);

  //Single is used to prevent rerouting to the parent group
  let { category, id, single } = match.params;

  //Load new content when Id Changes
  useEffect(() => {
    (async () => {
      await loadContent();
    })(); // eslint-disable-next-line
  }, [category, id, setLocation, setStorageUnit, setStorageGroup, setProduct, setProductLocations]);

  async function loadContent() {
    setStorageUnit({});
    setStorageGroup({});
    setGroupContents([]);
    setLocation({});
    setProduct({});
    setProductLocations([]);
    if (category === "location") {
      const result = await warehouseService.loadWarehouseLocation(id);
      setLocation(result);
    } else if (category === "storageunit") {
      if (id === "new") return;
      const storageUnit = await warehouseService.loadStorageUnit(id);
      if (storageUnit.parentGroup && storageUnit.parentGroup.storageGroupId && single !== "single")
        history.replace(`/warehouse/storagegroup/${storageUnit.parentGroup.storageGroupId}`);
      else {
        setStorageUnit(storageUnit);
      }
    } else if (category === "storagegroup") {
      const storageGroup = await warehouseService.loadStorageGroup(id);
      setStorageGroup(storageGroup);
      setGroupContents(storageGroup.contents);
    } else if (category === "product") {
      const product = await warehouseService.loadProduct(id);
      const productLocations = await warehouseService.loadProductLocations(product.sku);
      setProduct(product);
      setProductLocations(productLocations);
    }
  }

  const showConfirmModal = createModal(SimplePromiseModal);
  const showQuantityModal = createModal(QuantityModal);
  const showReceiveModal = createModal(ReceiveModal);

  function handleConnect() {
    barcode.setScanModeConnect(true);
    setShowConnectModal(true);
  }

  async function handleConfirmConnect(objectId, objectType) {
    try {
      if (category === "storageunit" && objectType === "location")
        await warehouseService.storeUnitAtLocation(storageUnit._id, objectId, showConfirmModal);
      else if (category === "storagegroup" && objectType === "location")
        await warehouseService.storeGroupAtLocation(storageGroup._id, objectId, showConfirmModal);
      else if (category === "product" && objectType === "location") {
        await warehouseService.changeProductQuantity(product._id, objectId, showQuantityModal);
      } else if (category === "product" && objectType === "locationsingle") {
        await warehouseService.changeProductQuantity(product._id, objectId, null, 1);
      } else if (category === "location") {
        if (objectType === "storagegroup")
          await warehouseService.storeGroupAtLocation(objectId, location._id, showConfirmModal);
        else if (objectType === "storageunit")
          await warehouseService.storeUnitAtLocation(objectId, location._id, showConfirmModal);
        else if (objectType === "product")
          await warehouseService.changeProductQuantity(objectId, location._id, showQuantityModal);
        else if (objectType === "productsingle") {
          await warehouseService.changeProductQuantity(objectId, location._id, null, 1);
        } else {
          toast.warning("Der gescannte Lagerplatz kann nicht gefunden werden.");
          return;
        }
      } else {
        toast.warning("Es ist ein Fehler aufgetreten. Die gescannte Einheit ist nicht kompatibel.");
        return;
      }
    } catch (err) {
      toast.error("Es ist ein unerwarteter Fehler aufgetreten. Bitte informiere den Administrator.");
      logger.error("Error confirming the connection. ", err);
      return;
    }
    barcode.setScanModeConnect(false);
    setShowConnectModal(false);
    await loadContent();
  }

  async function handleAdjustStock(location, product) {
    try {
      if (!product)
        return toast.warning("Ordne dem Picking-Platz ein Produkt zu, bevor du den Bestand anpassen kannst.");
      await warehouseService.changeProductQuantity(product._id, location._id, showQuantityModal);
      await loadContent();
    } catch (err) {
      toast.error("Fehler beim anpassen des Lagerbestandes.");
      logger.error("Error adjusting the stock", err);
    }
  }

  function handleHideConnectModal() {
    barcode.setScanModeConnect(false);
    setShowConnectModal(false);
  }

  async function handleReceiveModalShow(overrideReceiveUnit = null) {
    const receiveStorageUnit = overrideReceiveUnit || storageUnit;
    const result = await showReceiveModal({ receiveStorageUnit });
    if (!result) return;
    if (result) {
      return await handleReceiveModalSubmit(result, receiveStorageUnit);
    }
  }

  async function handleReceiveModalSubmit(newStorageUnit, receiveStorageUnit) {
    setGroupModalLoading({ loading: true, message: "Warenannahme wird gespeichert..." });
    const backupStorageUnit = _.cloneDeep(receiveStorageUnit);
    try {
      newStorageUnit.contents.map(c => {
        return Object.assign(c, {
          receivedQuantity: getReceivedQuantity(c),
        });
      });
      newStorageUnit.receiveDate = newStorageUnit.receiveDate || Date.now();
      setStorageUnit(newStorageUnit);

      const result = await warehouseService.updateStorageUnit(receiveStorageUnit._id, newStorageUnit);
      setGroupModalLoading({ loading: false, message: "" });
      return result;
    } catch (err) {
      setStorageUnit(backupStorageUnit);
      toast.error("Warenempfang konnte nicht gespeichert werden. " + err.response?.data);
      return;
    }
  }

  function handleHideGroupModal() {
    barcode.setScanModeConnect(false);
    setShowGroupModal(false);
  }

  async function handleGroupStorageUnits(groupContents) {
    barcode.setScanModeConnect(true);
    setGroupContents(groupContents);
    setShowGroupModal(true);
  }

  async function handleSubmitGroupStorageUnits(storageUnitIds, _id = null) {
    let result;
    if (_id) {
      setGroupModalLoading({ loading: true, message: "Gruppe wird gespeichert..." });
      result = await warehouseService.updateStorageGroup(storageUnitIds, _id);
    } else {
      setGroupModalLoading({ loading: true, message: "Gruppe wird erstellt und ein Label wird ausgedruckt..." });
      result = await warehouseService.createStorageGroup(storageUnitIds);
      if (!result) {
        setGroupModalLoading({ loading: false, message: "" });
        return;
      }
    }

    setGroupModalLoading({ loading: false, message: "" });
    barcode.setScanModeConnect(false);
    setShowGroupModal(false);
    setTimeout(() => {
      setGroupContents([]);
    }, 500);
    if (result && result.code === "groupDeleted") {
      history.push("/warehouse/");
      toast("Die leere Gruppe wurde automatisch entfernt");
    } else if (result && result.storageGroupId) {
      history.push(`/warehouse/storagegroup/${result.storageGroupId}`);
      toast(`Gruppe ${result.storageGroupId} erfolgreich ${_id ? "aktualisiert" : "erstellt"}.`);
    }
  }

  function handleHideEditStuModal() {
    barcode.setScanModeConnect(false);
    setShowEditStuModal(false);
  }

  async function handleEditStorageUnit() {
    if (barcode.setScanModeConnect) barcode.setScanModeConnect(true);
    setShowEditStuModal(true);
  }

  async function handleSubmitEditStorageUnit(newStorageUnit) {
    try {
      let result;
      if (newStorageUnit?._id) {
        setEditStuModalLoading({ loading: true, message: "Lagereinheit wird gespeichert..." });
        result = await warehouseService.updateStorageUnit(newStorageUnit._id, newStorageUnit);
        setStorageUnit(newStorageUnit);
      } else {
        setEditStuModalLoading({
          loading: true,
          message: "Lagereinheit wird erstellt und ein Label wird ausgedruckt...",
        });
        result = await warehouseService.createStorageUnit(newStorageUnit);
        setStorageUnit(newStorageUnit);
        if (!result) {
          setEditStuModalLoading({ loading: false, message: "" });
          return;
        }
      }

      setEditStuModalLoading({ loading: false, message: "" });
      barcode.setScanModeConnect(false);
      setShowEditStuModal(false);
      if (result && result.code === "storageUnitDeleted") {
        history.push("/warehouse/");
        toast("Die leere Lagereinheit wurde automatisch entfernt");
      } else if (result && result.storageUnitId) {
        history.push(`/warehouse/storageunit/${result.storageUnitId}`);
        toast(`Lagereinheit ${result.storageUnitId} erfolgreich ${newStorageUnit?._id ? "aktualisiert" : "erstellt"}.`);
      }
    } catch (err) {
      setEditStuModalLoading({ loading: false, message: "" });
    }
  }

  return (
    <Fragment>
      {category === "location" ? (
        <LocationScreen
          id={id}
          data={location}
          onStore={handleConnect}
          modalActive={showConnectModal}
          onReload={loadContent}
          onAdjustStock={handleAdjustStock}
        />
      ) : category === "storageunit" ? (
        id === "new" ? (
          <NewStu onCreate={handleEditStorageUnit} />
        ) : (
          <StorageUnitScreen
            id={id}
            data={storageUnit}
            onEdit={handleEditStorageUnit}
            onStore={handleConnect}
            onReceive={handleReceiveModalShow}
            onGroup={handleGroupStorageUnits}
            modalActive={showConnectModal}
            onReload={loadContent}
          />
        )
      ) : category === "storagegroup" ? (
        <StorageGroupScreen
          id={id}
          data={storageGroup}
          onStore={handleConnect}
          onEdit={handleGroupStorageUnits}
          setGroupContents={setGroupContents}
          modalActive={showConnectModal}
          onReload={loadContent}
        />
      ) : category === "product" ? (
        <ProductScreen
          id={id}
          data={product}
          locations={productLocations}
          onStore={handleConnect}
          modalActive={showConnectModal}
        />
      ) : (
        <WarehouseList showConfirmModal={showConfirmModal} />
      )}
      {showConnectModal && (
        <AddModal
          show={showConnectModal}
          onHide={handleHideConnectModal}
          onSubmit={handleConfirmConnect}
          category={category}
          baseObject={
            category === "location"
              ? location
              : category === "storageunit"
              ? storageUnit
              : category === "storagegroup"
              ? storageGroup
              : category === "product"
              ? product
              : null
          }
        />
      )}
      <GroupModal
        storageGroup={storageGroup}
        loading={groupModalLoading}
        show={showGroupModal}
        onHide={handleHideGroupModal}
        onSubmit={handleSubmitGroupStorageUnits}
        onReceive={handleReceiveModalShow}
        showConfirmModal={showConfirmModal}
        groupContents={groupContents}
        setGroupContents={setGroupContents}
      />
      <EditStuModal
        storageUnit={storageUnit}
        setStorageUnit={setStorageUnit}
        loading={editStuModalLoading}
        show={showEditStuModal}
        onHide={handleHideEditStuModal}
        onSubmit={handleSubmitEditStorageUnit}
        showConfirmModal={showConfirmModal}
        showQuantityModal={showQuantityModal}
      />
    </Fragment>
  );
};

export default Warehouse;
