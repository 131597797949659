import React, { Component } from "react";
import { toast } from "react-toastify";
import RefundTable from "./RefundTable";
import Pagination from "../common/Pagination";
import SimpleModal from "../common/SimplePromiseModal";
import { createModal } from "react-modal-promise";
import QuantityModal from "../common/QuantityModal";
import returnsService from "../../services/returnsService";
import OrdersService from "../../services/ordersService";
import ImageService from "../../services/imageService";
import logger from "../../services/logService";
import SearchForm from "../common/SearchForm";
import Loader from "../common/Loader";
import Joi from "joi-browser";
import { timeout } from "../../utils/asyncUtils";
import _ from "lodash";
import { isSafeToClick } from "../../utils/helper";

class Refund extends Component {
  _isMounted = false;
  state = {
    pageSize: 10,
    currentPage: 1,
    returns: [],
    billbeeOrders: [], //Corresponding indexed to returns
    sortColumn: { path: "receive.date", order: "asc" },
    areYouSureModalShow: false,
    searchQuery: "",
    activeOrderReturn: {},
    modalLoading: false,
    listLoading: true,
    imageDb: {
      loading: true,
      data: {},
    },
    modalStatusText: "",
  };

  processCounter = 0;

  showModal = createModal(SimpleModal);
  showQuantityModal = createModal(QuantityModal);

  //SETUP
  async componentDidMount() {
    this._isMounted = true;
    await Promise.all([this.fetchReceivedItems(), this.fetchImageDb()]);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchReceivedItems = async () => {
    try {
      const { currentPage, pageSize, searchQuery, sortColumn } = this.state;
      const sortBy = sortColumn.order === "asc" ? sortColumn.path : "-" + sortColumn.path;
      const { returns, totalCount } = await returnsService.getReceivedReturns(
        sortBy,
        currentPage,
        pageSize,
        searchQuery,
        true,
        false,
        false,
        true,
        false,
        true
      );

      if (this._isMounted) this.setState({ listLoading: false, returns, totalCount }, this.fetchBillbeeOrders);
    } catch (err) {
      toast.error("Fehler beim Aktualisieren der Liste, bitte lade die Seite manuell neu.");
      logger.error("Error refreshing the returns data list.", err);
    }
  };

  fetchImageDb = async () => {
    let imageDb = { ...this.state.imageDb };
    if (imageDb.loading === false) return;

    //if cache is not available
    imageDb = await ImageService.getImageDb();
    if (!imageDb.data) return;

    imageDb.loading = false;
    if (this._isMounted) this.setState({ imageDb });
  };

  fetchBillbeeOrders = async () => {
    const { returns } = this.state;
    if (!returns) return;
    const billbeeOrders = [];
    for (const orderReturn of returns) {
      const billbeeId = orderReturn[0].order.billbeeId;
      if (billbeeId) {
        const orderResult = await OrdersService.getOrderDetails(billbeeId);
        if (orderResult.status === 200) {
          billbeeOrders.push(orderResult.data);
          this.setState({ billbeeOrders });
        }
      }
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page }, this.fetchReceivedItems);
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn }, this.fetchReceivedItems);
  };

  handleHideDialog = () => {
    this.setState({ areYouSureModalShow: false, modalLoading: false });
  };

  handleDelete = orderReturn => {
    this.setState({
      areYouSureModalShow: true,
      deleteItem: orderReturn,
    });
  };

  handleSearch = searchQuery => {
    this.setState({ currentPage: 1, searchQuery }, this.fetchReceivedItems);
  };

  handleConfirmDelete = async () => {
    const originalReturns = this.state.returns;
    const orderReturn = this.state.deleteItem;

    try {
      this.setState({ modalLoading: true });
      await returnsService.deleteReceivedReturn(orderReturn._id);
      await this.fetchReceivedItems();
      this.setState({ areYouSureModalShow: false, modalLoading: false });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) toast.error("This return has already been deleted");
      this.setState({ returns: originalReturns, modalLoading: false });
    }
  };

  //FILTERING AND PAGINATION
  getPageData = () => {
    const { returns } = this.state;
    return { pageData: returns };
  };

  handleRefund = async (orderReturns, amount) => {
    if (orderReturns.every(o => o.product.price)) {
      //show Modal
      await this.showModal({
        title: "Erstattungsdetails",
        confirmText: "Erstattung ausführen" + isSafeToClick(false),
        spinner: true,
        spinnerText: this.state.modalStatusText,
        onLoadDescription: () => this.loadOrderDetails(orderReturns, amount),
        primaryFunction: async setModalTextFunc => await this.refundAndReload(orderReturns, amount, setModalTextFunc),
      });
    } else {
      toast.error("Es sind nicht genügend Daten verfügbar, um diese Rücksendung automatisch zu erstatten.");
    }
  };

  refundAndReload = async (orderReturns, amount, setModalTextFunc) => {
    //Do the refund
    const timestamp = await returnsService.refund(orderReturns, amount);

    if (timestamp) {
      for (let i = 0; i < 4000; i++) {
        await timeout(1000);
        const { data } = await returnsService.getRefundStatus(timestamp);

        if (data?.status === "done") {
          setModalTextFunc("");
          toast.success(`Gutschrift über ${amount.toFixed(2).replace(".", ",")}€ wurde erfolgreich ausgeführt.`);
          break;
        }

        setModalTextFunc(data.message);

        if (data?.status === "error") {
          toast.error(data?.message, { autoClose: false, closeButton: true, closeOnClick: false });
          break;
        }
      }
    }
    await this.fetchReceivedItems();
  };

  handleChangeNote = async (orderReturns, notes) => {
    const returns = _.cloneDeep(this.state.returns);
    const returnsCopy = _.cloneDeep(this.state.returns);

    const refundNotesItems = returns.find(o => o[0]._id === orderReturns[0]._id);
    refundNotesItems.forEach(refundItem => _.set(refundItem, "refund.refundNotes", notes));

    this.setState({ returns });
    for (const refundItem of refundNotesItems) {
      try {
        await returnsService.updateRefundDetails(refundItem._id, { refundNotes: notes });
      } catch (err) {
        this.setState({ returns: returnsCopy });
        toast.error("Fehler beim aktualisieren der Rücksendung." + err.reponse.data);
      }
    }
  };

  loadOrderDetails = async (orderReturns, refundAmount) => {
    try {
      const { billbeeId } = orderReturns[0].order;
      const fragment = {
        PaymentReference: true,
        TotalCost: true,
        TaxRate1: true,
        Seller: {
          Platform: true,
        },
        OrderNumber: true,
        SellerComment: true,
        cacheUpdatedAt: true,
        BillBeeOrderId: true,
        BillbeeId: true,
      };
      const orderResult = await OrdersService.getOrderDetails(billbeeId, undefined, fragment);

      const { data: order } = orderResult;
      const paymentId = order ? order.PaymentReference : null;
      const totalOrderAmount = order ? order.TotalCost : null;
      const taxRate = order ? order.TaxRate1 : null;
      const amazonOrder = order && order.Seller.Platform === "Amazon";
      const shopifyOrder = order && order.Seller.Platform === "Shopify";
      const shopifyId = order ? order.BillbeeId : null;
      const orderNumber = order ? order.OrderNumber : null;
      const notes = orderReturns ? orderReturns.map(orderReturn => orderReturn.notes || "").join(", ") : "";
      const billbeeNotes = order ? order.SellerComment : "";

      const description = getModalDescription(
        paymentId,
        amazonOrder,
        shopifyOrder,
        shopifyId,
        orderNumber,
        notes,
        billbeeNotes,
        totalOrderAmount,
        taxRate
      );

      return description;
    } catch (err) {
      logger.error("Could not get order details when processing refund", err);
      return `<div class="text-center w-100">Die Details der Bestellung konnten nicht geladen werden, versuche es später erneut.</div>`;
    }

    function getModalDescription(
      paymentId,
      amazonOrder,
      shopifyOrder,
      shopifyId,
      orderNumber,
      notes,
      billbeeNotes,
      totalOrderAmount,
      taxRate
    ) {
      return paymentId
        ? `<div class="text-center w-100">Um die Erstattung auszuführen, prüfe und bestätige bitte den folgenden Erstattungsbetrag: <br /><br />
        <span class="display-4 text-success">${refundAmount.toFixed(2).replace(".", ",")}€</span><br />
        <span>von ${totalOrderAmount.toFixed(2).replace(".", ",")} € Gesamtbestellsumme </span><br /><br />
        ${billbeeNotes ? `<span class="alert alert-success d-block">Billbee Notizen: "${billbeeNotes}"</span>` : ""}    
        ${
          notes
            ? `<span class="alert alert-warning d-block">Folgender Hinweis wurde hinterlassen: "${notes}"</span>`
            : ""
        }
        ${
          shopifyOrder
            ? `Zur Zahlung in Shopify:<br /><a href="https://admin.shopify.com/store/formbench/orders/${shopifyId}" target="_blank">${shopifyId}</a></div>`
            : `Zur Zahlung in Unzer:<br /><a href="https://insights.unzer.com/merchant/5928/orders?channels=16057,16072,17177&core_short_id=${paymentId}" target="_blank">${paymentId}</a></div>`
        }
        `
        : amazonOrder && orderNumber
        ? `<div class="text-center w-100">Die Erstattung muss über Amazon Sellercentral ausgeführt werden.<br /><br />
          <span class="display-4 text-success">${refundAmount.toFixed(2).replace(".", ",")}€</span><br />
          <span class="text-success">${(refundAmount / (taxRate / 100 + 1))
            .toFixed(2)
            .replace(".", ",")}€ (Netto bei ${taxRate.toFixed(2).replace(".", ",")}% USt.)</span><br /><br />
        <span>von ${totalOrderAmount.toFixed(2).replace(".", ",")} € Gesamtbestellsumme </span><br /><br />
        ${billbeeNotes ? `<span class="alert alert-success d-block">Billbee Notizen: "${billbeeNotes}"</span>` : ""}
        ${
          notes
            ? `<span class="alert alert-warning d-block">Folgender Hinweis wurde hinterlassen: "${notes}"</span>`
            : ""
        }
          Bitte folge diesem Link, um zur Bestellung bei Amazon zu gelangen:<br />
          <a href="https://sellercentral.amazon.de/orders-v3/order/${orderNumber}" target="_blank">${orderNumber}</a></div>`
        : "Diese Erstattung muss manuell getätigt werden. Die Zahlungsreferenz konnte nicht ausgelesen werden.";
    }
  };

  handleMarkRefunded = async orderReturns => {
    // Mark as refunded
    const result = await this.showModal({
      title: "Als erstattet markieren?",
      confirmText: "Erstattet markieren" + isSafeToClick(true),
      description: "Willst du die Rücksendung manuell als erstattet markieren?",
    });
    if (!result) return;

    this.processCounter++;

    const previousReturns = [...this.state.returns];
    this.setState({
      returns: [
        ...this.state.returns.filter(returnArray => !returnArray.every(r => orderReturns.find(o => o._id === r._id))),
      ],
    });

    try {
      await returnsService.markAsRefunded(orderReturns);
      await timeout(1000);
      this.processCounter--;
      if (this.processCounter <= 0) {
        this.processCounter = 0;
        await this.fetchReceivedItems();
      }
    } catch (err) {
      this.processCounter--;
      this.setState({ returns: previousReturns });
    }
  };

  handleChangeDeduction = async orderReturn => {
    //Show Quantity Modal
    const refundDeduction = await this.showQuantityModal({
      title: "Abzug von Gutschrift?",
      description: "Soll von der Gutschrift etwas abgezogen werden? (Betrag in Euro)",
      confirmText: "Speichern" + isSafeToClick(true),
      initialQuantity: (orderReturn.refund && orderReturn.refund.refundDeduction) || 0,
      schema: {
        quantity: Joi.number().min(0).required().label("Abzug"),
      },
    });

    if (!refundDeduction && refundDeduction !== 0) return;

    //Save values
    await returnsService.updateRefundDetails(orderReturn._id, { refundDeduction });
    await this.fetchReceivedItems();
  };

  handleToggleRefundRequired = async orderReturn => {
    const returns = _.cloneDeep(this.state.returns);
    const returnsCopy = _.cloneDeep(this.state.returns);

    let toggleItem;
    returns.find(o =>
      o.forEach(i => {
        if (i._id === orderReturn._id) {
          toggleItem = i;
          return true;
        } else return false;
      })
    );
    _.set(
      toggleItem,
      "refund.refundRequired",
      !(toggleItem.refund && toggleItem.refund.refundRequired === false ? false : true)
    );
    this.setState({ returns });
    try {
      await returnsService.updateRefundDetails(toggleItem._id, { refundRequired: toggleItem.refund.refundRequired });
    } catch (err) {
      this.setState({ returns: returnsCopy });
      toast.error("Fehler beim aktualisieren der Rücksendung." + err.reponse.data);
    }
  };

  render() {
    const { currentPage, pageSize, sortColumn, totalCount, listLoading, billbeeOrders } = this.state;
    const { pageData } = this.getPageData();

    return (
      <React.Fragment>
        <div className="container-fluid spacing-top">
          <div className="row justify-content-end align-items-center">
            <div className="col-12 col-lg-6 col-xl-8">
              <h1 className="mb-3">Erstattungen ausführen</h1>
              <p>Folgende Rücksendungen sind angekommen und müssen noch erstattet werden.</p>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
              <SearchForm
                placeholder="Suche..."
                onSubmit={this.handleSearch}
                onReset={this.handleSearch}
                submitLabel="Suchen"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              {pageData.length ? (
                <RefundTable
                  orderReturns={pageData}
                  billbeeOrders={billbeeOrders}
                  onSort={this.handleSort}
                  onDeleteMultiple={this.handleDeleteMultiple}
                  sortColumn={sortColumn}
                  onRefund={this.handleRefund}
                  onMarkRefunded={this.handleMarkRefunded}
                  onChangeDeduction={this.handleChangeDeduction}
                  onChangeNote={this.handleChangeNote}
                  onToggleRefundRequired={this.handleToggleRefundRequired}
                />
              ) : !listLoading ? (
                <div className="text-center m-auto py-5">
                  <h3>Keine offenen Erstattungen</h3>
                </div>
              ) : (
                <div
                  style={{
                    height: "30vh",
                    display: "flex",
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {totalCount && !listLoading ? (
                <Pagination
                  currentPage={currentPage}
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Refund;
