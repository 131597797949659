import HttpsRedirect from "react-https-redirect";
import { BrowserRouter, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import * as serviceWorker from "./serviceWorker";
import logger from "./services/logService";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "font-awesome/css/font-awesome.css";

logger.init();

ReactDOM.render(
  process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging" ? (
    <HttpsRedirect>
      <BrowserRouter>
        <Route path="/" component={App} />
      </BrowserRouter>
    </HttpsRedirect>
  ) : (
    <BrowserRouter>
      <Route path="/" component={App} />
    </BrowserRouter>
  ),
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
