import React from "react";

const Welcome = () => {
  return (
    <div className="container-fluid spacing-top">
      <h1 className="text-center">Willkommen</h1>
      <p className="text-center">Wähle oben aus dem Menü, wohin du navigieren möchtest.</p>
    </div>
  );
};

export default Welcome;
