import React, { Component } from "react";
import Card from "../common/Card";
import PropTypes from "prop-types";
import ProductDetail from "./ProductDetail";
import Loader from "../common/Loader";
import Button from "../common/Button";
import { isSafeToClick } from "../../utils/helper";

class ReturnCard extends Component {
  componentDidMount() {
    const { onLoadDetails, orderReturn } = this.props;
    onLoadDetails(orderReturn);
  }

  render() {
    const { orderReturn, orderDetail, imageDb, onReceive, onReceiveOther, receivedReturns, preannouncedReturns } =
      this.props;
    const { name, orderNumber } = orderReturn;
    const isCanceled = orderDetail ? orderDetail.State === 8 || orderDetail.State === 6 : false;

    return (
      <Card
        title={name}
        subtitle={orderNumber}
        description={
          !orderDetail ? (
            <Loader />
          ) : (
            <React.Fragment>
              <ProductDetail
                orderDetail={orderDetail}
                imageDb={imageDb}
                buttons={[
                  {
                    _id: "receiveButton",
                    title: "<i class='fas fa-check'></i>" + isSafeToClick(true),
                    onClick: onReceive,
                    primary: true,
                    top: false,
                    right: true,
                    circle: false,
                  },
                ]}
                receivedReturns={receivedReturns}
                preannouncedReturns={preannouncedReturns}
              />
              {!isCanceled && (
                <Button
                  title={"Anderes Produkt" + isSafeToClick(true)}
                  onClick={() => onReceiveOther(orderDetail)}
                  classes="btn-block"
                />
              )}
            </React.Fragment>
          )
        }
      />
    );
  }
}

ReturnCard.propTypes = {
  orderReturn: PropTypes.object.isRequired,
  imageDb: PropTypes.object.isRequired,
};

export default ReturnCard;
