import React, { Fragment, useEffect, useState } from "react";
import warehouseService from "../../services/warehouseService";
import WarehouseTable from "./WarehouseTable";
import { isSafeToClick } from "../../utils/helper";
import SearchForm from "../common/SearchForm";
import Pagination from "../common/Pagination";

const WarehouseList = ({ showConfirmModal }) => {
  const [storageUnits, setStorageUnits] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "receiveDate", order: "asc" });
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [itemsTotal, setItemsTotal] = useState();

  const pageSize = 50;

  useEffect(() => {
    (async () => {
      const { data, totalCount } = await warehouseService.loadStorageUnits(page, pageSize, sortColumn, searchQuery);
      setStorageUnits(data);
      setItemsTotal(totalCount);
    })();
  }, [page, sortColumn, searchQuery]);

  const handleSort = sortBy => {
    setSortColumn(sortBy);
  };

  const handleSearch = input => {
    setPage(1);
    setSearchQuery(input);
  };

  const handleMarkAsRemoved = async storageUnit => {
    const storageUnitsBackup = storageUnits;
    const result = await showConfirmModal({
      title: "Einheit entfernen?",
      description: `Soll die Lagereinheit ${storageUnit.storageUnitId} aus dem Lager entfernt werden?`,
      confirmText: "Entfernen" + isSafeToClick(true),
      cancelText: "Abbrechen",
    });
    if (!result) return;
    try {
      setStorageUnits(storageUnits.filter(s => s.storageUnitId !== storageUnit.storageUnitId));
      await warehouseService.removeStorageUnitFromWarehouse(storageUnit.storageUnitId);
    } catch (err) {
      setStorageUnits(storageUnitsBackup);
    }
  };

  return (
    <Fragment>
      <div className="container spacing-top">
        <h2>Lagerübersicht</h2>
        <div className="row">
          <div className="col my-auto">
            <SearchForm placeholder="Suche..." submitLabel="Suchen" onSubmit={handleSearch} onReset={handleSearch} />
            <WarehouseTable
              data={storageUnits}
              sortColumn={sortColumn}
              onSort={handleSort}
              onMarkAsRemoved={handleMarkAsRemoved}
            />
            <Pagination currentPage={page} itemsCount={itemsTotal} pageSize={pageSize} onPageChange={setPage} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WarehouseList;
