import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const WarehouseMenu = ({ taskCount, onClose }) => {
  return (
    <Fragment>
      <NavLink className="nav-link my-auto" to="/tasks" onClick={onClose}>
        <i className="fas fa-check-circle my-2 mr-1"></i>
        <span className="my-2" style={{ display: "inline-block" }}>
          Aufgaben
        </span>
        {taskCount ? <span className="taskBadge">{taskCount}</span> : ""}
      </NavLink>
      <NavLink className="nav-link my-auto" to="/receive" onClick={onClose}>
        <i className="fas fa-barcode mr-2"></i>Rücksendungen erfassen
      </NavLink>
      <NavLink className="nav-link my-auto" to="/refurbish" onClick={onClose}>
        <i className="fas fa-box-open mr-2"></i>Rücksendungen aufbereiten
      </NavLink>
      <NavLink className="nav-link my-auto" to="/remissions" onClick={onClose}>
        <i className="fab fa-amazon mr-2"></i>Remissionen aufbereiten
      </NavLink>
    </Fragment>
  );
};

export default WarehouseMenu;
