import React, { Fragment } from "react";
import LoadingScreen from "./LoadingScreen";
import Button from "../common/Button";
import SubmitButton from "./SubmitButton";
import StorageUnitContent from "./StorageUnitContent";
import { useHistory } from "react-router";
import warehouseService from "../../services/warehouseService";
import { isSafeToClick } from "../../utils/helper";

const StorageUnitScreen = ({ id, data, onEdit, onStore, onReceive, onGroup, onReload }) => {
  const { error, location, receiveDate, category, parentGroup, storageUnitId } = data || {};

  const history = useHistory();
  const navigateToParentGroup = () => {
    if (parentGroup.storageGroupId) history.push(`/warehouse/storagegroup/${parentGroup.storageGroupId}`);
  };

  const removeFromGroup = async () => {
    await warehouseService.removeFromGroup(storageUnitId, parentGroup);
    onReload();
  };

  const handleRemoveFromLocation = async () => {
    await warehouseService.removeStorageUnitFromLocation(storageUnitId, location._id);
    onReload();
  };

  return (
    <Fragment>
      {!Object.keys(data || {}).length ? (
        <LoadingScreen text="Lagereinheit wird geladen..." id={id} />
      ) : error ? (
        <div className="container-fluid spacing-top">
          <h4 className="text-light-gray">Lagereinheit</h4>
          <h3 className="mb-0">
            <b>{error === 404 ? "Nicht gefunden" : "Server Fehler"}</b>
          </h3>
          <p className="mb-5">
            {error === 404
              ? `Eintrag für ${id} konnten nicht gefunden werden, stelle sicher, dass der Barcode lesbar ist und korrekt gescannt wurde.`
              : "Bitte wende dich an den Administrator"}
          </p>
        </div>
      ) : (
        <div className="splitview">
          <StorageUnitContent
            id={id}
            data={data}
            onEdit={onEdit}
            onReceive={onReceive}
            navigateToParentGroup={navigateToParentGroup}
          />
          <footer className="container-fluid">
            {!receiveDate ? (
              <Button
                title={"Ware annehmen" + isSafeToClick(true)}
                onClick={() => onReceive()}
                outline={false}
                color="primary"
                classes="btn-block"
              />
            ) : (
              <Fragment>
                {category === "box" && !parentGroup ? (
                  <SubmitButton
                    subject={category}
                    verb="auf Palette gruppieren"
                    icon="boxes"
                    onSubmit={() => onGroup([data])}
                    buttonColor="secondary"
                  />
                ) : parentGroup ? (
                  <SubmitButton
                    subject={category}
                    verb="aus Gruppe entfernen"
                    icon="sign-out-alt"
                    onSubmit={removeFromGroup}
                    confirmationNeeded={true}
                    confirmationTitle="Aus Gruppe entfernen?"
                    confirmationDescription={`Bist du sicher, dass du die Einheit ${data.storageUnitId} aus der Gruppe entfernen möchtest?`}
                    confirmText="Entfernen"
                    buttonColor="secondary"
                  />
                ) : (
                  ""
                )}
                {parentGroup ? (
                  <SubmitButton
                    subject=""
                    verb="Zur Gruppe wechseln"
                    icon="angle-double-left"
                    onSubmit={navigateToParentGroup}
                    buttonColor={category === "box" ? "secondary" : "primary"}
                  />
                ) : (
                  <Fragment>
                    {location ? (
                      <SubmitButton
                        subject={category}
                        verb="auslagern"
                        icon="sign-out-alt"
                        onSubmit={handleRemoveFromLocation}
                        confirmationNeeded={true}
                        confirmationDescription={`Bist du sicher, dass du die Einheit ${storageUnitId} auslagern möchtest?`}
                        confirmationTitle={`${storageUnitId} auslagern?`}
                        confirmText="Auslagern"
                        buttonColor={"secondary"}
                      />
                    ) : (
                      ""
                    )}
                    <SubmitButton
                      subject={category}
                      verb={location ? "umlagern" : "einlagern"}
                      icon="pallet"
                      onSubmit={onStore}
                      buttonColor="primary"
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
          </footer>
        </div>
      )}
    </Fragment>
  );
};

export default StorageUnitScreen;
