import React, { Fragment } from "react";
import LoadingScreen from "./LoadingScreen";
import ProductContent from "./ProductContent";
import SubmitButton from "./SubmitButton";

const ProductScreen = ({ id, data, locations, modalActive, onStore, onAdjustStock }) => {
  const { error, ean } = data || {};

  return (
    <Fragment>
      {!Object.keys(data || {}).length ? (
        <LoadingScreen text="Produkt wird geladen..." id={id} />
      ) : error ? (
        <div className="container-fluid spacing-top">
          <h4 className="text-light-gray">Produkt</h4>
          <h3 className="mb-0">
            <b>{error === 404 ? "Nicht gefunden" : "Server Fehler"}</b>
          </h3>
          <p className="mb-5">
            {error === 404
              ? `Eintrag für ${ean} konnten nicht gefunden werden, stelle sicher, dass der Barcode lesbar ist und korrekt gescannt wurde.`
              : "Bitte wende dich an den Administrator"}
          </p>
        </div>
      ) : (
        <div className="splitview">
          <ProductContent data={data} locations={locations} />
          <footer className="container-fluid">
            <SubmitButton subject="Produkt" verb="einlagern" icon="pallet" onSubmit={onStore} buttonColor="primary" />
          </footer>
        </div>
      )}
    </Fragment>
  );
};

export default ProductScreen;
