import React from "react";
import Form from "../common/Form";
import Joi from "joi-browser";
import * as userService from "../../services/userService";
import { toast } from "react-toastify";

class RegisterForm extends Form {
  state = {
    data: { username: "", password: "", name: "", roles: [] },
    errors: {}
  };

  schema = {
    username: Joi.string().required().label("Username").email(),
    password: Joi.string().required().label("Password").min(5).max(255),
    name: Joi.string().required().label("Name"),
    roles: Joi.array()
      .required()
      .label("Rollen")
      .items(Joi.string().valid("returns", "products", "shipments", "storageUnits", "supplier", "tasks", "warehouse"))
  };

  doSubmit = async () => {
    try {
      await userService.register(this.state.data);
      toast("Benutzer erfolgreich angelegt");
      // auth.loginWithJwt(response.headers["x-auth-token"]);
      window.location = "/register";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        console.log(ex.response);
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="container-fluid spacing-top">
        <h1 className="mb-5">Registrieren</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "E-Mail Adresse", "name@domain.de")}
          {this.renderInput(
            "password",
            "Password",
            "Passwort muss Groß- und Kleinbuchstaben sowie Sonderzeichen und Zahlen enthalten.",
            "password"
          )}
          {this.renderInput("name", "Name", "Vorname Nachname")}
          {this.renderCheckboxGroup(
            "roles",
            "Benutzerrollen",
            [
              { _id: "returns", name: "Retouren" },
              { _id: "products", name: "Produkte" },
              { _id: "shipments", name: "Lieferungen" },
              { _id: "storageUnits", name: "Lagereinheiten" },
              { _id: "supplier", name: "Lieferant" },
              { _id: "warehouse", name: "Lagerverwaltung" },
              { _id: "tasks", name: "Aufgabenliste" }
            ],
            "mb-4"
          )}
          {this.renderButton("Registrieren")}
        </form>
      </div>
    );
  }
}

export default RegisterForm;
