import { useEffect, useState } from "react";
import productDocsService from "../../services/docsService";

const ProductDocsDisplay = () => {
  const [docs, setDocs] = useState([]); // Initialize docs as an empty array
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State to manage errors

  useEffect(() => {
    // Function to fetch product docs
    const fetchProductDocs = async () => {
      try {
        const docsData = await productDocsService.getProductDocs();
        setDocs(docsData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDocs(); // Call the fetch function
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return (
    <div className="container spacing-top">
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : !docs || docs.length === 0 ? (
        <div>Gebrauchsanweisungen nicht verfügbar</div>
      ) : (
        <div>
          <h1>Gebrauchsanweisungen</h1>
          <br />

          {docs.map((doc, index) => (
            <div className="row mb-1 bg-light p-3" key={index}>
              <a href={doc.url} target="_blank" rel="noopener noreferrer" className="col my-auto">
                <i className="fa fa-external-link mr-2 text-black"></i> {doc.title}
              </a>
            </div>
          ))}

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default ProductDocsDisplay;
