import { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./components/common/ProtectedRoute";
import ProtectedRouteAdmin from "./components/common/ProtectedRouteAdmin";
import { ToastContainer } from "react-toastify";
import logger from "./services/logService";
import MyNavbar from "./components/navigation/Navbar";
import Receive from "./components/receive/Receive";
import ReturnsList from "./components/returnsList/ReturnsList";
import Refund from "./components/refund/Refund";
import Refurbish from "./components/refurbish/Refurbish";
import Remissions from "./components/remissions/Remissions";
import RegisterForm from "./components/login/RegisterForm";
import LoginForm from "./components/login/LoginForm";
import Logout from "./components/login/Logout";
import NotFound from "./components/pages/NotFound";
import DashboardWarehouse from "./components/dashboard/DashboardWarehouse";
import InternalReasons from "./components/internalreasons/InternalReasons";
import CreateLabels from "./components/createlabels/CreateLabels";
import Purchasing from "./components/purchasing/Purchasing";
import Warehouse from "./components/warehouse/Warehouse";
import ParentProducts from "./components/parentProducts/ParentProductsList";
import Shipments from "./components/shipments/Shipments";
import Welcome from "./components/welcome/Welcome";
import Barcode from "./components/common/Barcode";
import UpdateNotification from "./components/update/UpdateNotification";
import barcodeService, { BarcodeProvider } from "./services/barcodeService";
import { LocalizationProvider } from "./services/localizationService";
import imageService from "./services/imageService";
import { getCurrentUser } from "./services/authService";
import { getTasks } from "./services/tasksService";
import ModalContainer from "react-modal-promise";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import moment from "moment-timezone";
import localization from "moment/locale/de";
import SpecialCutting from "./components/specialcutting/SpecialCutting";
import ProductDocs from "./components/products/ProductDocs";


const language = { current: "de_DE", options: ["de_DE", "en_GB"] };
moment.updateLocale(language.current, localization);
let interval;

class App extends Component {
  state = {
    user: null,
    tasks: [],
    loadingTasks: true,
    taskBadge: 0,
    barcode: {
      scan: {
        type: "return",
        data: "001383",
      },
      scanModeConnect: false,
      setScanModeConnect: null,
    },
  };

  componentDidMount() {
    const user = getCurrentUser();
    if (user) this.setState({ user }, this.initializeApp);

    const barcode = { ...this.state.barcode };
    barcode.setScanModeConnect = this.setScanModeConnect;
    this.setState({ barcode });
  }

  initializeApp() {
    this.loadTasks();
    this.loadImageDb();

    interval = setInterval(() => {
      this.loadTasks(false);
    }, 300000);
  }

  componentWillUnmount() {
    clearInterval(interval);
  }

  loadTasks = (showLoadingSpinner = true) => {
    const { user } = this.state;
    if (user && (user.isAdmin || (user.roles && user.roles.indexOf("Brun") !== -1))) {
      (async () => {
        try {
          console.log("Loading Tasks...");
          if (showLoadingSpinner) this.setState({ loadingTasks: true });
          const unsortedTasks = await getTasks();
          const tasks = sortTasks(unsortedTasks);
          this.setTasks(tasks);
          this.setState({ loadingTasks: false });
          console.log("Finished Loading Tasks");
        } catch (err) {
          logger.log("Could not load tasks", err);
        }
        //sorting
        function sortTasks(unsortedTasks) {
          const sortedTasks = _.orderBy(unsortedTasks, ["completed", "due_on", "name"], ["asc", "asc", "asc"]);
          return sortedTasks;
        }
      })();
    }
  };

  setTasks = tasks => {
    let taskBadge = 0;
    tasks.forEach(t => {
      if (!t.completed) taskBadge += 1;
    });
    this.setState({ tasks, taskBadge });
  };

  async loadImageDb() {
    try {
      await imageService.getImageDb();
    } catch (err) {
      logger.log("Error loading image database: ", err);
    }
  }

  setScanModeConnect = scanModeConnect => {
    const barcode = { ...this.state.barcode };
    barcode.scanModeConnect = scanModeConnect;
    this.setState({ barcode });
  };

  handleScan = async data => {
    const { history } = this.props;
    const barcode = { ...this.state.barcode };
    if (!barcode.scanModeConnect) {
      const sanitizedData = data.replaceAll(" ", "");
      barcode.scan = await barcodeService.route(encodeURIComponent(sanitizedData), history);
      this.setState({ barcode });
    }
  };

  render() {
    const { taskBadge, tasks, user, barcode, loadingTasks } = this.state;
    return (
      <LocalizationProvider value={language}>
        <ModalContainer />
        <div className="splitview-fullscreen">
          <UpdateNotification />
          <ToastContainer autoClose={10000} closeOnClick />
          {!barcode.scanModeConnect && <Barcode onScan={this.handleScan} />}
          <BarcodeProvider value={barcode}>
            <MyNavbar user={user} taskCount={taskBadge} />
            <main>
              <Switch>
                <Route path="/login" component={LoginForm} />
                <Route path="/logout" component={Logout} />
                <ProtectedRouteAdmin path="/register" component={RegisterForm} />
                <ProtectedRoute path="/receive" component={Receive} />
                <ProtectedRoute path="/returns" component={ReturnsList} />
                <ProtectedRoute path="/refurbish/:id" component={Refurbish} />
                <ProtectedRoute path="/refund" component={Refund} />
                <ProtectedRoute path="/refurbish" component={Refurbish} />
                <ProtectedRoute path="/remissions" component={Remissions} />
                <ProtectedRoute path="/warehouse/:category/:id?/:single?" component={Warehouse} />
                <ProtectedRoute path="/warehouse" exact component={Warehouse} />
                <ProtectedRoute path="/product-docs" exact component={ProductDocs} />
                <ProtectedRoute path="/products" exact component={ParentProducts} />
                <ProtectedRoute path="/shipments" exact component={Shipments} />
                <ProtectedRoute path="/zuschnitt" exact component={SpecialCutting} />
                <ProtectedRoute path="/welcome" exact component={Welcome} />
                <ProtectedRoute
                  path="/tasks"
                  render={props => (
                    <DashboardWarehouse
                      tasks={tasks}
                      setTasks={this.setTasks}
                      loadTasks={this.loadTasks}
                      loading={loadingTasks}
                      {...props}
                    />
                  )}
                />
                <ProtectedRouteAdmin path="/internalreasons" component={InternalReasons} />
                <ProtectedRouteAdmin path="/createlabels" component={CreateLabels} />
                <ProtectedRouteAdmin path="/purchasing" component={Purchasing} />
                {user && user.isAdmin && <Redirect path="/" exact to="/purchasing" />}
                {user && user.roles && user.roles.indexOf("Brun") !== -1 && <Redirect path="/" exact to="/tasks" />}
                {user && user.roles && user.roles.indexOf("supplier") !== -1 && (
                  <Redirect path="/" exact to="/shipments" />
                )}
                {user && <Redirect path="/" exact to="/welcome" />}
                <Redirect path="/" exact to="/login" />
                <Route path="/not-found" component={NotFound} />
                <Redirect to="/not-found" />
              </Switch>
            </main>
          </BarcodeProvider>
        </div>
      </LocalizationProvider>
    );
  }
}

export default App;
