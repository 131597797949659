import React, { Fragment, useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";

const UserMenu = ({ user, onClose }) => {
  //   const toggle = this.toggle.bind(this);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // function onMouseEnter() {
  //   setDropdownOpen(true);
  // }

  // function onMouseLeave() {
  //   setDropdownOpen(false);
  // }
  function toggleDropdown() {
    setDropdownOpen(!dropdownOpen);
  }

  return (
    <NavDropdown
      className="d-inline-block"
      alignRight
      // onMouseOver={onMouseEnter}
      // onMouseLeave={onMouseLeave}
      // show={dropDownOpen}
      onClick={toggleDropdown}
      title={
        <Fragment>
          <i className="fas fa-user-circle mr-2b"></i>
          <b>{user.name}</b>
        </Fragment>
      }
      id="basic-nav-dropdown"
    >
      <NavLink className="dropdown-item my-auto" to="/logout" onClick={onClose}>
        Logout
      </NavLink>
    </NavDropdown>
  );
};

export default UserMenu;
