export function linkToExternalSystem(orderId) {
  return `https://app.billbee.io/de/Order/OpenOrderInExtSystem?orderId=${orderId}`;
}

export function linkToBillbee(orderId) {
  return `https://app.billbee.io/app_v2/order?search=${orderId}`;
}

const linkUtils = {
  linkToExternalSystem,
  linkToBillbee
};

export default linkUtils;
