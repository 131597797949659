import React from "react";
import BootstrapButton from "react-bootstrap/Button";
import { createModal } from "react-modal-promise";
import SimplePromiseModal from "../common/SimplePromiseModal";

const PrintButton = ({ onPrint, compact = true, outline = false, color = "light" }) => {
  const showConfirmModal = createModal(SimplePromiseModal);

  const handlePrint = async () => {
    const result = await showConfirmModal({
      title: "Etikett drucken?",
      description: "Druckt ein Lageretikett in der Größe 5x3cm. Achte darauf, dass der passende Drucker gewählt ist.",
      confirmText: "Drucken"
      // secondaryAction: "Großes Label"
    });
    if (!result) return;
    result === "primary" ? onPrint() : onPrint("large");
  };

  return (
    <BootstrapButton
      variant={`${outline ? "outline-" : ""}${color ? color : "light"}`}
      className={`${compact ? "btn-sm " : ""}mr-2 px-3`}
      onClick={handlePrint}
    >
      <i className="fas fa-print"></i>
    </BootstrapButton>
  );
};

export default PrintButton;
