import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

// movies : object
// columns : object
class TableBodyStacked extends Component {
  state = {
    width: 0,
    height: 0,
  };

  constructor() {
    super();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  renderCell = (item, column) => {
    const { width } = this.state;
    if (column.content)
      return (
        <React.Fragment>
          {width < 768 ? <b>{column.label} </b> : ""}
          {column.content(item)}
        </React.Fragment>
      );
    return (
      <React.Fragment>
        {width < 768 ? <b>{column.label} </b> : ""}
        {_.get(item, column.path)}
      </React.Fragment>
    );
  };

  createKey = (itemId, column) => {
    return itemId + (column.path || column.key);
  };

  render() {
    const { getId, data, columns } = this.props;

    return data?.length ? (
      <tbody>
        {data.map(item => (
          <tr key={getId(item)}>
            {columns.map(column => (
              <td key={this.createKey(getId(item), column)} className="align-middle">
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td colSpan={columns.length} className="text-center">
            Keine Einträge gefunden
          </td>
        </tr>
      </tbody>
    );
  }
}

TableBodyStacked.propTypes = {
  getId: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

TableBodyStacked.defaultProps = {
  getId: item => item._id,
};

export default TableBodyStacked;
