import React, { Component, Fragment } from "react";
import TableStacked from "../common/TableStacked";
import TableSortBy from "../common/TableSortBy";
import SearchForm from "../common/SearchForm";
import dateUtils from "../../utils/dateUtils";
import PrintButton from "../warehouse/PrintButton";
import PropTypes from "prop-types";
import warehouseService from "../../services/warehouseService";
import Loader from "../common/Loader";
import { toast } from "react-toastify";
import { isSafeToClick } from "../../utils/helper";
import imageService from "../../services/imageService";

class RefurbishTable extends Component {
  columns = [
    {
      path: "product.imageObj.thumb",
      label: "",
      content: orderReturn => {
        return (
          <img
            src={
              this.props.imageDb
                ? imageService.getThumbUrl(orderReturn.product.sku, this.props.imageDb)
                : "https://www.formbench.com/upload/placeholder.png"
            }
            alt=""
            width="60"
          />
        );
      },
    },
    {
      path: "receive.date",
      label: "Annahme",
      content: orderReturn => {
        return dateUtils.formatDate(orderReturn.receive.date);
      },
    },
    {
      path: "receive.user.name",
      label: "Mitarbeiter",
      content: orderReturn => {
        return orderReturn.receive.user.name;
      },
    },
    {
      path: "product.sku",
      label: "Produkt",
      content: orderReturn => (
        <div className="row">
          <div className="col">
            <span>{orderReturn.product.name}</span>
            <br />
            <span className="text-muted">{orderReturn.product.sku}</span>
            <br />
            <span className="text-muted">Barcode: {orderReturn.barcode}</span>
          </div>
        </div>
      ),
    },
    { path: "product.count", label: "Anzahl" },
    {
      path: "order.orderNumber",
      label: "Bestellnummer",
      content: orderReturn => orderReturn.order.orderNumber,
    },
    { path: "order.customer.name", label: "Kunde" },
    {
      key: "refurbish",
      content: orderReturn => (
        <button onClick={() => this.props.onButtonClick(orderReturn)} className="btn btn-success btn-block">
          Zustand erfassen{isSafeToClick(true)}
        </button>
      ),
    },
    {
      path: "printLabel",
      key: "printLabel",
      content: orderReturn => (
        <PrintButton
          onPrint={() => {
            this.handlePrintLabel(orderReturn.barcode);
          }}
          description={`Soll das Label für die Rücksendung ${
            orderReturn && orderReturn.barcode ? '"' + orderReturn.barcode + '"' : ""
          } ausgedruckt werden?`}
          color="secondary"
          compact={false}
          outline={true}
        />
      ),
    },
    {
      key: "delete",
      content: orderReturn => (
        <button onClick={() => this.props.onDelete(orderReturn)} className="btn btn-danger">
          <i className="fas fa-trash-alt"></i>
          {isSafeToClick(true)}
        </button>
      ),
    },
  ];

  async handlePrintLabel(barcode) {
    await warehouseService.printReturnLabel(barcode);
    toast("Label wird gedruckt...");
  }

  render() {
    const { orderReturns, totalCount, onSort, onSearch, sortColumn, loading } = this.props;

    return (
      <Fragment>
        <div className="row justify-content-between align-items-end pb-4">
          <div className="col-12 col-lg-4 col-xl-6">
            <h1 className="mb-2">Empfangen und Ungeprüft</h1>
            <p className="mb-0">
              Folgende Artikel müssen noch geprüft werden. Klicke dafür auf "Zustand erfassen" oder scanne einen
              Retoure-Barcode.
            </p>
          </div>
          <div className="col col-auto py-2">{totalCount} Rücksendungen</div>
          <div className="col-12 col-lg-6 col-xl-4">
            <SearchForm placeholder="Suche..." onSubmit={onSearch} onReset={onSearch} submitLabel="Suchen" />
            <TableSortBy columns={this.columns} sortColumn={sortColumn} onSort={onSort} />
          </div>
        </div>
        <div className="row">
          {loading ? (
            <div className="py-3 mx-auto">
              <Loader message="Lade erfasste Retouren..." />
            </div>
          ) : (
            <TableStacked
              columns={this.columns}
              data={orderReturns}
              sortColumn={sortColumn}
              getId={item => item._id}
              onSort={onSort}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

RefurbishTable.propTypes = {
  orderReturns: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  sortColumn: PropTypes.object.isRequired,
};

export default RefurbishTable;
