import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Joi from "joi-browser";
import Form from "../common/Form";
import Product from "./Product";
import returnsService from "../../services/returnsService";
import Warning from "../common/Warning";
import PrintButton from "../warehouse/PrintButton";
import warehouseService from "../../services/warehouseService";
import { isSafeToClick } from "../../utils/helper";
import { toast } from "react-toastify";
import logService from "../../services/logService";

class RefurbishModal extends Form {
  _isMounted = false;

  state = {
    data: {
      result: "",
      notes: "",
    },
    timeout: null,
    errors: {},
  };

  schema = {
    result: Joi.string().required().label("Result"),
    notes: Joi.string().required().max(255).allow("").label("Notes"),
  };

  componentDidMount() {
    this._isMounted = true;
    this.timer = null;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timer);
    this.timer = null;
  }

  onHide = () => {
    this.props.onHide();
    setTimeout(this.resetState(), 500);
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  resetState = () => {
    this.setState({
      data: {
        result: "",
        notes: "",
      },
    });
  };

  doSubmit = async () => {
    //Call the server
    try {
      const { result, notes } = this.state.data;
      const { orderReturn } = this.props;
      const { notes: existingNotes } = orderReturn;

      const newNotes = `${existingNotes}, ${notes}`;

      await returnsService.saveInspectionResult(orderReturn, result, notes, newNotes);
      this.onHide();
    } catch (ex) {
      if (ex.response && ex.response.status < 500 && ex.response.status >= 400) {
        const errors = { ...this.state.errors };
        errors.notes = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  getBackgroundStyles = orderReturn => {
    const { inspection } = orderReturn;
    if (inspection && inspection.result === "stock")
      return { title: "Aufbereitet (Neuwertig)", classes: "bg-success text-white" };
    if (inspection && inspection.result === "stockUsed")
      return { title: "Aufbereitet (B-Ware)", classes: "bg-brown text-white" };

    if (inspection && inspection.result === "trash") return { title: "Entsorgt", classes: "bg-danger text-white" };

    return { title: "Zustand erfassen", classes: "" };
  };

  handlePrint = async () => {
    const { orderReturn } = this.props;
    const sku = orderReturn?.product?.sku;
    if (sku) await warehouseService.printProductLabel(sku);
    else {
      toast("Could not print label. SKU is undefined");
      logService.error("Failed to print label. props.orderreturn.product.sku undefined. Printing props", this.props);
    }
  };

  render() {
    const { notes } = this.state.data;
    const { orderReturn, onHide, onSubmit, show, onChangeProduct } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        onSubmit={onSubmit}
        size="md"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className={this.getBackgroundStyles(orderReturn).classes}>
          <Modal.Title id="contained-modal-title-vcenter">{this.getBackgroundStyles(orderReturn).title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Product data={orderReturn.product} />
          <p className="text-center">Retoure: {orderReturn.barcode}</p>
          <div className="form-row justify-content-center mb-4">
            <div className="col-6">
              <Button
                variant="outline-secondary"
                onClick={() => onChangeProduct(orderReturn._id)}
                style={{ width: "100%" }}
              >
                Produkt ändern{isSafeToClick(true)}
              </Button>
            </div>
            <div className="col-auto">
              <PrintButton
                onPrint={this.handlePrint}
                description={`Soll das Label für das Produkt ${
                  orderReturn && orderReturn.product
                    ? '"' + (orderReturn.product.name || orderReturn.product.sku) + '"'
                    : ""
                } ausgedruckt werden?`}
                outline={true}
                color="secondary"
                compact={false}
              />
            </div>
          </div>
          {orderReturn.notes ? <Warning text={`${orderReturn.notes}`} centered /> : ""}
          {this.renderRadioButtons(
            "result",
            [
              {
                label: "Neuwertig" + isSafeToClick(true),
                key: "stock",
                color: "success",
              },
              {
                label: "B-Ware" + isSafeToClick(true),
                key: "stockUsed",
                color: "brown",
              },
              {
                label: "Entsorgen" + isSafeToClick(true),
                key: "trash",
                color: "danger",
              },
            ],
            "line-height-normal"
          )}
          {this.renderTextarea("notes", "Kommentare", "Behobene Mängel sowie andere Besonderheiten", "textarea", notes)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onHide}>
            Abbrechen
          </Button>
          {this.renderButton(`Speichern ${isSafeToClick(true)}`, this.handleSubmit)}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RefurbishModal;
