import React, { useState, useEffect, Fragment } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import printerService from "../../services/printerService";

const PrinterMenu = ({ user }) => {
  const [currentPrinter, setCurrentPrinter] = useState({ name: "Drucker laden...", id: "" });
  const [printers, setPrinters] = useState([]);

  useEffect(() => {
    async function loadPrinters() {
      const result = await printerService.getListOfPrinters();
      setPrinters(result);
    }
    if (user && hasAccess(user)) loadPrinters();
  }, [user]);

  useEffect(() => {
    if (user && hasAccess(user)) loadCurrentPrinter();
  }, [user, printers]);

  async function loadCurrentPrinter() {
    const printer = await printerService.getCurrentPrinter();
    if (printer) setCurrentPrinter(printer);
  }

  function handleChangePrinter(id) {
    const printer = printers.find(p => parseInt(p.id) === parseInt(id));
    printerService.setCurrentPrinter(printer);
    loadCurrentPrinter();
  }

  function hasAccess(user) {
    return (
      user && (user.roles.indexOf("warehouse") !== -1 || user.roles.indexOf("Brun") !== -1 || user.isAdmin === true)
    );
  }

  return (
    user &&
    hasAccess(user) && (
      <DropdownButton
        as={ButtonGroup}
        title="Drucker"
        id="bg-nested-dropdown"
        variant="secondary"
        className="printerDropdown line-height-normal mr-3"
      >
        {printers.map(p => (
          <Dropdown.Item key={p.id} onClick={() => handleChangePrinter(p.id)}>
            {currentPrinter.id === p.id ? (
              <Fragment>
                <i className="fas fa-check-circle mr-2"></i>
                <b>{p.name}</b>
              </Fragment>
            ) : (
              <Fragment>
                <i className="far fa-circle mr-2"></i>
                {p.name}
              </Fragment>
            )}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    )
  );
};

export default PrinterMenu;
