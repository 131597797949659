import React, { Fragment } from "react";
import Form from "./Form";

class AutoSaveSelect extends Form {
  state = {
    errors: {},
  };
  schema = this.props.joiSchema;

  handleChange = async ({ currentTarget: input }) => {
    this.handleSave(input);
  };

  handleSave = async input => {
    const { onSave } = this.props;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (!errorMessage) {
      delete errors[input.name];
      await onSave(input.value);
    } else {
      errors[input.name] = errorMessage;
    }

    this.setState({ errors });
  };

  render() {
    const { name, label, chooseLabel, options, value } = this.props;

    return (
      <Fragment>
        {this.renderSelectPassive(name, label, value, chooseLabel || "Bitte auswählen...", options || [])}
      </Fragment>
    );
  }
}

export default AutoSaveSelect;
