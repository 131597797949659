import React from "react";
import TableHeader from "../common/TableHeader";
import PurchasingCollapsibleTableBody from "./PurchasingCollapsibleTableBody";
import PropTypes from "prop-types";
import Loader from "../common/Loader";

// columns: object
// sortColumn: object
// onSort: function
const PurchasingCollapsibleTable = ({
  sticky,
  columns,
  groupColumns,
  groupedData,
  getId,
  sortColumn,
  onSort,
  ...rest
}) => {
  return (
    <div {...rest}>
      {groupedData.length ? (
        <div className="table">
          <table className="table table-hover align-middle sticky">
            <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
            <PurchasingCollapsibleTableBody
              getId={getId}
              groupedData={groupedData}
              columns={columns}
              groupColumns={groupColumns}
            />
          </table>
        </div>
      ) : (
        <div
          style={{
            height: "80vh",
            display: "flex",
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
};

PurchasingCollapsibleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  groupedData: PropTypes.array.isRequired,
  getId: PropTypes.func.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
};

PurchasingCollapsibleTable.defaultProps = {
  getId: item => item._id,
};

export default PurchasingCollapsibleTable;
