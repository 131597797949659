import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import Button from "react-bootstrap/Button";

const TextboxPromiseModal = ({
  isOpen,
  onResolve,
  title,
  placeholder,
  description,
  spinner,
  confirmText,
  cancelText,
  buttonColor,
  onLoadDescription,
}) => {
  const [localDescription, setLocalDescription] = useState({ __html: description });
  const [localSpinner, setLocalSpinner] = useState(spinner);
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    if (onLoadDescription) {
      (async () => {
        const result = await onLoadDescription();
        setLocalDescription({ __html: result });
        setLocalSpinner(false);
      })();
    }
  }, [onLoadDescription]);

  function handleChange(e) {
    setTextValue(e.currentTarget.value);
  }

  return (
    <Modal
      show={isOpen}
      size="lg"
      backdrop="static"
      onHide={() => onResolve()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title || "Bist du sicher?"}</Modal.Title>
      </Modal.Header>
      {localDescription ? (
        <Modal.Body>
          {localSpinner ? (
            <div className="m-5 text-center">
              <Loader className="mb-2" />
            </div>
          ) : (
            <>
              <div dangerouslySetInnerHTML={localDescription || ""} className="mb-2"></div>
              <input
                className="form-control"
                placeholder={placeholder}
                type="text"
                value={textValue}
                onChange={handleChange}
              />
            </>
          )}
        </Modal.Body>
      ) : (
        ""
      )}
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onResolve()}>
          {cancelText || "Abbrechen"}
        </Button>
        <Button
          variant={buttonColor ? buttonColor : "primary"}
          onClick={textValue ? () => onResolve(textValue) : () => {}}
          disabled={!textValue}
        >
          {confirmText || "Ja"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TextboxPromiseModal;
