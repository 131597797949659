import React from "react";
import Joi from "joi";
import { isArray } from "lodash";

export const localizedStringSchema = Joi.object({
  lang: Joi.string().min(5).max(5),
  value: Joi.string().min(0).max(300).allow(""),
});

export function returnLocalizedString(data, lang) {
  if (!data || !isArray(data)) return;
  return data.find(d => d.lang === lang)?.value || data[0]?.value || "";
}

export function setLocalizedString(target, value, lang) {
  const existingString = target.find(t => t.lang === lang);
  if (existingString) existingString.value = value;
  else target = [...target, { lang, value }];
  return target;
}

export function getStringFromIsoValue(isoValue) {
  return `${isoValue.substr(0, 2)} (${isoValue.substr(3, 2)})`;
}

export const LocalizationContext = React.createContext({});
export const LocalizationProvider = LocalizationContext.Provider;
